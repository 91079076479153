import { DeviceType } from "@enums/deviceType.enum";
import { Model } from "@interfaces/model.interface";

export interface InterfaceDevice extends Model {
	dateCommissioned: Date;
	deviceType: DeviceType;
	machineId?: number;
	name: string;
}

export class InterfaceDevice implements InterfaceDevice {
	constructor(data?: Partial<InterfaceDevice>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}