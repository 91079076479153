import { Component, ViewEncapsulation } from "@angular/core";
import { environment } from "@environments/environment";
import notify from "devextreme/ui/notify";
import { RequestPasswordResetToken } from "@interfaces/requestPasswordResetToken.interface";
import { AuthService } from "@services/auth.service";
import { AppSettingsService } from "@services/appSettings.service";

@Component({
	encapsulation: ViewEncapsulation.None,
	moduleId: module.id,
	selector: "forgotPassword",
	styleUrls: ["forgotPassword.css"],
	templateUrl: "forgotPassword.html"
})
export class ForgotPasswordAdminComponent {
	companyName = "";
	copyright = "";
	requestDetails: RequestPasswordResetToken = new RequestPasswordResetToken();
	requestSuccess = false;
	validationErrors = {emailAddress: ""};	
	
	constructor(private appSettingsService: AppSettingsService, private authService: AuthService) {
		this.companyName = this.getEnvironmentProperty("companyLongName");
		this.copyright = "© Copyright " + this.companyName + " " + new Date().getFullYear();
	}

	getEnvironmentProperty(property: string) {
		// @ts-ignore // Required to get around having to iterate through the object
		return environment["build"][property];
	}

	submitData() {
		this.validationErrors = {emailAddress: ""};
		let validationFail = false;

		if (this.requestDetails.emailAddress === undefined || this.requestDetails.emailAddress === null || this.requestDetails.emailAddress === "") {
			this.validationErrors.emailAddress = "Email Address must not be blank.";
			validationFail = true;
		} else if(this.requestDetails.emailAddress.includes("@") == false){
			this.validationErrors.emailAddress = "You must enter a valid email address.";
			validationFail = true;
		}

		if (validationFail === true) { return; }

		this.appSettingsService.set("accessToken", null);
		this.appSettingsService.set("accessTokenExpiryTimeMilliseconds", 0);
		this.appSettingsService.set("groups", null);

		this.authService.requestPasswordResetToken(this.requestDetails)
			.subscribe(
				() => {
					this.requestSuccess = true;
				},
				(err) => {
					notify("Something went wrong!", "error", 5000);
					console.log(err);
				}
			);
	}

	windowWidthCheck() {
		return window.innerWidth >= 768;
	}
}