import { Component, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FirewallLogFailureReason } from "@enums/firewallLogFailureReason.enum";
import { ProductionStatus } from "@enums/productionStatus.enum";
import { environment } from "@environments/environment";
import { GetProductionStatusColor } from "@helpers/getProductionStatusColor";
import { GetPropertiesFromEnum } from "@helpers/getPropertiesFromEnum";
import { Allocation } from "@interfaces/allocation.interface";
import { CreateAllocationWorkOrder } from "@interfaces/createAllocationWorkOrder.interface";
import { FirewallLog } from "@interfaces/firewallLog.interface";
import { Machine } from "@interfaces/machine.interface";
import { Part } from "@interfaces/part.interface";
import { WorkOrder } from "@interfaces/workOrder.interface";
import { WorkOrderStage } from "@interfaces/workOrderStage.interface";
import { AllocationService } from "@services/allocation.service";
import { AllocationWorkOrderService } from "@services/allocationWorkOrder.service";
import { AppSettingsService } from "@services/appSettings.service";
import { EdiOrderService } from "@services/ediOrder.service";
import { FirewallLogService } from "@services/firewallLog.service";
import { InterfaceDeviceService } from "@services/interfaceDevice.service";
import { MachineService } from "@services/machine.service";
import { WorkOrderService } from "@services/workOrder.service";
import { WorkOrderStageService } from "@services/workOrderStage.service";
import { BarcodeFormat } from "@zxing/library";
import { ZXingScannerComponent } from "@zxing/ngx-scanner";
import notify from "devextreme/ui/notify";

@Component({
	encapsulation: ViewEncapsulation.None,
	moduleId: module.id,
	selector: "firewall",
	styleUrls: ["firewall.css"],
	templateUrl: "firewall.html"
})
export class FirewallComponent {
	allocationQty = 0;
	allocationWo = "";
	allowedFormats = BarcodeFormat.QR_CODE;
	areasOfIssue: any[] = [];
	availableDevices: MediaDeviceInfo[] = [];
	availableStages: WorkOrderStage[] = [];
	clientName = "";
	companyName = "";
	copyright = "";
	currentStatus = ProductionStatus.Idle;
	currentStatusColor = "";
	firewallLogs: FirewallLog[] = [];
	hasCameras = false;
	hasPermission = false;
	isLoading = false;
	machine = new Machine();
	machineId = 0;
	machines: any[] = [];
	nokReasons: any[] = [];
	operatorName = "";
	orderCurrent: any;
	ordersFuture: any[] = [];
	ordersToday: any[] = [];
	part = new Part();
	partImage = "";
	processName = "";
	profileId = 0;
	quantityComplete = 0;
	quantityEntered = 0;
	quantityNok = 0;
	quantityPicked = 0;
	quantityRequired = 0;
	runningStages: WorkOrderStage[] = [];
	scannedResult: any;
	scanner: ZXingScannerComponent = new ZXingScannerComponent();
	scannerEnabled = true;
	selectedArea = "";
	selectedDevice: any;
	selectedPaint = false;
	selectedProduction = false;
	selectedReason: any;
	showAllocate = false;
	showComplete = false;
	showFirewall = false;
	showMode = false;
	showNok = false;
	showPopup = false;
	showScan = false;
	showSuperLogin = false;
	showSupervisor = false;
	siteId = 0;
	stageId = 0;
	stageNumber = "";
	workOrder: WorkOrder = new WorkOrder;
	workOrderId = 0;
	workStation: any;

	constructor(private route: ActivatedRoute, private router: Router, private woService: WorkOrderService, private workOrderStageService: WorkOrderStageService, private machineService: MachineService, private appSettingsService: AppSettingsService, private firewallLogService: FirewallLogService, private allocationService: AllocationService, private allocatiionWorkOrderService: AllocationWorkOrderService, private interfaceService: InterfaceDeviceService, private ediOrderService: EdiOrderService) {
		this.getCameras();
		this.nokReasons = GetPropertiesFromEnum(FirewallLogFailureReason);
		this.areasOfIssue = [
			"CAD Issue",
			"Health and Safety",
			"Issue Found at Customer",
			"Maintenance Issue",
			"Person / Training Issue",
			"Process wrong",
			"Process - Assembly",
			"Process - 5 Axis Issue",
			"Process - Flat Laser issue",
			"Process - Goods-In",
			"Process - Press Issue",
			"Process - Press Brake issue",
			"Process - Weld Issue",
			"Process - Spot Weld Issue",
			"Process - Kingsland Issue",
			"Process - Fab/Deburr Issue",
			"Process - Firewall Issue",
			"Process - Logistics issue",
			"Process - Quality Issue",
			"Process - Toolroom",
			"Supplier - Material Issue",
			"Supplier - Outsource Issue",
		];
		this.currentStatusColor = GetProductionStatusColor(ProductionStatus.Idle);
		this.route.params
			.subscribe((params) => {
				this.siteId = Number.parseInt(params.id);
				this.getInterface();
				this.isLoading = true;
				this.showPopup = true;
				// this.getData();
				setTimeout(() => {
					this.isLoading = false;
					this.showPopup = false;
				}, 2000);
			});
	}

	assignToAllocations(workOrder: string, qty: number) {
		const createLink = new CreateAllocationWorkOrder();
		createLink.allocationId = this.orderCurrent.allocationId;
		createLink.workOrderId = workOrder;
		createLink.quantity = qty;
		this.quantityPicked = this.quantityPicked + qty;

		this.allocatiionWorkOrderService.app_CreateForWorkOrder(createLink)
			.subscribe(
				() => {
					notify("Work Order Allocated", "success", 5000);
					this.toggleAllocate();
					this.toggleComplete();
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				},
			);
		return false;

	}

	cameraFound(e: any) {
		// Log to see if the camera was found
		console.log(e);
		this.availableDevices = e;
		this.selectedDevice = e[0];
		this.hasCameras = true;
		this.hasPermission = true;
		console.log("Devices: ", this.availableDevices);

		// selects the devices's back camera by default'
		for (const device of e) {
			if (/back|rear|environment/gi.test(device.label)) {
				new this.scanner.deviceChange(true);
				this.selectedDevice = device;
				break;
			} else if (e.length == 1) {
				new this.scanner.deviceChange(true);
				this.selectedDevice = device;
				break;
			}
		}
	}

	camerasNotFound() {
		// Display an alert modal here
	}

	changeMode(status: ProductionStatus) {
		this.submitProductionMode(status);
		this.toggleMode();
	}

	createAllocationForEdiOrder(id: number) {
		// if (confirm("Create allocation for this Edi Order? This can be assigned work orders later.") === false) {
		// 	return false;
		// }

		// Do the actual creation here		
		this.allocationService.app_createForEdiOrder(id)
			.subscribe(
				() => {
					notify("Allocation Created", "success", 5000);
					this.reloadPage();
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				},
			);
		return false;
	}

	getAllocation() {
		this.allocationService.app_getById(this.orderCurrent.allocationId)
			.subscribe(
				(res: any) => {
					const allo: Allocation = res.response;
					this.quantityPicked = allo.totalAssigned;
				},
				(err) => {
					console.log(err);
					notify("Something Went Wrong!", "Error", 5000);
				}
			);
	}

	getCameras() {
		console.log("Hit!");
	}

	getData(id: number) {
		this.orderCurrent = undefined;
		this.ordersFuture = [];
		this.ordersToday = [];
		this.machineId = 0;
		this.operatorName = "";
		

		console.log(id);
		this.machineService.app_OpsPlannerEdiOrders()
			.subscribe(
				(res: any) => {
					if (res.response.length > 1) {
						this.machines = res.response;
						this.workStation = this.machines.find(x => x.machineId === id);
						this.machineId = id;
						this.ordersToday = this.workStation.todayEdiOrders;
						this.ordersFuture = this.workStation.futureEdiOrders;
						if (this.ordersToday.length > 0) {
							this.orderCurrent = this.ordersToday[0];
						}
						console.log("Today");
						console.log(this.ordersToday);
						console.log("Future");
						console.log(this.ordersFuture);
						console.log("Current");
						console.log(this.orderCurrent);

						this.machine = res.response[0];
						this.machineId = this.workStation.machineId;
						this.currentStatus = this.workStation.productionStatus;
						this.currentStatusColor = this.renderStatusColor();

						this.appSettingsService.set("profileId", this.orderCurrent.operatorId);
						this.profileId = this.orderCurrent.operatorId;

						if (this.orderCurrent.allocationId > 0) {
							this.getAllocation();
						}
					}
				},
				(err) => {
					console.log(err);
					notify("Something Went Wrong!", "Error", 5000);
				}
			);
	}

	getEnvironmentProperty(property: string) {
		// @ts-ignore // Required to get around having to iterate through the object
		return environment["build"][property];
	}

	getInterface() {
		this.interfaceService.app_InterfaceById(this.siteId)
			.subscribe(
				(res: any) => {
					console.log(res);
					this.machineId = res.response.machineId;
					this.getData(res.response.machineId);
				},
				(err) => {
					console.log(err);
					notify("Something Went Wrong!", "Error", 5000);
				}
			);
	}

	getWo(scannedResult: any) {
		this.woService.appGetAllDetails(scannedResult)
			.subscribe(
				(res: any) => {
					console.log(res);
					this.workOrder = res.response;
					this.allocationWo = res.response.id;

					notify("Work Order Loaded Successfully", "Success", 5000);
				},
				(err) => {
					console.log(err);
					notify("Something Went Wrong!", "Error", 5000);
				}
			);
	}

	increaseScrap() {
		this.workOrderStageService.increaseScrapAmount(this.availableStages[0].id, this.quantityNok)
			.subscribe(
				() => {
					// notify("Stage Paused due to incomplete quantity", "success", 5000);
					this.reloadPage();
				},
				(err) => {
					notify("Something went wrong!", "error", 5000);
					console.log(err);
				}
			);
	}

	onDeviceSelectChange() {
		console.log("Selection changed: ", this.selectedDevice.label);
		// this.selectedDevice = this.scanner.device!;
	}

	onScanSuccess(result: string) {
		console.log(result);
		this.scannedResult = result;
		this.getWo(result);
		this.toggleScan();
	}

	pauseWorkOrder() {
		this.workOrderStageService.app_togglePause(this.availableStages[0].id)
			.subscribe(
				() => {
					notify("Stage Paused due to incomplete quantity", "success", 5000);
					this.reloadPage();
				},
				(err) => {
					notify("Something went wrong!", "error", 5000);
					console.log(err);
				}
			);
	}

	reloadPage() {
		this.isLoading = true;
		this.showPopup = true;
		this.getInterface();
		setTimeout(() => {
			this.isLoading = false;
			this.showPopup = false;
		}, 2000);
	}

	renderStatusColor() {
		return GetProductionStatusColor(this.currentStatus);
	}

	setToPicked() {
		this.ediOrderService.app_SetAsPicked(this.orderCurrent.id)
			.subscribe(
				() => {
					notify("Firewall Complete!", "success", 5000);
					setTimeout(() => {
						this.getInterface();
					}, 2000);
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				},
			);
	}

	submitArea() {
		console.log(this.selectedArea);
	}

	submitCompleted() {
		const firewall = true;
		this.toggleComplete();
		this.isLoading = true;
		this.showPopup = true;

		this.quantityEntered = this.quantityEntered + this.quantityNok;

		this.workOrderStageService.completedQuantityApiKey(this.availableStages[0].id, this.quantityEntered - this.quantityComplete)
			.subscribe(
				() => {
					notify("Part Complete Submitted Successfully!", "success", 5000);
					if (firewall) {
						this.showFirewall = false;
						this.showNok = false;
						if ((this.quantityComplete + this.quantityNok) != this.quantityRequired) {
							//Pause Work Order.
							this.pauseWorkOrder();
						}
						if (this.quantityNok > 0) {
							this.increaseScrap();
						}
					}
					this.reloadPage();
				},
				(err) => {
					notify("Something went wrong!", "error", 5000);
					console.log(err);
				}
			);
	}

	submitFirewall() {
		if (this.quantityNok > 0) {
			this.showFirewall = false;
			this.showNok = true;
		} else {
			const log = new FirewallLog();
			log.numberOfPartsChecked = this.quantityEntered;
			log.numberOfPartsFailed = this.quantityNok;
			log.operatorId = this.profileId;
			log.partId = this.part.id;
			log.allocationId = this.orderCurrent.allocationId;
			this.firewallLogs.push(log);
			//this.submitFirewallLogService(log);
			this.showFirewall = false;
			this.showPopup = false;
			this.getInterface();

		}
	}

	submitFirewallLog() {
		// Create log.
		const log = new FirewallLog();
		log.areaOfIssue = this.selectedArea;
		log.failureReason = this.selectedReason;
		log.numberOfPartsChecked = this.quantityEntered;
		log.numberOfPartsFailed = this.quantityNok;
		log.numberOfPartsReworkable = 0;
		log.isProduction = this.selectedProduction == true ? true : false;
		log.prePaint = this.selectedPaint == true ? true : false;
		log.asnNumber = "";
		log.operatorId = this.profileId;
		log.partId = this.part.id;

		// this.firewallLogs.push(log);

		this.showNok = false;
		this.showPopup = false;

		this.submitFirewallLogService(log);

	}

	submitFirewallLogService(log: any) {

		this.firewallLogService.appCreateFirewallLog(log)
			.subscribe(
				() => {

					this.showPopup = false;
					this.showNok = false;
					this.showFirewall = false;
					this.submitCompleted();
					this.getInterface();
				},
				(err) => {
					notify("Something went wrong!", "error", 5000);
					console.log(err);
				}
			);
	}

	submitIsProduction() {
		console.log(this.selectedProduction);
	}



	submitPaint() {
		console.log(this.selectedPaint);
	}

	submitProductionMode(prodStatus: ProductionStatus) {
		this.machineService.updateProductionStatus(this.machineId, prodStatus)
			.subscribe(
				() => {
					notify("Production Mode Changed Successfully!", "success", 5000);
					this.reloadPage();
				},
				(err) => {
					notify("Something went wrong!", "error", 5000);
					console.log(err);
				}
			);
	}

	submitReason() {
		console.log(this.selectedReason);
	}

	submitStillageRequest() {
		this.machineService.requestStillageForStage(this.stageId)
			.subscribe(
				() => {
					notify("Request Submitted Successfully!", "success", 10000);
				},
				(err) => {
					notify("Something went wrong!", "error", 5000);
					console.log(err);
				}
			);
		this.getInterface();
	}

	submitSupervisorRequest() {
		this.machineService.requestSupervisorForStage(this.stageId)
			.subscribe(
				() => {
					notify("Request Submitted Successfully!", "success", 5000);
				},
				(err) => {
					notify("Something went wrong!", "error", 5000);
					console.log(err);
				}
			);
		this.getInterface();
	}

	/* Toggles */
	toggleAllocate() {
		this.showAllocate = !this.showAllocate;
		this.showPopup = !this.showPopup;
	}

	toggleComplete() {
		this.showFirewall = !this.showFirewall;
		this.showPopup = !this.showPopup;
	}

	toggleMode() {
		this.showMode = !this.showMode;
		this.showPopup = !this.showPopup;
	}

	toggleScan() {
		this.showScan = !this.showScan;
		this.showPopup = !this.showPopup;
	}

	toggleSupervisor() {
		if (!this.showSupervisor) {
			this.submitSupervisorRequest();
		}
		this.showSupervisor = !this.showSupervisor;
		this.showPopup = !this.showPopup;
	}

	updateNokQuantity() {
		this.quantityNok = this.quantityRequired - this.quantityEntered;
	}

	windowWidthCheck() {
		return window.innerWidth >= 768;
	}

}