import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { Client } from "@interfaces/client.interface";
import { FirewallLog } from "@interfaces/firewallLog.interface";
import { LogExport } from "@interfaces/logExport.interface";
import { Part } from "@interfaces/part.interface";
import { Project } from "@interfaces/project.interface";
import { UserProfile } from "@interfaces/userProfile.interface";
import { ClientService } from "@services/client.service";
import { ExportLogService } from "@services/exportLog.service";
import { FirewallLogService } from "@services/firewallLog.service";
import { PartService } from "@services/part.service";
import { ProjectService } from "@services/project.service";
import { UserProfileService } from "@services/userProfile.service";
import notify from "devextreme/ui/notify";

@Component({
	moduleId: module.id,
	selector: "firewallLogs",
	styleUrls: ["firewallLogs.css"],
	templateUrl: "firewallLogs.html"
})
export class FirewallLogsAdminComponent {
	clients: Client[] = [];
	gridLoading = false;
	headerPrimaryButtons: AdminHeaderButton[] = [];
	itemType = "Firewall Log"
	itemUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	items: FirewallLog[] = [];
	parts: Part[] = [];
	projects: Project[] = [];
	title: string;
	userProfiles: UserProfile[] = [];
	
	constructor(private router: Router, private itemService: FirewallLogService, private clientService: ClientService, private exportLogService: ExportLogService, private partService: PartService, private projectService: ProjectService, private userProfileService: UserProfileService) {
		this.headerPrimaryButtons.push({ icon: "fal fa-plus", method: "addNewItem", text: `New ${this.itemType}` });
		this.title = "Firewall Logs";
		this.viewRow = this.viewRow.bind(this);
		this.deleteRow = this.deleteRow.bind(this);
		this.getClients();
		this.getItems();
		this.getParts();
		this.getProjects();
		this.getUserProfiles();
	}

	addNewItem() {
		this.router.navigate([`${this.itemUrl}/create`]);
	}
	
	deleteRow(e: any) {
		const result = confirm("Are you sure you want to delete this firewall log?");
		if(result)
		{
			this.itemService.deleteSingleById(e.row.data.id)
				.subscribe(
					() => {
						notify("Delivery note deleted!", "success", 5000);
						this.getItems();
					},
					(err) => {
						console.log(err);
						notify("Something went wrong!", "error", 5000);
					},
				);
		}
	}
	
	getClients() {
		this.clientService.getAll()
			.subscribe(
				(res: any) =>  {
					this.clients = res.response.items;
				},
				(err) => console.log(err)
			);
	}

	getItems() {
		this.gridLoading = true;
		this.itemService.getAll()
			.subscribe(
				(res: any) => {
					this.items = res.response.items;
					this.gridLoading = false;
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				},
			);
	}
	
	getParts() {
		this.partService.getAll()
			.subscribe(
				(res: any) =>  {
					this.parts = res.response.items;
				},
				(err) => console.log(err)
			);
	}
	
	getProjects() {
		this.projectService.getAll()
			.subscribe(
				(res: any) =>  {
					this.projects = res.response.items;
				},
				(err) => console.log(err)
			);
	}
	
	getUserProfiles() {
		this.userProfileService.getActiveUsers()
			.subscribe(
				(res: any) =>  {
					this.userProfiles = res.response;
					this.userProfiles.sort((a,b) => a.fullName.localeCompare(b.fullName));
				},
				(err) => console.log(err)
			);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	logExport() {
		const logExport = new LogExport();
		logExport.gridName = "Firewall Log";
		this.exportLogService.logExport(logExport)
			.subscribe(
				() => console.log("Export Logged"),
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
					return false;
				},
			);
	}

	viewRow(e: any) {
		this.router.navigate([`${this.itemUrl}/${e.row.data.lookupId}`]);
	}
}