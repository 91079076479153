import { Injectable, Injector } from "@angular/core";
import { environment } from "@environments/environment";
import { CreateAllocationWorkOrder } from "@interfaces/createAllocationWorkOrder.interface";
import { Service } from "@services/service";

@Injectable()
export class AllocationWorkOrderService extends Service {
	constructor(injector: Injector) {
		super("AllocationWorkOrder", injector);
	}

	// API
	app_CreateForWorkOrder(data: CreateAllocationWorkOrder) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/App_CreateForWorkOrder`, data);
	}

	createForWorkOrder(data: CreateAllocationWorkOrder) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/CreateForWorkOrder`, data);
	}

	forAllocation(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/ForAllocation/${id}`);
	}

	forWorkOrder(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/ForWorkOrder/${id}`);
	}
}