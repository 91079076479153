export interface ChangeEdiOrderAssignment {
	assignedId?: number;
	ediOrderId: number;
}

export class ChangeEdiOrderAssignment implements ChangeEdiOrderAssignment {	
	constructor(data?: Partial<ChangeEdiOrderAssignment>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}