<admin-header [primaryButtons]="headerPrimaryButtons" [secondaryButtons]="headerSecondaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<dx-data-grid id="grid" class="data-grid" [dataSource]="items" [remoteOperations]="false" [allowColumnReordering]="true" [allowColumnResizing]="true" [rowAlternationEnabled]="true" [showBorders]="true" (onExporting)="logExport()">
    <dxo-paging [pageSize]="100"></dxo-paging>
	<dxo-state-storing [enabled]="true" type="localStorage" storageKey="purchaseItemMain"></dxo-state-storing>
    <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
    <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
	<dxo-export [enabled]="true"></dxo-export>
	<dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>
	<dxi-column type="buttons">
        <dxi-button hint="View" icon="fal fa-eye" [onClick]="viewRow"></dxi-button>
		<dxi-button *ngIf="editEnabled" hint="Edit" icon="fal fa-pencil" [onClick]="editRow"></dxi-button>
		<dxi-button *ngIf="deleteEnabled" icon="fal fa-trash" [onClick]="deleteRow"></dxi-button>
    </dxi-column>
    <dxi-column dataField="id" dataType="number" alignment="left"></dxi-column>
    <dxi-column dataField="name" dataType="string" alignment="left"></dxi-column>
	<dxi-column dataField="purchaseType" dataType="string" alignment="left">
		<dxo-lookup [dataSource]="purchaseTypes" valueExpr="id" displayExpr="name">
	</dxo-lookup>
	</dxi-column>
	<dxi-column dataField="materialType" dataType="string" alignment="left">
		<dxo-lookup [dataSource]="materialTypes" valueExpr="id" displayExpr="name">
	</dxo-lookup>
	</dxi-column>
</dx-data-grid>

<dx-popup [width]="750" [height]="600" [showTitle]="true" [title]="popupTitle" [dragEnabled]="false" [closeOnOutsideClick]="false" [showCloseButton]="false" container=".dx-viewport" [(visible)]="popupVisible">
	<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="before" [options]="popupAddButtonOptions"></dxi-toolbar-item>
	<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="after" [options]="popupCloseButtonOptions"></dxi-toolbar-item>
		<div *ngIf="popupTitle === 'Import CSV'">
			<dx-form class="m-3" [(formData)]="popupImportDetails" [showColonAfterLabel]="true" labelLocation="top">
				<dxi-item>
					<dx-file-uploader [maxFileSize]="2097152" [uploadFile]="uploadCSVForImport"></dx-file-uploader>
				</dxi-item>
				<dxi-item>
					<p *ngIf="validationErrors.length > 0">Please fix the below issues and re-upload:</p>
					<ul *ngIf="validationErrors.length > 0" style="overflow-y: scroll; height: 120px;">
						<li *ngFor="let validationError of validationErrors" class="text-danger">{{validationError}}</li>
					</ul>
					<p>&nbsp;</p>
				</dxi-item>
			</dx-form>
		</div>
</dx-popup>
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#grid' }" [(visible)]="gridLoading" [showIndicator]="true" [showPane]="true" [shading]="true"></dx-load-panel>