import { Department } from "@enums/department";
import { ProductionStatus } from "@enums/productionStatus.enum";
import { Model } from "@interfaces/model.interface";

export interface Machine extends Model {
	cellNumber: string;
	department: Department;
	depth: number
	documents: string;
	fridayDayShiftEndTime?: number;
	fridayDayShiftStartTime?: number;
	fridayNightShiftEndTime?: number;
	fridayNightShiftStartTime?: number;
	manufacturer: string;
	mondayDayShiftEndTime?: number;
	mondayDayShiftStartTime?: number;
	mondayNightShiftEndTime?: number;
	mondayNightShiftStartTime?: number;
	name: string;
	productionStatus: ProductionStatus;
	qualityLoopDelay: number;
	rotationAmount: number;
	saturdayDayShiftEndTime?: number;
	saturdayDayShiftStartTime?: number;
	saturdayNightShiftEndTime?: number;
	saturdayNightShiftStartTime?: number;
	setUpCost: number;
	siteId: number;
	sundayDayShiftEndTime?: number;
	sundayDayShiftStartTime?: number;
	sundayNightShiftEndTime?: number;
	sundayNightShiftStartTime?: number;
	thursdayDayShiftEndTime?: number;
	thursdayDayShiftStartTime?: number;
	thursdayNightShiftEndTime?: number;
	thursdayNightShiftStartTime?: number;
	tonnage: string;
	tuesdayDayShiftEndTime?: number;
	tuesdayDayShiftStartTime?: number;
	tuesdayNightShiftEndTime?: number;
	tuesdayNightShiftStartTime?: number;
	wednesdayDayShiftEndTime?: number;
	wednesdayDayShiftStartTime?: number;
	wednesdayNightShiftEndTime?: number;
	wednesdayNightShiftStartTime?: number;
	width: number;
	xPosition: number;
	yPosition: number;
}

export class Machine implements Machine {
	cellNumber = "";
	department = Department.BackOffice;
	depth = 0;
	documents = "[]";
	manufacturer = "";
	name = "";
	productionStatus = ProductionStatus.Idle;
	qualityLoopDelay = 0;
	rotation = 0;
	siteId = 1;
	width = 0;
	xPosition = 0;
	yPosition = 0;

	constructor(data?: Partial<Machine>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}