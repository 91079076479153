<admin-header [primaryButtons]="headerPrimaryButtons" [secondaryButtons]="headerSecondaryButtons" [tertiaryButtons]="headerTertiaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<div class="form-container">
	<dx-form class="m-3" [(formData)]="item" [showColonAfterLabel]="true" labelLocation="top" [readOnly]="readOnly" (onFieldDataChanged)="formDataChanged($event)">
		<dxi-item itemType="tabbed" [tabPanelOptions]="{hoverStateEnabled:false}">
			<dxo-tab-panel-options [deferRendering]="false"></dxo-tab-panel-options>
			<dxi-tab title="Details" [colCount]="6">
				<dxi-item [colSpan]="3" dataField="partNumber"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
				<dxi-item [colSpan]="3" dataField="description" [label]="{ text: 'Part Description' }"></dxi-item>
				<dxi-item [colSpan]="3" dataField="clientId" editorType="dxSelectBox" [editorOptions]="{ items: clients, searchEnabled: true, displayExpr:'name', valueExpr:'id' }" [label]="{ text: 'Client' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>	
				<dxi-item [colSpan]="3" dataField="projectId" editorType="dxSelectBox" [editorOptions]="{ items: projects, searchEnabled: true, displayExpr:'name', valueExpr:'id' }" [label]="{ text: 'Project' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>	
				<dxi-item [colSpan]="3" dataField="clientContactId" editorType="dxSelectBox" [editorOptions]="{ items: contacts, searchEnabled: true, displayExpr: 'displayName', valueExpr:'id' }" [label]="{ text: 'Purchasing Contact' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>	
				<dxi-item [colSpan]="3" dataField="engineeringContactId" editorType="dxSelectBox" [editorOptions]="{ items: contacts, searchEnabled: true, displayExpr: 'displayName', valueExpr:'id' }" [label]="{ text: 'Engineering Contact' }"></dxi-item>	
				<dxi-item [colSpan]="3" dataField="projectManagerId" editorType="dxSelectBox" [editorOptions]="{ items: people, searchEnabled: true, displayExpr:'fullName', valueExpr:'id' }" [label]="{ text: 'Project Manager' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>	
				<dxi-item [colSpan]="3" dataField="materialId" editorType="dxSelectBox" [editorOptions]="{ items: materialPurchasableItems, searchEnabled: true, displayExpr:'name', valueExpr:'id' }" [label]="{ text: 'Material' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>	
				<dxi-item [colSpan]="3" dataField="gauge" editorType="dxSelectBox" [editorOptions]="{ items: thicknesses, searchEnabled: false }" [label]="{ text: 'Gauge' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
				<dxi-item [colSpan]="3" dataField="weight" editorType="dxNumberBox" [label]="{ text: 'Weight (Kg)' }"></dxi-item>
				<dxi-item [colSpan]="3" dataField="materialShape" editorType="dxSelectBox" [editorOptions]="{ items: materialShapes, searchEnabled: true, displayExpr:'name', valueExpr:'id' }" [label]="{ text: 'Shape' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>	
				<dxi-item [colSpan]="3" dataField="pitch" editorType="dxNumberBox" [label]="{ text: 'Pitch (for Coils)' }"></dxi-item>
				<dxi-item [colSpan]="2" dataField="blankLength" editorType="dxNumberBox" [label]="{ text: 'Blank Length (mm)' }" [editorOptions]="{ format : '#0'}"></dxi-item>
				<dxi-item [colSpan]="2" dataField="blankWidth" editorType="dxNumberBox" [label]="{ text: 'Blank Width (mm)' }" [editorOptions]="{ format : '#0'}"></dxi-item>
				<dxi-item [colSpan]="2" dataField="blankDimensionsConfirmed"></dxi-item>
				<dxi-item [colSpan]="3" dataField="alertNumberIPSW" [label]="{ text: 'Alert Number iPSW' }"></dxi-item>
				<dxi-item [colSpan]="3" dataField="eclNumber" [label]="{ text: 'ECL Number' }"></dxi-item>
				<dxi-item [colSpan]="3" dataField="revisionLevel" editorType="dxNumberBox" [label]="{ text: 'Revision Level' }" [editorOptions]="{ format : '#0'}"></dxi-item>
				<dxi-item [colSpan]="3" dataField="bplVersion" editorType="dxNumberBox" [label]="{ text: 'BPL Version' }" [editorOptions]="{ format : '#0'}"></dxi-item>
				<dxi-item [colSpan]="3" dataField="stockLeadTime" editorType="dxNumberBox" [label]="{ text: 'Stock Lead Time' }"></dxi-item>
				<dxi-item [colSpan]="3" dataField="needsCompleting"></dxi-item>
				<dxi-item [colSpan]="2" dataField="cumulativeProductionDelivery" [label]="{ text: 'Cumulative Quantity' }"></dxi-item>
				<dxi-item [colSpan]="2" dataField="productionPrice" editorType="dxNumberBox" [label]="{ text: 'Production Price' }" [editorOptions]="{format: {type:'currency', currency:'GBP', precision: 4} }"></dxi-item>
				<dxi-item [colSpan]="2" dataField="deliveryArrears" [label]="{ text: 'Delivery Arrears' }"></dxi-item>
			</dxi-tab>
			<dxi-tab title="Tile Image" [colCount]="2">
				<dxi-item>
					<div class="tileImage" [style]="tileImageStyle"></div>
				</dxi-item>
				<dxi-item>
					<dx-file-uploader [visible]="screenMode != 'view'" [showFileList]="false" [maxFileSize]="2097152" [uploadFile]="uploadTileImage" accept="image/*" [allowedFileExtensions]="['.gif', '.jpg', '.jpeg', '.png']"></dx-file-uploader>
				</dxi-item>
			</dxi-tab>
			<dxi-tab title="Production Requirements" [colCount]="8">
				<dxi-item [colSpan]="2" dataField="batchQuantity" editorType="dxNumberBox" [label]="{ text: 'Batch Quantity' }" [editorOptions]="{format: {type:'fixedPoint', precision: 0, min: 0} }"></dxi-item>
				<dxi-item [colSpan]="2" dataField="minimumStockLevel" editorType="dxNumberBox" [label]="{ text: 'Min Stock Level' }" [editorOptions]="{format: {type:'fixedPoint', precision: 0, min: 0} }"></dxi-item>
				<dxi-item [colSpan]="2" dataField="maximumStockLevel" editorType="dxNumberBox" [label]="{ text: 'Max Stock Level' }" [editorOptions]="{format: {type:'fixedPoint', precision: 0, min: 0} }"></dxi-item>
				<dxi-item [colSpan]="2" dataField="stockThreshold" editorType="dxNumberBox" [label]="{ text: 'Stock Threshold %' }" [editorOptions]="{format: {type:'fixedPoint', precision: 0, max: 100, min: 0} }"></dxi-item>
				<dxi-item [colSpan]="2" dataField="availableStock" editorType="dxNumberBox" [label]="{ text: 'Available Stock' }" [editorOptions]="{format: {type:'fixedPoint', precision: 0, min: 0} }"></dxi-item>
				<dxi-item [colSpan]="2" dataField="wipStock" editorType="dxNumberBox" [label]="{ text: 'WIP Stock' }" [editorOptions]="{format: {type:'fixedPoint', precision: 0, min: 0} }"></dxi-item>
				<dxi-item [colSpan]="2" dataField="backfillStock" editorType="dxNumberBox" [label]="{ text: 'Backfill Stock' }" [editorOptions]="{format: {type:'fixedPoint', precision: 0, min: 0} }"></dxi-item>
			</dxi-tab>
			<dxi-tab title="Part Relationships" [colCount]="2">
				<dxi-item itemType="group" caption="Master Parts" [colSpan]="1" [colCount]="2">
					<dx-button stylingMode="contained" text="Add New Master" type="default" [width]="240" (onClick)="addNewRelationshipMasterPopup()"></dx-button>
					<dxi-item>
						<dx-data-grid class="data-grid" [dataSource]="masterParts" [remoteOperations]="false" [allowColumnReordering]="false" [rowAlternationEnabled]="true" [showBorders]="true" height="200px">
							<dxo-paging [pageSize]="100"></dxo-paging>
							<dxo-state-storing [enabled]="true" type="localStorage" storageKey="partsMaster"></dxo-state-storing>
							<dxo-pager [visible]="false" [showPageSizeSelector]="false" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
							<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
							<dxo-group-panel [visible]="false"></dxo-group-panel>
							<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
							<dxo-export [enabled]="false"></dxo-export>
							<dxi-column dataField="masterPart.partNumber" dataType="text" alignment="left" caption="Part Number"></dxi-column>
							<dxi-column dataField="quantityRequired" dataType="text" alignment="left" caption="Quantity"></dxi-column>
							<dxi-column type="buttons">
								<dxi-button hint="View" icon="fal fa-eye" [onClick]="viewMasterPart"></dxi-button>
								<dxi-button [visible]="screenMode != 'view'" hint="Delete" icon="fal fa-trash" [onClick]="deletePartRelationship"></dxi-button>
							</dxi-column>
						</dx-data-grid>
					</dxi-item>
				</dxi-item>
				<dxi-item itemType="group" caption="Child Parts" [colSpan]="1" [colCount]="2">
					<dx-button stylingMode="contained" text="Add New Child" type="default" [width]="240" (onClick)="addNewRelationshipChildPopup()"></dx-button>
					<dxi-item>
						<dx-data-grid class="data-grid" [dataSource]="subParts" [remoteOperations]="false" [allowColumnReordering]="false" [rowAlternationEnabled]="true" [showBorders]="true" height="200px">
							<dxo-paging [pageSize]="100"></dxo-paging>
							<dxo-state-storing [enabled]="true" type="localStorage" storageKey="partsSub"></dxo-state-storing>
							<dxo-pager [visible]="false" [showPageSizeSelector]="false" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
							<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
							<dxo-group-panel [visible]="false"></dxo-group-panel>
							<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
							<dxo-export [enabled]="false"></dxo-export>
							<dxi-column dataField="childPart.partNumber" dataType="text" alignment="left" caption="Part Number"></dxi-column>
							<dxi-column dataField="quantityRequired" dataType="text" alignment="left" caption="Quantity"></dxi-column>
							<dxi-column type="buttons">
								<dxi-button hint="View" icon="fal fa-eye" [onClick]="viewSubPart"></dxi-button>
								<dxi-button [visible]="screenMode != 'view'" hint="Delete" icon="fal fa-trash" [onClick]="deletePartRelationship"></dxi-button>
							</dxi-column>
						</dx-data-grid>
					</dxi-item>
				</dxi-item>
			</dxi-tab>
			<dxi-tab title="Packaging">
				<dxi-item itemType="group" caption="Packaging - Internal" [colCount]="2">
					<dxi-item dataField="packagingId" editorType="dxSelectBox" [editorOptions]="{ items: packaging, searchEnabled: true, displayExpr:'name', valueExpr:'id' }" [label]="{ text: 'Packaging' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>	
					<dxi-item dataField="packFactor" [label]="{ text: 'Pack Factor' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
					<dxi-item dataField="packagingSpecification" [label]="{ text: 'Specification' }" editorType="dxTextArea" [editorOptions]="{ height: 150 }"></dxi-item>
					<dxi-item>
						<p>Packing Image Preview</p>				
						<div class="packingImage" [style]="packagingImageStyle"></div>
						<dx-file-uploader [visible]="screenMode != 'view'" [showFileList]="false" [maxFileSize]="2097152" [uploadFile]="uploadPackingImage" accept="image/*" [allowedFileExtensions]="['.gif', '.jpg', '.jpeg', '.png']"></dx-file-uploader>
					</dxi-item>
				</dxi-item>
				<dxi-item itemType="group" caption="Packaging - External" [colCount]="2">
					<dxi-item dataField="packagingExternalId" editorType="dxSelectBox" [editorOptions]="{ items: packaging, searchEnabled: true, displayExpr:'name', valueExpr:'id' }" [label]="{ text: 'Packaging' }"></dxi-item>	
					<dxi-item dataField="packFactorExternal" [label]="{ text: 'Pack Factor' }"></dxi-item>
					<dxi-item dataField="packagingSpecificationExternal" [label]="{ text: 'Specification' }" editorType="dxTextArea" [editorOptions]="{ height: 150 }"></dxi-item>
					<dxi-item>
						<p>Packing Image Preview</p>				
						<div class="packingImage" [style]="packagingImageStyleExternal"></div>
						<dx-file-uploader [visible]="screenMode != 'view'" [showFileList]="false" [maxFileSize]="2097152" [uploadFile]="uploadPackingImageExternal" accept="image/*" [allowedFileExtensions]="['.gif', '.jpg', '.jpeg', '.png']"></dx-file-uploader>
					</dxi-item>
				</dxi-item>
			</dxi-tab>
			<dxi-tab title="Fixings">
				<dx-data-grid #fixingsGridContainer class="data-grid" [dataSource]="fixingsRequired" [remoteOperations]="false" [allowColumnReordering]="false" [rowAlternationEnabled]="true" [showBorders]="true" height="200px">
					<dxo-paging [pageSize]="100"></dxo-paging>
					<dxo-state-storing [enabled]="true" type="localStorage" storageKey="partsFixings"></dxo-state-storing>
					<dxo-pager [visible]="false" [showPageSizeSelector]="false" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
					<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
					<dxo-group-panel [visible]="false"></dxo-group-panel>
					<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
					<dxo-export [enabled]="false"></dxo-export>
					<dxo-editing mode="popup" [allowAdding]="!readOnly" [allowDeleting]="!readOnly" [allowUpdating]="!readOnly" [confirmDelete]="true" [useIcons]="true" newRowPosition="last" [changes]="changes" [(editRowKey)]="editRowKey">
					<dxo-popup title="Add New Fixing" [showTitle]="true" [width]="'75%'" [height]="'auto'"></dxo-popup>
					<dxo-form [colCount]="1">
						<dxi-item dataField="purchasableItemId" editorType="dxSelectBox" [editorOptions]="{ items: fixingPurchasableItems, searchEnabled: true, displayExpr:'name', valueExpr:'id'}" [label]="{ text: 'Fixing' }"></dxi-item>	
						<dxi-item dataField="quantity" editorType="dxNumberBox" [editorOptions]="{ format : '#0'}"></dxi-item>
					</dxo-form>
					</dxo-editing>
					<dxo-toolbar>
						<dxi-item name="addRowButton" showText="always"></dxi-item>
					</dxo-toolbar>
					<dxi-column dataField="purchasableItemId" dataType="number" alignment="left" caption="Fixing">
						<dxo-lookup [dataSource]="fixingPurchasableItems" displayExpr="name" valueExpr="id"></dxo-lookup>
						<dxi-validation-rule type="required"></dxi-validation-rule>
					</dxi-column>
					<dxi-column dataField="quantity" dataType="number" alignment="left" caption="QTY">
						<dxi-validation-rule type="required"></dxi-validation-rule>
					</dxi-column>
					<dxi-column type="buttons">
						<dxi-button name="edit"></dxi-button>
						<dxi-button name="delete"></dxi-button>
					</dxi-column>
				</dx-data-grid>
				<!-- <dxi-item itemType="group" caption="Consumables Required" [colSpan]="2" [colCount]="1">
					<dx-data-grid #consumablesGridContainer class="data-grid" [dataSource]="consumablesRequired" [remoteOperations]="false" [allowColumnReordering]="false" [rowAlternationEnabled]="true" [showBorders]="true" height="200px">
						<dxo-paging [pageSize]="100"></dxo-paging>
						<dxo-state-storing [enabled]="true" type="localStorage" storageKey="storage"></dxo-state-storing>
						<dxo-pager [visible]="false" [showPageSizeSelector]="false" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
						<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
						<dxo-group-panel [visible]="false"></dxo-group-panel>
						<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
						<dxo-export [enabled]="false"></dxo-export>
						<dxo-editing mode="popup" [allowAdding]="!readOnly" [allowDeleting]="!readOnly" [allowUpdating]="!readOnly" [confirmDelete]="true" [useIcons]="true" newRowPosition="last" [changes]="changes" [(editRowKey)]="editRowKey">
						<dxo-popup title="Add New Consumable" [showTitle]="true" [width]="'75%'" [height]="'auto'"></dxo-popup>
						<dxo-form [colCount]="1">
							<dxi-item dataField="purchasableItemId" editorType="dxSelectBox" [editorOptions]="{ items: consumablePurchasableItems, searchEnabled: true, displayExpr:'name', valueExpr:'id'}" [label]="{ text: 'Consumable' }"></dxi-item>	
							<dxi-item dataField="quantity" editorType="dxNumberBox" [editorOptions]="{ format : '#0'}"></dxi-item>
						</dxo-form>
						</dxo-editing>
						<dxo-toolbar>
							<dxi-item name="addRowButton" showText="always"></dxi-item>
						</dxo-toolbar>
						<dxi-column dataField="purchasableItemId" dataType="number" alignment="left" caption="Fixing">
							<dxo-lookup [dataSource]="consumablePurchasableItems" displayExpr="name" valueExpr="id"></dxo-lookup>
							<dxi-validation-rule type="required"></dxi-validation-rule>
						</dxi-column>
						<dxi-column dataField="quantity" dataType="number" alignment="left" caption="QTY">
							<dxi-validation-rule type="required"></dxi-validation-rule>
						</dxi-column>
						<dxi-column type="buttons">
							<dxi-button name="edit"></dxi-button>
							<dxi-button name="delete"></dxi-button>
						</dxi-column>
					</dx-data-grid>
				</dxi-item> -->
			</dxi-tab>
			<dxi-tab title="Workflow" [colCount]="2">
				<dxi-item dataField="linkedPartGroupId" [label]="{ text: 'Linked Group Id' }" [editorOptions]="{ readOnly: true }"></dxi-item>
				<dxi-item dataField="linkedPartGroupProportion" [label]="{ text: 'Linked Group Proportion' }" [editorOptions]="{ readOnly: true }"></dxi-item>
				<dxi-item [colSpan]="2"><p>If the group and proportion are set then the router may not be edited.</p></dxi-item>
				<dxi-item itemType="group" caption="Router" [colSpan]="2" [colCount]="1">
					<dx-data-grid #routingGridContainer class="data-grid" [dataSource]="processRouters" [remoteOperations]="false" [allowColumnReordering]="false" [allowColumnResizing]="true" [rowAlternationEnabled]="true" [showBorders]="true" height="200px" (onRowInserted)="updateRouterNumbers()">
						<dxo-paging [pageSize]="100"></dxo-paging>
						<dxo-state-storing [enabled]="true" type="localStorage" storageKey="partsRouter"></dxo-state-storing>
						<dxo-pager [visible]="false" [showPageSizeSelector]="false" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
						<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
						<dxo-group-panel [visible]="false"></dxo-group-panel>
						<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
						<dxo-export [enabled]="false"></dxo-export>
						<dxo-row-dragging [allowReordering]="true" [onReorder]="updateRouterOrder" [showDragIcons]="true"></dxo-row-dragging>
						<dxo-editing mode="popup" [allowAdding]="allowRouterChanges" [allowDeleting]="allowRouterChanges" [allowUpdating]="allowRouterChanges" [confirmDelete]="true" [useIcons]="true" newRowPosition="last" [changes]="changes" [(editRowKey)]="editRowKey">
						<dxo-popup title="Add New Stage" [showTitle]="true" [width]="'75%'" [height]="'auto'"></dxo-popup>
						<dxo-form [colCount]="1">
							<dxi-item dataField="processId" editorType="dxSelectBox" [editorOptions]="{ items: processes, searchEnabled: true, displayExpr:'name', valueExpr:'id'}" [label]="{ text: 'Process' }"></dxi-item>	
							<dxi-item dataField="machineId" editorType="dxSelectBox" [editorOptions]="{ items: machines, searchEnabled: true, displayExpr:displayMachineInDropdown, valueExpr:'id', showClearButton:true}" [label]="{ text: 'Cell' }"></dxi-item>	
							<dxi-item dataField="qualityControlCheckInterval" [editorOptions]="{ format : '#0'}"></dxi-item>
							<dxi-item dataField="qualityControlCheckType" editorType="dxSelectBox" [editorOptions]="{ items: qualityControlCheckTypes, searchEnabled: true, displayExpr:'name', valueExpr:'id'}" [label]="{ text: 'Quality Control Check Type' }"></dxi-item>	
							<dxi-item dataField="setupInformation"></dxi-item>
							<dxi-item dataField="runtime" [label]="{ text: 'Operation Runtime (seconds)' }"></dxi-item>
						</dxo-form>
						</dxo-editing>
						<dxo-toolbar>
							<dxi-item name="addRowButton" showText="always"></dxi-item>
						</dxo-toolbar>
						<dxi-column dataField="stage" dataType="number" alignment="left" caption="Stage">>
							<dxi-validation-rule type="required"></dxi-validation-rule>
						</dxi-column>
						<dxi-column dataField="processId" dataType="number" alignment="left" caption="Process">
							<dxo-lookup [dataSource]="processes" displayExpr="name" valueExpr="id"></dxo-lookup>
							<dxi-validation-rule type="required"></dxi-validation-rule>
						</dxi-column>
						<dxi-column dataField="machineId" dataType="number" alignment="left" caption="Cell">
							<dxo-lookup [dataSource]="machines" [displayExpr]="displayMachineInDropdown" valueExpr="id"></dxo-lookup>
						</dxi-column>
						<dxi-column dataField="qualityControlCheckInterval" dataType="number" alignment="left" caption="QC Interval">
							<dxi-validation-rule type="required"></dxi-validation-rule>
						</dxi-column>
						<dxi-column dataField="qualityControlCheckType" dataType="number" alignment="left" caption="QC Type">
							<dxo-lookup [dataSource]="qualityControlCheckTypes" displayExpr="name" valueExpr="id"></dxo-lookup>
							<dxi-validation-rule type="required"></dxi-validation-rule>
						</dxi-column>
						<dxi-column dataField="setupInformation" dataType="text" alignment="left" caption="Setup Information">		
						</dxi-column>
						<dxi-column dataField="runtime" dataType="number" alignment="left" caption="Operation Runtime">		
						</dxi-column>
						<dxi-column type="buttons">
							<dxi-button name="edit"></dxi-button>
							<dxi-button name="delete"></dxi-button>
						</dxi-column>
					</dx-data-grid>
				</dxi-item>
				<dxi-item itemType="group" caption="Current Operations" [colSpan]="2" [colCount]="1">
					<dx-data-grid class="data-grid" [dataSource]="workOrderStageSummary" [remoteOperations]="false" [allowColumnReordering]="false" [allowColumnResizing]="true" [rowAlternationEnabled]="true" [showBorders]="true" height="200px">
						<dxo-paging [pageSize]="100"></dxo-paging>
						<dxo-state-storing [enabled]="true" type="localStorage" storageKey="partsWorkOrderStageSummary"></dxo-state-storing>
						<dxo-pager [visible]="false" [showPageSizeSelector]="false" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
						<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
						<dxo-group-panel [visible]="false"></dxo-group-panel>
						<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
						<dxo-export [enabled]="false"></dxo-export>
						<dxi-column dataField="stage" dataType="string" alignment="left" caption="Operation"></dxi-column>
						<dxi-column dataField="quantity" dataType="number" alignment="left" caption="Quantity"></dxi-column>
					</dx-data-grid>
				</dxi-item>
			</dxi-tab>
			<dxi-tab title="Outsourced Specifications">
				<dx-data-grid #fixingsGridContainer class="data-grid" [dataSource]="partOutsourcedSpecifications" [remoteOperations]="false" [allowColumnReordering]="true" [rowAlternationEnabled]="true" [showBorders]="true" height="200px">
					<dxo-paging [pageSize]="100"></dxo-paging>
					<dxo-state-storing [enabled]="true" type="localStorage" storageKey="partsSpec"></dxo-state-storing>
					<dxo-pager [visible]="false" [showPageSizeSelector]="false" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
					<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
					<dxo-group-panel [visible]="false"></dxo-group-panel>
					<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
					<dxo-export [enabled]="false"></dxo-export>
					<dxo-editing mode="popup" [allowAdding]="!readOnly" [allowDeleting]="!readOnly" [allowUpdating]="!readOnly" [confirmDelete]="true" [useIcons]="true" newRowPosition="last" [changes]="changes" [(editRowKey)]="editRowKey">
					<dxo-popup title="Add New Specification" [showTitle]="true" [width]="'75%'" [height]="'auto'"></dxo-popup>
					<dxo-form [colCount]="1">
						<dxi-item dataField="outsourcedSpecificationId" editorType="dxSelectBox" [editorOptions]="{ items: outsourcedSpecifications, searchEnabled: true, displayExpr:'specification', valueExpr:'id'}" [label]="{ text: 'Specification' }"></dxi-item>	
						<dxi-item dataField="supplierId" editorType="dxSelectBox" [editorOptions]="{ items: suppliers, searchEnabled: true, displayExpr:'name', valueExpr:'id'}" [label]="{ text: 'Supplier' }"></dxi-item>	
						<dxi-item dataField="defaultPrice" [editorOptions]="{format: {type:'currency', currency:'GBP', precision: 4} }"></dxi-item>
					</dxo-form>
					</dxo-editing>
					<dxo-toolbar>
						<dxi-item name="addRowButton" showText="always"></dxi-item>
					</dxo-toolbar>
					<dxi-column dataField="outsourcedSpecificationId" dataType="number" alignment="left" caption="Specification">
						<dxo-lookup [dataSource]="outsourcedSpecifications" displayExpr="specification" valueExpr="id"></dxo-lookup>
						<dxi-validation-rule type="required"></dxi-validation-rule>
					</dxi-column>
					<dxi-column dataField="supplierId" dataType="number" alignment="left" caption="Supplier">
						<dxo-lookup [dataSource]="suppliers" displayExpr="name" valueExpr="id"></dxo-lookup>
						<dxi-validation-rule type="required"></dxi-validation-rule>
					</dxi-column>
					<dxi-column dataField="defaultPrice" dataType="number" alignment="left" caption="Default Price" [format]="{ style: 'currency', currency: 'GBP', useGrouping: true, minimumSignificantDigits: 4 }">
						<dxi-validation-rule type="required"></dxi-validation-rule>
					</dxi-column>
					<dxi-column type="buttons">
						<dxi-button name="edit"></dxi-button>
						<dxi-button name="delete"></dxi-button>
					</dxi-column>
				</dx-data-grid>
			</dxi-tab>
			<dxi-tab title="Documents" [colCount]="2">
				<dxi-item itemType="group" caption="CAD Files" [colSpan]="1" [colCount]="1">
					<dx-data-grid class="data-grid" [dataSource]="cadFiles" [remoteOperations]="false" [allowColumnReordering]="false" [rowAlternationEnabled]="true" [showBorders]="true" height="200px">
						<dxo-paging [pageSize]="100"></dxo-paging>
						<dxo-state-storing [enabled]="true" type="localStorage" storageKey="partsCad"></dxo-state-storing>
						<dxo-pager [visible]="false" [showPageSizeSelector]="false" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
						<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
						<dxo-group-panel [visible]="false"></dxo-group-panel>
						<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
						<dxo-export [enabled]="false"></dxo-export>
						<dxo-toolbar>
							<dxi-item name="addRowButton" showText="always"></dxi-item>
						</dxo-toolbar>
						<dxi-column dataField="fileName" dataType="text" alignment="left" caption="File">
							<dxi-validation-rule type="required"></dxi-validation-rule>
						</dxi-column>
						<dxi-column dataField="version" dataType="number" alignment="left" caption="Version">
							<dxi-validation-rule type="required"></dxi-validation-rule>
						</dxi-column>
						<dxi-column type="buttons">
							<dxi-button hint="Download" icon="fal fa-download" [onClick]="downloadFileLink"></dxi-button>
							<dxi-button [visible]="screenMode != 'view'" hint="Delete" icon="fal fa-trash" [onClick]="deleteCadFileLink"></dxi-button>
						</dxi-column>
					</dx-data-grid>
					<dx-file-uploader [visible]="screenMode != 'view'" [showFileList]="false" [maxFileSize]="2097152" [uploadFile]="uploadCadFile"></dx-file-uploader>
				</dxi-item>
				<dxi-item itemType="group" caption="Supporting Files" [colSpan]="1" [colCount]="1">
					<dxi-item itemType="group" [colSpan]="1" [colCount]="1">
					<dx-data-grid class="data-grid" [dataSource]="supportingDocuments" [remoteOperations]="false" [allowColumnReordering]="false" [rowAlternationEnabled]="true" [showBorders]="true" height="200px">
						<dxo-paging [pageSize]="100"></dxo-paging>
						<dxo-state-storing [enabled]="true" type="localStorage" storageKey="storage"></dxo-state-storing>
						<dxo-pager [visible]="false" [showPageSizeSelector]="false" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
						<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
						<dxo-group-panel [visible]="false"></dxo-group-panel>
						<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
						<dxo-export [enabled]="false"></dxo-export>
						<dxo-toolbar>
							<dxi-item name="addRowButton" showText="always"></dxi-item>
						</dxo-toolbar>
						<dxi-column dataField="fileName" dataType="text" alignment="left" caption="File">
							<dxi-validation-rule type="required"></dxi-validation-rule>
						</dxi-column>
						<dxi-column dataField="description" dataType="text" alignment="left" caption="Description">
							<dxi-validation-rule type="required"></dxi-validation-rule>
						</dxi-column>
						<dxi-column type="buttons">
							<dxi-button hint="Download" icon="fal fa-download" [onClick]="downloadFileLink"></dxi-button>
							<dxi-button [visible]="screenMode != 'view'" hint="Delete" icon="fal fa-trash" [onClick]="deleteSupportingDocumentLink"></dxi-button>
						</dxi-column>
					</dx-data-grid></dxi-item>
					<dxi-item itemType="group" [colSpan]="1" [colCount]="1">
						<dxi-item [visible]="screenMode != 'view'" itemType="button" [buttonOptions]="supportingDocumentButtonOptions"></dxi-item>
					</dxi-item>
				</dxi-item>
				<dxi-item itemType="group" caption="Technical Drawings" [colSpan]="1" [colCount]="1">
					<dx-data-grid class="data-grid" [dataSource]="technicalDrawings" [remoteOperations]="false" [allowColumnReordering]="false" [rowAlternationEnabled]="true" [showBorders]="true" height="200px">
						<dxo-paging [pageSize]="100"></dxo-paging>
						<dxo-state-storing [enabled]="true" type="localStorage" storageKey="partsDraw"></dxo-state-storing>
						<dxo-pager [visible]="false" [showPageSizeSelector]="false" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
						<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
						<dxo-group-panel [visible]="false"></dxo-group-panel>
						<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
						<dxo-export [enabled]="false"></dxo-export>
						<dxo-toolbar>
							<dxi-item name="addRowButton" showText="always"></dxi-item>
						</dxo-toolbar>
						<dxi-column dataField="fileName" dataType="text" alignment="left" caption="File">
							<dxi-validation-rule type="required"></dxi-validation-rule>
						</dxi-column>
						<dxi-column dataField="version" dataType="number" alignment="left" caption="Version">
							<dxi-validation-rule type="required"></dxi-validation-rule>
						</dxi-column>
						<dxi-column type="buttons">
							<dxi-button hint="Download" icon="fal fa-download" [onClick]="downloadFileLink"></dxi-button>
							<dxi-button [visible]="screenMode != 'view'" hint="Delete" icon="fal fa-trash" [onClick]="deleteTechnicalDrawingLink"></dxi-button>
						</dxi-column>
					</dx-data-grid>
					<dx-file-uploader [visible]="screenMode != 'view'" [showFileList]="false" [maxFileSize]="2097152" [uploadFile]="uploadTechnicalDrawing"></dx-file-uploader>
				</dxi-item>
			</dxi-tab>
			<dxi-tab title="Ops Audits">
				<dx-data-grid class="data-grid" [dataSource]="opsActionAudits" [remoteOperations]="false" [allowColumnReordering]="false" [rowAlternationEnabled]="true" [showBorders]="true" height="500px">
					<dxo-paging [pageSize]="100"></dxo-paging>
					<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
					<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
					<dxo-group-panel [visible]="false"></dxo-group-panel>
					<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
					<dxo-export [enabled]="false"></dxo-export>
					<dxi-column dataField="createdAt" dataType="datetime" format="dd/MM/yyyy HH:mm:ss" alignment="left" caption="Date"></dxi-column>
					<dxi-column dataField="workOrderId" dataType="text" alignment="left" caption="Work Order"></dxi-column>
					<dxi-column dataField="operationNumber" dataType="text" alignment="left" caption="OP"></dxi-column>
					<dxi-column dataField="part.partNumber" dataType="text" alignment="left" caption="Part"></dxi-column>
					<dxi-column dataField="machine.cellNumber" dataType="text" alignment="left" caption="Cell"></dxi-column>
					<dxi-column dataField="machine.name" dataType="text" alignment="left" caption="Machine"></dxi-column>
					<dxi-column dataField="operator.fullName" dataType="text" alignment="left" caption="Operator"></dxi-column>
					<dxi-column dataField="quantity" dataType="number" alignment="left" caption="Qty"></dxi-column>
					<dxi-column dataField="description" dataType="text" alignment="left" caption="Description"></dxi-column>
					<dxi-column dataField="userProfile.fullName" dataType="text" alignment="left" caption="User"></dxi-column>
				</dx-data-grid>
			</dxi-tab>
			<dxi-tab title="Work Orders">
				<dx-data-grid class="data-grid" [dataSource]="workOrders" [remoteOperations]="false" [allowColumnReordering]="false" [rowAlternationEnabled]="true" [showBorders]="true" height="500px">
					<dxo-paging [pageSize]="100"></dxo-paging>
					<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
					<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
					<dxo-group-panel [visible]="false"></dxo-group-panel>
					<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
					<dxo-export [enabled]="false"></dxo-export>
					<dxi-column type="buttons">
						<dxi-button hint="View" icon="fal fa-eye" [onClick]="viewWorkOrder"></dxi-button>
						<dxi-button hint="Edit" icon="fal fa-pencil" [onClick]="editWorkOrder"></dxi-button>
						<dxi-button hint="Master Label PDF" icon="fal fa-download" [onClick]="generateAndDownloadWorkOrderMasterLabelPDF"></dxi-button>
						<dxi-button hint="Change Quantity" icon="fal fa-arrows-alt-v" [onClick]="changeWorkOrderQuantityPopup"></dxi-button>
					</dxi-column>
					<dxi-column dataField="id" dataType="number" alignment="left" caption="Id"></dxi-column>
					<dxi-column dataField="salesOrderId" dataType="number" alignment="left" caption="Sales Order"></dxi-column>
					<dxi-column dataField="quantity" dataType="number" alignment="left" caption="Qty"></dxi-column>
					<dxi-column dataField="currentStage" dataType="number" alignment="left" caption="Current Stage"></dxi-column>
					<dxi-column dataField="isQLoop" caption="Q-Loop"></dxi-column>
					<dxi-column dataField="workOrderComplete" caption="Completed"></dxi-column>
					<dxi-column dataField="dateRequired" dataType="date" format="dd/MM/yyyy" alignment="left"></dxi-column>
				</dx-data-grid>
			</dxi-tab>
		</dxi-item>
	</dx-form>
	<dx-popup [width]="'75%'" [height]="600" [showTitle]="true" [title]="popupTitle" [dragEnabled]="false" [closeOnOutsideClick]="false" [showCloseButton]="false" container=".dx-viewport" [(visible)]="popupVisible">
		<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="before" [options]="popupAddButtonOptions"></dxi-toolbar-item>
		<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="after" [options]="popupCloseButtonOptions"></dxi-toolbar-item>
		<div *dxTemplate="let data of 'content'">
			<div *ngIf="popupTitle == 'Add New Purchasable Item'">
				<dx-form class="m-3" [(formData)]="popupPurchasableItem" [showColonAfterLabel]="true" labelLocation="top" (onFieldDataChanged)="popupPurchasableItemFormDataChanged($event)">
					<dxi-item dataField="name" [label]="{ text: 'Name / Part Number' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
					<dxi-item dataField="description"></dxi-item>
					<dxi-item dataField="purchaseType" editorType="dxSelectBox" [editorOptions]="{ items: purchaseTypes, displayExpr:'name', valueExpr:'id', searchEnabled: true }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
					<dxi-item dataField="materialType" editorType="dxSelectBox" [editorOptions]="{ items: materialTypes, displayExpr:'name', valueExpr:'id', searchEnabled: true, readOnly: (popupPurchasableItem.purchaseType != 30 && popupPurchasableItem.purchaseType != 70) }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
					<dxi-item dataField="minimumBuyableQuantity" editorType="dxNumberBox" [label]="{ text: 'MBQ' }"></dxi-item>
				</dx-form>
			</div>
			<div *ngIf="popupTitle == 'Add New Client'">
				<dx-form class="m-3" [(formData)]="popupClient" [showColonAfterLabel]="true" labelLocation="top" [colCount]="2">
					<dxi-item dataField="name" [colSpan]="2" [label]="{ text: 'Client Name' }">
						<dxi-validation-rule type="required" message="Client Name is required"></dxi-validation-rule>
					</dxi-item>
					<dxi-item dataField="accountingNumber"></dxi-item>
					<dxi-item dataField="telephoneNumber" [label]="{ text: 'Contact Phone Number' }"></dxi-item>
					<dxi-item dataField="address1" [label]="{ text: 'Address Line 1' }"></dxi-item>
					<dxi-item dataField="address2" [label]="{ text: 'Address Line 2' }"></dxi-item>
					<dxi-item dataField="town"></dxi-item>
					<dxi-item dataField="postcode"></dxi-item>
				</dx-form>
			</div>
			<div *ngIf="popupTitle == 'Add New Contact'">
				<dx-form class="m-3" [(formData)]="popupContact" [showColonAfterLabel]="true" labelLocation="top" [colCount]="2">
					<dxi-item dataField="clientId" editorType="dxSelectBox" [editorOptions]="{ items: clients, searchEnabled: true, displayExpr:'name', valueExpr:'id' }" [label]="{ text: 'Client' }"><dxi-validation-rule type="required" message="Client is required"></dxi-validation-rule></dxi-item>	
					<dxi-item dataField="contactName">
						<dxi-validation-rule type="required" message="Contact Name is required"></dxi-validation-rule>					
					</dxi-item>
					<dxi-item dataField="contactEmailAddress">
						<dxi-validation-rule type="required" message="Contact Email is required"></dxi-validation-rule>
					</dxi-item>
					<dxi-item dataField="jobTitle"></dxi-item>
				</dx-form>
			</div>
			<div *ngIf="popupTitle == 'Add New Supplier'">
				<dx-form class="m-3" [(formData)]="popupSupplier" [showColonAfterLabel]="true" labelLocation="top" [colCount]="2">
					<dxi-item dataField="name" [label]="{ text: 'Supplier Name' }">
						<dxi-validation-rule type="required" message="Supplier Name is required"></dxi-validation-rule>
					</dxi-item>
					<dxi-item dataField="telephoneNumber" [label]="{ text: 'Contact Phone Number' }"></dxi-item>
					<dxi-item dataField="address1" [label]="{ text: 'Address Line 1' }"></dxi-item>
					<dxi-item dataField="address2" [label]="{ text: 'Address Line 2' }"></dxi-item>
					<dxi-item dataField="town"></dxi-item>
					<dxi-item dataField="postcode"></dxi-item>
					<dxi-item dataField="excludeFromRFQs" editorType="dxCheckBox" [colSpan]="2"></dxi-item>
					<dxi-item [colSpan]="2">
						<p class="red">Please Note: Further details will need configuring (e.g. contacts, locations, purchasable items) through the admin page once this supplier is created.</p>
					</dxi-item>
				</dx-form>
			</div>
			<div *ngIf="popupTitle == 'Add New Project'">
				<dx-form class="m-3" [(formData)]="popupProject" [showColonAfterLabel]="true" labelLocation="top">
					<dxi-item dataField="clientId" editorType="dxSelectBox" [editorOptions]="{ items: clients, searchEnabled: true, displayExpr:'name', valueExpr:'id' }" [label]="{ text: 'Client' }"><dxi-validation-rule type="required" message="Client is required"></dxi-validation-rule></dxi-item>	
					<dxi-item dataField="name">
						<dxi-validation-rule type="required" message="Name is required"></dxi-validation-rule>					
					</dxi-item>
					<dxi-item dataField="description"></dxi-item>
				</dx-form>
			</div>
			<div *ngIf="popupTitle == 'Add New Master Relationship'">
				<dx-form class="m-3" [(formData)]="popupRelationship" [showColonAfterLabel]="true" labelLocation="top">
					<dxi-item dataField="masterPartId" editorType="dxSelectBox" [editorOptions]="{ items: parts, searchEnabled: true, displayExpr:'partNumber', valueExpr:'id' }" [label]="{ text: 'Master Part' }"><dxi-validation-rule type="required" message="Client is required"></dxi-validation-rule></dxi-item>	
					<dxi-item dataField="quantityRequired">
						<dxi-validation-rule type="required" message="Quantity Required is required"></dxi-validation-rule>
					</dxi-item>
				</dx-form>
			</div>
			<div *ngIf="popupTitle == 'Add New Child Relationship'">
				<dx-form class="m-3" [(formData)]="popupRelationship" [showColonAfterLabel]="true" labelLocation="top">
					<dxi-item dataField="childPartId" editorType="dxSelectBox" [editorOptions]="{ items: parts, searchEnabled: true, displayExpr:'partNumber', valueExpr:'id' }" [label]="{ text: 'Child Part' }"><dxi-validation-rule type="required" message="Client is required"></dxi-validation-rule></dxi-item>	
					<dxi-item dataField="quantityRequired">
						<dxi-validation-rule type="required" message="Quantity Required is required"></dxi-validation-rule>					
					</dxi-item>
				</dx-form>
			</div>
			<div *ngIf="popupTitle == 'Add New Supporting Document'">
				<dx-form class="m-3" [(formData)]="popupSupportingDocument" [showColonAfterLabel]="true" labelLocation="top">
					<dxi-item dataField="description">
						<dxi-validation-rule type="required" message="Description is required"></dxi-validation-rule>					
					</dxi-item>
					<dxi-item>
						<dx-file-uploader [maxFileSize]="2097152" [uploadFile]="uploadSupportingDocument"></dx-file-uploader>
					</dxi-item>
					<dxi-item>
						<p>Adding this document will save all outstanding changes on the Part. Please ensure all details are correct before uploading.</p>
					</dxi-item>
				</dx-form>
			</div>
			<div *ngIf="popupTitle == 'Create Sales Order'">
				<dx-form class="m-3" [(formData)]="popupCreateSalesOrderFromPart" [showColonAfterLabel]="true" labelLocation="top" [colCount]="2">
					<dxi-item [colSpan]="2" dataField="clientContactId" editorType="dxSelectBox" [editorOptions]="{ items: contacts, searchEnabled: true, displayExpr:'displayName', valueExpr:'id' }" [label]="{ text: 'Contact' }"><dxi-validation-rule type="required" message="Contact is required"></dxi-validation-rule></dxi-item>	
					<dxi-item dataField="quantity" editorType="dxNumberBox" [label]="{ text: 'Quantity' }" [editorOptions]="{ format: '#0', min: 1}"><dxi-validation-rule type="required" message="Quantity is required"></dxi-validation-rule></dxi-item>
					<dxi-item dataField="unitCost" editorType="dxNumberBox" [label]="{ text: 'Unit Cost' }" [editorOptions]="{ format: {type:'currency', currency:'GBP', precision: 2}, min: 0.01}"><dxi-validation-rule type="required" message="Quantity is required"></dxi-validation-rule></dxi-item>
					<dxi-item dataField="deliveryCost" editorType="dxNumberBox" [label]="{ text: 'Delivery Cost' }" [editorOptions]="{ format: {type:'currency', currency:'GBP', precision: 2}, min: 0}"></dxi-item>
					<dxi-item dataField="assemblyFixtureValue" editorType="dxNumberBox" [label]="{ text: 'Assembly Fixture' }" [editorOptions]="{ format: {type:'currency', currency:'GBP', precision: 2}, min: 0}"></dxi-item>
					<dxi-item dataField="inspectionFixtureValue" editorType="dxNumberBox" [label]="{ text: 'Inspection Fixture' }" [editorOptions]="{ format: {type:'currency', currency:'GBP', precision: 2}, min: 0}"></dxi-item>
					<dxi-item dataField="laserFixtureValue" editorType="dxNumberBox" [label]="{ text: 'Laser Fixture' }" [editorOptions]="{ format: {type:'currency', currency:'GBP', precision: 2}, min: 0}"></dxi-item>
					<dxi-item dataField="toolingValue" editorType="dxNumberBox" [label]="{ text: 'Tooling' }" [editorOptions]="{ format: {type:'currency', currency:'GBP', precision: 2}, min: 0}"></dxi-item>
					<dxi-item dataField="weldFixtureValue" editorType="dxNumberBox" [label]="{ text: 'Weld Fixture' }" [editorOptions]="{ format: {type:'currency', currency:'GBP', precision: 2}, min: 0}"></dxi-item>
				</dx-form>
			</div>
			<div *ngIf="popupTitle == 'Copy Router From Part'">
				<dx-form class="m-3" [(formData)]="popupPartToCopyRouterFrom" [showColonAfterLabel]="true" labelLocation="top" [colCount]="2">
					<dxi-item [colSpan]="2" dataField="id" editorType="dxSelectBox" [editorOptions]="{ items: parts, searchEnabled: true, displayExpr:'partNumber', valueExpr:'id' }" [label]="{ text: 'Part to Copy From' }"><dxi-validation-rule type="required" message="Contact is required"></dxi-validation-rule></dxi-item>	
				</dx-form>
			</div>
			<div *ngIf="popupTitle === 'Change Work Order Quantity'">
				<dx-form class="m-3" [(formData)]="popupChangeWorkOrderQuantity" [showColonAfterLabel]="true" labelLocation="top">
					<dxi-item dataField="quantity" [label]="{ text: 'New Quantity' }">
						<dxi-validation-rule type="required" message="Quantity is required"></dxi-validation-rule>					
					</dxi-item>
					<dxi-item>
						<p>Note that completed, split and parent work orders cannot be adjusted.</p>
						<p>This may complete the work order or undo completion of stages.</p>
						<p>If this work order is linked to others, they will also be changed (except when changing a child work order).</p>
					</dxi-item>
				</dx-form>
			</div>
		</div>
	</dx-popup>
</div>