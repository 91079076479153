<admin-header [primaryButtons]="headerPrimaryButtons" [secondaryButtons]="headerSecondaryButtons" [tertiaryButtons]="headerTertiaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<div class="row">
	<div class="col-8">
		<div class="row">
			<div *ngFor="let machine of machines" class="col-12 machineInfo">
				<h2 style="background-color: {{machine.siteColour}};"><i class="fa fa-dot-circle" style="margin-right:20px; color:{{this.renderStatusColor(machine.productionStatus)}};"></i>{{machine.cellNumber}} <a *ngIf="machine.machineId !== undefined && machine.machineId > 0" href="#" style="color:rgb(213, 213, 213)" (click)="changeMachineProductionStatusPopup(machine.machineId)">- {{machine.productionStatusName}}</a></h2>
				<p>{{machine.machineName}}</p>
				<p>{{machine.siteName}}</p>				
				<table>
					<tbody>
						<tr>
							<th class="today" colspan="8">Today</th>
						</tr>
						<tr>
							<th scope="col">Order</th>
							<th scope="col">Date Req.</th>
							<th scope="col">Part</th>
							<th scope="col">Qty.</th>
							<th scope="col">All.</th>
							<th scope="col">Operator</th>
							<th></th>
							<th scope="col">Priority</th>
							<th scope="col">Move</th>
						</tr>
						<tr *ngFor="let ediOrder of machine.todayEdiOrders" [class]="ediOrder.topFive === true || machinesShowingAllToday.indexOf(machine.machineId) >= 0 ? '' : 'hidden'">
							<th scope="row">{{ediOrder.id}}</th>
							<td>{{renderDateString(ediOrder.dateRequired)}}</td>
							<td id="runningPartNumber{{ediOrder.id}}">{{ediOrder.partNumber}}								
								<dx-tooltip target="#runningPartNumber{{ediOrder.id}}" showEvent="mouseenter" hideEvent="mouseleave" position="right">
									<div *dxTemplate="let data = data; of: 'content'">
										<img alt="{{ediOrder.partNumber}}" width="300" src="{{ediOrder.partImage}}" />
									</div>
								</dx-tooltip>
							</td>
							<td>{{ediOrder.quantityOrdered}}</td>
							<td>
								<a *ngIf="ediOrder.allocationId > 0" href="#" (click)="goToAllocation(ediOrder.allocationId)">{{ediOrder.allocationId}}</a>
								<a *ngIf="ediOrder.allocationId === undefined || ediOrder.allocationId === null" href="#" (click)="createAllocationForEdiOrder(ediOrder.id)">+</a></td>
							<td>{{ediOrder.operatorFullName}}</td>
							<td><a href="#" (click)="assignEdiOrderOperatorPopup(ediOrder.id)"><i class="fal fa-user-edit"></i></a></td>
							<td><dx-select-box [dataSource]="priorities" [value]="ediOrder.priority" [showClearButton]="false" (onValueChanged)="changeEdiOrderPriorityDropDown($event, ediOrder.id)"></dx-select-box></td>
							<td><a href="#" (click)="assignEdiOrderMachinePopup(ediOrder.id)"><i class="fal fa-arrows-alt"></i></a></td>
						</tr>
						<tr *ngIf="machine.todayEdiOrders.length > 5">
							<th colspan="8"><a href="#" (click)="toggleMachineShowingAllToday(machine.machineId)">Show {{(machinesShowingAllToday.indexOf(machine.machineId) >= 0 ? 'Less' : 'All')}}</a></th>
						</tr>
					</tbody>
				</table>									
				<table *ngIf="machine.machineId === undefined || machine.machineId === null">
					<tbody>
						<tr>
							<th class="future" colspan="4">Future</th>
						</tr>
						<tr>
							<th scope="col">Order</th>
							<th scope="col">Date Req.</th>
							<th scope="col">Part</th>
							<th scope="col">Qty.</th>
						</tr>
						<tr *ngFor="let ediOrder of machine.futureEdiOrders">
							<th scope="row">{{ediOrder.id}}</th>
							<td>{{renderDateString(ediOrder.dateRequired)}}</td>
							<td id="runningPartNumber{{ediOrder.id}}">{{ediOrder.partNumber}}								
								<dx-tooltip target="#runningPartNumber{{ediOrder.id}}" showEvent="mouseenter" hideEvent="mouseleave" position="right">
									<div *dxTemplate="let data = data; of: 'content'">
										<img alt="{{ediOrder.partNumber}}" width="300" src="{{ediOrder.partImage}}" />
									</div>
								</dx-tooltip>
							</td>
							<td>{{ediOrder.quantityOrdered}}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
	<div class="col-4">
		<dx-data-grid class="data-grid" [dataSource]="userProfiles" [remoteOperations]="false"
			[allowColumnReordering]="false" [allowColumnResizing]="false" [rowAlternationEnabled]="true"
			[showBorders]="false">
			<dxo-paging [pageSize]="100"></dxo-paging>
			<dxo-pager [visible]="false" [showPageSizeSelector]="false" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
			<dxo-search-panel [visible]="false" [highlightCaseSensitive]="false"></dxo-search-panel>
			<dxo-group-panel [visible]="false"></dxo-group-panel>
			<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
			<dxo-export [enabled]="false"></dxo-export>
			<dxi-column dataField="fullName" dataType="string" alignment="left" caption="Name"></dxi-column>
			<dxi-column dataField="shiftId" dataType="string" alignment="left" caption="Shift"><dxo-lookup [dataSource]="shifts" valueExpr="id" displayExpr="name"></dxo-lookup></dxi-column>
			<dxi-column dataField="jobTitle" dataType="string" alignment="left" caption="Job Title"></dxi-column>
			<dxi-column dataField="siteId" dataType="string" alignment="left" caption="Site"><dxo-lookup [dataSource]="sites" valueExpr="id" displayExpr="name"></dxo-lookup></dxi-column>
		</dx-data-grid>
	</div>
</div>
<dx-popup [width]="750" [height]="300" [showTitle]="true" [title]="popupTitle" [dragEnabled]="false" [closeOnOutsideClick]="false" [showCloseButton]="false" container=".dx-viewport" [(visible)]="popupVisible">
	<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="before" [options]="popupAddButtonOptions"></dxi-toolbar-item>
	<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="after" [options]="popupCloseButtonOptions"></dxi-toolbar-item>
	<div *ngIf="popupTitle === 'Assign Machine'">
		<dx-form class="m-3" [(formData)]="popupChangeEdiOrderAssignment" [showColonAfterLabel]="true" labelLocation="top">
			<dxi-item dataField="assignedId" editorType="dxSelectBox" [editorOptions]="{ items: machines, searchEnabled: true, displayExpr: displayMachineInDropdown, valueExpr:'machineId' }" [label]="{ text: 'Machine' }"></dxi-item>
		</dx-form>
	</div>
	<div *ngIf="popupTitle === 'Assign Operator'">
		<dx-form class="m-3" [(formData)]="popupChangeEdiOrderAssignment" [showColonAfterLabel]="true" labelLocation="top">
			<dxi-item dataField="assignedId" editorType="dxSelectBox" [editorOptions]="{ items: userProfiles, searchEnabled: true, displayExpr:'fullName', valueExpr:'id' }" [label]="{ text: 'User' }"></dxi-item>
		</dx-form>
	</div>
	<div *ngIf="popupTitle === 'Change Production Status'">
		<dx-form class="m-3" [(formData)]="popupChangeMachineProductionStatus" [showColonAfterLabel]="true" labelLocation="top">
			<dxi-item dataField="productionStatus" editorType="dxSelectBox" [editorOptions]="{ items: productionStatuses, searchEnabled: true, displayExpr:'name', valueExpr:'id' }" [label]="{ text: 'Production Status' }"></dxi-item>
		</dx-form>
	</div>
</dx-popup>