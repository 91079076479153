import { Component, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { StockStoreService } from "@services/stockStore.service";
import { BarcodeFormat } from "@zxing/library";
import { ZXingScannerComponent } from "@zxing/ngx-scanner";
import notify from "devextreme/ui/notify";
import { Guid } from "guid-typescript";

@Component({
	encapsulation: ViewEncapsulation.None,
	moduleId: module.id,
	selector: "stockControl",
	styleUrls: ["stockControl.css"],
	templateUrl: "stockControl.html"
})
export class StockControlComponent {
	allowedFormats = BarcodeFormat.QR_CODE;
	availableDevices: MediaDeviceInfo[] = [];
	hasCameras = false;
	hasPermission = false;
	mode = 0;
	modeText = "";
	result = "";
	scanner: ZXingScannerComponent = new ZXingScannerComponent();
	scannerEnabled = true;
	selectedDevice: any;
	userCode = 0;

	constructor(private stockStoreService: StockStoreService, private router: Router, private route: ActivatedRoute) {
		this.getCameras();
		this.route.params
			.subscribe(async (params) => {
				this.mode = Number.parseInt(params.id);
				if (Number.parseInt(params.id) == 0) {
					this.modeText = "Check In";
				} else {
					this.modeText = "Check Out";
				}
			});

		// Get Supervisor Code
		const code = window.prompt("Please enter your user code to continue", "");
		if (code == null) {
			return;
		}
		this.userCode = Number.parseInt(code!);
	}

	cameraFound(e: any) {
		// Log to see if the camera was found
		console.log(e);
		this.availableDevices = e;
		this.selectedDevice = e[0];
		this.hasCameras = true;
		this.hasPermission = true;
		console.log("Devices: ", this.availableDevices);

		// selects the devices's back camera by default'
		for (const device of e) {
			if (/back|rear|environment/gi.test(device.label)) {
				new this.scanner.deviceChange(true);
				this.selectedDevice = device;
				break;
			} else if (e.length == 1) {
				new this.scanner.deviceChange(true);
				this.selectedDevice = device;
				break;
			}
		}
	}

	camerasNotFound() {
		// Display an alert modal here
	}

	getCameras() {
		console.log("Hit!");
	}

	onDeviceSelectChange() {
		console.log("Selection changed: ", this.selectedDevice.label);
		// this.selectedDevice = this.scanner.device!;
	}

	onScanSuccess(result: string) {
		const input = window.prompt("Enter Quantity to " + this.modeText, "");
		if (input == null) {
			return;
		}

		if (this.mode == 0) {
			this.stockStoreService.App_CheckIn(Guid.parse(result), Number.parseInt(input!), this.userCode)
				.subscribe(
					() => {
						notify(this.modeText + " Successful!", "Success", 5000);
					},
					(err) => {
						console.log(err);
						notify("Something Went Wrong!", "Error", 5000);
					}
				);
		} else {
			this.stockStoreService.App_CheckOut(Guid.parse(result), Number.parseInt(input!), this.userCode)
				.subscribe(
					() => {
						notify(this.modeText + " Successful!", "Success", 5000);
					},
					(err) => {
						console.log(err);
						notify("Something Went Wrong!", "Error", 5000);
					}
				);
		}
	}
}