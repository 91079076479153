export interface DeleteObject {
	id: number;
	reason: string;
}

export class DeleteObject implements DeleteObject {	
	constructor(data?: Partial<DeleteObject>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}