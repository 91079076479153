import { Injectable, Injector } from "@angular/core";
import { environment } from "@environments/environment";
import { Service } from "@services/service";

@Injectable()
export class WorkloadLogService extends Service {
	constructor(injector: Injector) {
		super("WorkloadLog", injector);
	}

	// API
	byAssignee(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/ByAssignee/${id}`);
	}

	dashboard() {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/Dashboard`);
	}

	last24Hours(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/Last24Hours/${id}`);
	}

	myWorkload() {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/MyWorkload`);
	}

	nudge(id: number) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/Nudge/${id}`, {});
	}
}