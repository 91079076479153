import { Component, ViewChild, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute,Router } from "@angular/router";
import { DxDataGridComponent } from "devextreme-angular";
import notify from "devextreme/ui/notify";
import { GetPropertiesFromEnum } from "@helpers/getPropertiesFromEnum";
import { GetPropertiesFromCadQuoteLevelEnum } from "@helpers/getPropertiesFromCadQuoteLevelEnum";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { SalesEnquiry } from "@interfaces/salesEnquiry.interface";
import { SalesEnquiryService } from "@services/salesEnquiry.service";
import { Client } from "@interfaces/client.interface";
import { CloseSalesEnquiry } from "@interfaces/closeSalesEnquiry.interface";
import { ClientService } from "@services/client.service";
import { CadQuoteLevel } from "@enums/cadQuoteLevel.enum";
import { Incoterms } from "@enums/incoterms.enum";
import { InspectionType } from "@enums/inspectionType.enum";
import { SalesEnquiryStatus } from "@enums/salesEnquiryStatus.enum";
import { UserProfileService } from "@services/userProfile.service";
import { AuthService } from "@services/auth.service";
import { ClientContact } from "@interfaces/clientContact.interface";
import { ClientContactService } from "@services/clientContact.service";
import { ProjectService } from "@services/project.service";
import { Project } from "@interfaces/project.interface";
import { UserProfile } from "@interfaces/userProfile.interface";
import { SalesLineItem } from "@interfaces/salesLineItem.interface";
import { Guid } from "guid-typescript";
import { SalesEnquiryFollowUp } from "@interfaces/salesEnquiryFollowUp.interface";
import { SalesEnquiryFollowUpType } from "@enums/salesEnquiryFollowUpType.enum";
import { DatePipe } from "@angular/common";

@Component({
	encapsulation: ViewEncapsulation.None,
	moduleId: module.id,
	selector: "salesEnquiries-id",
	styleUrls: ["salesEnquiries-id.css"],
	templateUrl: "salesEnquiries-id.html"
})
export class SalesEnquiriesIdAdminComponent {
	@ViewChild("gridContainer", { static: false }) dataGrid!: DxDataGridComponent;
	activeProcessIds: number[] = [];
	cadQuoteLevels: any = [];
	changes: any = [];
	clients: Client[] = [];
	closingStatuses: any = []
	contact: ClientContact = new ClientContact();
	contacts: ClientContact[] = [];
	createEnabled = true;
	deliveryOutsourcedOptions: string[] = ["N/A","YES","NO"];
	editEnabled = true;
	editRowKey: any = null;
	followUpTypes: any = [];
	followUps: SalesEnquiryFollowUp[] = [];
	headerPrimaryButtons: AdminHeaderButton[] = [];
	headerSecondaryButtons: AdminHeaderButton[] = [];
	headerTertiaryButtons: AdminHeaderButton[] = [];
	incoterms: any = [];
	inspectionTypes: any = [];
	item: SalesEnquiry = new SalesEnquiry();
	itemType = "Enquiry";
	itemUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	lineItems: SalesLineItem[] = [];	
	people: UserProfile[] = [];
	popupClient: Client = new Client();
	popupCloseButtonOptions: any = { onClick: () => this.closePopup(), text: "Close" };
	popupCloseSalesEnquiryDetails: CloseSalesEnquiry = new CloseSalesEnquiry();
	popupContact: ClientContact = new ClientContact();
	popupProject: Project = new Project();
	popupSaveButtonOptions: any;
	popupTitle = "";
	popupVisible = false;
	projects: Project[] = [];
	readOnly = true;
	readOnlyAlways = true;
	returnUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	screenMode = "";
	showForm = false;
	statuses: any = [];
	title: string = "View " + this.itemType;
	user: any;
	yesNoDropdown: any = [{ id: true, name: "Yes" }, { id: false, name: "No" }];

	constructor(private router: Router, private route: ActivatedRoute, private itemService: SalesEnquiryService, private clientContactService: ClientContactService, private clientService: ClientService, private userProfileService: UserProfileService, private projectService: ProjectService, private authServices: AuthService,) {
		this.getClients();
		this.getPeople();
		this.getProjects();
		this.cadQuoteLevels = GetPropertiesFromCadQuoteLevelEnum(CadQuoteLevel);
		this.closingStatuses = GetPropertiesFromEnum(SalesEnquiryStatus);
		this.followUpTypes = GetPropertiesFromEnum(SalesEnquiryFollowUpType);
		this.incoterms = GetPropertiesFromEnum(Incoterms, true);
		this.inspectionTypes = GetPropertiesFromCadQuoteLevelEnum(InspectionType);
		this.statuses = GetPropertiesFromEnum(SalesEnquiryStatus);

		// When setting a closing status, do not allow 'Ongoing' so remove it from the array.
		this.closingStatuses.splice(0, 1);

		this.route.params.subscribe((params) => {
			if (params.id != "create") {
				if (params.id.split("_").length > 1) {
					this.getItem(params.id.split("_")[0], "edit");
					this.edit();
				} else {
					this.getItem(params.id, "view");
				}
			} else {
				this.showForm = true;
				this.getUser(true, "create");
				this.create();
			}
		});
		this.cloneIconClick = this.cloneIconClick.bind(this);		
		this.updateLineNumbers = this.updateLineNumbers.bind(this);
		this.setNewFollowUpUserId = this.setNewFollowUpUserId.bind(this);
	}

	addNewClient() {
		this.clientService.createSingle(this.popupClient)
			.subscribe(
				() => {
					this.popupVisible = false;
					this.popupClient = new Client();
					this.getClients(true);
				},
				(err: any) => console.log(err)
			);
	}

	addNewClientPopup() {
		this.popupTitle = "Add New Client";
		this.popupVisible = true;
		this.popupSaveButtonOptions = { onClick: () => this.addNewClient(), text: "Add Client", visible: true };
	}

	addNewContact() {
		this.clientContactService.createSingle(this.popupContact)
			.subscribe(
				() => {
					this.popupVisible = false;
					this.getContacts(this.item.clientId, true);
				},
				(err: any) => {
					notify("Could not add client contact. Please check the details are valid and this contact does not already exist.", "Error", 5000);
					console.log(err);
				}
			);
	}

	addNewContactPopup() {
		this.popupContact = new ClientContact();
		this.popupContact.clientId = this.item.clientId;
		this.popupTitle = "Add New Contact";
		this.popupVisible = true;
		this.popupSaveButtonOptions = { onClick: () => this.addNewContact(), text: "Add Contact", visible: true };
	}

	addNewProject() {
		this.projectService.createSingle(this.popupProject)
			.subscribe(
				() => {
					this.popupVisible = false;
					this.getProjects(true);
				},
				(err: any) => {
					notify("Could not add client project. Please check the details are valid and this project does not already exist.", "Error", 5000);
					console.log(err);
				}
			);
	}

	addNewProjectPopup() {
		this.popupProject = new Project();
		this.popupProject.clientId = this.item.clientId;
		this.popupTitle = "Add New Project";
		this.popupVisible = true;
		this.popupSaveButtonOptions = { onClick: () => this.addNewProject(), text: "Add Project", visible: true };
	}

	approvalAllowed(){
		if (this.item == null || this.user == null || this.item.assignedToId == this.user.id || this.item.approved)
		{
			return false;
		}

		return true;
	}

	approveQuote(){
		if (!this.item.approved)
		{
			if (this.approvalAllowed())
			{
				this.itemService.approveQuote(this.item.id)
					.subscribe(
						() => {
							this.getItem(this.item.lookupId, "view");
						},
						(err: any) => console.log(err),
					);
			}
			else
			{
				notify("You are not authorised to approve this!", "Error", 5000);
			}
		}
	}

	buildButtons(mode: string) {
		this.screenMode = mode;
		this.headerSecondaryButtons = [];
		this.headerTertiaryButtons = [];

		if (mode == "edit" && this.editEnabled) {
			this.headerPrimaryButtons = [
				{ method: "updateItem", text: "Save Changes" },
				{ method: "cancelEditing", text: "View Mode" },
				{ method: "close", text: "Close" }
			];
			this.headerTertiaryButtons = [
				{ method: "addNewClientPopup", text: "Add New Client" },
				{ method: "addNewContactPopup", text: "Add New Contact" },
				{ method: "addNewProjectPopup", text: "Add New Project" }
			];
		} else if (mode == "create" && this.createEnabled) {
			this.headerPrimaryButtons = [
				{ method: "createItem", text: "Save" },
				{ method: "close", text: "Close" }
			];
			this.headerTertiaryButtons = [
				{ method: "addNewClientPopup", text: "Add New Client" },
				{ method: "addNewContactPopup", text: "Add New Contact" },
				{ method: "addNewProjectPopup", text: "Add New Project" }
			];
		} else if (mode == "view") {
			this.headerPrimaryButtons = [
				{ method: "edit", text: "Edit" },
				{ icon: "fal fa-copy", method: "duplicate", text: "Duplicate" },
				{ method: "close", text: "Close" }
			];
			this.headerSecondaryButtons = [];
			if (this.item.approved) {
				this.headerSecondaryButtons = [
					{ method: "generatePDF", text: "Send Quote" }					
				];
			}
			else
			{
				if (this.approvalAllowed()) {
					this.headerSecondaryButtons = [
						{ method: "approveQuote", text: "Approve" }
					];
				}
				this.headerSecondaryButtons.push({ method: "requestApproval", text: "Request Approval" });

			}
			this.headerSecondaryButtons.push({ method: "generatePreviewPDF", text: "Download PDF" });

			if (this.item.salesEnquiryStatus === SalesEnquiryStatus.Ongoing) {
				this.headerSecondaryButtons.push({ method: "closeSalesEnquiryPopup", text: "Won / Lost" });
			}

			if (this.item.feasibilityQuestionnaireCompletedOn === null) {
				this.headerSecondaryButtons.push({ method: "completeFeasibilityPopup", text: "Complete Feasibility" });
			} else {
				this.headerSecondaryButtons.push({ method: "viewFeasibilityPopup", text: "View Feasibility" });
			}
		}
	}

	calculateCellValue(data: any) {
		const totalUnitCost =  (data.unitCost * data.quantity) + data.tooling + data.inspectionFixture + data.laserFixture + data.weldFixture + data.assemblyFixture + data.deliveryCost;
		return totalUnitCost;
	}

	cancelEditing() {
		if (confirm("Are you sure you wish to switch modes? Unsaved changes will be discarded.") == false) {
			return;
		}
		this.readOnly = true;
		this.buildButtons("view");
		this.title = "View " + this.itemType;
	}

	cloneIconClick(e: any) {
		const clonedItem = { ...e.row.data };  
		this.lineItems.splice(e.row.rowIndex, 0, clonedItem);	  
		this.generateValue();
		e.event.preventDefault();
		this.updateItem();
	}

	close() {
		this.router.navigate([`/${this.returnUrl}/`]);
	}

	closePopup() {
		this.popupVisible = false;
		this.popupClient = new Client();
	}

	closeSalesEnquiry() {
		if (confirm("Are you sure you wish to close this sales enquiry? This cannot be undone.") === false) {
			return;
		}
		this.itemService.closeSalesEnquiry(this.item.id, this.popupCloseSalesEnquiryDetails)
			.subscribe(
				(res: any) => {
					this.popupVisible = false;
					this.popupCloseSalesEnquiryDetails = new CloseSalesEnquiry();

					const closingResults = res.response;
					if (closingResults.salesOrderCreated === true) {
						this.router.navigate([`/admin/salesOrders/${closingResults.salesOrderLookupId}_edit`]);
					}

					this.getItem(this.item.lookupId, "view");
				},
				(err: any) => {
					notify("Could not close sales enquiry. Please double check the details and that this enquiry was not already closed.", "Error", 5000);
					console.log(err);
				}
			);
	}

	closeSalesEnquiryPopup() {
		this.popupCloseSalesEnquiryDetails = new CloseSalesEnquiry();
		this.popupTitle = "Close Sales Enquiry";
		this.popupVisible = true;
		this.popupSaveButtonOptions = { onClick: () => this.closeSalesEnquiry(), text: "Save Changes", visible: true };
	}

	completeFeasibility() {
		this.itemService.completeFeasibility(this.item)
			.subscribe(
				() => {
					this.popupVisible = false;
					this.getItem(this.item.lookupId, "view");
				},
				(err: any) => {
					notify("Could not complete feasibility", "Error", 5000);
					console.log(err);
				}
			);
	}

	completeFeasibilityPopup() {
		this.popupTitle = "Complete Feasibility Checklist";
		this.popupVisible = true;
		this.popupSaveButtonOptions = { onClick: () => this.completeFeasibility(), text: "Complete", visible: true };
	}

	create() {
		this.readOnly = false;
		this.title = "Create New " + this.itemType;
	}

	createItem() {
		this.updateLineNumbers();
		this.item.followUps = JSON.stringify(this.followUps);
		this.item.lineItems = JSON.stringify(this.lineItems);
		this.generateValue();
		this.itemService.createSingle(this.item)
			.subscribe(
				(res: any) => {
					notify("Successfully Created " + this.itemType, "success", 5000);
					this.router.navigate([`${this.itemUrl}/${res.response.lookupId}_edit`]);
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	customizeText(bool: boolean){  
		if (bool == true)
		{
			return "YES";
		}
		else
		{
			return "NO";
		}
	}

	duplicate() {
		if (confirm("Are you sure you wish to duplicate this sales enquiry? This cannot be undone.") === false) {
			return;
		}
		this.itemService.duplicateSalesEnquiry(this.item.id)
			.subscribe(
				(res: any) => {
					const duplicateItem = res.response;
					this.router.navigate([`${this.itemUrl}/${duplicateItem.lookupId}`]);
				},
				(err: any) => {
					notify("Could not close sales enquiry. Please double check the details and that this enquiry was not already closed.", "Error", 5000);
					console.log(err);
				}
			);
	}

	edit() {
		this.readOnly = false;
		this.title = "Edit " + this.itemType;
		this.buildButtons("edit");
		notify("You are now editing...", "warning", 5000);
	}	

	formDataChanged(args: any) {
		switch (args.dataField) {
			case "clientId": {
				const selectedClient = this.clients.filter((c) => c.id === args.value)[0];
				this.item.paymentTerms = selectedClient.paymentTerms;
				this.getContacts(args.value);
				this.getProjects();
				break;
			}
			case "clientContactId": {
				this.getContact(args.value);
				break;
			}
		}
	}

	formatDateTime(dt: Date)
	{
		const datepipe: DatePipe = new DatePipe("en-GB");
		const formattedDate = datepipe.transform(dt, "dd-MMM-YYYY");
		return formattedDate;
	}

	generatePDF() {
		this.itemService.generateAndEmailPdf(this.item.id)
			.subscribe(
				() => {
					this.getItem(this.item.lookupId, "view");
					notify("Quote has been emailed to " + this.item.contactEmail, "Success", 5000);
				},
				(err: any) => console.log(err)
			);
	}

	generatePreviewPDF() {
		this.itemService.generateAndPreviewPdf(this.item.id).subscribe(
			(response: any) =>{
				const dataType = response.type;
				const binaryData = [];
				binaryData.push(response);
				const downloadLink = document.createElement("a");
				downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
				downloadLink.setAttribute("download", "Preview_Quote_" + this.item.id + ".pdf");
				document.body.appendChild(downloadLink);
				downloadLink.click();
			}
		);
	}

	generateValue()	{
		let tempValue = 0;
		let tempQuantity =0;
		this.lineItems.forEach(obj => {
			tempValue = tempValue + ((obj.unitCost * obj.quantity) + obj.tooling + obj.inspectionFixture + obj.laserFixture + obj.weldFixture + obj.assemblyFixture + obj.deliveryCost);
			tempQuantity = tempQuantity + obj.quantity;

		});
		this.item.enquiryLines = this.lineItems.length;
		this.item.enquiryValue = tempValue;
		this.item.enquiryQuantity = tempQuantity;
	}

	getClients(setNewestClient = false) {
		this.clientService.getAll()
			.subscribe(
				(res: any) => {
					this.clients = res.response.items;

					if (setNewestClient) {
						this.item.clientId = this.clients[this.clients.length - 1].id;
					}

					this.clients.sort((a,b) => a.name.localeCompare(b.name));
				},
				(err) => console.log(err)
			);
	}

	getContact(contactId: number) {
		this.clientContactService.getSingleById(contactId)
			.subscribe(
				(res: any) => {
					this.contact = res.response;
					this.item.clientContactId = contactId;
					this.item.contactEmail = res.response.contactEmailAddress;
					this.item.contactName = res.response.contactName;
				},
				(err) => console.log(err)
			);
	}

	getContacts(clientId: number, setNewestContact = false)	{
		this.clientContactService.getContactByParentId(clientId)
			.subscribe(
				(res: any) => {
					this.contacts = res.response;

					if (setNewestContact) {
						this.getContact(this.contacts[this.contacts.length - 1].id);
					} else if (this.contacts.some(c => c.id === this.item.clientContactId)) {
						// do nothing, the contact is present
					}
					else
					{
						this.getContact(this.contacts[0].id);
					}

					this.contacts.sort((a,b) => a.contactName.localeCompare(b.contactName));
				},
				(err) => console.log(err)
			);
	}

	getItem(lookupId: Guid, buttonMode: string) {
		this.itemService.getSingleByLookupId(lookupId)
			.subscribe(
				(res: any) => {
					this.item = res.response;
					this.followUps = JSON.parse(this.item.followUps);
					this.lineItems = JSON.parse(this.item.lineItems);
					this.getContacts(this.item.clientId);
					this.getUser(false, buttonMode);
					this.generateValue();
					this.showForm = true;
				},
				(err) => {
					console.log(err);
					notify("Something Went Wrong!", "Error", 5000);
				},
			);
	}
	
	getPeople() {
		this.userProfileService.getActiveUsers()
			.subscribe(
				(res: any) =>  {
					this.people = res.response;
					this.people.sort((a,b) => a.fullName.localeCompare(b.fullName));
				},
				(err) => console.log(err)
			);
	}

	getProjects(setNewestProject = false) {
		this.projectService.getAll()
			.subscribe(
				(res: any) => {
					this.projects = res.response.items;
					if (setNewestProject) {
						this.item.projectId = this.projects[this.projects.length - 1].id;
					}

					this.projects.sort((a,b) => a.name.localeCompare(b.name));
				},
				(err) => console.log(err)
			);
	}

	getUser(setAssignedUser: boolean, buttonMode: string) {
		this.authServices.getCurrentUser()
			.subscribe(
				(res: any) => { 
					this.user = res.response;
					if (setAssignedUser) {
						this.item.assignedToId = this.user.id;
						this.item.pointOfContactId = this.user.id;
					}
					this.buildButtons(buttonMode);
				},
				(err) => console.log(err)
			);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	renderUser(id?: number){
		if (id === undefined || id === null) {
			return "";
		}
		
		const element = this.people.find(x=>x.id === id);

		return element!.fullName;
	}

	requestApproval() {
		if (!this.item.approved)
		{
			this.itemService.requestApproval(this.item.id)
				.subscribe(
					() => {
						notify("Approval Requested by Email", "success", 5000);
					},
					(err: any) => console.log(err),
				);
		}
	}

	setNewFollowUpUserId(e: any) {
		e.data.followUpDate = new Date;
		e.data.userId = this.user.id;
	}

	updateItem() {
		this.updateLineNumbers();
		this.item.followUps = JSON.stringify(this.followUps);
		this.item.lineItems = JSON.stringify(this.lineItems);
		this.generateValue();
		this.itemService.updateSingleById(this.item.id, this.item)
			.subscribe(
				() => {
					notify("Successfully Updated", "success", 5000);
					// this.cancelEditing();
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	updateLineNumbers() {
		for (let i = 0; i < this.lineItems.length; i++) {
			this.lineItems[i].lineNumber = i + 1;
		}
	}

	viewFeasibilityPopup() {
		this.popupTitle = "View Feasibility";
		this.popupVisible = true;
		this.popupSaveButtonOptions = { onClick: () => this.closePopup(), text: "Close", visible: false };
	}
}