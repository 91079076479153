import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { HolidayRequestStatus } from "@enums/holdayRequestStatus.enum";
import { HolidayTime } from "@enums/holidayTime.enum";
import { PromiseDepartment } from "@enums/promiseDepartment.enum";
import { PromiseType } from "@enums/promiseType.enum";
import { Shift } from "@enums/shift.enum";
import { GetPropertiesFromEnum } from "@helpers/getPropertiesFromEnum";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { Dashboard } from "@interfaces/dashboard.interface";
import { HolidayRequest } from "@interfaces/holidayRequest.interface";
import { Promise } from "@interfaces/promise.interface";
import { SalesEnquiry } from "@interfaces/salesEnquiry.interface";
import { UserProfile } from "@interfaces/userProfile.interface";
import { AppSettingsService } from "@services/appSettings.service";
import { DashboardService } from "@services/dashboard.service";
import { HolidayRequestService } from "@services/holidayRequest.service";
import { PromiseService } from "@services/promise.service";
import { PurchaseLogService } from "@services/purchaseLog.service";
import { SalesEnquiryService } from "@services/salesEnquiry.service";
import { UserProfileService } from "@services/userProfile.service";
import notify from "devextreme/ui/notify";

@Component({
	moduleId: module.id,
	selector: "dashboard",
	styleUrls: ["dashboard.css"],
	templateUrl: "dashboard.html"
})
export class DashboardAdminComponent {
	dashboards: Dashboard[] = [];
	dayShiftHolidayRequests: HolidayRequest[] = [];
	filterPromiseDepartment?: PromiseDepartment;
	goodsInDashboard: any[] = [];
	headerPrimaryButtons: AdminHeaderButton[] = [];
	headerSecondaryButtons: AdminHeaderButton[] = [];
	holidayRequestStatuses: any;
	holidayRequests: HolidayRequest[] = [];
	holidayTimes: any;
	mode = "";
	myBreakdown = [
		{amount: 0, type: "approved"},
		{amount: 0, type: "assigned"},
		{amount: 0, type: "submitted"}
	];
	mySummary: SalesEnquiry[] = [];
	nightShiftHolidayRequests: HolidayRequest[] = [];
	people: UserProfile[] = [];
	popupAddButtonOptions: any;
	popupCloseButtonOptions: any = { onClick: () => this.closePopup(), text: "Close" };
	popupPromise: Promise = new Promise();
	popupTitle = "";
	popupVisible = false;
	promiseBoard: any[] = [];
	promiseDepartments: any;
	promiseTypes: any;
	quotesSent = 0;
	quotesWaitingApproval = 0;
	thirtyDayValue = 0;
	title = "Dashboard";
	today = "";

	constructor(private router: Router, private appSettingsService: AppSettingsService, private dashboardService: DashboardService, private holidayRequestService: HolidayRequestService, private promiseService: PromiseService, private purchaseLogService: PurchaseLogService, private userProfileService: UserProfileService, private salesEnquiryService: SalesEnquiryService) {
		this.viewSalesEnquiryRow = this.viewSalesEnquiryRow.bind(this);
		this.getDashboards();
		this.holidayRequestStatuses = GetPropertiesFromEnum(HolidayRequestStatus);
		this.holidayTimes = GetPropertiesFromEnum(HolidayTime);
		this.promiseDepartments = GetPropertiesFromEnum(PromiseDepartment);
		this.promiseTypes = GetPropertiesFromEnum(PromiseType);

		const today = new Date();
		this.today = today.toLocaleDateString("en-GB");
	}

	addNewPromise() {		
		this.promiseService.createSingle(this.popupPromise)
			.subscribe(
				() => {
					this.popupVisible = false;
					this.popupPromise = new Promise();
					this.getPromiseBoard();
					notify("Promise Created", "Success", 5000);
				},
				(err: any) => {
					notify("Something Went Wrong!", "Error", 5000);
					console.log(err);
				}
			);

		this.closePopup();
	}

	addNewPromisePopup() {
		this.popupPromise = new Promise();
		this.popupTitle = "Add New Promise";
		this.popupVisible = true;
		this.popupAddButtonOptions = { onClick: () => this.addNewPromise(), text: "Add Promise" };
	}

	boolYesNo(bool: boolean) {
		return bool === true ? "YES" : "NO";
	}

	closePopup() {
		this.popupVisible = false;
		this.popupPromise = new Promise();
	}

	deletePromise(e: any) {		
		if (confirm("Are you sure you wisth to delete this promise? This can't be undone.")== false) {
			return;
		}
		this.promiseService.deleteSingleById(e.id)
			.subscribe(
				() => {
					this.getPromiseBoard();
					notify("Promise Deleted", "Success", 5000);
				},
				(err: any) => {
					notify("Something Went Wrong!", "Error", 5000);
					console.log(err);
				}
			);
	}

	departmentFilterChanged(args: any) {
		this.filterPromiseDepartment = args.value;

		this.getPromiseBoard();
	}

	editPromise() {		
		this.promiseService.updateSingleById(this.popupPromise.id, this.popupPromise)
			.subscribe(
				() => {
					this.popupVisible = false;
					this.popupPromise = new Promise();
					this.getPromiseBoard();
					notify("Promise Updated", "Success", 5000);
				},
				(err: any) => {
					notify("Something Went Wrong!", "Error", 5000);
					console.log(err);
				}
			);

		this.closePopup();
	}

	editPromisePopup(e: any) {
		this.popupPromise = new Promise(e);
		this.popupTitle = "Edit Promise";
		this.popupVisible = true;
		this.popupAddButtonOptions = { onClick: () => this.editPromise(), text: "Save Changes" };
		return false;
	}

	getDashboards() {
		this.headerPrimaryButtons = [
			{ method: "Promises", text: "Promises" },
			{ method: "GoodsIn", text: "Goods In" }
		];

		this.dashboardService.getForCurrentUser()
			.subscribe(
				(res:any) => {
					this.dashboards = res.response;

					this.dashboards.forEach(dashboard => {
						this.headerPrimaryButtons.push({ method: dashboard.name, text: dashboard.name });
					});

					// Default Mode
					const defaultMode = this.appSettingsService.get("dashboard", "Promises");
					if (this.headerPrimaryButtons.findIndex(h => h.text === defaultMode) > -1) {
						this.setMode(defaultMode);
					} else {
						this.setMode("Promises");
					}
				},
				(err) => console.log(err)
			);
	}

	getGoodsInDashboard() {
		this.purchaseLogService.goodsInDashboard()
			.subscribe(
				(res: any) => this.goodsInDashboard = res.response,
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				},
			);
	}
	
	getPeople() {
		this.userProfileService.getActiveUsers()
			.subscribe(
				(res: any) =>  {
					this.people = res.response;
					this.people.sort((a,b) => a.fullName.localeCompare(b.fullName));
				},
				(err) => console.log(err)
			);
	}

	getPromiseBoard() {
		if (this.filterPromiseDepartment !== undefined && this.filterPromiseDepartment !== null) {		
			this.promiseService.getPromiseBoardForDepartment(this.filterPromiseDepartment)
				.subscribe(
					(res: any) => this.promiseBoard = res.response,
					(err) => {
						console.log(err);
						notify("Something went wrong!", "error", 5000);
					},
				);

			return;
		}

		this.promiseService.getPromiseBoard()
			.subscribe(
				(res: any) => this.promiseBoard = res.response,
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				},
			);
	}
	
	getSalesDashboardData() {
		this.salesEnquiryService.myBreakdown()
			.subscribe(
				(res: any) => this.myBreakdown = res.response,
				(err) => console.log(err)
			);
		this.salesEnquiryService.mySummary()
			.subscribe(
				(res: any) => this.mySummary = res.response,
				(err) => console.log(err)
			);
		this.salesEnquiryService.quotesSent()
			.subscribe(
				(res: any) => this.quotesSent = res.response,
				(err) => console.log(err)
			);
		this.salesEnquiryService.quotesWaitingApproval()
			.subscribe(
				(res: any) => this.quotesWaitingApproval = res.response,
				(err) => console.log(err)
			);
		this.salesEnquiryService.thirtyDayValue()
			.subscribe(
				(res: any) => this.thirtyDayValue = res.response,
				(err) => console.log(err)
			);
	}
	
	getStaffDashboardData() {
		this.getPeople();

		this.holidayRequestService.dashboard()
			.subscribe(
				(res: any) => {
					this.holidayRequests = res.response;
					this.dayShiftHolidayRequests = this.holidayRequests.filter((hr) => hr.requestedBy.shiftId === Shift.Day);
					this.nightShiftHolidayRequests = this.holidayRequests.filter((hr) => hr.requestedBy.shiftId === Shift.Night);
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				},
			);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) { this[method](); }
		else {
			this.setMode(method);
		}
	}

	setMode(mode: string) {
		this.mode = mode;
		this.title = `${mode} Dashboard`;
		this.appSettingsService.set("dashboard", mode);
		this.headerSecondaryButtons = [];

		// Fetch data relevant to the mode here
		switch (mode) {
			case "GoodsIn":
				this.getGoodsInDashboard();
				break;
			case "Promises":
				this.headerSecondaryButtons.push({ icon: "fal fa-plus", method: "addNewPromisePopup", text: "New Promise" });
				this.getPromiseBoard();
				break;
			case "Sales":
				this.getSalesDashboardData();
				break;
			case "Staff":
				this.getStaffDashboardData();
				break;
		}
	}

	viewSalesEnquiryRow(e: any) {
		this.router.navigate([`admin/salesEnquiries/${e.row.data.id}`]);
	}
}