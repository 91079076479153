import { Currency } from "@enums/currency.enum";
import { Incoterms } from "@enums/incoterms.enum";
import { PackagingTerms } from "@enums/packagingTerms.enum";
import { Model } from "@interfaces/model.interface";

export interface QuotationAnalysisForm extends Model {
	additionalCostsSubtotal: number;
	additionalCostsSubtotalDisplayToCustomer: boolean;
	additionalNotes: string;
	annualVolume: number;
	approved: boolean;
	approvedById? : number;
	approvedOn?: Date;
	batchSize: number;
	boughtOutPartsDisplayToCustomer: boolean;
	boughtOutPartsLineData: string; // JSON format, BoughtOutPartsLineData
	boughtOutPartsOverhead: number;
	boughtOutPartsSubtotal: number;
	cadFiles: string; // JSON format, SupportingDocumentFileLineData
	clientContactId?: number;
	clientId: number;
	clientLocationId?: number;
	containerCost: number;
	currency: Currency;
	currentEngineeringLevel: string;
	deliveryTerms: Incoterms;
	destinationName: string;
	grandTotal: number;
	leadTimeFirstOff: string;
	leadTimePhaseThree: string;
	manufacturingVendorCode: string;
	materialOverhead: number;
	materialOverheadDisplayToCustomer: boolean;
	numberOfShifts: number;
	oldEngineeringLevel: string;
	outsourcedOverhead: number;
	outsourcedOverheadDisplayToCustomer: boolean;
	outsourcedProcessesLineData: string; // JSON format, OutsourcedProcessesLineData
	outsourcedProcessesSubtotal: number;
	packagingAndLogistics: number;
	packagingId?: number;
	packagingTerms: PackagingTerms;
	parentQuotationAnalysisFormId?: number;
	partDescription: string;
	partImageUrl: string;
	partNumber: string;
	pressProcessLineData: string; // JSON format, PressProcessLineData
	pressProcessSubtotal: number;
	profit: number;
	profitDisplayToCustomer: boolean;
	projectId?: number;
	rawMaterialLineData: string; // JSON format, RawMaterialLineData
	rawMaterialSubtotal: number;
	revisionLevel: string;
	shippingGSDBCode: string;
	supersededPartNumber: string;
	supportingDocumentsLineData: string; // JSON format, SupportingDocumentFileLineData
	toolingFixtureLineData: string; // JSON format, ToolingFixtureLineData
	toolingFixtureSubtotal: number;
	toolingFixtureTotalChanges: number;
	toolingOverhead: number;
	toolingOverheadDisplayToCustomer: boolean;
	totalPartCost: number;
	unitOfMeasure: string;
	weeklyMaxCapacity: number;
	weldAndAssemblyLineData: string; // JSON format, WeldAndAssemblyLineData
	weldAndAssemblySubtotal: number;
}

export class QuotationAnalysisForm implements QuotationAnalysisForm {
	currency = Currency.GBP;
	constructor(data?: Partial<QuotationAnalysisForm>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}