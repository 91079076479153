import { FirewallLogFailureReason } from "@enums/firewallLogFailureReason.enum";
import { Model } from "@interfaces/model.interface";

export interface FirewallLog extends Model {
	allocationId?: number;
	areaOfIssue: string;
	asnNumber: string;
	clientId?: number;
	failureReason: FirewallLogFailureReason;
	imageUrl: string;
	isProduction: boolean;
	nonConformanceReportId?: number;
	numberOfPartsChecked: number;
	numberOfPartsFailed: number;
	numberOfPartsReworkable: number;
	operatorId: number;
	partId?: number;
	prePaint: boolean;
	projectId?: number;
}

export class FirewallLog implements FirewallLog {
	isProduction = false;
	prePaint = false;
	
	constructor(data?: Partial<FirewallLog>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}