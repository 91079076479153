import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { NonConformanceReportConcernOrigin } from "@enums/nonConformanceReportConcernOrigin.enum";
import { NonConformanceReportStatus } from "@enums/nonConformanceReportStatus.enum";
import { GetPropertiesFromEnum } from "@helpers/getPropertiesFromEnum";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { Client } from "@interfaces/client.interface";
import { Part } from "@interfaces/part.interface";
import { LogExport } from "@interfaces/logExport.interface";
import { UserProfile } from "@interfaces/userProfile.interface";
import { ClientService } from "@services/client.service";
import { ExportLogService } from "@services/exportLog.service";
import { NonConformanceReportService } from "@services/nonConformanceReport.service";
import { PartService } from "@services/part.service";
import { UserProfileService } from "@services/userProfile.service";
import notify from "devextreme/ui/notify";

@Component({
	moduleId: module.id,
	selector: "nonConformanceReports",
	styleUrls: ["nonConformanceReports.css"],
	templateUrl: "nonConformanceReports.html"
})
export class NonConformanceReportsAdminComponent {
	clients: Client[] =[];
	concernOrigins: any;
	deleteEnabled = true;
	editEnabled = true;
	gridLoading = false;
	headerPrimaryButtons: AdminHeaderButton[] = [];
	itemType = "Non Conformance Report";
	itemUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	items: any[] = [];
	parts: Part[] = [];
	people: UserProfile[] = [];
	statuses: any;
	title: string;
	
	constructor(private router: Router, private exportLogService: ExportLogService, private itemService: NonConformanceReportService, private partService: PartService, private userProfileService: UserProfileService, private clientService: ClientService) {
		this.concernOrigins = GetPropertiesFromEnum(NonConformanceReportConcernOrigin);
		this.statuses = GetPropertiesFromEnum(NonConformanceReportStatus);
		this.headerPrimaryButtons.push({ icon: "fal fa-plus", method: "addNewItem", text: `New ${this.itemType}` });
		this.title = `${this.itemType}s`;
		this.viewRow = this.viewRow.bind(this);
		this.editRow = this.editRow.bind(this);
		this.deleteRow = this.deleteRow.bind(this);
		this.getItems();
		this.getParts();
		this.getPeople();
		this.getClients();
	}

	addNewItem() {
		this.router.navigate([`${this.itemUrl}/create`]);
	}

	deleteRow(e: any) {
		const result = confirm("Are you sure you want to delete this item?");
		if(result)
		{
			this.itemService.deleteSingleById(e.row.data.id)
				.subscribe(
					() => {
						notify(this.itemType + " deleted!", "success", 5000);
						this.getItems();
					},
					(err) => {
						console.log(err);
						notify("Something went wrong!", "error", 5000);
					},
				);
		}
	}

	editRow(e: any) {
		this.router.navigate([`${this.itemUrl}/${e.row.data.lookupId}_edit`]);
	}

	getClients() {
		this.clientService.getAll()
			.subscribe(
				(res: any) => this.clients = res.response.items,
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				},
			);
	}

	getItems() {
		this.itemService.getGrid()
			.subscribe(
				(res: any) => this.items = res.response,
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				},
			);
	}

	getParts() {
		this.partService.getAll()
			.subscribe(
				(res: any) => this.parts = res.response.items,
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				},
			);
	}
	
	getPeople() {
		this.userProfileService.getActiveUsers()
			.subscribe(
				(res: any) =>  {
					this.people = res.response;
					this.people.sort((a,b) => a.fullName.localeCompare(b.fullName));
				},
				(err) => console.log(err)
			);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	isEditEnabledForRow(e: any) {
		return e.row.data.status !== NonConformanceReportStatus.Closed;
	}

	logExport() {
		const logExport = new LogExport();
		logExport.gridName = "Non Conformance Reports";
		this.exportLogService.logExport(logExport)
			.subscribe(
				() => console.log("Export Logged"),
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
					return false;
				},
			);
	}

	viewRow(e: any) {
		this.router.navigate([`${this.itemUrl}/${e.row.data.lookupId}`]);
	}
}