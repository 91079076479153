import { MachineEventType } from "@enums/machineEventType.enum";
import { Model } from "@interfaces/model.interface";

export interface MachineEvent extends Model {
	eventDate: Date;
	eventType: MachineEventType;
	machineId: number;
}

export class MachineEvent implements MachineEvent {
	eventDate = new Date;
	eventType = MachineEventType.Service;

	constructor(data?: Partial<MachineEvent>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}