<dx-data-grid #masterDetailsGridContainer class="data-grid" [dataSource]="allocationWorkOrders" [remoteOperations]="false" [allowColumnReordering]="true" [rowAlternationEnabled]="true" [showBorders]="true">
	<dxo-paging [pageSize]="100"></dxo-paging>
	<dxo-state-storing [enabled]="true" type="localStorage" storageKey="allocationGridLineItems"></dxo-state-storing>
	<dxo-pager [visible]="false" [showPageSizeSelector]="false" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
	<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
	<dxo-group-panel [visible]="false"></dxo-group-panel>
	<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
	<dxo-export [enabled]="false"></dxo-export>
	<dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>
	<dxi-column dataField="workOrderId" dataType="text" alignment="left" caption="Work Order"></dxi-column>
	<dxi-column dataField="quantity" dataType="number" alignment="right" caption="Quantity Allocated"></dxi-column>
	<dxi-column dataField="reportedBy.fullName" dataType="text" alignment="left" caption="Allocated By"></dxi-column>
</dx-data-grid>