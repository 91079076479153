import { Component, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CheckGroupPermissions } from "@helpers/checkGroupPermissions";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { QmsDocument } from "@interfaces/qmsDocument.interface";
import { QmsDocumentVersion } from "@interfaces/qmsDocumentVersion.interface";
import { UserProfile } from "@interfaces/userProfile.interface";
import { AppSettingsService } from "@services/appSettings.service";
import { AuthService } from "@services/auth.service";
import { FileService } from "@services/file.service";
import { QmsDocumentService } from "@services/qmsDocument.service";
import { QmsDocumentVersionService } from "@services/qmsDocumentVersion.service";
import { UserProfileService } from "@services/userProfile.service";
import notify from "devextreme/ui/notify";
import { Guid } from "guid-typescript";

@Component({
	encapsulation: ViewEncapsulation.None,
	moduleId: module.id,
	selector: "qmsDocuments-id",
	styleUrls: ["qmsDocuments-id.css"],
	templateUrl: "qmsDocuments-id.html"
})
export class QmsDocumentsIdAdminComponent {
	changes: any = [];
	createEnabled = true;
	currentUserProfile: UserProfile = new UserProfile;
	editEnabled = true;
	headerPrimaryButtons: AdminHeaderButton[] = [];
	headerSecondaryButtons: AdminHeaderButton[] = [];
	headerTertiaryButtons: AdminHeaderButton[] = [];
	isSuperAdmin = false;
	item: QmsDocument = new QmsDocument();
	itemType = "QMS Document";
	itemUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	people: UserProfile[] = [];
	popupAddButtonOptions: any;
	popupCloseButtonOptions: any = { onClick: () => this.closePopup(), text: "Close" };
	popupTitle = "";
	popupVersion: QmsDocumentVersion = new QmsDocumentVersion();
	popupVersionFileLookupId = "";
	popupVisible = false;
	readOnly = true;
	returnUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	screenMode = "";
	title: string = "View " + this.itemType;
	versions: any[] = [];

	constructor(private router: Router, private route: ActivatedRoute, private appSettingsService: AppSettingsService, private authService: AuthService, private itemService: QmsDocumentService, private fileService: FileService, private qmsDocumentVersionService: QmsDocumentVersionService, private userProfileService: UserProfileService) {
		this.isSuperAdmin = CheckGroupPermissions(appSettingsService, "Super Admin");
		
		this.getCurrentUserProfile();
		this.getPeople();

		this.route.params.subscribe((params) => {
			if (params.id != "create") {
				if (params.id.split("_").length > 1) {
					this.getItem(params.id.split("_")[0], "edit");
					this.edit();
				} else {
					this.getItem(params.id, "view");
					this.buildButtons("view");
				}
			} else {
				this.create();
			}
		});

		this.approveVersion = this.approveVersion.bind(this);
		this.deleteVersion = this.deleteVersion.bind(this);
		this.downloadVersionFileLink = this.downloadVersionFileLink.bind(this);
		this.isVersionApproveButtonAvailable = this.isVersionApproveButtonAvailable.bind(this);
		this.isVersionDeleteButtonAvailable = this.isVersionDeleteButtonAvailable.bind(this);
		this.isVersionDownloadButtonAvailable = this.isVersionDownloadButtonAvailable.bind(this);
		this.uploadVersionFile = this.uploadVersionFile.bind(this);
	}

	addNewVersion() {
		if (this.popupVersionFileLookupId === "") {
			alert("Please upload the new file");
			return;
		}
		this.fileService.getSingleByLookupId(Guid.parse(this.popupVersionFileLookupId))
			.subscribe(
				(res: any) => {
					this.popupVersion.fileId = res.response.id;
					
					this.qmsDocumentVersionService.createSingle(this.popupVersion)
						.subscribe(
							() => {
								this.popupVisible = false;
								this.popupVersion = new QmsDocumentVersion();
								this.getVersions(this.item.id, this.screenMode);
							},
							(err: any) => console.log(err)
						);
				}
			);
	}

	addNewVersionPopup() {
		this.popupVersion = new QmsDocumentVersion();
		this.popupVersion.qmsDocumentId = this.item.id;
		this.popupTitle = "Add New Version";
		this.popupVisible = true;
		this.popupAddButtonOptions = { onClick: () => this.addNewVersion(), text: "Add Item" };
	}

	approveVersion(e: any) {
		if (this.isSuperAdmin === false && this.item.documentOwnerId != this.currentUserProfile.id) {
			return;
		}

		if (confirm("Are you sure you want to approve this version?") == true)
		{
			this.qmsDocumentVersionService.approve(e.row.data.id)
				.subscribe(
					() => {
						notify("Version approved!", "success", 5000);
						this.getItem(this.item.lookupId, this.screenMode);
					},
					(err) => {
						console.log(err);
						notify("Something went wrong!", "error", 5000);
					},
				);
		}
	}

	buildButtons(mode: string) {
		this.screenMode = mode;
		this.headerPrimaryButtons = [];
		this.headerSecondaryButtons = [];
		this.headerTertiaryButtons = [];

		if (mode == "edit" && this.editEnabled) {
			this.headerPrimaryButtons = [
				{ method: "updateItem", text: "Save Changes" },
				{ method: "cancelEditing", text: "View Mode" },
				{ method: "close", text: "Close" }
			];
		} else if (mode == "create" && this.createEnabled) {
			this.headerPrimaryButtons = [
				{ method: "createItem", text: "Save" },
				{ method: "close", text: "Close" }
			];
		} else if (mode == "view") {
			this.headerPrimaryButtons = [
				{ method: "edit", text: "Edit" },
				{ method: "close", text: "Close" }
			];

			if (this.versions.length === 0 || this.versions.filter(v => v.isApproved === false).length === 0) {
				this.headerTertiaryButtons.push({ method: "addNewVersionPopup", text: "Add New Version" });
			}
		}
	}

	cancelEditing() {
		if (confirm("Are you sure you wish to switch modes? Unsaved changes will be discarded.") == false) {
			return;
		}
		this.readOnly = true;
		this.buildButtons("view");
		this.title = "View " + this.itemType;
	}

	close() {
		if (this.screenMode === "view") {
			this.router.navigate([`/${this.returnUrl}/`]);
			return;
		}
		if (confirm("Are you sure you wish to leave the page? Unsaved changes will be discarded.")) {
			this.router.navigate([`/${this.returnUrl}/`]);
			return;
		}
	}

	closePopup() {
		this.popupVisible = false;
	}

	create() {
		this.readOnly = false;
		this.buildButtons("create");
		this.title = "Create New " + this.itemType;
	}

	createItem() {
		this.itemService.createSingle(this.item)
			.subscribe(
				(res: any) => {
					notify("Successfully Created " + this.itemType, "success", 5000);
					this.router.navigate([`${this.itemUrl}/${res.response.lookupId}_edit`]);
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	deleteVersion(e: any) {
		if (this.isSuperAdmin === false && this.item.documentOwnerId != this.currentUserProfile.id) {
			return;
		}

		if (confirm("Are you sure you want to delete this item?") == true)
		{
			this.qmsDocumentVersionService.deleteSingleById(e.row.data.id)
				.subscribe(
					() => {
						notify("Version deleted!", "success", 5000);
						this.getVersions(this.item.id, this.screenMode);
					},
					(err) => {
						console.log(err);
						notify("Something went wrong!", "error", 5000);
					},
				);
		}
	}

	downloadVersionFileLink(e: any) {
		const lookupId = Guid.parse(e.row.data.file.lookupId);
		this.fileService.downloadFile(lookupId).subscribe(
			(response: any) =>{
				const dataType = response.type;
				const binaryData = [];
				binaryData.push(response);
				const downloadLink = document.createElement("a");
				downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
				downloadLink.setAttribute("download", e.row.data.file.name);
				document.body.appendChild(downloadLink);
				downloadLink.click();
			}
		);
		return false;
	}

	edit() {
		this.buildButtons("edit");
		this.readOnly = false;
		this.title = "Edit " + this.itemType;
		notify("You are now editing...", "warning", 5000);
	}

	getCurrentUserProfile()
	{
		this.authService.getCurrentUser()
			.subscribe(
				(res: any) => {
					this.currentUserProfile = res.response;
				},
				(err) => console.log(err)
			);
	}

	getItem(itemId: Guid, mode: string) {
		this.itemService.getSingleByLookupId(itemId)
			.subscribe(
				(res: any) => {
					this.item = res.response;
					this.getVersions(this.item.id, mode);
				},
				(err) => {
					console.log(err);
					notify("Something Went Wrong!", "Error", 5000);
				}
			);
	}
	
	getPeople() {
		this.userProfileService.getActiveUsers()
			.subscribe(
				(res: any) =>  {
					this.people = res.response;
					this.people.sort((a,b) => a.fullName.localeCompare(b.fullName));
				},
				(err) => console.log(err)
			);
	}

	getVersions(id: number, mode: string) {
		this.qmsDocumentVersionService.getByQMSDocumentId(id)
			.subscribe(
				(res: any) => {
					this.versions = res.response;
					this.buildButtons(mode);
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				},
			);
	}
	
	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	isVersionApproveButtonAvailable(e: any) {
		return e.row.data.isApproved == false && (this.isSuperAdmin || this.item.documentOwnerId == this.currentUserProfile.id);
	}

	isVersionDeleteButtonAvailable(e: any) {
		return e.row.data.isApproved == false && (this.isSuperAdmin || this.item.documentOwnerId == this.currentUserProfile.id);
	}

	isVersionDownloadButtonAvailable(e: any) {
		return e.row.data.isApproved == true || this.isSuperAdmin || this.item.documentOwnerId == this.currentUserProfile.id;
	}

	updateItem() {
		this.itemService.updateSingleById(this.item.id, this.item)
			.subscribe(
				() => {
					notify("Successfully Updated", "success", 5000);
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	uploadVersionFile(file: File) {
		const containerName = "qmsdocuments";
		const uploadData = new FormData();
		uploadData.append("containerName", containerName);
		uploadData.append("files", file);
		this.fileService.uploadFile(uploadData)
			.subscribe(
				(res: any) => {
					this.popupVersionFileLookupId = res.response[0].lookupId;
				},
				(err) => {
					console.log(err);
					notify("Upload Failed - Please ensure you are uploading a valid file", "error", 5000);
				}
			);
	}
}