<admin-header [primaryButtons]="headerPrimaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<dx-data-grid id="grid" class="data-grid" [dataSource]="items" [remoteOperations]="false" [allowColumnReordering]="true" [allowColumnResizing]="true" [rowAlternationEnabled]="true" [showBorders]="true" (onExporting)="logExport()">
    <dxo-paging [pageSize]="100"></dxo-paging>
	<dxo-state-storing [enabled]="true" type="localStorage" storageKey="edisnMain"></dxo-state-storing>
    <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
    <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
	<dxo-export [enabled]="true"></dxo-export>
	<dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>
	<dxi-column type="buttons">
        <dxi-button hint="View" icon="fal fa-eye" [onClick]="viewRow"></dxi-button>
		<dxi-button hint="Edit" icon="fal fa-pencil" [onClick]="editRow" [visible]="isEditButtonAvailable"></dxi-button>
    </dxi-column>
    <dxi-column dataField="id" dataType="number" alignment="left"></dxi-column>
	<dxi-column dataField="asnNumber" dataType="string"  alignment="left"></dxi-column>
	<dxi-column dataField="dateRequired" dataType="date" format="dd/MM/yyy" alignment="left"></dxi-column>
	<dxi-column dataField="clientLocationId" dataType="number" alignment="left" caption="Location">
		<dxo-lookup [dataSource]="clientLocations" displayExpr="name" valueExpr="id"></dxo-lookup>
		<dxi-validation-rule type="required"></dxi-validation-rule>
	</dxi-column>
    <dxi-column dataField="clientLocationId" dataType="string" alignment="left"></dxi-column>
    <dxi-column [showEditorAlways]='false' dataField="isLocked" alignment="left" [customizeText]="boolYesNo" caption="Finalised"></dxi-column>
    <dxi-column [showEditorAlways]='false' dataField="isSent" alignment="left" [customizeText]="boolYesNo" caption="Sent"></dxi-column>
</dx-data-grid>
<dx-popup [width]="'55%'" [height]="'auto'" [showTitle]="true" [title]="popupTitle" [dragEnabled]="false" [closeOnOutsideClick]="false" [showCloseButton]="false" container=".dx-viewport" [(visible)]="popupVisible">
	<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="before" [options]="popupAddButtonOptions"></dxi-toolbar-item>
	<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="after" [options]="popupCloseButtonOptions"></dxi-toolbar-item>
	<dx-form class="m-3" [(formData)]="popupCreateShippingNote" [showColonAfterLabel]="true" labelLocation="top" [colCount]="3">
		<dxi-item [colSpan]="3"><p>Please ensure there are no existing unsent EDI orders for any of the selected parts for the date, location and dock.</p></dxi-item>
		<dxi-item [label]="{ text: 'Location' }" dataField="clientLocationId" editorType="dxSelectBox" [editorOptions]="{ items: clientLocations, displayExpr:'name', valueExpr:'id' }"></dxi-item>
		<dxi-item dataField="dock"><dxi-validation-rule type="required" message="Dock is required"></dxi-validation-rule></dxi-item>
		<dxi-item dataField="dateRequired" editorType="dxDateBox" dataType="date" [editorOptions]="{ displayFormat: 'dd/MM/yyyy' }"><dxi-validation-rule type="required" message="Date Required is required"></dxi-validation-rule></dxi-item>
		<dxi-item itemType="group" caption="Parts" [colSpan]="3">
			<dx-data-grid class="data-grid" [dataSource]="popupCreateShippingNoteRequiredParts" [remoteOperations]="false" [allowColumnReordering]="false" [allowColumnResizing]="true" [rowAlternationEnabled]="true" [showBorders]="true" height="250px">
				<dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
				<dxo-editing mode="popup" [allowAdding]="true" [allowDeleting]="true" [allowUpdating]="true" [confirmDelete]="true" [useIcons]="true" newRowPosition="last" [changes]="changes" [(editRowKey)]="editRowKey">
					<dxo-popup title="Part Details" [showTitle]="true" [width]="'55%'" [height]="'auto'"></dxo-popup>
					<dxo-form [colCount]="2">
						<dxi-item [colSpan]="2" dataField="partId" editorType="dxSelectBox" [editorOptions]="{ items: parts, searchEnabled: true, displayExpr:'partNumber', valueExpr:'id' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>	
						<dxi-item dataField="quantity"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
						<dxi-item dataField="orderReference"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
						<dxi-item dataField="buyerReference"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
					</dxo-form>
				</dxo-editing>
				<dxo-toolbar>
					<dxi-item name="addRowButton" showText="always"></dxi-item>
				</dxo-toolbar>
				<dxi-column type="buttons">
					<dxi-button name="edit"></dxi-button>
					<dxi-button name="delete"></dxi-button>
				</dxi-column>
				<dxi-column dataField="partId" dataType="number" alignment="left" caption="Part">
					<dxo-lookup [dataSource]="parts" displayExpr="partNumber" valueExpr="id"></dxo-lookup>
					<dxi-validation-rule type="required"></dxi-validation-rule></dxi-column>
				<dxi-column dataField="quantity" dataType="number" alignment="left"></dxi-column>
				<dxi-column dataField="orderReference" dataType="string" alignment="left" caption="Order Ref."></dxi-column>
				<dxi-column dataField="buyerReference" dataType="string" alignment="left" caption="Buyer Ref."></dxi-column>
			</dx-data-grid>
		</dxi-item>
		<dxi-item [colSpan]="3">
			<p *ngIf="validationErrors.length > 0">Please fix the below issues:</p>
			<ul *ngIf="validationErrors.length > 0" style="overflow-y: scroll; height: 30px;">
				<li *ngFor="let validationError of validationErrors" class="text-danger">{{validationError}}</li>
			</ul>
			<p>&nbsp;</p>
		</dxi-item>
	</dx-form>
</dx-popup>
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#grid' }" [(visible)]="gridLoading" [showIndicator]="true" [showPane]="true" [shading]="true"></dx-load-panel>