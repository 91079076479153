import { Injectable, Injector } from "@angular/core";
import { environment } from "@environments/environment";
import { Service } from "@services/service";

@Injectable()
export class AllocationService extends Service {
	constructor(injector: Injector) {
		super("Allocation", injector);
	}

	// API	
	app_createForEdiOrder(id: number) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/App_CreateForEdiOrder/${id}`, {});
	}

	app_getById(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/App_GetById/${id}`);
	}

	app_getEmpty() {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/App_GetEmpty`);
	}
	
	app_getIncomplete() {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/App_GetIncomplete`);
	}

	createForEdiOrder(id: number) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/CreateForEdiOrder/${id}`, {});
	}

	getFullDetails() {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetFullDetails`);
	}
}