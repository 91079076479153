<admin-header [primaryButtons]="headerPrimaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<div style="width:100%; background-color: lightgrey; height:50px;margin-top:15px; margin-bottom:15px; border-radius:15px; overflow:hidden">
<span style="float:left; padding:15px;">Client Selector</span>
<span style="float:right; padding-top:10px !important; padding-right:10px !important; color:white;">
	<dx-drop-down-button [text]="selectedClient.name" [dropDownOptions]="{ width: 230 }" icon="runner" [items]="clients" displayExpr="name" keyExpr="id" (onItemClick)="syncTreeViewSelection($event)" ></dx-drop-down-button>
</span>
</div>
<div id="orderTableContainer">
	<div id="quantityKey">
		<span>Quantities:</span>
		<span class="forecast">Forecast</span>
		<span class="noShippingNote">Ordered</span>
		<span class="shippingNoteCreated">Shipping Note Created</span>
		<span class="shippingNoteQuantityIssues">Shipping Note Quantity Issues</span>
		<span class="shippingNoteFinished">Shipping Note Sent</span>
		<span class="shippingNoteZeroSent">Shipping Note Sent but missing items</span>
	</div>
	<table id="orderTable">
		<thead>
			<tr class="topHeader">
				<th scope="row" colspan="6">Week Number</th>
				<th *ngFor="let header of orderTable.orderHeaders" colspan="8">{{header.weekNumber}}</th>
				<th *ngFor="let header of orderTable.forecastHeaders">{{header.weekNumber}}</th>
			</tr>
			<tr class="secondHeader">
				<th scope="col" class="partColumn">Part</th>
				<th scope="col" class="imageColumn">Image</th>
				<th scope="col">Location</th>
				<th scope="col">Dock</th>
				<th scope="col">YTD</th>
				<th scope="col">Arrears</th>
				<ng-template ngFor let-header [ngForOf]="orderTable.orderHeaders">
					<th scope="col">{{header.monday | date: 'd-MMM'}}</th>
					<th scope="col">{{header.tuesday | date: 'd-MMM'}}</th>
					<th scope="col">{{header.wednesday | date: 'd-MMM'}}</th>
					<th scope="col">{{header.thursday | date: 'd-MMM'}}</th>
					<th scope="col">{{header.friday | date: 'd-MMM'}}</th>
					<th scope="col" class="weekend">{{header.saturday | date: 'd-MMM'}}</th>
					<th scope="col" class="weekend">{{header.sunday | date: 'd-MMM'}}</th>
					<th scope="col" class="weekTotal">Total</th>
				</ng-template>
				<ng-template ngFor let-header [ngForOf]="orderTable.forecastHeaders">
					<th scope="col" class="weekTotal">Forecast</th>
				</ng-template>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let row of orderTable.rows">
				<th scope="row" class="partColumn" style="background: {{row.rowBackgroundColour}}"><a href="/admin/parts/{{row.partId}}" target="_blank" style="display: inline-block; width: 150px;">{{row.partNumber}}</a></th>
				<th scope="row" class="imageColumn" style="text-align:center; background: {{row.rowBackgroundColour}}"><img style="max-height:75px; width: 150px;" *ngIf="row.partImageUrl != ''" src="{{row.partImageUrl}}"/></th>
				<th scope="row" style="background: {{row.rowBackgroundColour}}">{{row.clientLocationName}}</th>
				<th scope="row" style="background: {{row.rowBackgroundColour}}">{{row.dock}}</th>
				<th scope="row" style="background: {{row.rowBackgroundColour}}">{{row.shippedToDate}}</th>
				<th scope="row" style="background: {{row.rowBackgroundColour}}">{{row.deliveryArrears}}</th>
				<ng-template ngFor let-quantity [ngForOf]="row.orderQuantities">
					<td><ediOrderDayQuantities [quantities]="quantity.monday" [dock]="row.dock" [locationId]="row.clientLocationId" [locationName]="row.clientLocationName" [isSuperAdmin]="isSuperAdmin"></ediOrderDayQuantities></td>
					<td><ediOrderDayQuantities [quantities]="quantity.tuesday" [dock]="row.dock" [locationId]="row.clientLocationId" [locationName]="row.clientLocationName" [isSuperAdmin]="isSuperAdmin"></ediOrderDayQuantities></td>
					<td><ediOrderDayQuantities [quantities]="quantity.wednesday" [dock]="row.dock" [locationId]="row.clientLocationId" [locationName]="row.clientLocationName" [isSuperAdmin]="isSuperAdmin"></ediOrderDayQuantities></td>
					<td><ediOrderDayQuantities [quantities]="quantity.thursday" [dock]="row.dock" [locationId]="row.clientLocationId" [locationName]="row.clientLocationName" [isSuperAdmin]="isSuperAdmin"></ediOrderDayQuantities></td>
					<td><ediOrderDayQuantities [quantities]="quantity.friday" [dock]="row.dock" [locationId]="row.clientLocationId" [locationName]="row.clientLocationName" [isSuperAdmin]="isSuperAdmin"></ediOrderDayQuantities></td>
					<td class="weekend"><ediOrderDayQuantities [quantities]="quantity.saturday" [dock]="row.dock" [locationId]="row.clientLocationId" [locationName]="row.clientLocationName" [isSuperAdmin]="isSuperAdmin"></ediOrderDayQuantities></td>
					<td class="weekend"><ediOrderDayQuantities [quantities]="quantity.sunday" [dock]="row.dock" [locationId]="row.clientLocationId" [locationName]="row.clientLocationName" [isSuperAdmin]="isSuperAdmin"></ediOrderDayQuantities></td>
					<td class="weekTotal"><ediOrderDayQuantities [quantities]="quantity.total" [dock]="row.dock" [locationId]="row.clientLocationId" [locationName]="row.clientLocationName" [isSuperAdmin]="isSuperAdmin"></ediOrderDayQuantities></td>
				</ng-template>
				<ng-template ngFor let-quantity [ngForOf]="row.forecastQuantities">
					<td class="forecast">{{quantity.total}}</td>
				</ng-template>
			</tr>
		</tbody>
	</table>
</div>
<dx-popup [width]="750" [height]="600" [showTitle]="true" [title]="popupTitle" [dragEnabled]="false" [closeOnOutsideClick]="false" [showCloseButton]="false" container=".dx-viewport" [(visible)]="popupVisible">
	<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="before" [options]="popupAddButtonOptions"></dxi-toolbar-item>
	<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="after" [options]="popupCloseButtonOptions"></dxi-toolbar-item>
		<div *ngIf="popupTitle === 'Import Edi Orders Message CSV'">
			<dx-form class="m-3" [(formData)]="popupImportDetails" [showColonAfterLabel]="true" labelLocation="top">
				<dxi-item>
					<p>This will create an EDI message and orders/forecasts from the CSV. This will cancel all existing future (Date >= Today) orders and shipping notes associated with the selected client <em>except</em> locked ones.</p>
				</dxi-item>
				<dxi-item dataField="clientId" editorType="dxSelectBox" [editorOptions]="{ items: clients, searchEnabled: true, displayExpr:'name', valueExpr:'id' }" [label]="{ text: 'Client' }"></dxi-item>
				<dxi-item dataField="orderReference" [label]="{ text: 'Order Reference' }"></dxi-item>
				<dxi-item>
					<dx-file-uploader [maxFileSize]="2097152" [uploadFile]="uploadCSVForImport"></dx-file-uploader>
				</dxi-item>
				<dxi-item>
					<p *ngIf="validationErrors.length > 0">Please fix the below issues and re-upload:</p>
					<ul *ngIf="validationErrors.length > 0" style="overflow-y: scroll; height: 120px;">
						<li *ngFor="let validationError of validationErrors" class="text-danger">{{validationError}}</li>
					</ul>
					<p>&nbsp;</p>
				</dxi-item>
			</dx-form>
		</div>
</dx-popup>