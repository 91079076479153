import { Routes } from "@angular/router";

import { environment } from "@environments/environment";

// Components
import { AdminHeaderComponent } from "@components/admin-header/admin-header";
import { InvoiceGridDetailsComponent } from "@components/invoiceGridDetails/invoiceGridDetails";
import { EdiOrderDayQuantitiesComponent } from "@components/ediOrderDayQuantities/ediOrderDayQuantities";
import { FooterComponent } from "@components/footer/footer";
import { HeaderComponent } from "@components/header/header";
import { ModalComponent } from "@components/modal/modal";

// Modals

// Admin Routes
import { AccidentAdminComponent } from "@pages/adminRoute/accidents/accidents";
import { AccidentIdAdminComponent } from "@pages/adminRoute/accidents-id/accidents-id";
import { AdminRouteComponent } from "@pages/adminRoute/adminRoute";
import { AuditAdminComponent } from "@pages/adminRoute/audits/audits";
import { AuditIdAdminComponent } from "@pages/adminRoute/audits-id/audits-id";
import { BankDetailsAdminComponent } from "@pages/adminRoute/bankDetails/bankDetails";
import { BankDetailsIdAdminComponent } from "@pages/adminRoute/bankDetails-id/bankDetails-id";
import { ClientAdminComponent } from "@pages/adminRoute/clients/clients";
import { ClientIdAdminComponent } from "@pages/adminRoute/clients-id/clients-id";
import { ClockingAdminComponent } from "@pages/adminRoute/clocking/clocking";
import { CompanyHolidaysAdminComponent } from "@pages/adminRoute/companyHolidays/companyHolidays";
import { CompanyHolidaysIdAdminComponent } from "@pages/adminRoute/companyHolidays-id/companyHolidays-id";
import { CompleteAccountIdAdminComponent } from "@pages/adminRoute/complete-account-id/complete-account-id";
import { DashboardAdminComponent } from "@pages/adminRoute/dashboard/dashboard";
import { DeliveryNotesAdminComponent } from "@pages/adminRoute/deliveryNotes/deliveryNotes";
import { DeliveryNotesIdAdminComponent } from "@pages/adminRoute/deliveryNotes-id/deliveryNotes-id";
import { EdiForecastsAdminComponent } from "@pages/adminRoute/edi/ediForecasts/ediForecasts";
import { EdiMessagesAdminComponent } from "@pages/adminRoute/edi/ediMessages/ediMessages";
import { EdiMessagesIdAdminComponent } from "@pages/adminRoute/edi/ediMessages-id/ediMessages-id";
import { EdiOrdersAdminComponent } from "@pages/adminRoute/edi/ediOrders/ediOrders";
import { EdiShippingNotesAdminComponent } from "@pages/adminRoute/edi/ediShippingNotes/ediShippingNotes";
import { EdiShippingNotesIdAdminComponent } from "@pages/adminRoute/edi/ediShippingNotes-id/ediShippingNotes-id";
import { EmailsAdminComponent } from "@pages/adminRoute/emails/emails";
import { EmailsIdAdminComponent } from "@pages/adminRoute/emails-id/emails-id";
import { EmailTemplateAdminComponent } from "@pages/adminRoute/emailTemplates/emailTemplates";
import { EmailTemplateIdAdminComponent } from "@pages/adminRoute/emailTemplates-id/emailTemplates-id";
import { ExportLogsAdminComponent } from "@pages/adminRoute/exportLogs/exportLogs";
import { FeatureAdminComponent } from "@pages/adminRoute/features/features";
import { FloorplanComponent } from "@pages/adminRoute/floorplan/floorplan";
import { ForgotPasswordAdminComponent } from "@pages/adminRoute/forgotPassword/forgotPassword";
import { GroupAdminComponent } from "@pages/adminRoute/groups/groups";
import { HolidayAllowancesAdminComponent } from "@pages/adminRoute/holidayAllowances/holidayAllowances";
import { HolidayAllowancesIdAdminComponent } from "@pages/adminRoute/holidayAllowances-id/holidayAllowances-id";
import { HolidayRequestsIdAdminComponent } from "@pages/adminRoute/holidayRequests-id/holidayRequests-id";
import { HolidayRequestsPlannerAdminComponent } from "@pages/adminRoute/holidayRequests-planner/holidayRequests-planner";
import { HolidayRequestsAdminComponent } from "@pages/adminRoute/holidayRequests/holidayRequests";
import { HolidaySettingsAdminComponent } from "@pages/adminRoute/holidaySettings/holidaySettings";
import { InterfaceDevicesIdAdminComponent } from "@pages/adminRoute/interfaceDevices-id/interfaceDevices-id";
import { InterfaceDevicesAdminComponent } from "@pages/adminRoute/interfaceDevices/interfaceDevices";
import { InvoicesAdminComponent } from "@pages/adminRoute/invoices/invoices";
import { InvoicesArchiveAdminComponent } from "@pages/adminRoute/invoices-archive/invoices-archive";
import { InvoicesIdAdminComponent } from "@pages/adminRoute/invoices-id/invoices-id";
import { LogAdminComponent } from "@pages/adminRoute/logs/logs";
import { LogIdAdminComponent } from "@pages/adminRoute/logs-id/logs-id";
import { MachinesAdminComponent } from "@pages/adminRoute/machines/machines";
import { MachinesIdAdminComponent } from "@pages/adminRoute/machines-id/machines-id";
import { MachinesIdPFMEAsAdminComponent } from "@pages/adminRoute/machines-id-pfmeas/machines-id-pfmeas";
import { MachineQuestionnairesAdminComponent } from "@pages/adminRoute/machineQuestionnaires/machineQuestionnaires";
import { MenuAdminComponent } from "@pages/adminRoute/menu/menu";
import { NonConformanceReportsAdminComponent } from "@pages/adminRoute/nonConformanceReports/nonConformanceReports";
import { NonConformanceReportsIdAdminComponent } from "@pages/adminRoute/nonConformanceReports-id/nonConformanceReports-id";
import { OperatorComponent } from "@pages/adminRoute/webapp/operator/operator";
import { OpFloorplanComponent } from "@pages/adminRoute/opfloorplan/opfloorplan";
import { OpsPlanningDepartmentsIdAdminComponent } from "@pages/adminRoute/opsPlanning/departments-id/departments-id";
import { OpsPlanningSitesIdAdminComponent } from "@pages/adminRoute/opsPlanning/sites-id/sites-id";
import { OpsPlanningWorkforceAdminComponent } from "@pages/adminRoute/opsPlanning/workforce/workforce";
import { OutstandingOrdersAdminComponent } from "@pages/adminRoute/outstandingOrders/outstandingOrders";
import { PackagingAdminComponent } from "@pages/adminRoute/packaging/packaging";
import { PackagingIdAdminComponent } from "@pages/adminRoute/packaging-id/packaging-id";
import { PartsAdminComponent } from "@pages/adminRoute/parts/parts";
import { PartsIdAdminComponent } from "@pages/adminRoute/parts-id/parts-id";
import { PFMEASIdAdminComponent } from "@pages/adminRoute/pfmeas-id/pfmeas-id";
import { ProcessesAdminComponent } from "@pages/adminRoute/processes/processes";
import { ProcessesIdAdminComponent } from "@pages/adminRoute/processes-id/processes-id";
import { ProductionRequestsAdminComponent } from "@pages/adminRoute/productionRequests/productionRequests";
import { ProjectAdminComponent } from "@pages/adminRoute/projects/projects";
import { ProjectIdAdminComponent } from "@pages/adminRoute/projects-id/projects-id";
import { PromisesAdminComponent } from "@pages/adminRoute/promises/promises";
import { PromisesBoardAdminComponent } from "@pages/adminRoute/promises-board/promises-board";
import { PromisesIdAdminComponent } from "@pages/adminRoute/promises-id/promises-id";
import { PurchaseGridDetailsComponent } from "@components/purchaseGridDetails/purchaseGridDetails";
import { PurchasableItemsAdminComponent } from "@pages/adminRoute/purchasableItems/purchasableItems";
import { PurchasableItemsIdAdminComponent } from "@pages/adminRoute/purchasableItems-id/purchasableItems-id";
import { PurchaseLogsAdminComponent } from "@pages/adminRoute/purchaseLogs/purchaseLogs";
import { PurchaseLogsIdAdminComponent } from "@pages/adminRoute/purchaseLogs-id/purchaseLogs-id";
import { QmsDocumentsAdminComponent } from "@pages/adminRoute/qmsDocuments/qmsDocuments";
import { QmsDocumentsIdAdminComponent } from "@pages/adminRoute/qmsDocuments-id/qmsDocuments-id";
import { RequestForQuotesAdminComponent } from "@pages/adminRoute/requestForQuotes/requestForQuotes";
import { RequestForQuotesIdAdminComponent } from "@pages/adminRoute/requestForQuotes-id/requestForQuotes-id";
import { ResetPasswordAdminComponent } from "@pages/adminRoute/resetPassword/resetPassword";
import { SalesEnquiriesAdminComponent } from "@pages/adminRoute/salesEnquiries/salesEnquiries";
import { SalesEnquiriesIdAdminComponent } from "@pages/adminRoute/salesEnquiries-id/salesEnquiries-id";
import { SalesEnquiryGridDetailsComponent } from "@components/salesEnquiryGridDetails/salesEnquiryGridDetails";
import { SalesOrdersAdminComponent } from "@pages/adminRoute/salesOrders/salesOrders";
import { SalesOrderGridDetailsComponent } from "@components/salesOrderGridDetails/salesOrderGridDetails";
import { SalesOrdersIdAdminComponent } from "@pages/adminRoute/salesOrders-id/salesOrders-id";
import { SalesOrdersIdDeliveryNotesAdminComponent } from "@pages/adminRoute/salesOrders-id-deliveryNotes/salesOrders-id-deliveryNotes";
import { SalesOrdersIdInvoicesAdminComponent } from "@pages/adminRoute/salesOrders-id-invoices/salesOrders-id-invoices";
import { SalesOrdersIdRFQsAdminComponent } from "@pages/adminRoute/salesOrders-id-rfqs/salesOrders-id-rfqs";
import { SitesAdminComponent } from "@pages/adminRoute/sites/sites";
import { SitesIdAdminComponent } from "@pages/adminRoute/sites-id/sites-id";
import { SmsAdminComponent } from "@pages/adminRoute/sms/sms";
import { SmsTemplateAdminComponent } from "@pages/adminRoute/smsTemplates/smsTemplates";
import { SmsTemplateIdAdminComponent } from "@pages/adminRoute/smsTemplates-id/smsTemplates-id";
import { StoresAdminComponent } from "@pages/adminRoute/stores/stores";
import { StoresIdAdminComponent } from "@pages/adminRoute/stores-id/stores-id";
import { SupplierAdminComponent } from "@pages/adminRoute/suppliers/suppliers";
import { SupplierIdAdminComponent } from "@pages/adminRoute/suppliers-id/suppliers-id";
import { SystemParamsAdminComponent } from "@pages/adminRoute/system-params/system-params";
import { SystemParamIdAdminComponent } from "@pages/adminRoute/system-param-id/system-param-id";
import { SystemParamIdValueAdminComponent } from "@pages/adminRoute/system-param-id-values-vid/system-param-id-values-vid";
import { ToolsAdminComponent } from "@pages/adminRoute/tools/tools";
import { ToolsIdAdminComponent } from "@pages/adminRoute/tools-id/tools-id";
import { UserHolidaysAdminComponent } from "@pages/adminRoute/userHolidays/userHolidays";
import { UserProfilesAdminComponent } from "@pages/adminRoute/userProfiles/userProfiles";
import { UserProfilesIdAdminComponent } from "@pages/adminRoute/userProfiles-id/userProfiles-id";
import { UserSettingsAdminComponent } from "@pages/adminRoute/userSettings/userSettings";
import { VisitorsAdminComponent } from "@pages/adminRoute/visitors/visitors";
import { VisitorsIdAdminComponent } from "@pages/adminRoute/visitors-id/visitors-id";
import { WorkOrdersAdminComponent } from "@pages/adminRoute/workOrders/workOrders";
import { WorkOrdersIdAdminComponent } from "@pages/adminRoute/workOrders-id/workOrders-id";

// Public Routes
import { HomeRouteComponent } from "@pages/homeRoute/homeRoute";
import { MainComponent } from "@pages/homeRoute/main/main";

// Generic Pages and components
import { PageNotFoundComponent } from "@pages/pageNotFound/pageNotFound";
import { ServiceNotAvailableComponent } from "@pages/serviceNotAvailable/serviceNotAvailable";
import { SupervisorComponent } from "@pages/adminRoute/webapp/supervisor/supervisor";
import { WorkOrderScanComponent } from "@pages/adminRoute/webapp/workOrderScan/workOrderScan";
import { WorkOrderViewComponent } from "@pages/adminRoute/webapp/workOrderView/workOrderView";

export const routes: Routes = [
	{ children: [
		{ component: DashboardAdminComponent, path: "" },
		{ children: [
			{ component: AccidentAdminComponent, path: "" },
			{ component: AccidentIdAdminComponent, path: ":id" },
		], path: "accidents" },
		{ children: [
			{ component: AuditAdminComponent, path: "" },
			{ component: AuditIdAdminComponent, path: ":id" },
		], path: "audits" },
		{ children: [
			{ component: BankDetailsAdminComponent, path: "" },
			{ component: BankDetailsIdAdminComponent, path: ":id" },
		], path: "bank-details" },
		{ children: [
			{ component: ClientAdminComponent, path: "" },
			{ component: ClientIdAdminComponent, path: ":id" },
		], path: "clients" },
		{ component: ClockingAdminComponent, path: "clocking/:id" },
		{ children: [
			{ component: CompanyHolidaysAdminComponent, path: "" },
			{ component: CompanyHolidaysIdAdminComponent, path: ":id" },
		], path: "companyHolidays" },
		{ component: CompleteAccountIdAdminComponent, path: "complete-account/:id" },
		{ children: [
			{ component: DeliveryNotesAdminComponent, path: "" },
			{ component: DeliveryNotesIdAdminComponent, path: ":id" },
		], path: "deliveryNotes" },
		{ component: EdiForecastsAdminComponent, path: "edi-forecasts" },
		{ children: [
			{ component: EdiMessagesAdminComponent, path: "" },
			{ component: EdiMessagesIdAdminComponent, path: ":id" },
		], path: "edi-messages" },
		{ component: EdiOrdersAdminComponent, path: "edi-orders" },
		{ children: [
			{ component: EdiShippingNotesAdminComponent, path: "" },
			{ component: EdiShippingNotesIdAdminComponent, path: ":id" },
		], path: "edi-shipping-notes" },
		{ children: [
			{ component: EmailsAdminComponent, path: "" },
			{ component: EmailsIdAdminComponent, path: ":id" }
		], path: "emails" },
		{ children: [
			{ component: EmailTemplateAdminComponent, path: "" },
			{ component: EmailTemplateIdAdminComponent, path: ":id" },
		], path: "email-templates" },
		{ component: ExportLogsAdminComponent, path: "export-logs" },
		{ component: FeatureAdminComponent, path: "features-hidden" },
		{ component: FloorplanComponent, path: "floorplan" },
		{ component: ForgotPasswordAdminComponent, path: "forgotPassword" },
		{ component: GroupAdminComponent, path: "groups" },
		{ children: [
			{ component: HolidayAllowancesAdminComponent, path: "" },
			{ component: HolidayAllowancesIdAdminComponent, path: ":id" },
		], path: "holidayAllowances" },
		{ children: [
			{ component: HolidayRequestsAdminComponent, path: "" },
			{ component: HolidayRequestsIdAdminComponent, path: ":id" },
		], path: "holidayRequests" },
		{ component: HolidayRequestsPlannerAdminComponent, path: "holidayRequestsPlanner" },
		{ component: HolidaySettingsAdminComponent, path: "holidaySettings" },
		{ children: [
			{ component: InterfaceDevicesAdminComponent, path: "" },
			{ component: InterfaceDevicesIdAdminComponent, path: ":id" },
		], path: "interfaceDevices" },
		{ children: [
			{ component: InvoicesAdminComponent, path: "" },
			{ component: InvoicesArchiveAdminComponent, path: "archive" },
			{ component: InvoicesIdAdminComponent, path: ":id" },
		], path: "invoices" },
		{ children: [
			{ component: LogAdminComponent, path: "" },
			{ component: LogIdAdminComponent, path: ":id" },
		], path: "logs" },
		{ children: [
			{ component: MachinesAdminComponent, path: "" },
			{ component: MachinesIdAdminComponent, path: ":id" },
			{ component: MachinesIdPFMEAsAdminComponent, path: ":id/pfmeas" },
		], path: "machines" },
		{ component: MachineQuestionnairesAdminComponent, path: "machineQuestionnaires" },
		{ component: MenuAdminComponent, path: "menu/:id" },
		{ component: OperatorComponent, path: "operator/:id" },
		{ component: OpFloorplanComponent, path: "opfloorplan" },
		{ children: [
			{ component: OpsPlanningDepartmentsIdAdminComponent, path: "department/:department" },
			{ component: OpsPlanningSitesIdAdminComponent, path: "site/:site" },
			{ component: OpsPlanningWorkforceAdminComponent, path: "workforce" },
		], path: "opsPlanning" },
		{ component: OutstandingOrdersAdminComponent, path: "outstandingOrders" },
		{ children: [
			{ component: NonConformanceReportsAdminComponent, path: "" },
			{ component: NonConformanceReportsIdAdminComponent, path: ":id" },
		], path: "nonConformanceReports" },
		{ children: [
			{ component: PackagingAdminComponent, path: "" },
			{ component: PackagingIdAdminComponent, path: ":id" },
		], path: "packaging" },
		{ children: [
			{ component: PartsAdminComponent, path: "" },
			{ component: PartsIdAdminComponent, path: ":id" },
		], path: "parts" },
		{ component: PFMEASIdAdminComponent, path: "pfmeas/:machineId/:id" },
		{ children: [
			{ component: ProcessesAdminComponent, path: "" },
			{ component: ProcessesIdAdminComponent, path: ":id" },
		], path: "processes" },
		{ component: ProductionRequestsAdminComponent, path: "productionRequests" },
		{ children: [
			{ component: ProjectAdminComponent, path: "" },
			{ component: ProjectIdAdminComponent, path: ":id" },
		], path: "projects" },
		{ children: [
			{ component: PromisesAdminComponent, path: "" },
			{ component: PromisesBoardAdminComponent, path: "board" },
			{ component: PromisesIdAdminComponent, path: ":id" },
		], path: "promises" },
		{ children: [
			{ component: PurchasableItemsAdminComponent, path: "" },
			{ component: PurchasableItemsIdAdminComponent, path: ":id" },
		], path: "purchasableItems" },
		{ children: [
			{ component: PurchaseLogsAdminComponent, path: "" },
			{ component: PurchaseLogsIdAdminComponent, path: ":id" },
		], path: "purchaseLogs" },
		{ children: [
			{ component: QmsDocumentsAdminComponent, path: "" },
			{ component: QmsDocumentsIdAdminComponent, path: ":id" },
		], path: "qmsDocuments" },
		{ children: [
			{ component: RequestForQuotesAdminComponent, path: "" },
			{ component: RequestForQuotesIdAdminComponent, path: ":id" },
		], path: "requestForQuotes" },
		{ component: ResetPasswordAdminComponent, path: "resetPassword/:id" },
		{ children: [
			{ component: SalesEnquiriesAdminComponent, path: "" },
			{ component: SalesEnquiriesIdAdminComponent, path: ":id" },
		], path: "salesEnquiries" },
		{ children: [
			{ component: SalesOrdersAdminComponent, path: "" },
			{ component: SalesOrdersIdAdminComponent, path: ":id" },			
			{ component: SalesOrdersIdDeliveryNotesAdminComponent, path: ":id/deliveryNotes" },
			{ component: SalesOrdersIdInvoicesAdminComponent, path: ":id/invoices" },
			{ component: SalesOrdersIdRFQsAdminComponent, path: ":id/rfqs" },
		], path: "salesOrders" },
		{ children: [
			{ component: SitesAdminComponent, path: "" },
			{ component: SitesIdAdminComponent, path: ":id" },
		], path: "sites" },
		{ component: SmsAdminComponent, path: "sms" },
		{ children: [
			{ component: SmsTemplateAdminComponent, path: "" },
			{ component: SmsTemplateIdAdminComponent, path: ":id" },
		], path: "sms-templates" },
		{ children: [
			{ component: StoresAdminComponent, path: "" },
			{ component: StoresIdAdminComponent, path: ":id" },
		], path: "stores" },
		{ component: SupervisorComponent, path: "supervisor/:id" },
		{ children: [
			{ component: SupplierAdminComponent, path: "" },
			{ component: SupplierIdAdminComponent, path: ":id" },
		], path: "suppliers" },
		{ children: [
			{ component: SystemParamsAdminComponent, path: "" },
			{ children: [
				{ component: SystemParamIdAdminComponent, path: "" },
				{ component: SystemParamIdValueAdminComponent, path: "values/:vid" },
			], path: ":id" },
		], path: "system-params" },
		{ children: [
			{ component: ToolsAdminComponent, path: "" },
			{ component: ToolsIdAdminComponent, path: ":id" },
		], path: "tools" },
		{ component: UserSettingsAdminComponent, path: "user-settings" },
		{ component: UserHolidaysAdminComponent, path: "userHolidays" },
		{ children: [
			{ component: UserProfilesAdminComponent, path: "" },
			{ component: UserProfilesIdAdminComponent, path: ":id" },
		], path: "users" },
		{ children: [
			{ component: VisitorsAdminComponent, path: "" },
			{ component: VisitorsIdAdminComponent, path: ":id" },
		], path: "visitors" },
		{ children: [
			{ component: WorkOrdersAdminComponent, path: "" },
			{ component: WorkOrdersIdAdminComponent, path: ":id" },
		], path: "workOrders" },
		{ component: WorkOrderScanComponent, path: "woscan" },
		{ component: WorkOrderViewComponent, path: "woview/:id" },
		{ component: PageNotFoundComponent, path: "**" }
	], component: AdminRouteComponent, path: environment.build.adminRoute },
	{ children: [
		{ component: MainComponent, path: "" },
		{ component: ServiceNotAvailableComponent, path: "unavailable" },
		{ component: PageNotFoundComponent, path: "**" }
	], component: HomeRouteComponent, path: "" }
];

export const components = [
	// Components
	AdminHeaderComponent,
	EdiOrderDayQuantitiesComponent,
	InvoiceGridDetailsComponent,
	PurchaseGridDetailsComponent,
	SalesEnquiryGridDetailsComponent,
	SalesOrderGridDetailsComponent,
	FooterComponent,
	HeaderComponent,
	ModalComponent,
	// Modals
	// Admin
	AccidentAdminComponent,
	AccidentIdAdminComponent,
	AdminRouteComponent,
	AuditAdminComponent,
	AuditIdAdminComponent,
	BankDetailsAdminComponent,
	BankDetailsIdAdminComponent,
	ClientAdminComponent,
	ClientIdAdminComponent,
	ClockingAdminComponent,
	CompanyHolidaysAdminComponent,
	CompanyHolidaysIdAdminComponent,
	CompleteAccountIdAdminComponent,
	DashboardAdminComponent,
	DeliveryNotesAdminComponent,
	DeliveryNotesIdAdminComponent,
	EdiForecastsAdminComponent,
	EdiMessagesAdminComponent,
	EdiMessagesIdAdminComponent,
	EdiOrdersAdminComponent,
	EdiShippingNotesAdminComponent,
	EdiShippingNotesIdAdminComponent,
	EmailsAdminComponent,
	EmailsIdAdminComponent,
	EmailTemplateAdminComponent,
	EmailTemplateIdAdminComponent,
	ExportLogsAdminComponent,
	FeatureAdminComponent,
	FloorplanComponent,
	ForgotPasswordAdminComponent,
	GroupAdminComponent,
	HolidayAllowancesAdminComponent,
	HolidayAllowancesIdAdminComponent,
	HolidayRequestsAdminComponent,
	HolidayRequestsIdAdminComponent,
	HolidayRequestsPlannerAdminComponent,
	HolidaySettingsAdminComponent,
	InterfaceDevicesAdminComponent,
	InterfaceDevicesIdAdminComponent,
	InvoicesAdminComponent,
	InvoicesArchiveAdminComponent,
	InvoicesIdAdminComponent,
	LogAdminComponent,
	LogIdAdminComponent,
	MachinesAdminComponent,
	MachinesIdAdminComponent,
	MachinesIdPFMEAsAdminComponent,
	MachineQuestionnairesAdminComponent,
	MenuAdminComponent,
	NonConformanceReportsAdminComponent,
	NonConformanceReportsIdAdminComponent,
	OperatorComponent,
	OpFloorplanComponent,
	OpsPlanningDepartmentsIdAdminComponent,
	OpsPlanningSitesIdAdminComponent,
	OpsPlanningWorkforceAdminComponent,
	OutstandingOrdersAdminComponent,
	PackagingAdminComponent,
	PackagingIdAdminComponent,
	PartsAdminComponent,
	PartsIdAdminComponent,
	PFMEASIdAdminComponent,
	ProcessesAdminComponent,
	ProcessesIdAdminComponent,
	ProductionRequestsAdminComponent,
	ProjectAdminComponent,
	ProjectIdAdminComponent,
	PromisesAdminComponent,
	PromisesBoardAdminComponent,
	PromisesIdAdminComponent,
	PurchasableItemsAdminComponent,
	PurchasableItemsIdAdminComponent,
	PurchaseLogsAdminComponent,
	PurchaseLogsIdAdminComponent,
	QmsDocumentsAdminComponent,
	QmsDocumentsIdAdminComponent,
	RequestForQuotesAdminComponent,
	RequestForQuotesIdAdminComponent,
	ResetPasswordAdminComponent,
	SalesEnquiriesAdminComponent,
	SalesEnquiriesIdAdminComponent,
	SalesOrdersAdminComponent,
	SalesOrdersIdAdminComponent,
	SalesOrdersIdDeliveryNotesAdminComponent,
	SalesOrdersIdInvoicesAdminComponent,
	SalesOrdersIdRFQsAdminComponent,
	SitesAdminComponent,
	SitesIdAdminComponent,
	SmsAdminComponent,
	SmsTemplateAdminComponent,
	SmsTemplateIdAdminComponent,
	StoresAdminComponent,
	StoresIdAdminComponent,
	SupervisorComponent,
	SupplierAdminComponent,
	SupplierIdAdminComponent,
	SystemParamsAdminComponent,
	SystemParamIdAdminComponent,
	SystemParamIdValueAdminComponent,
	ToolsAdminComponent,
	ToolsIdAdminComponent,
	UserHolidaysAdminComponent,
	UserProfilesAdminComponent,
	UserProfilesIdAdminComponent,
	UserSettingsAdminComponent,
	VisitorsAdminComponent,
	VisitorsIdAdminComponent,
	WorkOrderScanComponent,
	WorkOrdersAdminComponent,
	WorkOrdersIdAdminComponent,
	WorkOrderViewComponent,
	// Public
	HomeRouteComponent,
	MainComponent,
	//Other
	PageNotFoundComponent,
	ServiceNotAvailableComponent,
];