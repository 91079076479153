import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { Client } from "@interfaces/client.interface";
import { LogExport } from "@interfaces/logExport.interface";
import { ClientService } from "@services/client.service";
import { ExportLogService } from "@services/exportLog.service";
import { QuotationAnalysisFormService } from "@services/quotationAnalysisForm.service";
import notify from "devextreme/ui/notify";

@Component({
	moduleId: module.id,
	selector: "quotationAnalysisForms",
	styleUrls: ["quotationAnalysisForms.css"],
	templateUrl: "quotationAnalysisForms.html"
})
export class QuotationAnalysisFormsAdminComponent {
	clients: Client[] = [];
	deleteEnabled = true;
	editEnabled = true;
	gridLoading = false;
	headerPrimaryButtons: AdminHeaderButton[] = [];
	itemType = "QAF"
	itemUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	items: any[] = [];
	title: string;
	
	constructor(private router: Router, private clientService: ClientService, private exportLogService: ExportLogService, private itemService: QuotationAnalysisFormService) {
		this.headerPrimaryButtons.push({ icon: "fal fa-plus", method: "addNewItem", text: `New ${this.itemType}` });
		this.title = `${this.itemType}s`;
		this.viewRow = this.viewRow.bind(this);
		this.editRow = this.editRow.bind(this);
		this.deleteRow = this.deleteRow.bind(this);
		this.getClients();
		this.getItems();
	}

	addNewItem() {
		this.router.navigate([`${this.itemUrl}/create`]);
	}

	deleteRow(e: any) {
		const result = confirm("Are you sure you want to delete this item?");
		if(result)
		{
			this.itemService.deleteSingleById(e.row.data.id)
				.subscribe(
					() => {
						notify(this.itemType + " deleted!", "success", 5000);
						this.getItems();
					},
					(err) => {
						console.log(err);
						notify("Something went wrong!", "error", 5000);
					},
				);
		}
	}

	editRow(e: any) {
		this.router.navigate([`${this.itemUrl}/${e.row.data.id}_edit`]);
	}

	getClients() {
		this.clientService.getAll()
			.subscribe(
				(res: any) => this.clients = res.response.items,
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				},
			);
	}

	getItems() {
		this.gridLoading = true;
		this.itemService.getAll()
			.subscribe(
				(res: any) => {
					this.items = res.response.items;
					this.gridLoading = false;
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				},
			);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	logExport() {
		const logExport = new LogExport();
		logExport.gridName = "QAFs";
		this.exportLogService.logExport(logExport)
			.subscribe(
				() => console.log("Export Logged"),
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
					return false;
				},
			);
	}

	viewRow(e: any) {
		this.router.navigate([`${this.itemUrl}/${e.row.data.id}`]);
	}
}