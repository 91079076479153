import { Department } from "@enums/department";
import { DeviceType } from "@enums/deviceType.enum";
import { EdiMessageDirection } from "@enums/ediMessageDirection.enum";
import { EdiMessageProcessingStatus } from "@enums/ediMessageProcessingStatus.enum";
import { EdiMessageType } from "@enums/ediMessageType.enum";
import { EdiStyle } from "@enums/ediStyle.enum";
import { EmailPriority } from "@enums/emailPriority.enum";
import { EmailStatus } from "@enums/emailStatus.enum";
import { HolidayRequestStatus } from "@enums/holdayRequestStatus.enum";
import { HolidayTime } from "@enums/holidayTime.enum";
import { IncidentType } from "@enums/incidentType.enum";
import { Incoterms } from "@enums/incoterms.enum";
import { InspectionType } from "@enums/inspectionType.enum";
import { MachineEventType } from "@enums/machineEventType.enum";
import { MaterialPurpose } from "@enums/materialPurpose.enum";
import { MaterialShape } from "@enums/materialShape.enum";
import { MaterialType } from "@enums/materialType.enum";
import { NonConformanceReportConcernOrigin } from "@enums/nonConformanceReportConcernOrigin.enum";
import { NonConformanceReportPriority } from "@enums/nonConformanceReportPriority.enum";
import { NonConformanceReportStatus } from "@enums/nonConformanceReportStatus.enum";
import { OpsPlanningWorkOrderSummaryPdfRowStatus } from "@enums/opsPlanningWorkOrderSummaryPdfRowStatus.enum";
import { PackagingLabelSize } from "@enums/packagingLabelSize.enum";
import { PartRelationshipType } from "@enums/partRelationshipType.enum";
import { PersonType } from "@enums/personType.enum";
import { ProcessTemplate } from "@enums/processTemplate.enum";
import { ProductionRequestStatus } from "@enums/productionRequestStatus.enum";
import { ProductionStatus } from "@enums/productionStatus.enum";
import { ProfileAccountStatus } from "@enums/profileAccountStatus.enum";
import { PromiseDepartment } from "@enums/promiseDepartment.enum";
import { PromiseType } from "@enums/promiseType.enum";
import { PurchaseType } from "@enums/purchaseType.enum";
import { QualityControlCheckType } from "@enums/qualityControlCheckType.enum";
import { SalesEnquiryFollowUpType } from "@enums/salesEnquiryFollowUpType.enum";
import { SalesEnquiryStatus } from "@enums/salesEnquiryStatus.enum";
import { Shift } from "@enums/shift.enum";
import { Status } from "@enums/status.enum";
import { ToolEventType } from "@enums/toolEventType.enum";
import { TrainingStatus } from "@enums/trainingStatus.enum";
import { VisitorType } from "@enums/visitorType.enum";

export function GetPropertiesFromEnum(enumName: typeof Department | typeof DeviceType |  typeof EdiMessageDirection | typeof EdiMessageProcessingStatus | typeof EdiMessageType | typeof EdiStyle | typeof EmailPriority | typeof EmailStatus | typeof HolidayRequestStatus | typeof HolidayTime | typeof IncidentType | typeof Incoterms | typeof InspectionType | typeof MachineEventType | typeof MaterialPurpose | typeof MaterialShape | typeof MaterialType | typeof NonConformanceReportConcernOrigin | typeof NonConformanceReportPriority | typeof NonConformanceReportStatus | typeof OpsPlanningWorkOrderSummaryPdfRowStatus | typeof PackagingLabelSize | typeof PartRelationshipType | typeof PersonType | typeof ProcessTemplate | typeof ProductionStatus | typeof ProductionRequestStatus | typeof ProfileAccountStatus | typeof PromiseDepartment | typeof PromiseType | typeof PurchaseType | typeof QualityControlCheckType | typeof SalesEnquiryStatus | typeof SalesEnquiryFollowUpType | typeof Shift | typeof Status | typeof ToolEventType | typeof TrainingStatus | typeof VisitorType, preserveNames = false) {
	const arrayObjects = [];  
	for (const [propertyKey, propertyValue] of Object.entries(enumName)) {  
		if (!Number.isNaN(Number(propertyKey))) continue;
		arrayObjects.push({ id: propertyValue, name: preserveNames ? propertyKey : propertyKey.replace(/(\B[A-Z])/g, " $1") });  
	}
	return arrayObjects;
}