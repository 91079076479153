import { Injectable, Injector } from "@angular/core";
import { environment } from "@environments/environment";
import { Service } from "@services/service";
import { Guid } from "guid-typescript";

@Injectable()
export class AssetService extends Service {
	constructor(injector: Injector) {
		super("Asset", injector);
	}

	// API
	checkIn(lookupId: Guid) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/CheckIn/${lookupId}`, {});
	}

	checkOut(lookupId: Guid) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/CheckOut/${lookupId}`, {});
	}
	
	generateQrCode(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GenerateQrCode/${id}`, { responseType: "blob" as "json"});
	}
}