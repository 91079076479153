<admin-header [primaryButtons]="headerPrimaryButtons" [secondaryButtons]="headerSecondaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<dx-data-grid id="grid" class="data-grid" [dataSource]="items" [remoteOperations]="false" [allowColumnReordering]="true" [allowColumnResizing]="true" [rowAlternationEnabled]="true" [showBorders]="true" (onExporting)="logExport()">
    <dxo-paging [pageSize]="100"></dxo-paging>
	<dxo-state-storing [enabled]="true" type="localStorage" storageKey="purchaseMain"></dxo-state-storing>
    <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
    <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
	<dxo-export [enabled]="true"></dxo-export>
	<dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>
	<dxi-column type="buttons" [visible]="true">
        <dxi-button hint="View" icon="fal fa-eye" [onClick]="viewRow"></dxi-button>
		<dxi-button *ngIf="editEnabled" hint="Edit" icon="fal fa-pencil" [onClick]="editRow"></dxi-button>
		<dxi-button *ngIf="deleteEnabled" icon="fal fa-trash" [onClick]="deleteRow"></dxi-button>
    </dxi-column>
    <dxi-column dataField="id" alignment="left" sortOrder="desc" [visible]="true"></dxi-column>
	<dxi-column dataField="workReferenceNo" caption="BPL Reference"></dxi-column>
	<dxi-column dataField="email" [visible]="true"></dxi-column>
	<dxi-column dataField="supplierId" caption="Supplier Name" [visible]="true">
		<dxo-lookup [dataSource]="suppliers" valueExpr="id" displayExpr="name"></dxo-lookup>
	</dxi-column>
	<dxi-column dataField="purchaseType" caption="Purchase Type" [visible]="true">
		<dxo-lookup [dataSource]="purchaseTypes" valueExpr="id" displayExpr="name"></dxo-lookup>
	</dxi-column>
	<dxi-column dataField="projectId" caption="Project" [visible]="true">
		<dxo-lookup [dataSource]="projects" valueExpr="id" displayExpr="name"></dxo-lookup>
	</dxi-column>
	<dxi-column dataField="totalCost" alignment="right" [format]="{ type: 'currency', currency: 'GBP', precision: 4 }" [visible]="true"></dxi-column>
	<dxi-column dataField="createdAt" dataType="date" format="dd/MM/yyyy" [visible]="true"></dxi-column>
	<dxi-column dataField="dueDate" dataType="date" format="dd/MM/yyyy" [visible]="true"></dxi-column>
	<dxi-column dataField="lineItems" dataType="string" [visible]="false"></dxi-column>
	<dxo-master-detail [enabled]="true" template="detail"></dxo-master-detail>
	<div *dxTemplate="let purchase of 'detail'">
	  <div class="master-detail-caption">{{
		"Line Items for Purchase Log #" + purchase.data.id
	  }}</div>
	  <purchaseGridDetails [lineItemData]="purchase.data.lineItems"></purchaseGridDetails>
	</div>
</dx-data-grid>
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#grid' }" [(visible)]="gridLoading" [showIndicator]="true" [showPane]="true" [shading]="true"></dx-load-panel>