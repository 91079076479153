import { Injectable, Injector } from "@angular/core";
import { environment } from "@environments/environment";
import { PurchaseLogGridLineItem } from "@interfaces/purchaseLogGridLineItem.interface";
import { PurchaseLogGridLineItem_App } from "@interfaces/purchaseLogGridLineItem_App.interface";
import { Service } from "@services/service";
import { Guid } from "guid-typescript";

@Injectable()
export class StockStoreService extends Service {
	constructor(injector: Injector) {
		super("StockStore", injector);
	}

	// API
	App_CheckIn(id: Guid, quantity: number, code: number) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/App_CheckIn/${id}/${quantity}/${code}`, {});
	}

	App_CheckOut(id: Guid, quantity: number, code: number) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/App_CheckOut/${id}/${quantity}/${code}`, {});
	}

	App_PlacedItem(id: Guid, location: string) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/App_PlacedItem/${id}/${location}`,{});
	}

	App_Recieved(id: number, item: PurchaseLogGridLineItem_App) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/App_Recieved/${id}`, item);
	}

	checkIn(id: number, quantity: number) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/CheckIn/${id}/${quantity}`, {});
	}

	checkOut(id: number, quantity: number) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/CheckOut/${id}/${quantity}`, {});
	}

	getDetails(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/Details/${id}`);
	}

	getInStock() {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/InStock`);
	}

	recievedItem(id: number, item: PurchaseLogGridLineItem) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/Recieved/${id}`, item);
	}
}