<admin-header [primaryButtons]="headerPrimaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<dx-data-grid id="grid" class="data-grid" [dataSource]="items" [remoteOperations]="false" [allowColumnReordering]="true" [allowColumnResizing]="true" [rowAlternationEnabled]="true" [showBorders]="true" (onExporting)="logExport()">
    <dxo-paging [pageSize]="100"></dxo-paging>
	<dxo-state-storing [enabled]="true" type="localStorage" storageKey="holallowMain"></dxo-state-storing>
    <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
    <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
	<dxo-export [enabled]="true"></dxo-export>
	<dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>
	<dxi-column type="buttons">
        <dxi-button hint="View" icon="fal fa-eye" [onClick]="viewRow"></dxi-button>
		<dxi-button *ngIf="editEnabled" hint="Edit" icon="fal fa-pencil" [onClick]="editRow"></dxi-button>
		<dxi-button *ngIf="deleteEnabled" icon="fal fa-trash" [onClick]="deleteRow"></dxi-button>
    </dxi-column>
	<dxi-column dataField="userProfileId" caption="Employee">
		<dxo-lookup [dataSource]="people" valueExpr="id" displayExpr="fullName"></dxo-lookup>
	</dxi-column>
	<dxi-column dataField="allowance" dataType="number" alignment="left"></dxi-column>
	<dxi-column dataField="year" dataType="number" alignment="left"></dxi-column>
</dx-data-grid>
<dx-popup [width]="'75%'" [height]="600" [showTitle]="true" [title]="popupTitle" [dragEnabled]="false" [closeOnOutsideClick]="false" [showCloseButton]="false" container=".dx-viewport" [(visible)]="popupVisible">
	<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="before" [options]="popupAddButtonOptions"></dxi-toolbar-item>
	<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="after" [options]="popupCloseButtonOptions"></dxi-toolbar-item>
	<div *dxTemplate="let data of 'content'">
		<dx-form class="m-3" [(formData)]="popupYear" [showColonAfterLabel]="true" labelLocation="top">
			<dxi-item>This will add Holiday Allowances for all Employees who do not already have an allowance set for the chosen year. This allowance will be based on each Employee's individual Default Allowance and can be edited later if needed. Any Employees who already have an allowance for the year will be skipped.</dxi-item>
			<dxi-item dataField="year" editorType="dxTextBox" [label]="{ text: 'Year' }"></dxi-item>
		</dx-form>
	</div>
</dx-popup>
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#grid' }" [(visible)]="gridLoading" [showIndicator]="true" [showPane]="true" [shading]="true"></dx-load-panel>