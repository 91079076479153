import { Injectable, Injector } from "@angular/core";
import { environment } from "@environments/environment";
import { Service } from "@services/service";

@Injectable()
export class NonConformanceReportService extends Service {
	constructor(injector: Injector) {
		super("NonConformanceReport", injector);
	}

	// API
	closeNonConformanceReport(data: any) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/CloseReport`, data);
	}

	generateAndDownloadCarepointPdf(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GenerateAndDownloadCarepointPdf/${id}`, { responseType: "blob" as "json"} );
	}

	generateAndDownloadSummaryPdf(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GenerateAndDownloadSummaryPdf/${id}`, { responseType: "blob" as "json"} );
	}

	getGrid() {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetGrid`);
	}

	getNCRQualityCostPerHour() {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetNCRQualityCostPerHour`);
	}

	notifyStaff(id: number) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/NotifyStaff/${id}`, {} );
	}

	reopenReport(id: number) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/ReopenReport/${id}`, {});
	}
}