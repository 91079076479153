import { Component, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute,Router } from "@angular/router";
import notify from "devextreme/ui/notify";
import { Guid } from "guid-typescript";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { Client } from "@interfaces/client.interface";
import { ClientContact } from "@interfaces/clientContact.interface";
import { ClientLocation } from "@interfaces/clientLocation.interface";
import { CreateInvoiceFromShippingNote } from "@interfaces/createInvoiceFromShippingNote.interface";
import { DeliveryNote } from "@interfaces/deliveryNote.interface";
import { Project } from "@interfaces/project.interface";
import { SalesOrder } from "@interfaces/salesOrder.interface";
import { SalesOrderPart } from "@interfaces/salesOrderPart.interface";
import { SalesOrderPartForDeliveryNote } from "@interfaces/salesOrderPartForDeliveryNote.interface";
import { SalesOrderPartForInvoice } from "@interfaces/salesOrderPartForInvoice.interface";
import { UserProfile } from "@interfaces/userProfile.interface";
import { WorkOrder } from "@interfaces/workOrder.interface";
import { AuthService } from "@services/auth.service";
import { ClientService } from "@services/client.service";
import { ClientLocationService } from "@services/clientLocation.service";
import { ClientContactService } from "@services/clientContact.service";
import { DeliveryNoteService } from "@services/deliveryNote.service";
import { FileService } from "@services/file.service";
import { InvoiceService } from "@services/invoice.service";
import { ProjectService } from "@services/project.service";
import { SalesOrderService } from "@services/salesOrder.service";
import { SalesOrderPartService } from "@services/salesOrderPart.service";
import { UserProfileService } from "@services/userProfile.service";
import { WorkOrderService } from "@services/workOrder.service";

@Component({
	encapsulation: ViewEncapsulation.None,
	moduleId: module.id,
	selector: "salesOrders-id",
	styleUrls: ["salesOrders-id.css"],
	templateUrl: "salesOrders-id.html"
})
export class SalesOrdersIdAdminComponent {
	clientLocations: ClientLocation[] = [];
	clients: Client[] = [];
	contact: ClientContact = new ClientContact();
	contacts: ClientContact[] = [];
	createEnabled = false;
	currentUser: UserProfile = new UserProfile();
	deliveryNotesForInvoice: DeliveryNote[] = [];
	downloadPoDocumentButton = {
		onClick: () => this.downloadPoDocument(),
		text: "Download",
		type: "default",
		useSubmitBehavior: false
	}
	editEnabled = true;
	editRowKey: any = null;
	headerPrimaryButtons: AdminHeaderButton[] = [];
	headerSecondaryButtons: AdminHeaderButton[] = [];
	headerTertiaryButtons: AdminHeaderButton[] = [];
	item: SalesOrder = new SalesOrder();
	itemType = "Sales Order";
	itemUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	people: UserProfile[] = [];
	poDocument: any = null;
	popupClient: Client = new Client();
	popupCloseButtonOptions: any = { onClick: () => this.closePopup(), text: "Close" };
	popupContact: ClientContact = new ClientContact();
	popupDeliveryNote: DeliveryNote = new DeliveryNote();
	popupInvoice: CreateInvoiceFromShippingNote = new CreateInvoiceFromShippingNote();
	popupProject: Project = new Project();
	popupSalesOrderPart: SalesOrderPart = new SalesOrderPart();
	popupSaveButtonOptions: any;
	popupTitle = "";
	popupVisible = false;
	popupWorkOrder: WorkOrder = new WorkOrder();
	projects: Project[] = [];
	readOnly = true;
	readOnlyAlways = true;
	removePoDocumentButton = {
		onClick: () => this.removePO(),
		text: "Remove",
		type: "default",
		useSubmitBehavior: false
	}
	returnUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	salesOrderParts: SalesOrderPart[] = [];
	salesOrderPartsForDeliveryNote: SalesOrderPartForDeliveryNote[] = [];
	salesOrderPartsForInvoice: SalesOrderPartForInvoice[] = [];
	screenMode = "";
	title: string = "View " + this.itemType;
	workOrders: WorkOrder[] = [];

	constructor(private router: Router, private route: ActivatedRoute, private itemService: SalesOrderService, private authService: AuthService, private clientContactService: ClientContactService, private clientLocationService: ClientLocationService, private clientService: ClientService, private deliveryNoteService: DeliveryNoteService, private fileService: FileService, private invoiceService: InvoiceService, private userProfileService: UserProfileService, private projectService: ProjectService, private authServices: AuthService, private salesOrderPartService: SalesOrderPartService, private workOrderService: WorkOrderService) {
		this.getClients();
		this.getCurrentUser();
		this.getPeople();
		this.getProjects();

		this.route.params.subscribe((params) => {
			if (params.id.split("_").length > 1) {
				this.getItem(params.id.split("_")[0], "edit");
				this.edit();
			} else {
				this.getItem(params.id, "view");
			}
		});

		this.addNewWorkOrderPopup = this.addNewWorkOrderPopup.bind(this);
		this.deleteSalesOrderPart = this.deleteSalesOrderPart.bind(this);
		this.editSalesOrderPartPopup = this.editSalesOrderPartPopup.bind(this);
		this.downloadPoDocument = this.downloadPoDocument.bind(this);
		this.uploadPoDocument = this.uploadPoDocument.bind(this);
		this.viewWorkOrder = this.viewWorkOrder.bind(this);
		this.viewPart = this.viewPart.bind(this);
	}

	addNewClient() {
		this.clientService.createSingle(this.popupClient)
			.subscribe(
				() => {
					this.popupVisible = false;
					this.popupClient = new Client();
					this.getClients(true);
				},
				(err: any) => console.log(err)
			);
	}

	addNewClientPopup() {
		this.popupClient = new Client();
		this.popupTitle = "Add New Client";
		this.popupVisible = true;
		this.popupSaveButtonOptions = { onClick: () => this.addNewClient(), text: "Add Client" };
	}

	addNewContact() {
		this.clientContactService.createSingle(this.popupContact)
			.subscribe(
				() => {
					this.popupVisible = false;
					this.getContacts(this.item.clientId, true);
					this.getClientLocations(this.item.clientId);
				},
				(err: any) => {
					notify("Could not add client contact. Please check the details are valid and this contact does not already exist.", "Error", 5000);
					console.log(err);
				}
			);
	}

	addNewContactPopup() {
		this.popupContact = new ClientContact();
		this.popupContact.clientId = this.item.clientId;
		this.popupTitle = "Add New Contact";
		this.popupVisible = true;
		this.popupSaveButtonOptions = { onClick: () => this.addNewContact(), text: "Add Contact" };
	}

	addNewProject() {
		this.projectService.createSingle(this.popupProject)
			.subscribe(
				() => {
					this.popupVisible = false;
					this.getProjects(true);
				},
				(err: any) => {
					notify("Could not add client contact. Please check the details are valid and this contact does not already exist.", "Error", 5000);
					console.log(err);
				}
			);
	}

	addNewProjectPopup() {
		this.popupProject = new Project();
		this.popupProject.clientId = this.item.clientId;
		this.popupTitle = "Add New Project";
		this.popupVisible = true;
		this.popupSaveButtonOptions = { onClick: () => this.addNewProject(), text: "Add Project" };
	}

	addNewWorkOrder() {
		if (this.popupWorkOrder.quantity < 0 || this.popupWorkOrder.quantity > this.popupWorkOrder.maximumQuantity) {
			notify("Could not add work order. Please check the details are valid.", "Error", 5000);
			return;
		}

		this.workOrderService.createSingle(this.popupWorkOrder)
			.subscribe(
				() => {
					this.popupVisible = false;
					this.getSalesOrderParts(this.item.id);
					this.getWorkOrders(this.item.id);
				},
				(err: any) => {
					notify("Could not add work order. Please check the details are valid.", "Error", 5000);
					console.log(err);
				}
			);
	}

	addNewWorkOrderPopup(e: any) {
		this.popupWorkOrder = new WorkOrder();
		this.popupWorkOrder.dateRequired = new Date();
		this.popupWorkOrder.salesOrderPartId = e.row.data.id;
		this.popupWorkOrder.quantity = e.row.data.quantityOrdered - e.row.data.quantityInWorkOrders;
		this.popupWorkOrder.maximumQuantity = e.row.data.quantityOrdered - e.row.data.quantityInWorkOrders;

		if (this.popupWorkOrder.maximumQuantity <= 0) {
			return;
		}

		this.popupTitle = "Add New Work Order";
		this.popupVisible = true;
		this.popupSaveButtonOptions = { onClick: () => this.addNewWorkOrder(), text: "Add Work Order" };
	}

	buildButtons(mode: string) {
		this.screenMode = mode;
		this.headerSecondaryButtons = [];
		this.headerTertiaryButtons = [];

		if (mode == "edit" && this.editEnabled) {
			this.headerPrimaryButtons = [
				{ method: "updateItem", text: "Save Changes" },
				{ method: "cancelEditing", text: "View Mode" },
				{ method: "close", text: "Close" }
			];
			this.headerTertiaryButtons = [
				{ method: "addNewClientPopup", text: "Add New Client" },
				{ method: "addNewContactPopup", text: "Add New Contact" },
				{ method: "addNewProjectPopup", text: "Add New Project" }
			];
		} else if (mode == "view") {
			this.headerPrimaryButtons = [
				{ method: "edit", text: "Edit" },
				{ method: "close", text: "Close" }
			];
			if (this.item.rfQsCreated === true) {
				this.headerSecondaryButtons = [{ method: "viewRFQs", text: "View RFQs" }];
			} else {
				this.headerSecondaryButtons = [{ method: "createRFQs", text: "Create RFQs" }];
			}
			this.headerSecondaryButtons.push({ method: "viewDeliveryNotes", text: "View Delivery Notes" });
			// this.headerSecondaryButtons.push({ method: "createDeliveryNotePopup", text: "Create Delivery Note" });
			this.headerSecondaryButtons.push({ method: "viewInvoices", text: "View Invoices" });
			this.headerSecondaryButtons.push({ method: "createInvoicePopup", text: "Create Invoice" });
			this.headerSecondaryButtons.push({ method: "mergeByPo", text: "Merge" });
		}
	}

	cancelEditing() {
		if (confirm("Are you sure you wish to switch modes? Unsaved changes will be discarded.") == false) {
			return;
		}
		this.readOnly = true;
		this.buildButtons("view");
		this.title = "View " + this.itemType;
	}

	close() {
		this.router.navigate([`/${this.returnUrl}/`]);
	}

	closePopup() {
		this.popupVisible = false;
	}

	createDeliveryNote() {
		let valid = true;
		let totalQuantity = 0;
		this.salesOrderPartsForDeliveryNote.forEach((row: SalesOrderPartForDeliveryNote) => {
			if (row.quantity < 0 || row.quantity > row.maximumAddableQuantity) {
				notify("Please ensure all quantities to add to delivery note fall within the correct range.", "Error", 5000);
				valid = false;
				return;
			}
			totalQuantity += row.quantity;
		});

		if (totalQuantity < 1) {
			notify("You must include at least one line item in the delivery note.", "Error", 5000);
			valid = false;
			return;
		}

		if (this.popupDeliveryNote.clientLocationId === undefined) {
			notify("You must select a client location.", "Error", 5000);
			valid = false;
			return;
		}
		if (valid) {
			// Actually create the note
			this.popupDeliveryNote.clientId = this.item.clientId;
			this.popupDeliveryNote.generatedById = this.currentUser.userId;
			this.popupDeliveryNote.lineItems = JSON.stringify(this.salesOrderPartsForDeliveryNote);
			this.popupDeliveryNote.salesOrderId = this.item.id;
			
			
			this.deliveryNoteService.createSingle(this.popupDeliveryNote)
				.subscribe(
					(res: any) => {									
						const newDeliveryNote = res.response;
						this.router.navigate([`admin/deliveryNotes/${newDeliveryNote.lookupId}`]);
						this.closePopup();
					},
					(err) => console.log(err)
				);
		}
	}

	createDeliveryNotePopup() {
		this.popupDeliveryNote = new DeliveryNote();
		this.popupTitle = "Create Delivery Note";
		this.popupVisible = true;
		this.popupSaveButtonOptions = { onClick: () => this.createDeliveryNote(), text: "Create Delivery Note" };
	}

	createInvoice() {
		let valid = true;
		this.salesOrderPartsForInvoice.forEach((row: SalesOrderPartForInvoice) => {
			if (row.quantity < 0 || row.quantity > row.maximumAddableQuantity) {
				notify("Please ensure all quantities to add to invoice fall within the correct range.", "Error", 5000);
				valid = false;
				return;
			}
		});

		if (this.popupInvoice.clientBillingLocationId === undefined) {
			notify("You must select a client billing location.", "Error", 5000);
			valid = false;
			return;
		}

		if (this.popupInvoice.clientDeliveryLocationId === undefined) {
			notify("You must select a client delivery location.", "Error", 5000);
			valid = false;
			return;
		}

		if (valid) {
			// Actually create the note
			this.popupInvoice.clientId = this.item.clientId;
			this.popupInvoice.generatedById = this.currentUser.userId;
			this.popupInvoice.lineItems = JSON.stringify(this.salesOrderPartsForInvoice);
			this.popupInvoice.salesOrderId = this.item.id;
			
			this.invoiceService.createSingle(this.popupInvoice)
				.subscribe(
					(res: any) => {									
						const newInvoice = res.response;
						this.router.navigate([`admin/invoices/${newInvoice.lookupId}`]);
						this.closePopup();
					},
					(err) => console.log(err)
				);
		}
	}

	createInvoicePopup() {
		this.popupInvoice = new CreateInvoiceFromShippingNote();
		this.popupTitle = "Create Invoice";
		this.popupVisible = true;
		this.popupSaveButtonOptions = { onClick: () => this.createInvoice(), text: "Create Invoice" };
	}

	createRFQs() {
		if (confirm("Are you sure you wish to create RFQs? Please ensure all line items are correct and sheet materials have dimensions set.") == false) {
			return;
		}
		this.itemService.createRFQs(this.item.id)
			.subscribe(
				() => {
					// go to RFQs for Sales Order Page
					this.router.navigate([`admin/salesOrders/${this.item.id}/rfqs`]);
				},
				(err: any) => {
					notify("Could not add create RFQs. Please double check the Sales Order details and settings on all purchasable items required.", "Error", 5000);
					console.log(err);
				}
			);
	}

	deleteSalesOrderPart(e: any) {
		if (e.row.data.quantityInDeliveryNotes > 0 || e.row.data.quantityInWorkOrders > 0 || e.row.data.quantityInvoiced > 0) {
			alert("Cannot delete this line as it has had delivery notes / invoices / work orders allocated.");
			return;
		}
		if (confirm("Are you sure you wish to delete this line? This cannot be undone.") == false) {
			return;
		}

		this.salesOrderPartService.deleteSingleById(e.row.data.id).subscribe(
			() => {
				notify("Successfully Deleted Line", "success", 5000);
				this.getSalesOrderParts(this.item.id);
			},
			(err) => {
				notify("Could not delete line - " + err, "error", 5000);
			}
		);
	}

	downloadPoDocument() {
		if (this.poDocument === null || this.poDocument === undefined) {
			alert("No PO Document has been uploaded for this Sales Order");
			return false;
		}
		this.fileService.downloadFile(this.poDocument.lookupId).subscribe(
			(response: any) =>{
				const dataType = response.type;
				const binaryData = [];
				binaryData.push(response);
				const downloadLink = document.createElement("a");
				downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
				downloadLink.setAttribute("download", this.poDocument.name);
				document.body.appendChild(downloadLink);
				downloadLink.click();
			}
		);
		return false;
	}

	edit() {
		this.readOnly = false;
		this.title = "Edit " + this.itemType;
		this.buildButtons("edit");
		notify("You are now editing...", "warning", 5000);
	}

	editSalesOrderPartPopup(e: any) {
		this.popupSalesOrderPart = e.row.data;
		this.popupTitle = "Edit Part Details";
		this.popupVisible = true;
		this.popupSaveButtonOptions = { onClick: () => this.updateSalesOrderPart(), text: "Save Changes" };
	}

	formDataChanged(args: any) {
		switch (args.dataField) {
			case "clientId":
				this.getContacts(args.value);
				this.getClientLocations(args.value);
				this.getProjects();
				break;
			case "clientContactId":
				this.getContact(args.value);
				break;
		}
	}

	getClientLocations(clientId: number)	{
		this.clientLocationService.getLocationsByClientId(clientId)
			.subscribe(
				(res: any) => {
					this.clientLocations = res.response;

					this.clientLocations.sort((a,b) => a.name.localeCompare(b.name));
				},
				(err) => console.log(err)
			);
	}

	getClients(setNewestClient = false) {
		this.clientService.getAll()
			.subscribe(
				(res: any) => {
					this.clients = res.response.items;

					if (setNewestClient) {
						this.item.clientId = this.clients[this.clients.length - 1].id;
					}

					this.clients.sort((a,b) => a.name.localeCompare(b.name));
				},
				(err) => console.log(err)
			);
	}
	
	getContact(contactId: number) {
		this.clientContactService.getSingleById(contactId)
			.subscribe(
				(res: any) => {
					this.contact = res.response;
					this.item.clientContactId = contactId;
				},
				(err) => console.log(err)
			);
	}

	getContacts(clientId: number, setNewestContact = false)	{
		this.clientContactService.getContactByParentId(clientId)
			.subscribe(
				(res: any) => {
					this.contacts = res.response;

					if (setNewestContact) {
						this.getContact(this.contacts[this.contacts.length - 1].id);
					} else if (this.contacts.some(c => c.id === this.item.clientContactId)) {
						// do nothing, the contact is present
					}
					else
					{
						this.getContact(this.contacts[0].id);
					}

					this.contacts.sort((a,b) => a.contactName.localeCompare(b.contactName));
				},
				(err) => console.log(err)
			);
	}

	getCurrentUser()
	{
		this.authService.getCurrentUser()
			.subscribe(
				(res: any) => {
					this.currentUser = res.response;
				},
				(err) => console.log(err)
			);
	}

	getDeliveryNotes(salesOrderId: number)	{
		this.deliveryNoteService.getBySalesOrderId(salesOrderId)
			.subscribe(
				(res: any) => {
					this.deliveryNotesForInvoice = res.response;
				},
				(err) => console.log(err)
			);
	}

	getItem(lookupId: Guid, buttonMode: string) {
		this.itemService.getSingleByLookupId(lookupId)
			.subscribe(
				(res: any) => {
					this.item = res.response;
					this.getContacts(this.item.clientId);
					this.getClientLocations(this.item.clientId);
					this.getDeliveryNotes(this.item.id);
					this.getSalesOrderParts(this.item.id);
					this.getWorkOrders(this.item.id);
					this.buildButtons(buttonMode);

					if (this.item.poDocumentId != undefined) {
						this.fileService.getSingleById(this.item.poDocumentId)
							.subscribe(
								(res: any) => {
									this.poDocument = res.response;
								}
							);
					} else {
						this.poDocument = { id: 0, name: "" };
					}
				},
				(err) => {
					console.log(err);
					notify("Something Went Wrong!", "Error", 5000);
				},
			);
	}
	
	getPeople() {
		this.userProfileService.getActiveUsers()
			.subscribe(
				(res: any) =>  {
					this.people = res.response;
					this.people.sort((a,b) => a.fullName.localeCompare(b.fullName));
				},
				(err) => console.log(err)
			);
	}

	getProjects(setNewestProject = false) {
		this.projectService.getAll()
			.subscribe(
				(res: any) => {
					this.projects = res.response.items;
					if (setNewestProject) {
						this.item.projectId = this.projects[this.projects.length - 1].id;
					}

					this.projects.sort((a,b) => a.name.localeCompare(b.name));
				},
				(err) => console.log(err)
			);
	}
	
	getSalesOrderParts(salesOrderId: number) {
		this.salesOrderPartService.getForSalesOrder(salesOrderId)
			.subscribe(
				(res: any) =>  {
					this.salesOrderParts = res.response;
					this.getSalesOrderPartsForDeliveryNote();
					this.getSalesOrderPartsForInvoice();
				},
				(err) => console.log(err)
			);
	}

	getSalesOrderPartsForDeliveryNote() {
		this.salesOrderPartsForDeliveryNote = [];

		this.salesOrderParts.forEach((salesOrderPart: SalesOrderPart) => {
			const deliveryPart: SalesOrderPartForDeliveryNote = {
				maximumAddableQuantity: salesOrderPart.quantityOrdered - salesOrderPart.quantityInDeliveryNotes,
				partDescription: salesOrderPart.part.description,
				partNumber: salesOrderPart.part.partNumber,
				partRevisionNumber: salesOrderPart.part.bplVersion,
				poNumber: salesOrderPart.poNumber,
				quantity: salesOrderPart.quantityOrdered - salesOrderPart.quantityInDeliveryNotes,
				quantityInDeliveryNotes: salesOrderPart.quantityInDeliveryNotes,
				quantityOrdered: salesOrderPart.quantityOrdered,
				salesOrderPartId: salesOrderPart.id,
			};

			this.salesOrderPartsForDeliveryNote.push(deliveryPart);
		});		
	}

	getSalesOrderPartsForInvoice() {
		this.salesOrderPartsForInvoice = [];

		this.salesOrderParts.forEach((salesOrderPart: SalesOrderPart) => {
			const invoicePart: SalesOrderPartForInvoice = {
				canIncludeAssemblyFixtureValue: salesOrderPart.assemblyFixtureInvoiced === false,
				canIncludeInspectionFixtureValue: salesOrderPart.inspectionFixtureInvoiced === false,
				canIncludeLaserFixtureValue: salesOrderPart.laserFixtureInvoiced === false,
				canIncludeToolingValue: salesOrderPart.toolingInvoiced === false,
				canIncludeWeldFixtureValue: salesOrderPart.weldFixtureInvoiced === false,
				includeAssemblyFixtureValue: false,
				includeInspectionFixtureValue: false,
				includeLaserFixtureValue: false,
				includeToolingValue: false,
				includeWeldFixtureValue: false,
				maximumAddableQuantity: salesOrderPart.quantityOrdered - salesOrderPart.quantityInvoiced,
				partDescription: salesOrderPart.part.description,
				partNumber: salesOrderPart.part.partNumber,
				partRevisionNumber: salesOrderPart.part.bplVersion,
				poNumber: salesOrderPart.poNumber,
				poNumberAssemblyFixture: salesOrderPart.poNumberAssemblyFixture,
				poNumberInspectionFixture: salesOrderPart.poNumberInspectionFixture,
				poNumberLaserFixture: salesOrderPart.poNumberLaserFixture,
				poNumberTooling: salesOrderPart.poNumberTooling,
				poNumberWeldFixture: salesOrderPart.poNumberWeldFixture,
				quantity: salesOrderPart.quantityOrdered - salesOrderPart.quantityInvoiced,
				quantityInvoiced: salesOrderPart.quantityInvoiced,
				quantityOrdered: salesOrderPart.quantityOrdered,
				salesOrderPartId: salesOrderPart.id,
			};

			this.salesOrderPartsForInvoice.push(invoicePart);
		});		
	}
	
	getWorkOrders(salesOrderId: number) {
		this.workOrderService.getForSalesOrder(salesOrderId)
			.subscribe(
				(res: any) =>  {
					this.workOrders = res.response;
				},
				(err) => console.log(err)
			);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	invoiceEditorPreparing(e: any) {	
		if (e.dataField === "includeAssemblyFixtureValue" && e.row.data.canIncludeAssemblyFixtureValue === false) {
			e.allowEditing = false;
			e.editorOptions.readOnly = true;
		}	
		if (e.dataField === "includeInspectionFixtureValue" && e.row.data.canIncludeInspectionFixtureValue === false) {
			e.allowEditing = false;
			e.editorOptions.readOnly = true;
		}	
		if (e.dataField === "includeLaserFixtureValue" && e.row.data.canIncludeLaserFixtureValue === false) {
			e.allowEditing = false;
			e.editorOptions.readOnly = true;
		}	
		if (e.dataField === "includeToolingValue" && e.row.data.canIncludeToolingValue === false) {
			e.allowEditing = false;
			e.editorOptions.readOnly = true;
		}	
		if (e.dataField === "includeWeldFixtureValue" && e.row.data.canIncludeWeldFixtureValue === false) {
			e.allowEditing = false;
			e.editorOptions.readOnly = true;
		}
	}

	isAddWorkOrderVisible(e: any) {
		return e.row.data.quantityOrdered > e.row.data.quantityInWorkOrders;
	}

	isDeleteSalesOrderPartVisible(e: any) {
		return e.row.data.quantityInDeliveryNotes == 0 && e.row.data.quantityInWorkOrders == 0 && e.row.data.quantityInvoiced == 0;
	}

	mergeByPo() {
		this.itemService.merge(this.item.id)
			.subscribe(
				() => {
					notify("Successfully Merged! Please refresh.", "success", 5000);
					// this.cancelEditing();
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	removePO() {
		this.item.poDocumentId = undefined;
		this.updateItem();
	}

	updateItem() {
		this.itemService.updateSingleById(this.item.id, this.item)
			.subscribe(
				() => {
					notify("Successfully Updated", "success", 5000);
					// this.cancelEditing();
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	// This is done from a popup modal
	updateSalesOrderPart() {
		this.salesOrderPartService.updateSingleById(this.popupSalesOrderPart.id, this.popupSalesOrderPart)
			.subscribe(
				() => {
					notify("Successfully Updated", "success", 5000);
					this.closePopup();
					this.getSalesOrderParts(this.item.id);
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	uploadPoDocument(file: File) {
		const uploadData = new FormData();
		uploadData.append("files", file);
		this.fileService.uploadFile(uploadData)
			.subscribe(
				(res: any) => {									
					this.fileService.getSingleByLookupId(res.response[0].lookupId)
						.subscribe(
							(res: any) => {
								this.item.poDocumentId = res.response.id;
								this.poDocument = res.response;
							},
							(err) => {console.log(err); notify("Something Went Wrong!", "Error", 5000);}
						);
				},
				(err) => console.log(err)
			);
	}

	viewDeliveryNotes() {		
		this.router.navigate([`admin/salesOrders/${this.item.id}/deliveryNotes`]);
	}

	viewInvoices() {		
		this.router.navigate([`admin/salesOrders/${this.item.id}/invoices`]);
	}

	viewPart(e: any) {
		this.router.navigate([`admin/parts/${e.row.data.partId}`]);
	}

	viewRFQs() {		
		this.router.navigate([`admin/salesOrders/${this.item.id}/rfqs`]);
	}

	viewWorkOrder(e: any) {
		this.router.navigate([`admin/workOrders/${e.row.data.id}`]);
	}
}