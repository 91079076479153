import { NonConformanceReportConcernOrigin } from "@enums/nonConformanceReportConcernOrigin.enum";
import { NonConformanceReportPriority } from "@enums/nonConformanceReportPriority.enum";
import { NonConformanceReportStatus } from "@enums/nonConformanceReportStatus.enum";
import { Model } from "@interfaces/model.interface";

export interface NonConformanceReport extends Model {
	areaOfIssue: string;
	automotive: boolean;
	carePoint: boolean;
	carePointDetails: string;
	clientId?: number;
	closedById?: number;
	concern: string;
	concernOrigin: NonConformanceReportConcernOrigin;
	containment: string;
	correctiveAction: string;
	costing?: number;
	customerConcernNumber: string;
	dateClosed?: Date;
	dateForClosure?: Date;
	dateReported: Date;
	deadline: Date;
	images: string;
	invoiceNumber: string;
	lastReminderSent?: Date;
	machineId?: number;
	masterPartId?: number;
	notes: string;
	numberOfPartsAffected: number;
	partId?: number;
	partRouterStageNumber?: number;
	peopleResponsibleForClosure: string;
	personResponsibleId? : number;
	previousOccurances: boolean;
	priority: NonConformanceReportPriority;
	problemSolvingTool1: boolean;
	problemSolvingTool2: boolean;
	problemSolvingTool3: boolean;
	problemSolvingTool4: boolean;
	problemSolvingTool5: boolean;
	problemSolvingTool6: boolean;
	problemSolvingToolUsed: boolean;
	projectId?: number;
	quantitySuspected?: number;
	reporterId?: number;
	rootCause: string;
	status: NonConformanceReportStatus;
	supplierId?: number;
	supportingDocuments: string;
	timesheet: string;
	totalCost: number;
	totalTime: number;
}

export class NonConformanceReport implements NonConformanceReport {	
	concernOrigin = NonConformanceReportConcernOrigin.Internal;
	dateReported = new Date;
	priority = NonConformanceReportPriority.Low;
	status = NonConformanceReportStatus.Open;

	constructor(data?: Partial<NonConformanceReport>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}