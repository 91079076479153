<admin-header [primaryButtons]="headerPrimaryButtons" [secondaryButtons]="headerSecondaryButtons" [tertiaryButtons]="headerTertiaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<div class="form-container">
	<dx-form class="m-3" [(formData)]="item" [showColonAfterLabel]="true" labelLocation="top" [readOnly]="readOnly" (onFieldDataChanged)="formDataChanged($event)">
		<dxi-item itemType="tabbed" [tabPanelOptions]="{hoverStateEnabled:false}">
			<dxo-tab-panel-options [deferRendering]="false"></dxo-tab-panel-options>
			<dxi-tab title="Issue Details" [colCount]="6">
				<dxi-item [colSpan]="2" dataField="areaOfIssue" editorType="dxSelectBox" [editorOptions]="{ items: areasOfIssue, searchEnabled: true }" [label]="{ text: 'Area Of Issue' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
				<dxi-item [colSpan]="2" dataField="allocationId" editorType="dxSelectBox" [editorOptions]="{ items: allocations, searchEnabled: true, displayExpr:'id', showClearButton: true, valueExpr:'id' }" [label]="{ text: 'Allocation' }"></dxi-item>
				<dxi-item [colSpan]="2" dataField="clientId" editorType="dxSelectBox" [editorOptions]="{ items: clients, searchEnabled: true, displayExpr:'name', showClearButton: false, valueExpr:'id', readOnly: true }" [label]="{ text: 'Client' }"></dxi-item>
				<dxi-item [colSpan]="2" dataField="partId" editorType="dxSelectBox" [editorOptions]="{ items: parts, searchEnabled: true, displayExpr:'partNumber', showClearButton: false, valueExpr:'id', readOnly: true }" [label]="{ text: 'Part' }"></dxi-item>
				<dxi-item [colSpan]="2" dataField="projectId" editorType="dxSelectBox" [editorOptions]="{ items: projects, searchEnabled: true, displayExpr:'displayName', showClearButton: false, valueExpr:'id', readOnly: true }" [label]="{ text: 'Project' }"></dxi-item>	
				<dxi-item [colSpan]="2" dataField="operatorId" editorType="dxSelectBox" [editorOptions]="{ items: userProfiles, searchEnabled: true, displayExpr:'fullName', showClearButton: true, valueExpr:'id' }" [label]="{ text: 'Operator' }"></dxi-item>
				<dxi-item [colSpan]="2" dataField="asnNumber" [label]="{ text: 'ASN Number / Delivery Note Number' }"></dxi-item>
				<dxi-item [colSpan]="1" dataField="failureReason" editorType="dxSelectBox" [editorOptions]="{ items: failureReasons, searchEnabled: true, displayExpr:'name', showClearButton: true, valueExpr:'id' }" [label]="{ text: 'Failure Reason' }"></dxi-item>
				<dxi-item [colSpan]="1" dataField="numberOfPartsChecked"></dxi-item>
				<dxi-item [colSpan]="1" dataField="numberOfPartsFailed"></dxi-item>
				<dxi-item [colSpan]="1" dataField="numberOfPartsReworkable"></dxi-item>
				<dxi-item [colSpan]="1" dataField="prePaint" editorType="dxCheckBox"></dxi-item>
				<dxi-item [colSpan]="1" dataField="isProduction" editorType="dxCheckBox"></dxi-item>
			</dxi-tab>
			<dxi-tab title="Image">
				<dxi-item>
					<div class="image" [style]="imageStyle"></div>
				</dxi-item>
				<dxi-item>
					<dx-file-uploader [visible]="mode != 'view'" [showFileList]="false" [maxFileSize]="2097152" [uploadFile]="uploadImage" accept="image/*" [allowedFileExtensions]="['.gif', '.jpg', '.jpeg', '.png']"></dx-file-uploader>
				</dxi-item>
			</dxi-tab>
		</dxi-item>
	</dx-form>
</div>