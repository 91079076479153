import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { EdiMessageType } from "@enums/ediMessageType.enum";
import { EdiMessageDirection } from "@enums/ediMessageDirection.enum";
import { EdiMessageProcessingStatus } from "@enums/ediMessageProcessingStatus.enum";
import { GetPropertiesFromEnum } from "@helpers/getPropertiesFromEnum";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { Client } from "@interfaces/client.interface";
import { ImportEdiOrdersCSV } from "@interfaces/importEdiOrdersCSV.interface";
import { LogExport } from "@interfaces/logExport.interface";
import { ClientService } from "@services/client.service";
import { EdiMessageService } from "@services/ediMessage.service";
import { ExportLogService } from "@services/exportLog.service";
import { FileService } from "@services/file.service";
import notify from "devextreme/ui/notify";

@Component({
	moduleId: module.id,
	selector: "ediMessages",
	styleUrls: ["ediMessages.css"],
	templateUrl: "ediMessages.html"
})
export class EdiMessagesAdminComponent {
	clients: Client[] = [];
	deleteEnabled = true;
	editEnabled = true;
	gridLoading = false;
	headerPrimaryButtons: AdminHeaderButton[] = [];
	itemType = "Edi Message"
	itemUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	items: any[] = [];
	messageDirections: any;
	messageTypes: any;
	popupAddButtonOptions: any = { onClick: () => this.importCSV(), text: "Import" };
	popupCloseButtonOptions: any = { onClick: () => this.closePopup(), text: "Close" };
	popupImportDetails: ImportEdiOrdersCSV = new ImportEdiOrdersCSV();
	popupTitle = "Import Edi Orders Message CSV";
	popupVisible = false;
	statuses: any;
	title: string;
	validationErrors: string[] = [];
	
	constructor(private router: Router, private itemService: EdiMessageService, private clientService: ClientService, private exportLogService: ExportLogService, private fileService: FileService) {
		this.title = `${this.itemType}s`;
		this.messageDirections = GetPropertiesFromEnum(EdiMessageDirection);
		this.messageTypes = GetPropertiesFromEnum(EdiMessageType);
		this.statuses = GetPropertiesFromEnum(EdiMessageProcessingStatus);
		this.uploadCSVForImport = this.uploadCSVForImport.bind(this);
		this.viewRow = this.viewRow.bind(this);
		this.getItems();
		this.getClients();
		this.headerPrimaryButtons.push({ icon: "fal fa-upload", method: "importCSVPopup", text: "Import Edi Orders Message CSV" });
	}
	
	closePopup() {
		this.popupVisible = false;
	}

	getClients() {
		this.clientService.getAll()
			.subscribe(
				(res: any) => {
					this.clients = res.response.items;
					this.clients.sort((a,b) => a.name.localeCompare(b.name));
				},
				(err: any) => console.log(err)
			);
	}

	getItems() {
		this.gridLoading = true;
		this.itemService.getLatest()
			.subscribe(
				(res: any) => {
					this.items = res.response;
					this.gridLoading = false;
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				},
			);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	importCSV() {
		this.validationErrors = [];
		this.itemService.importOrdersCSV(this.popupImportDetails)
			.subscribe(
				() => {
					this.closePopup();
					this.getItems();
				},
				(err: any) => {
					this.validationErrors = err.file;
				}
			);
	}

	importCSVPopup() {
		this.validationErrors = [];
		this.popupVisible = true;
		this.popupAddButtonOptions = { onClick: () => this.importCSV(), text: "Import" };
		this.popupCloseButtonOptions = { onClick: () => this.closePopup(), text: "Close" };
		this.popupImportDetails = new ImportEdiOrdersCSV();
		this.popupTitle = "Import Edi Orders Message CSV";
	}

	logExport() {
		const logExport = new LogExport();
		logExport.gridName = "EDI Messages";
		this.exportLogService.logExport(logExport)
			.subscribe(
				() => console.log("Export Logged"),
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
					return false;
				},
			);
	}

	onRowPrepared(e: any) {
		if (e.rowType === "data") { 
			console.log(e.data); 
			if (e.data.messageType === 20 && e.data.status === 30)  {
				e.rowElement.classList.add("needsCompleting");
				e.rowElement.style.backgroundColor = "#ffd2ad";  
				e.rowElement.className = e.rowElement.className.replace("dx-row-alt", "");
			} else if (e.data.messageType === 20 && e.data.status === 50)  {
				console.log("hit");
				e.rowElement.classList.add("incomplete");
				e.rowElement.style.backgroundColor = "#D9534F";  
				e.rowElement.className = e.rowElement.className.replace("dx-row-alt", "");
			} else if (e.data.messageType === 10 && e.data.status === 0)  {
				e.rowElement.classList.add("needsCompleting");
				e.rowElement.style.backgroundColor = "#ffd2ad";  
				e.rowElement.className = e.rowElement.className.replace("dx-row-alt", "");
			} else if (e.data.messageType === 0 && e.data.status === 0)  {
				e.rowElement.classList.add("needsCompleting");
				e.rowElement.style.backgroundColor = "#ffd2ad";  
				e.rowElement.className = e.rowElement.className.replace("dx-row-alt", "");
			}  
		}
	}

	uploadCSVForImport(file: File) {
		this.validationErrors = [];
		const containerName = "class-schedules";
		const uploadData = new FormData();
		uploadData.append("containerName", containerName);
		uploadData.append("files", file);
		this.fileService.uploadFile(uploadData)
			.subscribe(
				(res: any) => {
					this.popupImportDetails.fileLookupId = res.response[0].lookupId;
				},
				(err) => {
					console.log(err);
					notify("CSV Upload Failed - Please ensure you are uploading a valid file", "error", 5000);
				}
			);
	}

	viewRow(e: any) {
		this.router.navigate([`${this.itemUrl}/${e.row.data.id}`]);
	}
}