export enum Department {
	BackOffice = 0,
	PressBrake = 5,
	PressShop = 10,
	FlatLaser = 20,
	Laser = 30,
	Assembly = 40,
	Welding = 50,
	QualityControl = 60,
	Outsource = 70,
	ToolRoom = 80,
}