import { Model } from "@interfaces/model.interface";

export interface EdiShippingNote extends Model {
	asnNumber: string;
	clientLocationId: number;
	containerQuantity: number;
	dateRequired: Date;
	dock: string;
	ediMessageId: number;
	estimatedArrivalDate: Date;
	grossWeight: number;
	isLocked: boolean;
	isSent: boolean;
	netWeight: number;
	parentEdiShippingNoteId?: number;
	quantity: number;
	salesOrderId: number;
	shippingReferenceNumber: string;
	showPalletBalanceWarning: boolean;
	totalPallets: number;
}

export class EdiShippingNote implements EdiShippingNote {
	constructor(data?: Partial<EdiShippingNote>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}