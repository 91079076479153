<div class="operator-bg">
	<!-- Loading -->
	<div id="loading" class="popup" *ngIf="isLoading">
		<h2 style="margin-left:370px; margin-top:180px; color:white;">Loading</h2>
		<img src="../../../../assets/loading.gif" style="margin-left:340px;">
	</div>


	<!-- No Work Order -->
	<div id="mode" class="popup" *ngIf="!isLoading && orderCurrent === undefined">
		<h2 style="margin-left:30px; margin-top:30px; color:white;">{{this.machine.cellNumber}}</h2>
		<br />
		<br />
		<br />
		<p style="font-size:60px; text-align: center; color:white">No Work Order</p>
		<p style="font-size:60px; text-align: center;color:white">Assigned to Machine.</p>
		<br />
		<br />
		<br />
		<div (click)="reloadPage()"
			style="padding-top:20px; margin-left:32px; margin-top:40px; width:750px; height:125px; border:5px solid #a7ddff; background-color: #0076EB;  text-align: center;">
			<span style="font-size: 50px; color:white">Reload</span>
		</div>
	</div>


	<!-- No Machine -->
	<div id="mode" class="popup" *ngIf="!isLoading && machineId == 0">
		<br />
		<br />
		<br />
		<br />
		<p style="font-size:60px; text-align: center; color:white">No Machine</p>
		<p style="font-size:60px; text-align: center;color:white">Assigned to Interface.</p>
		<br />
		<br />
		<br />
		<div (click)="reloadPage()"
			style="padding-top:20px; margin-left:32px; margin-top:40px; width:750px; height:125px; border:5px solid #a7ddff; background-color: #0076EB;  text-align: center;">
			<span style="font-size: 50px; color:white">Reload</span>
		</div>
	</div>


	<!-- Assign Work Order -->
	<div id="complete" class="popup" style="height:674px; margin-top:40px;" *ngIf="showAllocate">
		<div style="height:40px;"></div>
		<span style="font-size:35px; text-align: left; color:white; margin-left:42px; margin-top:40px !important;">
			Please enter the work order number</span>
		<div class="row">
			<input class="input-override" type="text" name="woNumber" [(ngModel)]="allocationWo" placeholder="WO123"
				autocomplete="off"
				style="margin-left:42px; width: 762px;margin-top:50px;border-radius:0px; height:100px; font-size: 40px;">
		</div>
		<div style="height:40px;"></div>
		<span style="font-size:35px; text-align: left; color:white; margin-left:42px; margin-top:40px !important;">
			Please enter total parts used from WO.</span>
		<div class="row">
			<input class="input-override" type="number" name="quantity"
				(keyup.enter)="assignToAllocations(allocationWo,allocationQty)" [(ngModel)]="allocationQty"
				placeholder="Quantity" autocomplete="off"
				style="margin-left:42px; width: 762px;margin-top:50px;border-radius:0px; height:100px; font-size: 40px;">
		</div>
		<div class="row">
			<div (click)="assignToAllocations(allocationWo,allocationQty)"
				style="padding-top:20px; margin-left:42px; margin-top:40px; width:255px; height:125px; border:5px solid #A7FFA7; background-color: #07B500;  text-align: center;">
				<span style="font-size: 50px; color:white">SUBMIT</span>
			</div>
			<div (click)="toggleScan()"
				style="padding-top:20px; margin-left:42px; margin-top:40px; width:170px; height:125px; border:5px solid #ff6ad5; background-color: #eb00a8;  text-align: center;">
				<i class="fa fa-barcode-scan" style="font-size: 80px; color:white; margin-top:0px;"></i>
			</div>
			<div (click)="toggleAllocate()"
				style="padding-top:20px; margin-left:32px; margin-top:40px; width:255px; height:125px; border:5px solid #a7ddff; background-color: #0076EB;  text-align: center;">
				<span style="font-size: 50px; color:white">CANCEL</span>
			</div>
		</div>
	</div>

	<!-- Scan Work Order -->
	<div id="complete" class="popup" style="height:674px; margin-top:40px;" *ngIf="showScan">
		<div style="height:40px;"></div>
		<span style="font-size:35px; text-align: left; color:white; margin-left:42px; margin-top:40px !important;">
			Please Scan the Work Order</span>
		<div class="row">
			<zxing-scanner #scanner [formats]="[allowedFormats]" [device]="selectedDevice"
				(camerasFound)="cameraFound($event)" (camerasNotFound)="camerasNotFound()"
				(scanSuccess)="onScanSuccess($event)" style="width:30vw !important; margin-left:10vw; margin-top:30px">
			</zxing-scanner>
		</div>
		<div class="row">
			<div (click)="toggleScan()"
				style="padding-top:20px; margin-left:42px; margin-top:40px; width:762px; height:125px; border:5px solid #a7ddff; background-color: #0076EB;  text-align: center;">
				<span style="font-size: 50px; color:white">CANCEL</span>
			</div>
		</div>
	</div>


	<!-- Firewall NOK -->
	<div id="firewallNok" class="popup" *ngIf="showNok" style="color:white;">
		<p style="font-size:40px; text-align: center; color:white; margin-top:1%;">Report NCR </p>
		<div class="row" style="padding:2%">
			<div class="col-3" style="padding-top:20px">
				<h2>Reason:</h2>
			</div>
			<div class="col-9">
				<select id="Id" #Id="ngModel" class="hideLabel form-control" [(ngModel)]="selectedReason" name="Id"
					aria-placeholder="Select Operator" style="border-radius:0px; height:75px; font-size: 40px;"
					(change)="submitReason();">
					<option [ngValue]="k.id" *ngFor="let k of nokReasons">
						{{k.name}}
					</option>
				</select>
			</div>
		</div>
		<div class="row" style="padding:2%">
			<div class="col-3" style="padding-top:20px">
				<h2>Area:</h2>
			</div>
			<div class="col-9">
				<select id="Id" #Id="ngModel" class="hideLabel form-control" [(ngModel)]="selectedArea" name="Id"
					aria-placeholder="Select Operator" style="border-radius:0px; height:75px; font-size: 40px;"
					(change)="submitArea();">
					<option [ngValue]="k" *ngFor="let k of areasOfIssue">
						{{k}}
					</option>
				</select>
			</div>
		</div>
		<div class="row" style="padding:2%; width:100%; position:absolute; bottom:0; margin-left:0;">
			<div class="col-12">
				<div (click)="submitFirewallLog()"
					style="padding-top:20px; width:100%; height:100px; border:5px solid #a7ffaa; background-color: #00eb1f;  text-align: center;">
					<span style="font-size: 50px; color:white">Submit</span>
				</div>
			</div>
		</div>
	</div>


	<!-- Firewall -->
	<div id="firewall" class="popup" *ngIf="showFirewall" style="color:white;">
		<p style="font-size:40px; text-align: center; color:white; margin-top:2%;">Please Enter Final OK/NOK Count </p>
		<div class="row" style="padding:4%">

			<div class="col-2" style="padding-top:30px">
				<h2>OK:</h2>
			</div>
			<div class="col-10">
				<input class="input-override" type="number" name="quantity" (keyup.enter)="updateNokQuantity()"
					(onchange)="updateNokQuantity()" (oninput)="updateNokQuantity()" (keyup.any)="updateNokQuantity()"
					[(ngModel)]="quantityEntered" placeholder="Quantity OK" autocomplete="off"
					style="width: 100%;border-radius:0px; height:100px; font-size: 40px;">
			</div>
		</div>
		<div class="row" style="padding:4%">
			<div class="col-2" style="padding-top:30px">
				<h2>NOK:</h2>
			</div>
			<div class="col-10"> <input class="input-override" type="number" name="quantity"
					(keyup.enter)="submitFirewallLog()" [(ngModel)]="quantityNok" placeholder="Quantity NOT OK"
					autocomplete="off" style="width: 100%;border-radius:0px; height:100px; font-size: 40px;">
			</div>
		</div>

		<div class="row" style="padding:2%; width:100%; position:absolute; bottom:0; margin-left:0;">
			<div class="col-12">
				<div (click)="submitFirewall()"
					style="padding-top:20px; width:100%; height:125px; border:5px solid #a7ffaa; background-color: #00eb1f;  text-align: center;">
					<span style="font-size: 50px; color:white">Submit</span>
				</div>
			</div>
		</div>
	</div>


	<!-- Change Mode -->
	<div id="mode" class="popup" *ngIf="showMode">

		<div class="row" style="padding-left:10px;">
			<div (click)="changeMode(10)"
				style="padding-top:45px; margin-left:32px; margin-top:20px; width:227px; height:125px; border:5px solid #A7FFA7; background-color: #07B500;  text-align: center;">
				<span style="font-size: 20px; color:white;">PRODUCTION</span>
			</div>
			<div (click)="changeMode(50)"
				style="padding-top:45px; margin-left:32px; margin-top:20px; width:227px; height:125px; border:5px solid #FFEBA7; background-color: #EB9A00;  text-align: center;">
				<span style="font-size: 20px; color:white;">CHANGE OVER</span>
			</div>
			<div (click)="changeMode(60)"
				style="padding-top:45px; margin-left:32px; margin-top:20px; width:227px; height:125px; border:5px solid #FFEBA7; background-color: #EB9A00;  text-align: center;">
				<span style="font-size: 20px; color:white;">TOILET BREAK</span>
			</div>
		</div>
		<div class="row" style="padding-left:10px;">
			<div (click)="changeMode(70)"
				style="padding-top:45px; margin-left:32px; margin-top:20px; width:227px; height:125px; border:5px solid #FFEBA7; background-color: #EB9A00;  text-align: center;">
				<span style="font-size: 20px; color:white;">GENERAL BREAK</span>
			</div>
			<div (click)="changeMode(80)"
				style="padding-top:45px; margin-left:32px; margin-top:20px; width:227px; height:125px; border:5px solid #FFEBA7; background-color: #EB9A00;  text-align: center;">
				<span style="font-size: 20px; color:white;">Q LOOP</span>
			</div>
			<div (click)="changeMode(90)"
				style="padding-top:45px; margin-left:32px; margin-top:20px; width:227px; height:125px; border:5px solid #FFEBA7; background-color: #EB9A00;  text-align: center;">
				<span style="font-size: 20px; color:white;">QUALITY CHECK</span>
			</div>
		</div>
		<div class="row" style="padding-left:10px;">
			<div (click)="changeMode(30)"
				style="padding-top:45px; margin-left:32px; margin-top:20px; width:227px; height:125px; border:5px solid #FFA7A7; background-color: #EB0000;  text-align: center;">
				<span style="font-size: 20px; color:white;">MAINTENANCE</span>
			</div>
			<div (click)="changeMode(120)"
				style="padding-top:45px; margin-left:32px; margin-top:20px; width:227px; height:125px; border:5px solid #FFA7A7; background-color: #EB0000;  text-align: center;">
				<span style="font-size: 20px; color:white;">BREAKDOWN</span>
			</div>
			<div (click)="changeMode(20)"
				style="padding-top:45px; margin-left:32px; margin-top:20px; width:227px; height:125px; border:5px solid #FFA7A7; background-color: #EB0000;  text-align: center;">
				<span style="font-size: 20px; color:white;">NOT RUNNING</span>
			</div>
		</div>
		<div (click)="toggleMode()"
			style=" padding-top:20px;margin-left:32px; margin-top:40px; width:750px; height:125px; border:5px solid #a7ddff; background-color: #0076EB;  text-align: center;">
			<span style="font-size: 50px; color:white;">CANCEL</span>
		</div>
	</div>


	<!-- Completed -->
	<div id="complete" class="popup" style="height:474px; margin-top:0px;" *ngIf="showComplete">
		<div style="height:40px;"></div>
		<span style="font-size:35px; text-align: left; color:white; margin-left:42px; margin-top:40px !important;">
			Please enter how many completed overall</span>
		<div class="row">
			<input class="input-override" type="number" name="quantity" (keyup.enter)="submitCompleted()"
				[(ngModel)]="quantityEntered" placeholder="Quantity" autocomplete="off"
				style="margin-left:42px; width: 762px;margin-top:50px;border-radius:0px; height:100px; font-size: 40px;">
		</div>
		<div class="row">
			<div (click)="submitCompleted()"
				style="padding-top:20px; margin-left:42px; margin-top:40px; width:365px; height:125px; border:5px solid #A7FFA7; background-color: #07B500;  text-align: center;">
				<span style="font-size: 60px; color:white">SUBMIT</span>
			</div>
			<div (click)="toggleComplete()"
				style="padding-top:20px; margin-left:32px; margin-top:40px; width:365px; height:125px; border:5px solid #a7ddff; background-color: #0076EB;  text-align: center;">
				<span style="font-size: 50px; color:white">CANCEL</span>
			</div>
		</div>
	</div>
	<div id="supervisorLogin" class="popup" style="height:474px; margin-top:0px;" *ngIf="showSuperLogin">
		<div style="height:40px;"></div>
		<span style="font-size:40px; text-align: left; color:white; margin-left:42px; margin-top:40px !important;">Enter
			Enter Supervisor Code...</span>
		<div class="row">
			<input class="input-override" type="number" name="quantity" (keyup.enter)="submitCompleted()"
				[(ngModel)]="quantityComplete" placeholder="Quantity" autocomplete="off"
				style="margin-left:42px; width: 762px;margin-top:50px;border-radius:0px; height:100px; font-size: 40px;">
		</div>
		<div class="row">
			<div (click)="submitCompleted()"
				style="padding-top:20px; margin-left:42px; margin-top:40px; width:365px; height:125px; border:5px solid #A7FFA7; background-color: #07B500;  text-align: center;">
				<span style="font-size: 60px; color:white">SUBMIT</span>
			</div>
		</div>
	</div>
	<div id="supervisor" class="popup" *ngIf="showSupervisor">
		<p style="font-size:80px; text-align: center; color:white">A SUPERVISOR HAS BEEN NOTIFIED</p>
		<p style="font-size:80px; text-align: center;color:white">THANK YOU</p>
		<div (click)="toggleSupervisor()"
			style="padding-top:20px; margin-left:32px; margin-top:40px; width:750px; height:125px; border:5px solid #a7ddff; background-color: #0076EB;  text-align: center;">
			<span style="font-size: 50px; color:white">OK</span>
		</div>
	</div>
	<div class="popup-blackout" *ngIf="showPopup"></div>
	<div class="popup-blackout" *ngIf="orderCurrent === undefined || machineId == 0"></div>
	<div id="statusBar" class="status-active" style="background-color:{{this.currentStatusColor}}"></div>
	<div class="row" style="margin-left:20px">
		<div id="cellDetails"
			style="margin-left:32px;margin-top:20px;padding-left:22px;padding-top:10px;padding-bottom:10px; background-color: white; height:110px; width:477px">
			<p style="font-size: 20px;">Cell</p>
			<p style="font-size: 70px; margin-top:-30px">{{this.workStation.cellNumber}}</p>
		</div>
		<div id="cellDetails" [style.border]="(this.orderCurrent.operatorId === null) ? 'red solid 5px' : ''"
			style="margin-left:22px;margin-top:20px;padding-left:22px;padding-top:10px;padding-bottom:10px; background-color: white; height:110px; width:477px">
			<p style="font-size: 20px;" [style.color]="(this.orderCurrent.operatorId === null) ? 'red' : '#666666'">
				Operator</p>
			<p *ngIf="this.orderCurrent.operatorId !== null" style="font-size: 40px; margin-top:-10px">
				{{this.orderCurrent.operatorFullName}}</p>
			<p *ngIf="this.orderCurrent.operatorId === null" style="font-size: 40px; margin-top:-10px; color:red">NO
				OPERATOR</p>
		</div>
	</div>
	<div class="row" style="margin-left:20px">
		<div class="col">
			<div id="cellDetails"
				style="margin-left:22px;margin-top:25px;padding-left:22px;padding-top:10px;padding-bottom:10px; background-color: white; height:110px; width:618px">
				<p style="font-size: 20px;">Part Number</p>
				<p style="font-size: 40px; margin-top:0px">{{this.orderCurrent.partNumber}}</p>
			</div>
			<div class="row">
				<div id="cellDetails"
					style="margin-left:32px;margin-top:25px;padding-left:22px;padding-top:10px;padding-bottom:10px; background-color: white; height:110px; width:415px">
					<p style="font-size: 20px;">Client</p>
					<p style="font-size: 30px; margin-top:0px">{{this.orderCurrent.clientName}}</p>
				</div>
				<div id="cellDetails" *ngIf="this.orderCurrent.allocationId !== null"
					style="margin-left:25px;margin-top:25px;padding-left:22px;padding-top:10px;padding-bottom:10px; background-color: white; height:110px; width:180px">
					<p style="font-size: 20px;">Allocation No</p>
					<p style="font-size: 30px; margin-top:0px">{{this.orderCurrent.allocationId}}</p>
				</div>
				<div id="cellDetails" *ngIf="this.orderCurrent.allocationId === null"
					style="margin-left:25px;margin-top:25px;padding-left:22px;padding-top:10px;padding-bottom:10px; background-color: white; height:110px; width:180px; border:red 5px solid">
					<p style="font-size: 20px; color:red">No Allocation</p>
					<p style="font-size: 20px; margin-top:10px; color:red">Press Start</p>
				</div>
			</div>
			<div class="row">
				<div id="cellDetails"
					style="margin-left:32px;margin-top:25px;padding-left:22px;padding-top:10px;padding-bottom:10px; background-color: white; height:110px; width:210px">
					<p style="font-size: 20px;">Qty Required</p>
					<p style="font-size: 30px; margin-top:0px">
						{{this.quantityPicked}}/{{this.orderCurrent.quantityOrdered}}</p>
				</div>
				<div id="cellDetails"
					style="margin-left:25px;margin-top:25px;padding-left:22px;padding-top:10px;padding-bottom:10px; background-color: white; height:110px; width:180px">
					<p style="font-size: 20px;">Packaging</p>
					<p style="font-size: 30px; margin-top:0px">{{this.orderCurrent.part.packagingExternal.name}}</p>
				</div>
				<div id="cellDetails"
					style="margin-left:25px;margin-top:25px;padding-left:22px;padding-top:10px;padding-bottom:10px; background-color: white; height:110px; width:180px">
					<p style="font-size: 20px;">Pack Factor</p>
					<p style="font-size: 30px; margin-top:0px">{{this.orderCurrent.part.packFactorExternal}}</p>
				</div>
			</div>
		</div>
		<div class="col">
			<img style="width:332px; height:378px; margin-top:25px;" [src]="this.orderCurrent.partImage">
		</div>
	</div>
	<div class="row" style="margin-left:20px">

		<div *ngIf="(currentStatus!=10 || this.orderCurrent.operatorId === null)"
			style=" margin-left:32px; margin-top:20px; width:175px; height:130px; border:5px solid #a7a7a7; background-color: #717171; text-align: center; vertical-align: middle;">
			<i class="fa fa-plus" style="font-size: 80px; color:white; margin-top:20px;"></i>
		</div>
		<!-- NO ALLOCATION -->
		<div (click)="createAllocationForEdiOrder(this.orderCurrent.id)"
			*ngIf="(currentStatus==10 && this.orderCurrent.operatorId !== null) && this.orderCurrent.allocationId === null"
			style=" margin-left:32px; margin-top:20px; width:175px; height:130px; border:5px solid #A7FFA7; background-color: #07B500; text-align: center;">
			<i class="fa fa-play" style="font-size: 80px; color:white; margin-top:20px;"></i>
		</div>
		<!-- IF ALLOCATION -->
		<div (click)="toggleAllocate()"
			*ngIf="(currentStatus==10 && this.orderCurrent.operatorId !== null) && this.orderCurrent.allocationId !== null && this.orderCurrent.quantityOrdered !== this.quantityPicked"
			style=" margin-left:32px; margin-top:20px; width:175px; height:130px; border:5px solid #A7FFA7; background-color: #07B500; text-align: center;">
			<i class="fa fa-plus" style="font-size: 80px; color:white; margin-top:20px;"></i>
		</div>

		<div *ngIf="currentStatus!=10 || this.orderCurrent.operatorId === null || this.orderCurrent.allocationId !== null || this.orderCurrent.quantityOrdered === this.quantityPicked"
			style=" margin-left:32px; margin-top:20px; width:175px; height:130px; border:5px solid #a7a7a7; background-color: #717171; text-align: center; vertical-align: middle;">
			<i class="fa fa-plus" style="font-size: 80px; color:white; margin-top:20px;"></i>
		</div>

		<div *ngIf="currentStatus!=10 || this.orderCurrent.operatorId === null || this.orderCurrent.allocationId === null || this.quantityPicked < this.orderCurrent.quantityOrdered"
			style=" margin-left:22px; margin-top:20px; width:150px; height:130px; border:5px solid #a7a7a7; background-color: #717171; text-align: center; vertical-align: middle;">
			<i class="fa fa-check" style="font-size: 80px; color:white; margin-top:20px;"></i>
		</div>
		<div (click)="setToPicked()" *ngIf="currentStatus==10 && this.orderCurrent.operatorId !== null && this.orderCurrent.allocationId !== null && this.quantityPicked >= this.orderCurrent.quantityOrdered"
			style=" margin-left:22px; margin-top:20px; width:150px; height:130px; border:5px solid #A7FFA7; background-color: #07B500; text-align: center;">
			<i class="fa fa-check" style="font-size: 80px; color:white; margin-top:20px;"></i>
		</div>

		<!-- Dead Buttons -->
		<div *ngIf="currentStatus==100"
			style=" margin-left:22px; margin-top:20px; width:130px; height:130px; border:5px solid #a7a7a7; background-color: #717171; text-align: center;">
			<i class="fa fa-engine-warning" style="font-size: 80px; color:white; margin-top:20px;"></i>
		</div>
		<div *ngIf="currentStatus==100"
			style=" margin-left:22px; margin-top:20px; width:130px; height:130px; border:5px solid #a7a7a7; background-color: #717171; text-align: center;">
			<i class="fa fa-box" style="font-size: 80px; color:white; margin-top:20px;"></i>
		</div>
		<div *ngIf="currentStatus==100"
			style=" margin-left:22px; margin-top:20px; width:250px; height:130px; border:5px solid #a7a7a7; background-color: #717171; text-align: center;">
			<i class="fa fa-tools" style="font-size: 80px; color:white; margin-top:20px;"></i>
		</div>
		<!-- Active Buttons -->
		<div (click)="toggleSupervisor()" *ngIf="currentStatus!=100"
			style=" margin-left:22px; margin-top:20px; width:130px; height:130px; border:5px solid #FFEBA7; background-color: #EB9A00; text-align: center;">
			<i class="fa fa-engine-warning" style="font-size: 80px; color:white; margin-top:20px;"></i>
		</div>
		<div (click)="submitStillageRequest()" *ngIf="currentStatus!=100"
			style=" margin-left:22px; margin-top:20px; width:130px; height:130px; border:5px solid #ffa7f0; background-color: #eb00a8; text-align: center;">
			<i class="fa fa-box" style="font-size: 80px; color:white; margin-top:20px;"></i>
		</div>
		<div (click)="toggleMode()" *ngIf="currentStatus!=100"
			style=" margin-left:22px; margin-top:20px; width:150px; height:130px; border:5px solid #B5A7FF; background-color: #7F00EB; text-align: center;">
			<i class="fa fa-tools" style="font-size: 80px; color:white; margin-top:20px;"></i>
		</div>
		<div (click)="reloadPage()"
			style=" margin-left:22px; margin-top:20px; width:130px; height:130px; border:5px solid #A7DDFF; background-color: #0076EB; text-align: center;">
			<i class="fa fa-redo-alt" style="font-size: 80px; color:white; margin-top:20px;"></i>
		</div>
	</div>
	<div id="statusBar" style="background-color:{{this.currentStatusColor}}; margin-top:25px;"></div>

	<!-- <img src="../../../../assets/Logo.png" width="200" style="margin-top:25px; margin-left:140px; margin-bottom:25px;">
<input class="input-override" type="password" name="password" (keyup.enter)="submitData()"
	[(ngModel)]="fobScannedEntity.fobId" placeholder="Fob ID" autocomplete="off"
	style="margin-left:25px; margin-right:25px;">
<button class="btn-override" (click)="submitData()" style="margin-left:25px; margin-top:25px;">
	Submit Details
</button> -->

</div>