export interface ChangeEdiOrderPriority {
	ediOrderId: number;
	priority: number;
}

export class ChangeEdiOrderPriority implements ChangeEdiOrderPriority {
	constructor(data?: Partial<ChangeEdiOrderPriority>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}