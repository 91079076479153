<div class="operator-bg" style="overflow-x:auto; padding:20px">
	<div class="row">

	</div>
	<div class="row">
		<div class="col-9">
			<h1 style="color:white;padding-left:15px;">GOODS {{this.mode.toUpperCase()}}</h1>
			<h2 style="color:#77787F; padding-left:15px;">{{this.site}}</h2>
		</div>
		<div class="col-3" style="padding-left:10px; padding-right:10px;">
			<div
				style="width:100%; height:50px; background-color:#2a2434; color:white; border-radius:25px; text-align: center; margin-top:auto; padding-top:10px; border-color:#838088;">
				<h3>{{this.dateTime.toLocaleString("en", { weekday: "short" })}} {{this.dateTime.toLocaleString("en", { day: "numeric" })}} {{this.dateTime.toLocaleString("en", { month: "short" })}} {{this.dateTime.toLocaleTimeString().slice(0,-3)}}</h3>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-4">
			<div class="row">
				<div class="col-12 g-10" style="padding:0.5vw;" (click)="navigateToStockControl(0)">
					<div class="activeContainer" style="text-align: center; height:10vh; line-height:5vh; padding-top:2vh; border-color:white; color:white !important;">
						<h3 style="color:white;line-height:5vh;">CHECK IN STOCK
						</h3>
					</div>
				</div>
			</div>
		</div>
		<div class="col-4">
			<div class="row">
				<div class="col-12 g-10" style="padding:0.5vw;" (click)="navigateToStockControl(1)">
					<div class="activeContainer" style="text-align: center; height:10vh; line-height:5vh; padding-top:2vh; border-color:white; color:white !important;">
						<h3 style="color:white;line-height:5vh;">CHECK OUT STOCK
						</h3>
					</div>
				</div>
			</div>
		</div>
		<div class="col-4">
			<div class="row">
				<div class="col-12 g-10" style="padding:0.5vw;" (click)="navigateToRelocateStock()">
					<div class="activeContainer" style="text-align: center; height:10vh; line-height:5vh; padding-top:2vh; border-color:white; color:white !important;">
						<h3 style="color:white;line-height:5vh;">RELOCATE STOCK
						</h3>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row" *ngIf="this.mode == 'in'">
		<div class="col-6 g-10" style="padding:0.5vw;" (click)="navigateToPage(1)">
			<div class="activeContainer" style="text-align: center; height:25vh; line-height:8vh; padding-top:1vh; border-color:white; color:white !important;">
				<h2 style="color:white;line-height:10vh;">PURCHASES
				</h2>
				<h2 class="fal fa-box" style="color:white; font-size:50pt;line-height:10vh;"></h2>
			</div>
		</div>
		<div class="col-6 g-10" style="padding:0.5vw;" (click)="navigateToPage(2)">
			<div class="activeContainer" style="text-align: center; height:25vh; line-height:8vh; padding-top:1vh">
				<h2 style="color:white;line-height:10vh;">OUTSIDE PROCESS
				</h2>
				<h2 class="fal fa-spray-can" style="color:white; font-size:50pt;line-height:10vh;"></h2>
			</div>
		</div>

	</div>
	<div class="row" *ngIf="this.mode == 'out'">
		<div class="col-6 g-10" style="padding:0.5vw;" (click)="navigateToPage(4)">
			<div class="activeContainer" style="text-align: center; height:25vh; line-height:8vh; padding-top:1vh">
				<h1 style="color:white;line-height:10vh;line-height:10vh;">SUPPLIERS
				</h1>
				<h1 class="fal fa-parachute-box" style="color:white; font-size:50pt;line-height:10vh;"></h1>
			</div>
		</div>
		<div class="col-6 g-10" style="padding:0.5vw;" (click)="navigateToPage(5)">
			<div class="activeContainer" style="text-align: center; height:25vh; line-height:8vh; padding-top:1vh; border-color:white">
				<h1 style="color:white;line-height:10vh;">CUSTOMER
				</h1>
				<h1 class="fal fa-person-dolly" style="color:white; font-size:50pt;line-height:10vh;"></h1>
			</div>
		</div>

	</div>
	<div class="row">
		<div class="col-12 g-10" style="padding:0.5vw" (click)="navigateToInternal()">
			<div class="activeContainer" style="text-align: center; height:25vh; line-height:8vh; padding-top:2vh; border-color:red;">
				
				<h1 style="color:red; line-height: 10vh;">INTERNAL
				</h1>
				<h1 class="fal fa-repeat" style="color:red; font-size:50pt; line-height:10vh;"></h1>
			</div>
		</div>

	</div>

	<div class="row" *ngIf="this.mode == 'in'">
		<div class="col-12 g-10" style="padding:0.5vw" (click)="switchMode()">
			<div class="activeContainer" style="text-align: center; height:10vh; line-height:10vh; padding-top:0.5vh">
				<h1 style="color:white; line-height: 10vh;">SWITCH TO OUTBOUND
				</h1>
			</div>
		</div>
	</div>
	<div class="row" *ngIf="this.mode == 'out'">
		<div class="col-12 g-10" style="padding:0.5vw" (click)="switchMode()">
			<div class="activeContainer" style="text-align: center; height:10vh; line-height:10vh; padding-top:0.5vh">
				
				<h1 style="color:white; line-height: 10vh;">SWITCH TO INBOUND</h1>
			</div>
		</div>
	</div>
</div>