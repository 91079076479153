<admin-header [primaryButtons]="headerPrimaryButtons" [secondaryButtons]="headerSecondaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<dx-data-grid id="grid" class="data-grid" [dataSource]="items" [remoteOperations]="false" [allowColumnReordering]="true" [allowColumnResizing]="true" [rowAlternationEnabled]="true" [showBorders]="true" (onExporting)="logExport()">
    <dxo-paging [pageSize]="100"></dxo-paging>
	<dxo-state-storing [enabled]="true" type="localStorage" storageKey="invoicesMain"></dxo-state-storing>
    <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
    <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
	<dxo-export [enabled]="true"></dxo-export>
	<dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>
	<dxi-column type="buttons">
        <dxi-button hint="View" icon="fal fa-eye" [onClick]="viewRow"></dxi-button>
        <dxi-button hint="View Sales Order" icon="fal fa-truck" [onClick]="viewSalesOrder"></dxi-button>
        <dxi-button hint="Download PDF" icon="fal fa-download" [onClick]="generatePreviewPDF"></dxi-button>
		<dxi-button icon="fal fa-trash" [onClick]="deleteRow"></dxi-button>
    </dxi-column>
    <dxi-column dataField="id" alignment="left" sortOrder="asc" caption="Invoice Number"></dxi-column>
	<dxi-column dataField="clientId" caption="Client">
		<dxo-lookup [dataSource]="clients" displayExpr="name" valueExpr="id"></dxo-lookup>
	</dxi-column>
	<dxi-column dataField="clientBillingLocationId" caption="Billing Location">
		<dxo-lookup [dataSource]="clientLocations" displayExpr="name" valueExpr="id"></dxo-lookup>
	</dxi-column>
	<dxi-column dataField="clientDeliveryLocationId" caption="Delivery Location">
		<dxo-lookup [dataSource]="clientLocations" displayExpr="name" valueExpr="id"></dxo-lookup>
	</dxi-column>
	<dxi-column dataField="createdAt" dataType="date" format="dd/MM/yyyy"></dxi-column>
	<dxi-column dataField="hasBeenSent" caption="Sent"></dxi-column>
	<dxi-column dataField="invoiceTotalIncludingVat" caption="Total Inc VAT" [format]="{ style: 'currency', currency: 'GBP', useGrouping: true, minimumSignificantDigits: 4 }"></dxi-column>
	<dxi-column dataField="invoiceTotalExcludingVat" caption="Total Exc VAT" [format]="{ style: 'currency', currency: 'GBP', useGrouping: true, minimumSignificantDigits: 4 }"></dxi-column>
	<dxi-column dataField="salesOrderId" caption="Sales Order Id"></dxi-column>
	<dxi-column dataField="poNumber" caption="PO Number"></dxi-column>
	<dxi-column dataField="toolingInvoiced" caption="Tooling Invoiced?"></dxi-column>
	<dxi-column dataField="selfBilled" caption="Self Billed"></dxi-column>
	<dxi-column dataField="completed" caption="Completed"></dxi-column>
	<dxi-column dataField="lineItems" dataType="string" [visible]="false"></dxi-column>
	<dxo-master-detail [enabled]="true" template="detail"></dxo-master-detail>
	<div *dxTemplate="let invoice of 'detail'">
	  <div class="master-detail-caption">{{
		"Line Items for Invoice #" + invoice.data.id
	  }}</div>
	  <invoiceGridDetails [lineItemData]="invoice.data.lineItems"></invoiceGridDetails>
	</div>
</dx-data-grid>
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#grid' }" [(visible)]="gridLoading" [showIndicator]="true" [showPane]="true" [shading]="true"></dx-load-panel>