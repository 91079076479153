import { Component, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AssetEventType } from "@enums/assetEventType.enum";
import { GetPropertiesFromEnum } from "@helpers/getPropertiesFromEnum";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { Asset } from "@interfaces/asset.interface";
import { AssetEvent } from "@interfaces/assetEvent.interface";
import { AssetType } from "@interfaces/assetType.interface";
import { UserProfile } from "@interfaces/userProfile.interface";
import { AssetService } from "@services/asset.service";
import { AssetEventService } from "@services/assetEvent.service";
import { AssetTypeService } from "@services/assetType.service";
import { AuthService } from "@services/auth.service";
import { UserProfileService } from "@services/userProfile.service";
import notify from "devextreme/ui/notify";

@Component({
	encapsulation: ViewEncapsulation.None,
	moduleId: module.id,
	selector: "assets-id",
	styleUrls: ["assets-id.css"],
	templateUrl: "assets-id.html"
})
export class AssetsIdAdminComponent {
	assetEventTypes: any;
	assetEvents: AssetEvent[] = [];
	assetTypes: AssetType[] = [];
	changes: any = [];
	createEnabled = true;
	editEnabled = true;
	editRowKey: any = null;
	headerPrimaryButtons: AdminHeaderButton[] = [];
	headerSecondaryButtons: AdminHeaderButton[] = [];
	headerTertiaryButtons: AdminHeaderButton[] = [];
	item: Asset = new Asset();
	itemId = 0;
	itemType = "Asset";
	itemUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	people: UserProfile[] = [];
	popupAddButtonOptions: any;
	popupAssetEvent: AssetEvent = new AssetEvent();
	popupCloseButtonOptions: any = { onClick: () => this.closePopup(), text: "Close" };
	popupTitle = "";
	popupVisible = false;
	readOnly = true;
	returnUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	screenMode = "";
	title: string = "View " + this.itemType;
	user: UserProfile = new UserProfile();

	constructor(private router: Router, private route: ActivatedRoute, private authService: AuthService, private itemService: AssetService, private assetEventService: AssetEventService, private assetTypeService: AssetTypeService, private userProfileService: UserProfileService) {
		this.buildButtons("view");
		this.getAssetTypes();
		this.getPeople();
		this.getUser();

		this.assetEventTypes = GetPropertiesFromEnum(AssetEventType);

		this.route.params.subscribe((params) => {
			if (params.id != "create") {
				if (params.id.split("_").length > 1) {
					this.getItem(params.id.split("_")[0]);
					this.edit();
				} else {
					this.getItem(params.id);
				}
			} else {
				this.create();
			}
		});

		this.deleteAssetEvent = this.deleteAssetEvent.bind(this);
		this.editAssetEventPopup = this.editAssetEventPopup.bind(this);
		this.generateQrCode = this.generateQrCode.bind(this);
	}

	addNewAssetEvent() {
		this.assetEventService.createSingle(this.popupAssetEvent)
			.subscribe(
				() => {
					this.popupVisible = false;
					this.getAssetEvents(this.item.id);
				},
				(err: any) => {
					notify("Could not add event. Please check the details are valid.", "Error", 5000);
					console.log(err);
				}
			);
	}

	addNewAssetEventPopup() {
		this.popupAssetEvent = new AssetEvent();
		this.popupAssetEvent.assetId = this.item.id;
		this.popupAssetEvent.reporterId = this.user.id;
		this.popupTitle = "Add New Event";
		this.popupVisible = true;
		this.popupAddButtonOptions = { onClick: () => this.addNewAssetEvent(), text: "Add Event" };
	}

	buildButtons(mode: string) {
		this.screenMode = mode;

		if (mode == "edit" && this.editEnabled) {
			this.headerPrimaryButtons = [
				{ method: "updateItem", text: "Save Changes" },
				{ method: "cancelEditing", text: "View Mode" },
				{ method: "close", text: "Close" }
			];
			this.headerTertiaryButtons = [
				{ method: "addNewAssetEventPopup", text: "Add Event" },
				{ method: "generateQrCode", text: "Generate QR" },
			];
		} else if (mode == "create" && this.createEnabled) {
			this.headerPrimaryButtons = [
				{ method: "createItem", text: "Save" },
				{ method: "close", text: "Close" }
			];
			this.headerTertiaryButtons = [];
		} else if (mode == "view") {
			this.headerPrimaryButtons = [
				{ method: "edit", text: "Edit" },
				{ method: "close", text: "Close" }
			];
			this.headerTertiaryButtons = [
				{ method: "addNewAssetEventPopup", text: "Add Event" },
				{ method: "generateQrCode", text: "Generate QR" },
			];
		}
	}

	cancelEditing() {
		if (confirm("Are you sure you wish to switch modes? Unsaved changes will be discarded.") == false) {
			return;
		}
		this.readOnly = true;
		this.buildButtons("view");
		this.title = "View " + this.itemType;
	}

	close() {
		this.router.navigate([`/${this.returnUrl}/`]);
	}

	closePopup() {
		this.popupVisible = false;
	}

	create() {
		this.readOnly = false;
		this.buildButtons("create");
		this.title = "Create New " + this.itemType;
	}

	createItem() {
		this.itemService.createSingle(this.item)
			.subscribe(
				() => {
					notify("Successfully Updated " + this.itemType, "success", 5000);
					this.item = new Asset();
					this.close();
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	deleteAssetEvent(e: any) {
		if (confirm("Are you sure you with to delete this event?") == false) {
			return;
		}
		this.assetEventService.deleteSingleById(e.row.data.id)
			.subscribe(
				() => {
					notify("Successfully Removed Event", "success", 5000);
					this.getAssetEvents(this.item.id);
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	edit() {
		this.readOnly = false;
		this.buildButtons("edit");
		this.title = "Edit " + this.itemType;
		notify("You are now editing...", "warning", 5000);
	}

	editAssetEventPopup(e: any) {
		this.assetEventService.getSingleById(e.row.data.id)
			.subscribe(
				(res: any) => {
					this.popupAssetEvent = res.response;
					this.popupTitle = "Edit Event";
					this.popupVisible = true;
					this.popupAddButtonOptions = { onClick: () => this.updateToolEvent(), text: "Save Changes" };
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	generateQrCode() {
		this.itemService.generateQrCode(this.item.id).subscribe(
			(response: any) => {
				const dataType = response.type;
				const binaryData = [];
				binaryData.push(response);
				const downloadLink = document.createElement("a");
				downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
				downloadLink.setAttribute("download", this.item.lookupId.toString() + ".png");
				document.body.appendChild(downloadLink);
				downloadLink.click();
			}
		);
	}

	getAssetEvents(toolId: number)	{
		this.assetEventService.forAsset(toolId)
			.subscribe(
				(res: any) => {
					this.assetEvents = res.response;
				},
				(err) => console.log(err)
			);
	}

	getAssetTypes()	{
		this.assetTypeService.getAll()
			.subscribe(
				(res: any) => {
					this.assetTypes = res.response.items;
				},
				(err) => console.log(err)
			);
	}

	getItem(itemId: number) {
		this.itemService.getSingleById(itemId)
			.subscribe(
				(res: any) => {
					this.item = res.response;
					this.getAssetEvents(this.item.id);
				},
				(err) => {
					console.log(err);
					notify("Something Went Wrong!", "Error", 5000);
				}
			);
	}
	
	getPeople() {
		this.userProfileService.getActiveUsers()
			.subscribe(
				(res: any) =>  {
					this.people = res.response;
					this.people.sort((a,b) => a.fullName.localeCompare(b.fullName));
				},
				(err) => console.log(err)
			);
	}

	getUser() {
		this.authService.getCurrentUser()
			.subscribe(
				(res: any) => { 
					this.user = res.response;
				},
				(err) => console.log(err)
			);
	}
	
	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	updateItem() {
		this.itemService.updateSingleById(this.item.id, this.item)
			.subscribe(
				() => {
					notify("Successfully Updated", "success", 5000);
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	updateToolEvent() {
		this.assetEventService.updateSingleById(this.popupAssetEvent.id, this.popupAssetEvent)
			.subscribe(
				() => {
					notify("Successfully Updated Event", "success", 5000);
					this.getAssetEvents(this.item.id);
					this.closePopup();
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}
}