<admin-header [primaryButtons]="headerPrimaryButtons" [tertiaryButtons]="headerTertiaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<div class="form-container">
	<dx-form class="m-3" [(formData)]="item" [showColonAfterLabel]="true" labelLocation="top" [readOnly]="readOnly">
		<dxi-item itemType="tabbed" [tabPanelOptions]="{hoverStateEnabled:false}">
			<dxo-tab-panel-options [deferRendering]="false"></dxo-tab-panel-options>
			<dxi-tab title="Details" [colCount]="6">
				<dxi-item dataField="name" [colSpan]="4"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
				<dxi-item dataField="accountingNumber" [colSpan]="2"></dxi-item>
				<dxi-item dataField="address1" [colSpan]="3"></dxi-item>
				<dxi-item dataField="address2" [colSpan]="3"></dxi-item>
				<dxi-item dataField="town" [colSpan]="3"></dxi-item>
				<dxi-item dataField="postcode" [colSpan]="3"></dxi-item>
				<dxi-item dataField="telephoneNumber" [colSpan]="3"></dxi-item>
				<dxi-item dataField="supplierCode" [colSpan]="3"></dxi-item>
				<dxi-item dataField="paymentTerms" [colSpan]="3" [label]="{text: 'Payment Terms (0 for Pro Forma)'}"></dxi-item>
				<dxi-item dataField="sendASNsViaEDI" [colSpan]="3" [label]="{text: 'Send ASNs via EDI'}"></dxi-item>
				<dxi-item dataField="ediImportDaysBeforeForecasts" [colSpan]="3" [label]="{text: 'EDI CSV Importing Days before Forecasts'}" editorType="dxNumberBox" [editorOptions]="{format: {type:'fixedPoint', precision: 0, min: 0}, showClearButton:true }"></dxi-item>				
				<dxi-item dataField="bankDetailsId" [colSpan]="3" editorType="dxSelectBox" [editorOptions]="{ items: bankDetails, searchEnabled: true, displayExpr:'bankName', valueExpr:'id' }" [label]="{ text: 'Bank Account for Invoices' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>	
				<dxi-item dataField="deliveryBuffer" [colSpan]="6" [label]="{text: 'Delivery Buffer (days) - leave empty to use default setting.'}" editorType="dxNumberBox" [editorOptions]="{format: {type:'fixedPoint', precision: 0, min: 0}, showClearButton:true }"></dxi-item><dxi-item dataField="applyVAT" editorType="dxCheckBox" [label]="{text: 'Apply VAT'}"></dxi-item>
				<dxi-item dataField="showOnEdi" editorType="dxCheckBox" [label]="{text: 'Show On EDI'}"></dxi-item>
				<dxi-item dataField="ediSelfBilling" editorType="dxCheckBox" [label]="{text: 'EDI Self Billing'}"></dxi-item>
			</dxi-tab>
			<dxi-tab title="Production Request Settings" [colCount]="2">
				<dxi-item dataField="productionRequestOrderDays" [label]="{text: 'EDI Order Days'}" editorType="dxNumberBox" [editorOptions]="{format: {type:'fixedPoint', precision: 0, min: 0} }"></dxi-item>
				<dxi-item dataField="productionRequestForecastDays" [label]="{text: 'EDI Forecast Days'}" editorType="dxNumberBox" [editorOptions]="{format: {type:'fixedPoint', precision: 0, min: 0} }"></dxi-item>
			</dxi-tab>
			<dxi-tab title="Contacts">
				<dx-data-grid class="data-grid" [dataSource]="contacts" [remoteOperations]="false" [allowColumnReordering]="true" [allowColumnResizing]="true" [rowAlternationEnabled]="true" [showBorders]="true">
					<dxo-paging [pageSize]="100"></dxo-paging>
					<dxo-state-storing [enabled]="true" type="localStorage" storageKey="clientContacts"></dxo-state-storing>
					<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
					<dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
					<dxo-group-panel [visible]="true"></dxo-group-panel>
					<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
					<dxo-export [enabled]="true"></dxo-export>
					<dxi-column type="buttons">
						<dxi-button hint="Edit" icon="fal fa-pencil" [onClick]="editContactPopup"></dxi-button>
						<dxi-button icon="fal fa-trash" [onClick]="deleteContact"></dxi-button>
					</dxi-column>
					<dxi-column dataField="id" dataType="number" alignment="left"></dxi-column>
					<dxi-column dataField="contactName" dataType="string" alignment="left"></dxi-column>
					<dxi-column dataField="contactEmailAddress" dataType="string" alignment="left"></dxi-column>
					<dxi-column dataField="jobTitle" dataType="string" alignment="left"></dxi-column>
				</dx-data-grid>
			</dxi-tab>
			<dxi-tab title="Documents">
				<dx-data-grid class="data-grid" id="documentGridContainer" [dataSource]="documents" [remoteOperations]="false" [allowColumnReordering]="true" [allowColumnResizing]="true" [rowAlternationEnabled]="true" [showBorders]="true">
					<dxo-paging [pageSize]="100"></dxo-paging>
					<dxo-state-storing [enabled]="true" type="localStorage" storageKey="clientDocuments"></dxo-state-storing>
					<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
					<dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
					<dxo-group-panel [visible]="true"></dxo-group-panel>
					<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
					<dxo-export [enabled]="true"></dxo-export>
					<dxi-column type="buttons">
						<dxi-button hint="Download" icon="fal fa-download" [onClick]="downloadDocument"></dxi-button>
					</dxi-column>
					<dxi-column dataField="description" dataType="string" alignment="left"></dxi-column>
					<dxi-column dataField="fileName" dataType="string" alignment="left"></dxi-column>
					<dxi-column dataField="createdAt" dataType="date" format="dd/MM/yyy" alignment="left"></dxi-column>
				</dx-data-grid>
			</dxi-tab>
			<dxi-tab title="Locations">
				<dx-data-grid class="data-grid" id="locationGridContainer" [dataSource]="locations" [remoteOperations]="false" [allowColumnReordering]="true" [allowColumnResizing]="true" [rowAlternationEnabled]="true" [showBorders]="true">
					<dxo-paging [pageSize]="100"></dxo-paging>
					<dxo-state-storing [enabled]="true" type="localStorage" storageKey="clientLocations"></dxo-state-storing>
					<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
					<dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
					<dxo-group-panel [visible]="true"></dxo-group-panel>
					<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
					<dxo-export [enabled]="true"></dxo-export>
					<dxi-column type="buttons">
						<dxi-button hint="Set Primary Delivery" icon="fal fa-truck" [onClick]="setPrimaryDelivery"></dxi-button>
						<dxi-button hint="Set Primary Billing" icon="fal fa-money-bill-wave" [onClick]="setPrimaryBilling"></dxi-button>
						<dxi-button hint="Edit" icon="fal fa-pencil" [onClick]="editLocationPopup"></dxi-button>
						<dxi-button hint="Delete (cannot delete if in use by EDI system)" icon="fal fa-trash" [onClick]="deleteLocation"></dxi-button>
					</dxi-column>
					<dxi-column dataField="id" dataType="number" alignment="left"></dxi-column>
					<dxi-column dataField="name" dataType="string" alignment="left"></dxi-column>
					<dxi-column dataField="ediReference" dataType="string" alignment="left"></dxi-column>
					<dxi-column dataField="postcode" dataType="string" alignment="left"></dxi-column>
					<dxi-column dataField="town" dataType="string" alignment="left"></dxi-column>
					<dxi-column [showEditorAlways]='false' dataField="isDelivery" dataType="string" alignment="left" [customizeText]="boolYesNo" caption="Delivery"></dxi-column>
					<dxi-column [showEditorAlways]='false' dataField="isBilling" dataType="string" alignment="left" [customizeText]="boolYesNo" caption="Billing"></dxi-column>
					<dxi-column [showEditorAlways]='false' dataField="isPrimaryDelivery" dataType="string" alignment="left" [customizeText]="boolYesNo" caption="Primary Delivery"></dxi-column>
					<dxi-column [showEditorAlways]='false' dataField="isPrimaryBilling" dataType="string" alignment="left" [customizeText]="boolYesNo" caption="Primary Billing"></dxi-column>
					<dxi-column [showEditorAlways]='false' dataField="ediStyle" caption="EDI Style" dataType="number" alignment="left"><dxo-lookup [dataSource]="ediStyles" valueExpr="id" displayExpr="name"></dxo-lookup></dxi-column>
				</dx-data-grid>
			</dxi-tab>
			<dxi-tab title="Specifications">
				<dx-data-grid class="data-grid" id="specificationGridContainer" [dataSource]="outsourcedSpecifications" [remoteOperations]="false" [allowColumnReordering]="true" [allowColumnResizing]="true" [rowAlternationEnabled]="true" [showBorders]="true">
					<dxo-paging [pageSize]="100"></dxo-paging>
					<dxo-state-storing [enabled]="true" type="localStorage" storageKey="clientSpecifications"></dxo-state-storing>
					<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
					<dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
					<dxo-group-panel [visible]="true"></dxo-group-panel>
					<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
					<dxo-export [enabled]="true"></dxo-export>
					<dxi-column type="buttons">
						<dxi-button hint="Edit" icon="fal fa-pencil" [onClick]="editSpecificationPopup"></dxi-button>
						<dxi-button hint="Delete (cannot delete if in use by a Part)" icon="fal fa-trash" [onClick]="deleteSpecification"></dxi-button>
					</dxi-column>
					<dxi-column dataField="id" dataType="number" alignment="left"></dxi-column>
					<dxi-column dataField="specification" dataType="string" alignment="left"></dxi-column>
				</dx-data-grid>
			</dxi-tab>
			<dxi-tab title="Interactions">
				<dx-data-grid class="data-grid" id="interactionsGrid" [dataSource]="interactions" [remoteOperations]="false" [allowColumnReordering]="true" [allowColumnResizing]="true" [rowAlternationEnabled]="true" [showBorders]="true">
					<dxo-paging [pageSize]="100"></dxo-paging>
					<dxo-state-storing [enabled]="true" type="localStorage" storageKey="clientInteractions"></dxo-state-storing>
					<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
					<dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
					<dxo-group-panel [visible]="true"></dxo-group-panel>
					<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
					<dxo-export [enabled]="true"></dxo-export>
					<dxi-column dataField="createdAt" dataType="date" format="dd/MM/yyyy HH:mm:ss" alignment="left" caption="When?"></dxi-column>
					<dxi-column [showEditorAlways]='false' dataField="instigatorId" caption="Contacted By" dataType="string" alignment="left"><dxo-lookup [dataSource]="users" valueExpr="id" displayExpr="email"></dxo-lookup></dxi-column>
					<dxi-column [showEditorAlways]='false' dataField="clientContactId" caption="Contacted" dataType="string" alignment="left"><dxo-lookup [dataSource]="contacts" valueExpr="id" displayExpr="contactName"></dxo-lookup></dxi-column>
					<dxi-column dataField="interactionSummary" dataType="string" alignment="left"></dxi-column>
					

				</dx-data-grid>
			</dxi-tab>
		</dxi-item>
	</dx-form>
</div>
<dx-popup [width]="'75%'" [height]="600" [showTitle]="true" [title]="popupTitle" [dragEnabled]="false" [closeOnOutsideClick]="false" [showCloseButton]="false" container=".dx-viewport" [(visible)]="popupVisible">
	<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="before" [options]="popupAddButtonOptions"></dxi-toolbar-item>
	<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="after" [options]="popupCloseButtonOptions"></dxi-toolbar-item>
		<dx-form *ngIf="popupType == 'Contact'" class="m-3" [(formData)]="popupContact" [showColonAfterLabel]="true" labelLocation="top" [colCount]="2">
			<dxi-item dataField="contactName"><dxi-validation-rule type="required" message="Contact Name is required"></dxi-validation-rule>					</dxi-item>
			<dxi-item dataField="contactEmailAddress"><dxi-validation-rule type="required" message="Contact Email is required"></dxi-validation-rule></dxi-item>
			<dxi-item dataField="jobTitle"></dxi-item>
		</dx-form>
		<dx-form *ngIf="popupType == 'Specification'" class="m-3" [(formData)]="popupSpecification" [showColonAfterLabel]="true" labelLocation="top" [colCount]="2">
			<dxi-item dataField="specification"><dxi-validation-rule type="required" message="Specification Name is required"></dxi-validation-rule>					</dxi-item>
		</dx-form>
		<dx-form *ngIf="popupType == 'Interaction'" class="m-3" [(formData)]="popupInteraction" [showColonAfterLabel]="true" labelLocation="top" [colCount]="2">
			<dxi-item dataField="interactionType" editorType="dxSelectBox" [editorOptions]="{ items: interactionTypes, searchEnabled: true, displayExpr:'name', valueExpr:'id' }" [label]="{ text: 'Interaction Type' }"><dxi-validation-rule type="required" message="Interaction Type is required"></dxi-validation-rule></dxi-item>
			<dxi-item dataField="clientContactId" editorType="dxSelectBox" [editorOptions]="{ items: contacts, searchEnabled: true, displayExpr:'contactName', valueExpr:'id' }" [label]="{ text: 'Contact' }"><dxi-validation-rule type="required" message="Contact is required"></dxi-validation-rule></dxi-item>
			<dxi-item dataField="interactionSummary" editorType="dxTextArea" colSpan="2"><dxi-validation-rule type="required" message="Interaction Summary is required"></dxi-validation-rule>					</dxi-item>
		</dx-form>
		<dx-form *ngIf="popupType == 'Location'" class="m-3" [(formData)]="popupLocation" [showColonAfterLabel]="true" labelLocation="top" [colCount]="2">
			<dxi-item dataField="name"><dxi-validation-rule type="required" message="Name is required"></dxi-validation-rule></dxi-item>
			<dxi-item dataField="ediReference" [label]="{ text: 'EDI Reference' }"><dxi-validation-rule type="required" message="EDI Reference is required"></dxi-validation-rule></dxi-item>
			<dxi-item dataField="colour" editorType="dxColorBox" [label]="{ text: 'Colour (HTML #rrggbb)' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
			<dxi-item dataField="addressLine1"></dxi-item>
			<dxi-item dataField="addressLine2"></dxi-item>
			<dxi-item dataField="town"></dxi-item>
			<dxi-item dataField="county"></dxi-item>
			<dxi-item dataField="postcode"></dxi-item>
			<dxi-item dataField="country"></dxi-item>
			<dxi-item dataField="isDelivery" editorType="dxCheckBox"></dxi-item>
			<dxi-item dataField="isBilling" editorType="dxCheckBox"></dxi-item>
			<dxi-item dataField="ediStyle" editorType="dxSelectBox" [editorOptions]="{ items: ediStyles, searchEnabled: true, displayExpr:'name', valueExpr:'id' }" [label]="{ text: 'EDI Style' }"><dxi-validation-rule type="required" message="EDI Style is required"></dxi-validation-rule></dxi-item>
		</dx-form>
</dx-popup>
<dx-popup [width]="'75%'" [height]="600" [showTitle]="true" [title]="addDocumentPopupTitle" [dragEnabled]="false" [closeOnOutsideClick]="false" [showCloseButton]="false" container=".dx-viewport" [(visible)]="addDocumentPopupVisible">
	<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="before" [options]="addDocumentPopupAddButtonOptions"></dxi-toolbar-item>
	<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="after" [options]="addDocumentPopupCloseButtonOptions"></dxi-toolbar-item>
	<div class="col-6">
		<dx-file-uploader [disabled]="!uploadEnabled" class="mb-3 mx-2" [chunkSize]="200000" [maxFileSize]="10485760" [uploadChunk]="uploadChunk"></dx-file-uploader>
	</div>
	<div class="col-6">
		<input class="form-control shadow-none" type="text" placeholder="Enter Description" name="description" [(ngModel)]="newClientDocument.description">
	</div>
</dx-popup>