<admin-header [primaryButtons]="headerPrimaryButtons" [secondaryButtons]="headerSecondaryButtons" [tertiaryButtons]="headerTertiaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<div class="form-container">
	<dx-form class="m-3" [(formData)]="item" [showColonAfterLabel]="true" labelLocation="top" [readOnly]="readOnly" (onFieldDataChanged)="formDataChanged($event)">
		<dxi-item itemType="group" caption="Details" [colCount]="2">
			<dxi-item dataField="clientId" editorType="dxSelectBox" [editorOptions]="{ items: clients, searchEnabled: true, displayExpr:'name', valueExpr:'id' }" [label]="{ text: 'Client' }"></dxi-item>	
			<dxi-item dataField="clientLocationId" editorType="dxSelectBox" [editorOptions]="{ items: clientLocations, searchEnabled: true, displayExpr:'name', valueExpr:'id' }" [label]="{ text: 'Location' }"></dxi-item>	
		</dxi-item>
		<dxi-item itemType="group" caption="Line Items">
			<dx-data-grid #gridContainer class="data-grid" [dataSource]="lineItems" [remoteOperations]="false" [allowColumnReordering]="false" [rowAlternationEnabled]="true" [showBorders]="true">
				<dxo-paging [pageSize]="100"></dxo-paging>
				<dxo-state-storing [enabled]="true" type="localStorage" storageKey="deliveryLineItems"></dxo-state-storing>
				<dxo-pager [visible]="false" [showPageSizeSelector]="false" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
				<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
				<dxo-group-panel [visible]="false"></dxo-group-panel>
				<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
				<dxo-export [enabled]="false"></dxo-export>
				<dxi-column dataField="partNumber" dataType="text" alignment="left"></dxi-column>
				<dxi-column dataField="partDescription" dataType="text" alignment="right" caption="Description"></dxi-column>
				<dxi-column dataField="partRevisionNumber" dataType="number" alignment="right" caption="Revision"></dxi-column>
				<dxi-column dataField="poNumber" dataType="text" alignment="left" caption="PO Number"></dxi-column>
				<dxi-column dataField="quantity" dataType="number" alignment="right" caption="Quantity"></dxi-column>
				<dxi-column type="buttons">
					<dxi-button hint="View Allocation" icon="fal fa-eye" [onClick]="viewAllocation" [visible]="lineItemHasAllocation"></dxi-button>
					<dxi-button hint="Create Allocation" icon="fal fa-plus" [onClick]="createAllocationForDeliveryNoteLineItemPopup" [visible]="lineItemHasNoAllocation"></dxi-button>
				</dxi-column>
			</dx-data-grid>
		</dxi-item>
	</dx-form>
	<dx-popup [width]="'75%'" [height]="600" [showTitle]="true" [title]="popupTitle" [dragEnabled]="false" [closeOnOutsideClick]="false" [showCloseButton]="false" container=".dx-viewport" [(visible)]="popupVisible">
		<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="before" [options]="popupSaveButtonOptions"></dxi-toolbar-item>
		<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="after" [options]="popupCloseButtonOptions"></dxi-toolbar-item>
		<div *dxTemplate="let data of 'content'">
			<div *ngIf="popupTitle == 'Create Allocation for Line Item'">
				<p>Are you sure you wish to create this allocation? This cannot be undone.</p>
			</div>
		</div>
	</dx-popup>
</div>