import { Component, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ProductionStatus } from "@enums/productionStatus.enum";
import { GetProductionStatusColor } from "@helpers/getProductionStatusColor";
import { GetPropertiesFromEnum } from "@helpers/getPropertiesFromEnum";
import { UserProfile } from "@interfaces/userProfile.interface";
import { WorkOrder } from "@interfaces/workOrder.interface";
import { MachineService } from "@services/machine.service";
import { WorkOrderService } from "@services/workOrder.service";
import notify from "devextreme/ui/notify";

@Component({
	encapsulation: ViewEncapsulation.None,
	moduleId: module.id,
	selector: "machineOverview",
	styleUrls: ["machineOverview.css"],
	templateUrl: "machineOverview.html"
})
export class MachineOverviewComponent {
	breakdownCount = 0;
	isLoading = false;
	lastRefresh = "";
	machines: any[] = [];
	machinesNoJob: any[] = [];
	machinesRunning: any[] = [];
	noOperator = 0;
	prodAct = 0;
	prodAmb = 0;
	prodRed = 0;
	showPopup = false;
	siteId = 0;
	statusLookup: any[] = [];
	superRequiredCount = 0;
	workOrder: WorkOrder = new WorkOrder();


	constructor(private route: ActivatedRoute, private woService: WorkOrderService, private machineService: MachineService) {
		this.statusLookup = GetPropertiesFromEnum(ProductionStatus);
		this.route.params
			.subscribe((params) => {
				this.siteId = params.id;
				this.isLoading = true;
				this.showPopup = true;
				this.getWo();
				setTimeout(() => {
					this.isLoading = false;
					this.showPopup = false;
				}, 2000);
			});

		setInterval(() => {
			this.getWo();
		}, 5000);
	}

	formatDate(dateComplete: Date) {
		const date = new Date(dateComplete);
		const dateFormat = `${date.getDay()}/${date.getMonth()}/${date.getFullYear()}`;
		return dateFormat;
	}

	formatUser(user: UserProfile) {
		const firstInitial = user.firstName.substring(0, 1);
		const secondInitial = user.lastName.substring(0, 1);
		const initials = firstInitial + secondInitial;
		console.log(initials);
		return initials;
	}

	getWo() {
		this.machineService.app_OpsPlannerForSite(this.siteId)
			.subscribe(
				(res: any) => {
					this.machines = res.response;
					this.machinesRunning = this.machines.filter(x => x.runningStages.length > 0);
					this.machinesNoJob = this.machines.filter(x => x.runningStages.length == 0);
					// Supervisor required
					const tempSuperRequiredCount = this.superRequiredCount;
					this.superRequiredCount = this.machines.filter(x => x.productionStatus == ProductionStatus.AssistanceRequired).length;
					if (this.superRequiredCount > tempSuperRequiredCount) {
						this.playSupervisorAudio();
					}
					// Breakdown
					const tempBreakdownCount = this.breakdownCount;
					this.breakdownCount = this.machines.filter(x => x.productionStatus == ProductionStatus.Breakdown).length;
					if (this.breakdownCount > tempBreakdownCount) {
						this.playBreakdownAudio();
					}
					// Active No Operator
					const tempNoOperator = this.noOperator;
					this.noOperator = this.machines.filter(x => x.productionStatus == ProductionStatus.Active && x.assignedUserProfileId == null).length;
					if (this.noOperator > tempNoOperator) {
						// this.playNoOperator();
					}
					this.lastRefresh = new Date().toLocaleString();
					this.renderStatusOverview();
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	playBreakdownAudio() {
		const audio = new Audio();
		audio.src = "../../../../../assets/machineBreakdown.mp3";
		audio.load();
		audio.autoplay = true;
		audio.play();
	}

	playNoOperator() {
		const audio = new Audio();
		audio.src = "../../../../../assets/NoOperator.mp3";
		audio.load();
		audio.autoplay = true;
		audio.play();
	}

	playSupervisorAudio() {
		const audio = new Audio();
		audio.src = "../../../../../assets/supervisorRequired.mp3";
		audio.load();
		audio.autoplay = true;
		audio.play();
	}
	
	renderStatusColor(status: ProductionStatus) {
		return GetProductionStatusColor(status);
	}

	renderStatusName(status: ProductionStatus) {
		if (status == 100) {
			return "SUPERVISOR";
		}
		return this.statusLookup.filter(x => x.id == status)[0].name.toUpperCase();
	}

	renderStatusOverview() {
		this.prodAct = 0;
		this.prodAmb = 0;
		this.prodRed = 0;
		this.machines.forEach(machine => {
			if ((machine.productionStatus > 11 && machine.productionStatus < 41) || machine.productionStatus == 120) {
				this.prodRed = this.prodRed + 1;
			}
			if (machine.productionStatus == 10) {
				this.prodAct = this.prodAct + 1;
			}

			if (machine.productionStatus > 39 && machine.productionStatus < 101) {
				this.prodAmb = this.prodAmb + 1;
			}

		});
	}
}