import { Injectable, Injector } from "@angular/core";
import { environment } from "@environments/environment";
import { Service } from "@services/service";

@Injectable()
export class InterfaceDeviceService extends Service {
	constructor(injector: Injector) {
		super("InterfaceDevice", injector);
	}

	app_InterfaceById(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/App_InterfaceById/${id}`);
	}
}