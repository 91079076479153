import { Component, Input } from "@angular/core";

@Component({
	moduleId: module.id,
	selector: "allocationGridDetails",
	styleUrls: ["allocationGridDetails.css"],
	templateUrl: "allocationGridDetails.html"
})
export class AllocationGridDetailsComponent {
	@Input() allocationWorkOrders: any = [];  
}