<admin-header [primaryButtons]="headerPrimaryButtons" [secondaryButtons]="headerSecondaryButtons" [tertiaryButtons]="headerTertiaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<div class="form-container">
	<dx-form class="m-3" [(formData)]="item" [showColonAfterLabel]="true" labelLocation="top" [readOnly]="true">
		<dxi-item itemType="tabbed" [tabPanelOptions]="{hoverStateEnabled:false}">
			<dxo-tab-panel-options [deferRendering]="false"></dxo-tab-panel-options>
			<dxi-tab title="Details" [colCount]="2">
				<dxi-item dataField="id"></dxi-item>
				<dxi-item dataField="clientId" editorType="dxSelectBox" [editorOptions]="{ items: clients, searchEnabled: true, displayExpr:'name', valueExpr:'id' }" [label]="{ text: 'Client' }"></dxi-item>	
				<dxi-item dataField="deliveryNoteId"></dxi-item>
				<dxi-item dataField="clientLocationId" editorType="dxSelectBox" [editorOptions]="{ items: clientLocations, searchEnabled: true, displayExpr: 'name', valueExpr:'id' }" [label]="{ text: 'Location' }"></dxi-item>	
				<dxi-item dataField="totalAssigned"></dxi-item>
				<dxi-item dataField="partId" editorType="dxSelectBox" [editorOptions]="{ items: parts, searchEnabled: true, displayExpr:'partNumber', valueExpr:'id' }" [label]="{ text: 'Part' }"></dxi-item>	
				<dxi-item dataField="lineItemQuantity" [label]="{ text: 'Total Needed' }"></dxi-item>
				<dxi-item dataField="reportedById" editorType="dxSelectBox" [editorOptions]="{ items: people, searchEnabled: true, displayExpr:'fullName', valueExpr:'id' }" [label]="{ text: 'Created By' }"></dxi-item>	
			</dxi-tab>
			<dxi-tab title="Allocated Work Orders">
				<dx-data-grid class="data-grid" [dataSource]="allocationWorkOrders" [remoteOperations]="false" [allowColumnReordering]="true" [rowAlternationEnabled]="true" [showBorders]="true">
					<dxo-paging [pageSize]="100"></dxo-paging>
					<dxo-state-storing [enabled]="true" type="localStorage" storageKey="allocationWorkOrders"></dxo-state-storing>
					<dxo-pager [visible]="false" [showPageSizeSelector]="false" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
					<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
					<dxo-group-panel [visible]="false"></dxo-group-panel>
					<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
					<dxo-export [enabled]="false"></dxo-export>
					<dxi-column dataField="workOrderId" dataType="number" alignment="left" caption="Work Order"></dxi-column>
					<dxi-column dataField="quantity" dataType="number" alignment="left"></dxi-column>
					<dxi-column dataField="reportedBy.fullName" dataType="text" alignment="left" caption="Allocated By"></dxi-column>
					<dxi-column type="buttons">
						<dxi-button hint="View Work Order" icon="fal fa-eye" [onClick]="viewWorkOrder"></dxi-button>
					</dxi-column>
				</dx-data-grid>
			</dxi-tab>
		</dxi-item>
	</dx-form>
	<dx-popup [width]="'75%'" [height]="600" [showTitle]="true" [title]="popupTitle" [dragEnabled]="false" [closeOnOutsideClick]="false" [showCloseButton]="false" container=".dx-viewport" [(visible)]="popupVisible">
		<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="before" [options]="popupSaveButtonOptions"></dxi-toolbar-item>
		<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="after" [options]="popupCloseButtonOptions"></dxi-toolbar-item>
		<div *dxTemplate="let data of 'content'">
			<div *ngIf="popupTitle == 'Allocate from Work Order'">
				<dx-form class="m-3" [(formData)]="popupCreateAllocationWorkOrder" [showColonAfterLabel]="true" labelLocation="top" [colCount]="2">
					<dxi-item dataField="workOrderId" [colSpan]="2" [label]="{ text: 'Work Order Id / Lookup' }">
						<dxi-validation-rule type="required" message="Work Order is required"></dxi-validation-rule>
					</dxi-item>
					<dxi-item dataField="quantity">
						<dxi-validation-rule type="required" message="Quantity is required"></dxi-validation-rule>
					</dxi-item>
				</dx-form>
			</div>
		</div>
	</dx-popup>
</div>