import { Model } from "@interfaces/model.interface";
import { UserProfile } from "./userProfile.interface";

export interface WorkloadLog extends Model {
	assignee: UserProfile;
	assigneeId?: number;
	createdBy: UserProfile;
	createdById?: number;
	taskDescription: string;
}

export class WorkloadLog implements WorkloadLog {
	constructor(data?: Partial<WorkloadLog>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}