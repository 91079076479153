import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Department } from "@enums/department";
import { ProductionStatus } from "@enums/productionStatus.enum";
import { Shift } from "@enums/shift.enum";
import { environment } from "@environments/environment";
import { GetProductionStatusColor } from "@helpers/getProductionStatusColor";
import { GetPropertiesFromEnum } from "@helpers/getPropertiesFromEnum";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { ChangeWorkOrderStageMachine } from "@interfaces/changeWorkOrderStageMachine.interface";
import { ChangeWorkOrderStageOperator } from "@interfaces/changeWorkOrderStageOperator.interface";
import { ChangeWorkOrderStagePriority } from "@interfaces/changeWorkOrderStagePriority.interface";
import { Site } from "@interfaces/site.interface";
import { UserProfile } from "@interfaces/userProfile.interface";
import { MachineService } from "@services/machine.service";
import { SiteService } from "@services/site.service";
import { UserProfileService } from "@services/userProfile.service";
import { WorkOrderStageService } from "@services/workOrderStage.service";
import notify from "devextreme/ui/notify";

@Component({
	moduleId: module.id,
	selector: "departments-id",
	styleUrls: ["departments-id.css"],
	templateUrl: "departments-id.html"
})
export class OpsPlanningDepartmentsIdAdminComponent {
	department: Department = Department.Assembly;
	departmentId = 0;
	departments: any;
	headerPrimaryButtons: AdminHeaderButton[] = [];
	headerSecondaryButtons: AdminHeaderButton[] = [];
	headerTertiaryButtons: AdminHeaderButton[] = [];
	machines: any[] = [];
	machinesShowingAllAvailable: number[] = [];
	machinesShowingAllPending: number[] = [];
	machinesShowingAllRunning: number[] = [];
	popupAddButtonOptions: any;
	popupChangeMachineProductionStatus: { machineId: number, productionStatus: ProductionStatus } = { machineId: 0, productionStatus: ProductionStatus.Active };
	popupChangeWorkOrderStageMachine: ChangeWorkOrderStageMachine = new ChangeWorkOrderStageMachine();
	popupChangeWorkOrderStageOperator: ChangeWorkOrderStageOperator = new ChangeWorkOrderStageOperator();
	popupCloseButtonOptions: any = { onClick: () => this.closePopup(), text: "Close" };
	popupTitle = "Change Machine";
	popupVisible = false;
	priorities: number[];
	productionStatuses: any;
	shifts: any;
	sites: Site[] = [];
	title = "Ops Planning";
	userProfiles: UserProfile[] = [];

	constructor(private router: Router, private route: ActivatedRoute, private machineService: MachineService, private siteService: SiteService, private userProfileService: UserProfileService, private workOrderStageService: WorkOrderStageService) {
		this.departments = GetPropertiesFromEnum(Department);
		this.productionStatuses = GetPropertiesFromEnum(ProductionStatus);
		this.shifts = GetPropertiesFromEnum(Shift);
		this.route.params
			.subscribe((params) => {
				this.department = params.department;
				this.departmentId = params.department;
				this.getMachines();
				this.getSites();
				this.getUserProfiles();
			});
		this.changeWorkOrderStageMachinePopup = this.changeWorkOrderStageMachinePopup.bind(this);

		this.priorities = [];
		for (let i = 1; i <= environment.build.lowestWorkOrderStagePriority; i++) {
			this.priorities.push(i);
		}
	}

	buildButtons() {
		this.headerTertiaryButtons = [
			{ method: "goToEdiOrders", text: "Firewall" },
			{ method: "goToWorkforce", text: "Workforce" }
		];

		this.headerSecondaryButtons = [];

		const filteredDepartments = this.departments.filter((d: { id: number; name: string; }) => (d.id > 0 && d.id < 55) || d.id == 70);
		filteredDepartments.forEach((fd: any) => {
			this.headerSecondaryButtons.push({ method: "goToDepartment-" + fd.id, text: fd.name });
		});

		this.headerPrimaryButtons = [];

		this.sites.forEach((site: any) => {
			this.headerPrimaryButtons.push({ method: "goToSite-" + site.id, text: site.name });
		});

		const selectedDepartment = this.departments.filter((d: { id: number; name: string; }) => d.id == this.departmentId);
		this.title = selectedDepartment[0].name;
	}

	changeMachineProductionStatus() {
		this.machineService.updateProductionStatusSupervisor(this.popupChangeMachineProductionStatus.machineId, this.popupChangeMachineProductionStatus.productionStatus)
			.subscribe(
				() => {
					notify("Machine Production Status Updated", "success", 5000);
					this.getMachines();
					this.closePopup();
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				},
			);
	}

	changeMachineProductionStatusPopup(machineId: number) {
		const selectedMachine = this.machines.filter((m: any) => m.machineId === machineId);
		this.popupChangeMachineProductionStatus.machineId = machineId;
		this.popupChangeMachineProductionStatus.productionStatus = selectedMachine[0].productionStatus;
		this.popupAddButtonOptions = { onClick: () => this.changeMachineProductionStatus(), text: "Change Production Status" };
		this.popupTitle = "Change Production Status";
		this.popupVisible = true;
		return false;
	}

	changeWorkOrderStageMachine() {
		this.workOrderStageService.changeMachine(this.popupChangeWorkOrderStageMachine)
			.subscribe(
				() => {
					notify("Work Order Stage Machine Updated", "success", 5000);
					this.getMachines();
					this.closePopup();
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				},
			);
	}

	changeWorkOrderStageMachinePopup(stageId: number) {
		this.popupChangeWorkOrderStageMachine = new ChangeWorkOrderStageMachine();
		this.popupChangeWorkOrderStageMachine.workOrderStageId = stageId;
		this.popupAddButtonOptions = { onClick: () => this.changeWorkOrderStageMachine(), text: "Change Machine" };
		this.popupTitle = "Change Machine";
		this.popupVisible = true;
		return false;
	}

	changeWorkOrderStageOperator() {
		this.workOrderStageService.changeOperator(this.popupChangeWorkOrderStageOperator)
			.subscribe(
				() => {
					notify("Work Order Stage Operator Updated", "success", 5000);
					this.getMachines();
					this.closePopup();
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				},
			);
	}

	changeWorkOrderStageOperatorPopup(stageId: number) {
		this.popupChangeWorkOrderStageOperator = new ChangeWorkOrderStageOperator();
		this.popupChangeWorkOrderStageOperator.workOrderStageId = stageId;
		this.popupAddButtonOptions = { onClick: () => this.changeWorkOrderStageOperator(), text: "Change Operator" };
		this.popupTitle = "Change Operator";
		this.popupVisible = true;
		return false;
	}	

	changeWorkOrderStagePriority(stageId: number, priority: number) {
		const data = new ChangeWorkOrderStagePriority();
		data.priority = priority;
		data.workOrderStageId = stageId;
		this.workOrderStageService.changePriority(data)
			.subscribe(
				() => {
					notify("Priority Changed - Refresh page to update details and order.", "success", 5000);
					// this.getMachines();
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				},
			);
		return false;
	}

	changeWorkOrderStagePriorityDropDown(args: any, stageId: number) {
		const newPriority = args.value;

		this.changeWorkOrderStagePriority(stageId, newPriority);
	}

	closePopup() {
		this.popupVisible = false;
	}

	displayMachineInDropdown(e: any) { return e.cellNumber + " - " + e.machineName; }

	getMachines() {
		this.machineService.opsPlanner(this.department)
			.subscribe(
				(res: any) => this.machines = res.response,
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	getSites() {
		this.siteService.getAll()
			.subscribe(
				(res: any) => {
					this.sites = res.response.items;
					this.buildButtons();
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	getUserProfiles() {
		this.userProfileService.byCurrentDepartment(this.department)
			.subscribe(
				(res: any) => this.userProfiles = res.response,
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	goToDepartment(department: number) {
		this.router.navigate([`/admin/opsPlanning/department/${department}`]);
	}

	goToEdiOrders() {
		this.router.navigate(["/admin/opsPlanning/ediOrders"]);
	}

	goToSite(site: number) {
		this.router.navigate([`/admin/opsPlanning/site/${site}`]);
	}

	goToWorkforce() {
		this.router.navigate(["/admin/opsPlanning/workforce"]);
	}

	headerButtonClick(method: string) {
		if (method.indexOf("-") > 0) {
			const split = method.split("-");
			// @ts-ignore // Required to be able to call the method directly from the variable
			this[split[0]](split[1]);
		}
		else {
			// @ts-ignore // Required to be able to call the method directly from the variable
			if (this[method]) this[method]();
		}
	}

	renderDateString(date: Date)
	{
		const dateToChange = new Date(date);
		return dateToChange.toLocaleDateString("en-GB");
	}

	renderStatusColor(status: ProductionStatus) {
		return GetProductionStatusColor(status);
	}

	toggleMachineShowingAllAvailable(machineId: number) {
		const currentIndex = this.machinesShowingAllAvailable.indexOf(machineId);
		if (currentIndex >= 0) {
			this.machinesShowingAllAvailable.splice(currentIndex);
		} else {
			this.machinesShowingAllAvailable.push(machineId);
		}
		return false;
	}

	toggleMachineShowingAllPending(machineId: number) {
		const currentIndex = this.machinesShowingAllPending.indexOf(machineId);
		if (currentIndex >= 0) {
			this.machinesShowingAllPending.splice(currentIndex);
		} else {
			this.machinesShowingAllPending.push(machineId);
		}
		return false;
	}

	toggleMachineShowingAllRunning(machineId: number) {
		const currentIndex = this.machinesShowingAllRunning.indexOf(machineId);
		if (currentIndex >= 0) {
			this.machinesShowingAllRunning.splice(currentIndex);
		} else {
			this.machinesShowingAllRunning.push(machineId);
		}
		return false;
	}
}