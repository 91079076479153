import { Component, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Currency } from "@enums/currency.enum";
import { Incoterms } from "@enums/incoterms.enum";
import { PackagingTerms } from "@enums/packagingTerms.enum";
import { PurchaseType } from "@enums/purchaseType.enum";
import { ToolingFixtureDescription } from "@enums/toolingFixtureDescription.enum";
import { environment } from "@environments/environment";
import { GetPropertiesFromEnum } from "@helpers/getPropertiesFromEnum";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { BoughtOutPartsLineData } from "@interfaces/boughtOutPartsLineData.interface";
import { Client } from "@interfaces/client.interface";
import { ClientContact } from "@interfaces/clientContact.interface";
import { ClientLocation } from "@interfaces/clientLocation.interface";
import { Machine } from "@interfaces/machine.interface";
import { OutsourcedProcessLineData } from "@interfaces/outsourcedProcessLineData.interface";
import { OutsourcedSpecification } from "@interfaces/outsourcedSpecification.interface";
import { Packaging } from "@interfaces/packaging.interface";
import { PressProcessLineData } from "@interfaces/pressProcessLineData.interface";
import { Process } from "@interfaces/process.interface";
import { Project } from "@interfaces/project.interface";
import { PurchasableItem } from "@interfaces/purchasableItem.interface";
import { QuotationAnalysisForm } from "@interfaces/quotationAnalysisForm.interface";
import { RawMaterialLineData } from "@interfaces/rawMaterialLineData.interface";
import { Supplier } from "@interfaces/supplier.interface";
import { SupportingDocumentFileLineData } from "@interfaces/supportingDocumentFileLineData.interface";
import { ToolingFixtureLineData } from "@interfaces/toolingFixtureLineData.interface";
import { UserProfile } from "@interfaces/userProfile.interface";
import { WeldAndAssemblyLineData } from "@interfaces/weldAndAssemblyLineData.interface";
import { ClientService } from "@services/client.service";
import { ClientContactService } from "@services/clientContact.service";
import { ClientLocationService } from "@services/clientLocation.service";
import { FileService } from "@services/file.service";
import { MachineService } from "@services/machine.service";
import { OutsourcedSpecificationService } from "@services/outsourcedSpecification.service";
import { PackagingService } from "@services/packaging.service";
import { ProcessService } from "@services/processService";
import { ProjectService } from "@services/project.service";
import { PurchasableItemService } from "@services/purchasableItem.service";
import { QuotationAnalysisFormService } from "@services/quotationAnalysisForm.service";
import { SupplierService } from "@services/supplier.service";
import { UserProfileService } from "@services/userProfile.service";
import { DxDataGridComponent } from "devextreme-angular";
import notify from "devextreme/ui/notify";
import { Guid } from "guid-typescript";

@Component({
	moduleId: module.id,
	selector: "quotationAnalysisForms-id",
	styleUrls: ["quotationAnalysisForms-id.css"],
	templateUrl: "quotationAnalysisForms-id.html"
})
export class QuotationAnalysisFormsIdAdminComponent {
	@ViewChild("boughtOutMaterialsGridContainer", { static: false }) boughtOutMaterialsGrid!: DxDataGridComponent;
	@ViewChild("pressProcessesGridContainer", { static: false }) pressProcessesGrid!: DxDataGridComponent;
	@ViewChild("rawMaterialsGridContainer", { static: false }) rawMaterialsGrid!: DxDataGridComponent;
	@ViewChild("toolingFixturesGridContainer", { static: false }) toolingFixturesGrid!: DxDataGridComponent;
	@ViewChild("weldAndAssemblyGridContainer", { static: false }) weldAndAssemblyGrid!: DxDataGridComponent;
	boughtOutParts: BoughtOutPartsLineData[] = [];
	cadFileButtonOptions = {
		onClick: () => this.addNewCADFilePopup(),
		text: "Add CAD File",
		type: "default",
		useSubmitBehavior: false
	}
	cadFiles: SupportingDocumentFileLineData[] = [];
	changes: any = [];
	childQAFs: QuotationAnalysisForm[] = [];
	clientContacts: ClientContact[] = [];
	clientLocations: ClientLocation[] = [];
	clients: Client[] = [];
	currencies: any;
	currency = "GBP";
	editRowKey: any = null;
	headerPrimaryButtons: AdminHeaderButton[] = [];
	headerTertiaryButtons: AdminHeaderButton[] = [];
	imageStyle = "";
	incoterms: any;
	item: QuotationAnalysisForm = new QuotationAnalysisForm();
	itemType = "QAF";
	machines: Machine[] = [];
	materialPurchasableItems: PurchasableItem[] = [];
	mode = "";
	outsourcedProcesses: OutsourcedProcessLineData[] = [];
	outsourcedSpecifications: OutsourcedSpecification[] = [];
	packaging: Packaging[] = [];
	packagingTerms: any;
	parentQAFs: QuotationAnalysisForm[] = [];
	popupAddButtonOptions: any;
	popupCADFile: SupportingDocumentFileLineData = new SupportingDocumentFileLineData();
	popupCloseButtonOptions: any = { onClick: () => this.closePopup(), text: "Close" };
	popupImage: SupportingDocumentFileLineData = new SupportingDocumentFileLineData();
	popupSupportingDocument: SupportingDocumentFileLineData = new SupportingDocumentFileLineData();
	popupTitle = "";
	popupVisible = false;
	pressProcesses: PressProcessLineData[] = [];
	processes: Process[] = [];
	projects: Project[] = [];
	purchasableItems: PurchasableItem[] = [];
	purchaseTypes: any;
	rawMaterials: RawMaterialLineData[] = [];
	readOnly = true;
	returnUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	suppliers: Supplier[] = [];
	supportingDocumentButtonOptions = {
		onClick: () => this.addNewSupportingDocumentPopup(),
		text: "Add Document",
		type: "default",
		useSubmitBehavior: false
	}
	supportingDocuments: SupportingDocumentFileLineData[] = [];
	thicknesses: number[] = [0.2, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.5, 1.6, 1.8, 2, 2.5, 3, 3.5, 4, 5, 6, 7, 8, 10, 12, 15, 20, 25];
	title: string = "View " + this.itemType;
	toolingFixtureDescriptions: any;
	toolingFixtures: ToolingFixtureLineData[] = [];
	userProfiles: UserProfile[] = [];
	weldAndAssembly: WeldAndAssemblyLineData[] = [];

	constructor(private router:Router, private route: ActivatedRoute, private clientService: ClientService, private clientContactService: ClientContactService, private clientLocationService: ClientLocationService, private fileService: FileService, private machineService: MachineService, private outsourcedSpecificationService: OutsourcedSpecificationService, private packagingService: PackagingService, private processService: ProcessService, private projectService: ProjectService, private purchasableItemService: PurchasableItemService, private qafService: QuotationAnalysisFormService, private supplierService: SupplierService, private userProfileService: UserProfileService) {
		this.route.params
			.subscribe((params) => {
				if (params.id != "create") {
					if (params.id.split("_").length > 1) {
						this.getItem(params.id.split("_")[0], "edit");
					} else {
						this.getItem(params.id, "view");
					}
				} else {
					this.buildButtons("create");
				}
			});
			
		this.currencies = GetPropertiesFromEnum(Currency, true);
		this.incoterms = GetPropertiesFromEnum(Incoterms, true);
		this.packagingTerms = GetPropertiesFromEnum(PackagingTerms);
		this.purchaseTypes = GetPropertiesFromEnum(PurchaseType);
		this.toolingFixtureDescriptions = GetPropertiesFromEnum(ToolingFixtureDescription);

		this.getClients();
		this.getMachines();
		this.getPackaging();
		this.getParentQAFs();
		this.getProcesses();
		this.getPurchasableItems();
		this.getSuppliers();
		this.getUserProfiles();

		this.downloadFileLink = this.downloadFileLink.bind(this);
		this.removeChildQAF = this.removeChildQAF.bind(this);
		this.uploadCADFile = this.uploadCADFile.bind(this);
		this.uploadImage = this.uploadImage.bind(this);
		this.uploadSupportingDocument = this.uploadSupportingDocument.bind(this);
		this.viewChildQAF = this.viewChildQAF.bind(this);
	}

	addNewCADFile() {
		if (this.popupCADFile.description === "") {
			alert("Please enter a description");
			return;
		}
		this.cadFiles.push(this.popupCADFile);
		this.popupCADFile = new SupportingDocumentFileLineData();
		this.closePopup();
	}

	addNewCADFilePopup() {
		this.popupCADFile = new SupportingDocumentFileLineData();
		this.popupTitle = "Add New CAD File";
		this.popupVisible = true;
		this.popupAddButtonOptions = { onClick: () => this.addNewCADFile(), text: "Add CAD File" };
	}

	addNewSupportingDocument() {
		if (this.popupSupportingDocument.description === "") {
			alert("Please enter a description");
			return;
		}
		this.supportingDocuments.push(this.popupSupportingDocument);
		this.popupSupportingDocument = new SupportingDocumentFileLineData();
		this.closePopup();
	}

	addNewSupportingDocumentPopup() {
		this.popupSupportingDocument = new SupportingDocumentFileLineData();
		this.popupTitle = "Add New Supporting Document";
		this.popupVisible = true;
		this.popupAddButtonOptions = { onClick: () => this.addNewSupportingDocument(), text: "Add Document" };
	}

	approve() {
		if (confirm("Are you sure you wish to approve this QAF? This cannot be undone.") === false) {
			return;
		}
		
		this.qafService.approve(this.item.id)
			.subscribe(
				() => {
					notify("Successfully Approved " + this.itemType, "success", 5000);
					this.getItem(this.item.id, this.mode);
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	buildButtons(mode: string) {
		this.mode = mode;

		switch (mode) {
			case "create":
				this.title = "Create new " + this.itemType;
				this.readOnly = false;
				this.headerPrimaryButtons = [
					{ method: "createItem", text: "Save" },
					{ method: "close", text: "Close" }
				];
				break;
			case "edit":
				this.title = "Edit " + this.itemType + " #" + this.item.id;
				this.readOnly = false;
				this.headerPrimaryButtons = [
					{ method: "updateItem", text: "Save Changes" },
					{ method: "cancelEditing", text: "View Mode" },
					{ method: "close", text: "Close" }
				];
				break;
			case "view":
				this.title = "View " + this.itemType + " #" + this.item.id;
				this.readOnly = true;
				this.headerPrimaryButtons = [
					{ method: "edit", text: "Edit" },
					{ method: "close", text: "Close" }
				];
				if (this.item.approved === false && this.supportingDocuments.length >= 1) {					
					this.headerTertiaryButtons = [
						{ method: "approve", text: "Approve" }
					];
				}
				break;
		}
	}

	calculateBoughtOutMaterialsLineData() {
		let boughtOutPartsSubtotal = 0;
		let boughtOutPartsOverhead = 0;

		this.boughtOutParts.forEach((line) => {
			line.total = line.quantity * line.costPerPart;
			boughtOutPartsSubtotal += line.total;
			boughtOutPartsOverhead += line.overhead;
		});

		this.item.boughtOutPartsSubtotal = boughtOutPartsSubtotal;
		this.item.boughtOutPartsOverhead = boughtOutPartsOverhead;
	}

	calculateOutsourcedProcessesLineData() {
		let outsourcedProcessesSubtotal = 0;
		let outsourcedOverhead = 0;

		this.outsourcedProcesses.forEach((line) => {
			outsourcedProcessesSubtotal += line.pricePerPart;
			outsourcedOverhead += line.overhead;
		});

		this.item.outsourcedProcessesSubtotal = outsourcedProcessesSubtotal;
		this.item.outsourcedOverhead = outsourcedOverhead;
	}

	calculatePressProcessLineData() {
		this.item.pressProcessSubtotal = 0;

		this.pressProcesses.forEach((line) => {
			const process = this.processes.filter(x => x.id == line.processId)[0];	
			const machine = this.machines.filter(x => x.id == line.machineId)[0];	
			line.tonnage = machine.tonnage;
			if (line.hourlyRunningCostLabour === undefined || line.hourlyRunningCostLabour <= 0) {
				line.hourlyRunningCostLabour = process.hourlyLabourRate;
			}
			if (line.hourlyRunningCostMachine === undefined || line.hourlyRunningCostMachine <= 0) {
				line.hourlyRunningCostMachine = process.hourlyMachineRate;
			}
			line.hourlyRunningCostTotal = line.hourlyRunningCostLabour + line.hourlyRunningCostMachine;
			if (line.settingCost === undefined || line.settingCost <= 0) {
				line.settingCost = machine.setUpCost;
			}
			line.processCost = (line.hourlyRunningCostTotal / (line.partsPerHour * line.partsPerBlow)) + line.settingCost;
			this.item.pressProcessSubtotal += line.processCost;

			line.totalTime = line.setupTime + Math.ceil(line.processTime * this.item.batchSize / 60);
		});

		this.calculateProfit();
	}

	calculateProfit() {
		this.item.profit = this.item.pressProcessSubtotal === undefined ? 0 : (this.item.pressProcessSubtotal) * 0.08;
	}

	calculateRawMaterialLineData() {
		let rawMaterialSubtotal = 0;
		let materialOverhead = 0;

		this.rawMaterials.forEach((line) => {
			const weightConstant = this.materialPurchasableItems.filter(x => x.id == line.purchasableItemId)[0].weightConstant;	
			line.grossWeight = weightConstant * line.gauge * line.width * line.pitch;
			line.scrapWeight = line.grossWeight - line.netWeight;
			line.scrapValue = line.scrapWeight * line.scrapCostPerKg;
			line.grossMaterialCost = line.rawMaterialPricePerKg * line.grossWeight;
			line.netMaterialCost = line.grossMaterialCost; // - line.scrapValue;
			rawMaterialSubtotal += line.netMaterialCost;
			materialOverhead += line.overhead;
		});

		this.item.rawMaterialSubtotal = rawMaterialSubtotal;
		this.item.materialOverhead = materialOverhead;
	}

	calculateToolingFixturesLineData() {
		let toolingFixtureSubtotal = 0;
		let toolingFixtureTotalChanges = 0;
		let toolingOverhead = 0;

		this.toolingFixtures.forEach((line) => {
			if (line.total !== undefined && line.total > 0) {
				toolingFixtureSubtotal += line.total;
			}
			if (line.change !== undefined && line.change > 0) {
				toolingFixtureTotalChanges += line.change;
			}
			toolingOverhead += line.overhead;
		});

		this.item.toolingFixtureSubtotal = toolingFixtureSubtotal;
		this.item.toolingFixtureTotalChanges = toolingFixtureTotalChanges;
		this.item.toolingOverhead = toolingOverhead;
	}

	calculateWeldAndAssemblyLineData() {
		this.item.weldAndAssemblySubtotal = 0;

		this.weldAndAssembly.forEach((line) => {
			line.hourlyRunningCostTotal = line.hourlyRunningCostLabour + line.hourlyRunningCostMachine;
			line.processCost = line.hourlyRunningCostTotal / line.partsPerHour;
			this.item.weldAndAssemblySubtotal += line.processCost;
		});

		this.calculateProfit();
	}

	cancelEditing() {
		if (confirm("Are you sure you wish to switch modes? Unsaved changes will be discarded.") == false) {
			return;
		}
		this.router.navigate([`/${this.returnUrl}/${this.item.id}`])
			.then(() => {
				window.location.reload();
			});
	}

	close() {
		this.router.navigate([`/${this.returnUrl}/`]);
	}

	closePopup() {
		this.popupVisible = false;
	}

	createItem() {
		this.prepareItemForSaving();
		this.qafService.createSingle(this.item)
			.subscribe(
				() => {
					notify("Successfully Updated " + this.itemType, "success", 5000);
					this.item = new QuotationAnalysisForm();
					this.close();
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	deleteCADFileLink(e: any) {
		const index = this.cadFiles.findIndex(d => d.lookupId === e.row.data.lookupId);
		this.cadFiles.splice(index, 1);

		return false;
	}

	deleteSupportingDocumentLink(e: any) {
		const index = this.supportingDocuments.findIndex(d => d.lookupId === e.row.data.lookupId);
		this.supportingDocuments.splice(index, 1);

		return false;
	}

	displayMachineInDropdown(e: any) { 
		return e === null || e === undefined ? "" : (e.cellNumber === null || e.cellNumber === undefined ? "???" : e.cellNumber) + " - " + e.name;
	}
	
	downloadFileLink(e: any) {
		const lookupId = Guid.parse(e.row.data.lookupId);
		this.fileService.downloadFile(lookupId).subscribe(
			(response: any) =>{
				const dataType = response.type;
				const binaryData = [];
				binaryData.push(response);
				const downloadLink = document.createElement("a");
				downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
				downloadLink.setAttribute("download", e.row.data.fileName);
				document.body.appendChild(downloadLink);
				downloadLink.click();
			}
		);
		return false;
	}

	edit() {
		this.router.navigate([`/${this.returnUrl}/${this.item.id}_edit`])
			.then(() => {
				window.location.reload();
			});
	}
	
	getChildQAFs() {
		this.qafService.getByParentId(this.item.id)
			.subscribe(
				(res: any) =>  {
					this.childQAFs = res.response;
				},
				(err) => console.log(err)
			);
	}

	getClientContacts()	{
		this.clientContactService.getContactByParentId(this.item.clientId)
			.subscribe(
				(res: any) => {
					this.clientContacts = res.response;
					if (this.clientContacts.length === 1) {
						this.item.clientContactId = this.clientContacts[0].id;
					} else {
						this.clientContacts.sort((a,b) => a.displayName.localeCompare(b.displayName));
					}
				},
				(err) => console.log(err)
			);
	}

	getClientLocations()	{
		this.clientLocationService.getLocationsByClientId(this.item.clientId)
			.subscribe(
				(res: any) => {
					this.clientLocations = res.response;
					if (this.clientLocations.length === 1) {
						this.item.clientLocationId = this.clientLocations[0].id;
					} else {
						this.clientLocations.sort((a,b) => a.name.localeCompare(b.name));
					}
				},
				(err) => console.log(err)
			);
	}
	
	getClients() {
		this.clientService.getAll()
			.subscribe(
				(res: any) =>  {
					this.clients = res.response.items;
					this.clients.sort((a,b) => a.name.localeCompare(b.name));
				},
				(err) => console.log(err)
			);
	}

	getItem(itemId: number, mode: string) {
		this.qafService.getSingleById(itemId)
			.subscribe(
				(res: any) => {
					this.item = res.response;
					this.boughtOutParts = JSON.parse(this.item.boughtOutPartsLineData);
					this.cadFiles = JSON.parse(this.item.cadFiles);
					this.outsourcedProcesses = JSON.parse(this.item.outsourcedProcessesLineData);
					this.pressProcesses = JSON.parse(this.item.pressProcessLineData);
					this.rawMaterials = JSON.parse(this.item.rawMaterialLineData);
					this.supportingDocuments = JSON.parse(this.item.supportingDocumentsLineData);
					this.toolingFixtures = JSON.parse(this.item.toolingFixtureLineData);
					this.weldAndAssembly = JSON.parse(this.item.weldAndAssemblyLineData);
					this.setImageStyle(this.item.partImageUrl);
					this.getChildQAFs();
					this.getClientContacts();
					this.getClientLocations();
					this.getOutsourcedSpecifications();
					this.getProjects();
					this.buildButtons(mode);
				},
				(err) => {console.log(err); notify("Something Went Wrong!", "Error", 5000);}
			);
	}

	getMachines() {
		this.machineService.getAll()
			.subscribe(
				(res: any) =>  {
					this.machines = res.response.items;
					this.machines.sort((a,b) => a.name.localeCompare(b.name));
				},
				(err) => console.log(err)
			);
	}

	getOutsourcedSpecifications()	{
		this.outsourcedSpecificationService.getByClientId(this.item.clientId)
			.subscribe(
				(res: any) => {
					this.outsourcedSpecifications = res.response;
					this.outsourcedSpecifications.sort((a,b) => a.specification.localeCompare(b.specification));
				},
				(err) => console.log(err)
			);
	}
	
	getPackaging() {
		this.packagingService.getAll()
			.subscribe(
				(res: any) =>  {
					this.packaging = res.response.items;
					this.packaging.sort((a,b) => a.name.localeCompare(b.name));
				},
				(err) => console.log(err)
			);
	}
	
	getParentQAFs() {
		this.qafService.getWithNoParent()
			.subscribe(
				(res: any) =>  {
					this.parentQAFs = res.response;
				},
				(err) => console.log(err)
			);
	}

	getProcesses() {
		this.processService.getAll()
			.subscribe(
				(res: any) =>  {
					this.processes = res.response.items;
					this.processes.sort((a,b) => a.name.localeCompare(b.name));
				},
				(err) => console.log(err)
			);
	}
	
	getProjects() {
		this.projectService.GetProjectsByClient(this.item.clientId)
			.subscribe(
				(res: any) =>  {
					this.projects = res.response;
					this.projects.sort((a,b) => a.name.localeCompare(b.name));
				},
				(err) => console.log(err)
			);
	}
	
	getPurchasableItems() {
		this.purchasableItemService.getAll()
			.subscribe(
				(res: any) => {
					this.purchasableItems = res.response.items;
					this.purchasableItems.sort((a,b) => a.name.localeCompare(b.name));

					this.materialPurchasableItems = this.purchasableItems.filter((pi) => pi.purchaseType === PurchaseType.Material);
				},
				(err) => console.log(err)
			);
	}
	
	getSuppliers() {
		this.supplierService.getAll()
			.subscribe(
				(res: any) =>  {
					this.suppliers = res.response.items;
					this.suppliers.sort((a,b) => a.name.localeCompare(b.name));
				},
				(err) => console.log(err)
			);
	}
	
	getUserProfiles() {
		this.userProfileService.getActiveUsers()
			.subscribe(
				(res: any) =>  {
					this.userProfiles = res.response;
				},
				(err) => console.log(err)
			);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	mainFormDataChanged(args: any) {
		switch (args.dataField) {
			case "batchSize":
				this.calculatePressProcessLineData();
				break;
			case "clientId":
				{
					if (args.value === undefined || args.value === null) {
						this.item.manufacturingVendorCode = "";
						this.item.shippingGSDBCode = "";

					} else {
						const client = this.clients.filter((c: Client) => c.id == args.value);
						this.item.manufacturingVendorCode = client[0].supplierCode;
						this.item.shippingGSDBCode = client[0].supplierCode;

						this.getClientContacts();
						this.getClientLocations();
						this.getOutsourcedSpecifications();
						this.getProjects();
					}
				}
				break;
			case "currency":
				if (args.value === Currency.EUR) {
					this.currency = "EUR";
				} else {
					this.currency = "GBP";
				}
				break;
			case "boughtOutPartsOverhead":
			case "materialOverhead":
			case "outsourcedOverhead":
			case "toolingOverhead":
			case "profit":
				this.item.additionalCostsSubtotal = 0;
				if (this.item.boughtOutPartsOverhead !== undefined && this.item.boughtOutPartsOverhead > 0) {
					this.item.additionalCostsSubtotal += this.item.boughtOutPartsOverhead;
				}
				if (this.item.materialOverhead !== undefined && this.item.materialOverhead > 0) {
					this.item.additionalCostsSubtotal += this.item.materialOverhead;
				}
				if (this.item.outsourcedOverhead !== undefined && this.item.outsourcedOverhead > 0) {
					this.item.additionalCostsSubtotal += this.item.outsourcedOverhead;
				}
				if (this.item.toolingOverhead !== undefined && this.item.toolingOverhead > 0) {
					this.item.additionalCostsSubtotal += this.item.toolingOverhead;
				}
				if (this.item.profit !== undefined && this.item.profit > 0) {
					this.item.additionalCostsSubtotal += this.item.profit;
				}
				break;
			case "additionalCostsSubtotal":
			case "boughtOutPartsSubtotal":
			case "outsourcedProcessesSubtotal":
			case "rawMaterialSubtotal":
			case "toolingFixtureSubtotal":
			case "toolingFixtureTotalChanges":
				// case "pressProcessSubtotal": // changes to this also change the profit line, which changes additional costs, so don't need to re-trigger
				// case "weldAndAssemblySubTotal": // changes to this also change the profit line, which changes additional costs, so don't need to re-trigger
				this.item.totalPartCost = 0;
				if (this.item.additionalCostsSubtotal !== undefined && this.item.additionalCostsSubtotal > 0) {
					this.item.totalPartCost += this.item.additionalCostsSubtotal;
				}
				if (this.item.boughtOutPartsSubtotal !== undefined && this.item.boughtOutPartsSubtotal > 0) {
					this.item.totalPartCost += this.item.boughtOutPartsSubtotal;
				}
				if (this.item.outsourcedProcessesSubtotal !== undefined && this.item.outsourcedProcessesSubtotal > 0) {
					this.item.totalPartCost += this.item.outsourcedProcessesSubtotal;
				}
				if (this.item.pressProcessSubtotal !== undefined && this.item.pressProcessSubtotal > 0) {
					this.item.totalPartCost += this.item.pressProcessSubtotal;
				}
				if (this.item.rawMaterialSubtotal !== undefined && this.item.rawMaterialSubtotal > 0) {
					this.item.totalPartCost += this.item.rawMaterialSubtotal;
				}
				if (this.item.toolingFixtureSubtotal !== undefined && this.item.toolingFixtureSubtotal > 0) {
					this.item.totalPartCost += this.item.toolingFixtureSubtotal;
				}
				if (this.item.toolingFixtureTotalChanges !== undefined && this.item.toolingFixtureTotalChanges > 0) {
					this.item.totalPartCost += this.item.toolingFixtureTotalChanges;
				}
				break;
			case "totalPartCost":
			case "packagingAndLogistics":
			case "containerCost":
				this.item.grandTotal = 0;
				if (this.item.totalPartCost !== undefined && this.item.totalPartCost > 0) {
					this.item.grandTotal += this.item.totalPartCost;
				}
				if (this.item.packagingAndLogistics !== undefined && this.item.packagingAndLogistics > 0) {
					this.item.grandTotal += this.item.packagingAndLogistics;
				}
				if (this.item.containerCost !== undefined && this.item.containerCost > 0) {
					this.item.grandTotal += this.item.containerCost;
				}
				break;
		}
	}
	
	onInitNewPressProcessRow(e: any) {
		e.data.partsPerBlow = 1;
	}
	
	onInitNewToolingFixtureRow(e: any) {
		e.data.superseded = false;
	}

	prepareItemForSaving() {
		this.item.boughtOutPartsLineData = JSON.stringify(this.boughtOutParts);
		this.item.cadFiles = JSON.stringify(this.cadFiles);
		this.item.outsourcedProcessesLineData = JSON.stringify(this.outsourcedProcesses);
		this.item.pressProcessLineData = JSON.stringify(this.pressProcesses);
		this.item.rawMaterialLineData = JSON.stringify(this.rawMaterials);
		this.item.supportingDocumentsLineData = JSON.stringify(this.supportingDocuments);
		this.item.toolingFixtureLineData = JSON.stringify(this.toolingFixtures);
		this.item.weldAndAssemblyLineData = JSON.stringify(this.weldAndAssembly);
	}

	removeChildQAF(e: any) {
		this.qafService.removeParentFromQAF(e.row.data.id)
			.subscribe(
				() => {
					notify("Successfully Removed Child", "success", 5000);
					this.getChildQAFs();
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
		this.router.navigate([`${this.returnUrl}/${e.row.data.id}`]);
	}

	setImageStyle(imageUrl: string) {
		this.imageStyle = `background-image: url("${imageUrl}")`;
	}

	updateItem() {
		this.prepareItemForSaving();
		this.qafService.updateSingleById(this.item.id, this.item)
			.subscribe(
				() => {notify("Successfully Updated", "success", 5000);},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	uploadCADFile(file: File) {
		const containerName = "cadfiles";
		const uploadData = new FormData();
		uploadData.append("containerName", containerName);
		uploadData.append("files", file);
		// Add Mime Type key when mime types are decided upon...
		// uploadData.append("mimeTypeKey", "CADFileMimeTypes");
		this.fileService.uploadFile(uploadData)
			.subscribe(
				(res: any) => {
					this.popupCADFile.lookupId = res.response[0].lookupId;
					this.popupCADFile.fileName = res.response[0].name;
				},
				(err) => {
					console.log(err);
					notify("CAD File Upload Failed - Please ensure you are uploading a valid file", "error", 5000);
				}
			);
	}

	uploadImage(file: File) {
		const containerName = "images";
		const uploadData = new FormData();
		uploadData.append("containerName", containerName);
		uploadData.append("files", file);
		uploadData.append("mimeTypeKey", "ImageMimeTypes");
		this.fileService.uploadFile(uploadData)
			.subscribe(
				(res: any) => {
					this.item.partImageUrl = `${environment.server.storageUrl}/${containerName}/${res.response[0].location}`;
					this.setImageStyle(this.item.partImageUrl);
					notify("Image uploaded - Click Save to confirm change", "success", 5000);
				},
				(err) => {
					console.log(err);
					notify("Image Upload Failed - Please ensure you are uploading a valid image file", "error", 5000);
				}
			);
	}

	uploadSupportingDocument(file: File) {
		const containerName = "supportingdocuments";
		const uploadData = new FormData();
		uploadData.append("containerName", containerName);
		uploadData.append("files", file);
		// Add Mime Type key when mime types are decided upon...
		// uploadData.append("mimeTypeKey", "CADFileMimeTypes");
		this.fileService.uploadFile(uploadData)
			.subscribe(
				(res: any) => {
					this.popupSupportingDocument.lookupId = res.response[0].lookupId;
					this.popupSupportingDocument.fileName = res.response[0].name;
				},
				(err) => {
					console.log(err);
					notify("Supporting Document Upload Failed - Please ensure you are uploading a valid file", "error", 5000);
				}
			);
	}

	viewChildQAF(e: any) {
		this.router.navigate([`${this.returnUrl}/${e.row.data.id}`]);
	}
}