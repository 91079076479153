import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { PurchaseType } from "@enums/purchaseType.enum";
import { GetPropertiesFromEnum } from "@helpers/getPropertiesFromEnum";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { LogExport } from "@interfaces/logExport.interface";
import { Project } from "@interfaces/project.interface";
import { PurchaseLog } from "@interfaces/purchaseLog.interface";
import { Supplier } from "@interfaces/supplier.interface";
import { ExportLogService } from "@services/exportLog.service";
import { SupplierService } from "@services/supplier.service";
import { PurchaseLogService } from "@services/purchaseLog.service";
import { ProjectService } from "@services/project.service";
import notify from "devextreme/ui/notify";

@Component({
	moduleId: module.id,
	selector: "purchaseLogs",
	styleUrls: ["purchaseLogs.css"],
	templateUrl: "purchaseLogs.html"
})
export class PurchaseLogsAdminComponent {
	deleteEnabled = true;
	editEnabled = true;
	gridLoading = false;
	headerPrimaryButtons: AdminHeaderButton[] = [];
	headerSecondaryButtons: AdminHeaderButton[] = [];
	itemType = "Purchase Log"
	itemUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	items: PurchaseLog[] = [];
	projects: Project[]= [];
	purchaseTypes: any[] =[];
	statuses: any;
	suppliers: Supplier[] = [];
	title: string;
	
	constructor(private router: Router, private exportLogService: ExportLogService, private itemService: PurchaseLogService, private supplierService: SupplierService, private projectService: ProjectService) {
		this.headerPrimaryButtons.push({ icon: "fal fa-plus", method: "addNewItem", text: `New ${this.itemType}` });
		this.headerSecondaryButtons.push({ icon: "fal fa-shapes", method: "goToItems", text: "Items" });
		this.title = `${this.itemType}s`;
		this.purchaseTypes = GetPropertiesFromEnum(PurchaseType);
		this.viewRow = this.viewRow.bind(this);
		this.editRow = this.editRow.bind(this);
		this.deleteRow = this.deleteRow.bind(this);
		this.getSuppliers();
		this.getProjects();
		this.getItems();
	}

	addNewItem() {
		this.router.navigate([`${this.itemUrl}/create`]);
	}

	deleteRow(e: any) {
		const result = confirm("Are you sure you want to delete this item?");
		if (result) {
			this.itemService.deleteSingleById(e.row.data.id)
				.subscribe(
					() => {
						notify(this.itemType + " deleted!", "success", 5000);
						this.getItems();
					},
					(err) => {
						console.log(err);
						notify("Something went wrong!", "error", 5000);
					},
				);
		}
	}

	editRow(e: any) {
		this.router.navigate([`${this.itemUrl}/${e.row.data.lookupId}_edit`]);
	}

	getItems() {
		this.gridLoading = true;
		this.itemService.getAll()
			.subscribe(
				(res: any) => {
					this.items = res.response.items;
					this.gridLoading = false;
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				},
			);
	}

	getProjects() {
		this.projectService.getAll()
			.subscribe(
				(res: any) => this.projects = res.response.items,
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				},
			);
	}
	
	getSuppliers() {
		this.supplierService.getAll()
			.subscribe(
				(res: any) => this.suppliers = res.response.items,
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				},
			);
	}

	goToItems() {
		this.router.navigate(["admin/purchasableItems"]);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	logExport() {
		const logExport = new LogExport();
		logExport.gridName = "Purchase Logs";
		this.exportLogService.logExport(logExport)
			.subscribe(
				() => console.log("Export Logged"),
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
					return false;
				},
			);
	}

	viewRow(e: any) {
		this.router.navigate([`${this.itemUrl}/${e.row.data.lookupId}`]);
	}
}