import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { MaterialType } from "@enums/materialType.enum";
import { PurchaseType } from "@enums/purchaseType.enum";
import { GetPropertiesFromEnum } from "@helpers/getPropertiesFromEnum";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { LogExport } from "@interfaces/logExport.interface";
import { UploadCSV } from "@interfaces/uploadCSV.interface";
import { ExportLogService } from "@services/exportLog.service";
import { FileService } from "@services/file.service";
import { PurchasableItemService } from "@services/purchasableItem.service";
import notify from "devextreme/ui/notify";

@Component({
	moduleId: module.id,
	selector: "purchasableItems",
	styleUrls: ["purchasableItems.css"],
	templateUrl: "purchasableItems.html"
})
export class PurchasableItemsAdminComponent {
	deleteEnabled = true;
	editEnabled = true;
	gridLoading = false;
	headerPrimaryButtons: AdminHeaderButton[] = [];
	headerSecondaryButtons: AdminHeaderButton[] = [];
	itemType = "Purchasable Item"
	itemUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	items: any[] = [];
	materialTypes: any;
	popupAddButtonOptions: any = { onClick: () => this.importCSV(), text: "Import CSV" };
	popupCloseButtonOptions: any = { onClick: () => this.closePopup(), text: "Close" };
	popupImportDetails: UploadCSV = new UploadCSV();
	popupTitle = "Import CSV";
	popupVisible = false;
	purchaseTypes: any;
	statuses: any;
	title: string;
	validationErrors: string[] = [];
	
	constructor(private router: Router, private exportLogService: ExportLogService, private itemService: PurchasableItemService, private fileService: FileService) {
		this.materialTypes = GetPropertiesFromEnum(MaterialType);
		this.purchaseTypes = GetPropertiesFromEnum(PurchaseType);
		this.headerPrimaryButtons.push({ icon: "fal fa-plus", method: "addNewItem", text: `New ${this.itemType}` });
		this.headerPrimaryButtons.push({ icon: "fal fa-upload", method: "importCSVPopup", text: "Import CSV" });
		this.headerSecondaryButtons.push({ icon: "fal fa-pound-sign", method: "goToPurchaseLogs", text: "Purchase Logs" });
		this.title = `${this.itemType}s`;
		this.viewRow = this.viewRow.bind(this);
		this.editRow = this.editRow.bind(this);
		this.deleteRow = this.deleteRow.bind(this);
		this.uploadCSVForImport = this.uploadCSVForImport.bind(this);
		this.getItems();
	}

	addNewItem() {
		this.router.navigate([`${this.itemUrl}/create`]);
	}
	
	closePopup() {
		this.popupVisible = false;
	}

	deleteRow(e: any) {
		const result = confirm("Are you sure you want to delete this item?");
		if(result)
		{
			this.itemService.deleteSingleById(e.row.data.id)
				.subscribe(
					() => {
						notify(this.itemType + " deleted!", "success", 5000);
						this.getItems();
					},
					(err) => {
						console.log(err);
						notify("Something went wrong!", "error", 5000);
					},
				);
		}
	}

	editRow(e: any) {
		this.router.navigate([`${this.itemUrl}/${e.row.data.id}_edit`]);
	}

	getItems() {
		this.gridLoading = true;
		this.itemService.getAll()
			.subscribe(
				(res: any) => {
					this.items = res.response.items;
					this.gridLoading = false;
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				},
			);
	}

	goToPurchaseLogs() {
		this.router.navigate(["admin/purchaseLogs"]);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	importCSV() {
		this.validationErrors = [];
		this.itemService.importCSV(this.popupImportDetails)
			.subscribe(
				() => {
					this.closePopup();
					this.getItems();
				},
				(err: any) => {
					this.validationErrors = err.file;
				}
			);
	}

	importCSVPopup() {
		this.validationErrors = [];
		this.popupVisible = true;
		this.popupAddButtonOptions = { onClick: () => this.importCSV(), text: "Import CSV" };
		this.popupCloseButtonOptions = { onClick: () => this.closePopup(), text: "Close" };
		this.popupImportDetails = new UploadCSV();
		this.popupTitle = "Import CSV";
	}

	logExport() {
		const logExport = new LogExport();
		logExport.gridName = "Purchasable Items";
		this.exportLogService.logExport(logExport)
			.subscribe(
				() => console.log("Export Logged"),
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
					return false;
				},
			);
	}

	uploadCSVForImport(file: File) {
		this.validationErrors = [];
		const containerName = "purchasable-items-imports";
		const uploadData = new FormData();
		uploadData.append("containerName", containerName);
		uploadData.append("files", file);
		this.fileService.uploadFile(uploadData)
			.subscribe(
				(res: any) => {
					this.popupImportDetails.fileLookupId = res.response[0].lookupId;
				},
				(err) => {
					console.log(err);
					notify("CSV Upload Failed - Please ensure you are uploading a valid file", "error", 5000);
				}
			);
	}

	viewRow(e: any) {
		this.router.navigate([`${this.itemUrl}/${e.row.data.id}`]);
	}
}