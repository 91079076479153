import { Component, ViewChild, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { Client } from "@interfaces/client.interface";
import { ClientLocation } from "@interfaces/clientLocation.interface";
import { DeliveryNote } from "@interfaces/deliveryNote.interface";
import { DeliveryNoteLineItem } from "@interfaces/deliveryNoteLineItem.interface";
import { SalesOrder } from "@interfaces/salesOrder.interface";
import { ClientService } from "@services/client.service";
import { ClientLocationService } from "@services/clientLocation.service";
import { DeliveryNoteService } from "@services/deliveryNote.service";
import { SalesOrderService } from "@services/salesOrder.service";
import { DxDataGridComponent } from "devextreme-angular";
import notify from "devextreme/ui/notify";
import { Guid } from "guid-typescript";

@Component({
	encapsulation: ViewEncapsulation.None,
	moduleId: module.id,
	selector: "deliveryNotes-id",
	styleUrls: ["deliveryNotes-id.css"],
	templateUrl: "deliveryNotes-id.html"
})
export class DeliveryNotesIdAdminComponent {
	@ViewChild("gridContainer", { static: false }) dataGrid!: DxDataGridComponent;
	changes: any = [];
	clientLocations: ClientLocation[] = [];
	clients: Client[] = [];
	createEnabled = true;
	editEnabled = true;
	editRowKey: any = null;
	headerPrimaryButtons: AdminHeaderButton[] = [];
	headerSecondaryButtons: AdminHeaderButton[] = [];
	headerTertiaryButtons: AdminHeaderButton[] = [];
	item: DeliveryNote = new DeliveryNote();
	itemType = "Delivery Note";
	itemUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	lineItems: DeliveryNoteLineItem[] = [];
	readOnly = true;
	readOnlyAlways = true;
	returnUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	salesOrder?: SalesOrder;
	screenMode = "";
	title: string = "View " + this.itemType;

	constructor(private router: Router, private route: ActivatedRoute, private itemService: DeliveryNoteService, private clientService: ClientService, private clientLocationService: ClientLocationService, private salesOrderService: SalesOrderService) {
		this.getClients();
		this.route.params.subscribe((params) => {
			this.getItem(params.id, "view");
		});
	}

	buildButtons(mode: string) {
		this.screenMode = mode;
		this.headerSecondaryButtons = [];
		this.headerTertiaryButtons = [];

		if (this.item.deleted === true) {			
			this.headerPrimaryButtons = [
				{ method: "closeToDeleted", text: "Close" },
			];
			return;
		}

		if (mode == "view") {
			this.headerPrimaryButtons = [
				{ method: "close", text: "Close" },
			];
			this.headerTertiaryButtons = [
				{ method: "generatePreviewPDF", text: "Download PDF" }
			];

			if (this.item.isSent === false) {
				this.headerTertiaryButtons.push({ method: "markSent", text: "Mark Sent" });
			}
		}

		if (this.item.salesOrderId > 0) {
			this.headerTertiaryButtons.push({ method: "viewSalesOrder", text: "Go to Sales Order" });
		}
	}

	close() {
		this.router.navigate([`/${this.returnUrl}`]);
	}

	closeToDeleted() {
		this.router.navigate([`/${this.returnUrl}/deleted`]);
	}

	formDataChanged(args: any) {
		switch (args.dataField) {
			case "clientId":
				this.getClientLocations();
				break;
		}
	}

	generatePreviewPDF() {
		this.itemService.generateAndPreviewPdf(this.item.id).subscribe(
			(response: any) =>{
				const dataType = response.type;
				const binaryData = [];
				binaryData.push(response);
				const downloadLink = document.createElement("a");
				downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
				downloadLink.setAttribute("download", "Delivery_Note_" + this.item.id + ".pdf");
				document.body.appendChild(downloadLink);
				downloadLink.click();
			}
		);
	}
	
	getClientLocations() {
		this.clientLocationService.getLocationsByClientId(this.item.clientId)
			.subscribe(
				(res: any) =>  {
					this.clientLocations = res.response;
					this.clientLocations.sort((a,b) => a.name.localeCompare(b.name));
				},
				(err) => console.log(err)
			);
	}
	
	getClients() {
		this.clientService.getAll()
			.subscribe(
				(res: any) =>  {
					this.clients = res.response.items;
					this.clients.sort((a,b) => a.name.localeCompare(b.name));
				},
				(err) => console.log(err)
			);
	}

	getItem(lookupId: Guid, buttonMode: string) {
		this.itemService.getSingleByLookupId(lookupId)
			.subscribe(
				(res: any) => {
					this.item = res.response;
					this.lineItems = JSON.parse(this.item.lineItems);
					this.getClientLocations();
					this.buildButtons(buttonMode);
				},
				(err) => {
					console.log(err);
					notify("Something Went Wrong!", "Error", 5000);
				},
			);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	markSent() {
		if (confirm("Are you sure? This cannot be undone.") == false) { return; }
		this.itemService.markSent(this.item.id)
			.subscribe(
				() => {
					notify("Successfully Updated", "success", 5000);
					this.getItem(this.item.lookupId, this.screenMode);
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	updateItem() {
		this.item.lineItems = JSON.stringify(this.lineItems);
		this.itemService.updateSingleById(this.item.id, this.item)
			.subscribe(
				() => {
					notify("Successfully Updated", "success", 5000);
					// this.cancelEditing();
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}
	
	viewSalesOrder() {
		this.salesOrderService.getSingleById(this.item.salesOrderId)
			.subscribe(
				(res: any) =>  {
					this.salesOrder = res.response;
					this.router.navigate([`admin/salesOrders/${this.salesOrder?.lookupId}`]);
				},
				(err) => console.log(err)
			);
	}
}