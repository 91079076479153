import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { QualityControlCheckType } from "@enums/qualityControlCheckType.enum";
import { CheckGroupPermissions } from "@helpers/checkGroupPermissions";
import { GetPropertiesFromEnum } from "@helpers/getPropertiesFromEnum";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { ChangeWorkOrderQuantity } from "@interfaces/changeWorkOrderQuantity.interface";
import { ChangeWorkOrderStageQuantity } from "@interfaces/changeWorkOrderStageQuantity.interface";
import { CreateQLoopDto } from "@interfaces/createQLoopDto.interface";
import { Part } from "@interfaces/part.interface";
import { PartFileLink } from "@interfaces/partFileLink.interface";
import { PartPurchasableItemRequired } from "@interfaces/partPurchasableItemRequired.interface";
import { PurchasableItem } from "@interfaces/purchasableItem.interface";
import { SalesOrder } from "@interfaces/salesOrder.interface";
import { SalesOrderPart } from "@interfaces/salesOrderPart.interface";
import { UserProfile } from "@interfaces/userProfile.interface";
import { WorkOrder } from "@interfaces/workOrder.interface";
import { WorkOrderStage } from "@interfaces/workOrderStage.interface";
import { WorkOrderStageIncrementQuantity } from "@interfaces/workOrderStageIncrementQuantity.interface";
import { AppSettingsService } from "@services/appSettings.service";
import { FileService } from "@services/file.service";
import { OpsActionAuditService } from "@services/opsActionAudit.service";
import { PartService } from "@services/part.service";
import { PurchasableItemService } from "@services/purchasableItem.service";
import { SalesOrderService } from "@services/salesOrder.service";
import { SalesOrderPartService } from "@services/salesOrderPart.service";
import { UserProfileService } from "@services/userProfile.service";
import { WorkOrderService } from "@services/workOrder.service";
import { WorkOrderStageService } from "@services/workOrderStage.service";
import notify from "devextreme/ui/notify";
import { Guid } from "guid-typescript";

@Component({
	moduleId: module.id,
	selector: "workOrders-id",
	styleUrls: ["workOrders-id.css"],
	templateUrl: "workOrders-id.html"
})
export class WorkOrdersIdAdminComponent {
	childWorkOrders: WorkOrder[] = [];
	consumablesRequired: PartPurchasableItemRequired[] = [];
	createEnabled = false;
	editEnabled = true;
	fixingsRequired: PartPurchasableItemRequired[] = [];
	headerPrimaryButtons: AdminHeaderButton[] = [];
	headerSecondaryButtons: AdminHeaderButton[] = [];
	headerTertiaryButtons: AdminHeaderButton[] = [];
	isSuperAdmin = false;
	isSupervisor = false;
	item: WorkOrder = new WorkOrder();
	itemId = "";
	itemType = "Work Order";
	itemUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	mode = "view";
	opsActionAudits: any;
	part: Part = new Part();
	people: UserProfile[] = [];
	popupAddButtonOptions: any;
	popupChangeWorkOrderQuantity: ChangeWorkOrderQuantity = new ChangeWorkOrderQuantity();
	popupCloseButtonOptions: any = { onClick: () => this.closePopup(), text: "Close" };
	popupCreateQLoopDto: CreateQLoopDto = new CreateQLoopDto();
	popupSupportingDocument: PartFileLink = new PartFileLink();
	popupTitle = "";
	popupVisible = false;
	popupWorkOrderStageIncrementData: WorkOrderStageIncrementQuantity = new WorkOrderStageIncrementQuantity();
	popupWorkOrderStageReduceQuantityData: ChangeWorkOrderStageQuantity = new ChangeWorkOrderStageQuantity();
	purchasableItems: PurchasableItem[] = [];
	qualityControlCheckTypes: any;
	readOnly = true;
	returnUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	salesOrder: SalesOrder = new SalesOrder;
	salesOrderPart: SalesOrderPart = new SalesOrderPart;
	splitWorkOrders: WorkOrder[] = [];
	statuses: any;
	supportingDocumentButtonOptions = {
		onClick: () => this.addNewSupportingDocumentPopup(),
		text: "Add Document",
		type: "default",
		useSubmitBehavior: false
	}
	supportingDocuments: PartFileLink[] = [];
	title: string = "View " + this.itemType;
	workOrderStages: WorkOrderStage[] = [];

	constructor(private router: Router, private route: ActivatedRoute, private appSettingsService: AppSettingsService, private fileService: FileService, private opsActionAuditService: OpsActionAuditService, private partService: PartService, private purchasableItemService: PurchasableItemService, private salesOrderService: SalesOrderService, private salesOrderPartService: SalesOrderPartService, private userProfileService: UserProfileService, private itemService: WorkOrderService, private workOrderStageService: WorkOrderStageService) {
		this.qualityControlCheckTypes = GetPropertiesFromEnum(QualityControlCheckType);
		this.isSuperAdmin = CheckGroupPermissions(appSettingsService, "Super Admin");
		this.isSupervisor = CheckGroupPermissions(appSettingsService, "Supervisor");

		this.getPeople();
		this.getPurchasableItems();
		
		this.route.params
			.subscribe((params) => {
				if (params.id != "create") {
					if (params.id.split("_").length > 1) {
						this.getItem(params.id.split("_")[0], "edit");
						this.edit();
					} else {
						this.getItem(params.id, "view");
					}
				} else {
					this.create();
				}
			});

		this.canSplitWorkOrder = this.canSplitWorkOrder.bind(this);
		this.editChildRow = this.editChildRow.bind(this);
		this.viewChildRow = this.viewChildRow.bind(this);
		this.editSplitRow = this.editSplitRow.bind(this);
		this.viewSplitRow = this.viewSplitRow.bind(this);

		this.isReduceWorkOrderStagePopupButtonAvailable = this.isReduceWorkOrderStagePopupButtonAvailable.bind(this);
		
		this.deleteSupportingDocumentLink = this.deleteSupportingDocumentLink.bind(this);
		this.downloadFileLink = this.downloadFileLink.bind(this);
		this.uploadSupportingDocument = this.uploadSupportingDocument.bind(this);

		this.incrementWorkOrderStagePopup = this.incrementWorkOrderStagePopup.bind(this);
		this.reduceWorkOrderStagePopup = this.reduceWorkOrderStagePopup.bind(this);
		this.toggleStagePause = this.toggleStagePause.bind(this);
	}

	addNewSupportingDocument() {
		if (this.popupSupportingDocument === null || this.popupSupportingDocument === undefined || this.popupSupportingDocument.fileName === "") {
			alert("Please upload a document");
			return;
		}

		this.supportingDocuments.push(this.popupSupportingDocument);
		this.popupSupportingDocument = new PartFileLink();

		this.updateItem();
		this.closePopup();
	}

	addNewSupportingDocumentPopup() {
		this.popupSupportingDocument = new PartFileLink();
		this.popupTitle = "Add New Supporting Document";
		this.popupVisible = true;
		this.popupAddButtonOptions = { onClick: () => this.addNewSupportingDocument(), text: "Add Document" };
	}

	buildButtons(mode: string) {
		this.mode = mode;
		this.headerSecondaryButtons = [];
		this.headerTertiaryButtons = [];
		if (mode == "edit" && this.editEnabled) {
			this.headerPrimaryButtons = [
				{ method: "updateItem", text: "Save Changes" },
				{ method: "cancelEditing", text: "View Mode" },
				{ method: "close", text: "Close" }
			];
		} else if (mode == "create" && this.createEnabled) {
			this.headerPrimaryButtons = [
				{ method: "createItem", text: "Save" },
				{ method: "close", text: "Close" }
			];
		} else if (mode == "view") {
			this.headerPrimaryButtons = [
				{ method: "edit", text: "Edit" },
				{ method: "close", text: "Close" }
			];

			if (this.canSplitWorkOrder()) {
				this.headerPrimaryButtons.push({ icon: "fal fa-page-break", method: "splitWorkOrder", text: "Split" });	
			}

			if (this.item.workOrderComplete === false) {
				this.headerPrimaryButtons.push({ icon: "fal fa-check-double", method: "completeWorkOrder", text: "Complete" });	
			}

			this.headerSecondaryButtons.push({ icon: "fal fa-download", method: "generateAndDownloadPDF", text: "PDF" });
			this.headerSecondaryButtons.push({ icon: "fal fa-download", method: "generateAndDownloadMasterLabelPDF", text: "Master Label" });

			this.headerTertiaryButtons.push({ icon: "fal fa-plus", method: "createQLoopPopup", text: "Create Q-Loop" });
			
			
			if (this.isSuperAdmin === true || this.isSupervisor === true) {
				this.headerTertiaryButtons.push({ method: "changeQuantityPopup", text: "Change Quantity" });
			}
		}
		this.headerTertiaryButtons.push({ icon: "fal fa-eye", method: "viewSalesOrder", text: "Sales Order" });	
	}

	canSplitWorkOrder() {
		const currentStage = this.workOrderStages.filter((workOrderStage) => workOrderStage.stageNumber === this.item.currentStage);

		if (currentStage === undefined || currentStage === null || currentStage.length === 0) {
			return false;
		}

		return currentStage[0].quantityCompleted > 0 && currentStage[0].quantityCompleted < currentStage[0].quantityRequired;
	}

	cancelEditing() {
		if (confirm("Are you sure you wish to switch modes? Unsaved changes will be discarded.") == false) {
			return;
		}
		this.readOnly = true;
		this.buildButtons("view");
		this.title = "View " + this.itemType;
	}

	changeQuantity() {								
		this.itemService.changeQuantity(this.popupChangeWorkOrderQuantity.workOrderId, this.popupChangeWorkOrderQuantity.quantity).subscribe(
			() => {
				notify("Successfully Updated " + this.itemType, "success", 5000);
				this.getItem(this.item.id, this.mode);
			},
			(err) => {
				console.log(err);
				notify("Something went wrong!", "error", 5000);
			}
		);
		this.closePopup();
	}

	changeQuantityPopup() {
		this.popupChangeWorkOrderQuantity = new ChangeWorkOrderQuantity();
		this.popupChangeWorkOrderQuantity.workOrderId = this.item.id;
		this.popupChangeWorkOrderQuantity.quantity = this.item.quantity;
		this.popupTitle = "Change Work Order Quantity";
		this.popupVisible = true;
		this.popupAddButtonOptions = { onClick: () => this.changeQuantity(), text: "Change Quantity" };
	}

	close() {
		this.router.navigate([`/${this.returnUrl}/`]);
	}

	closePopup() {
		this.popupVisible = false;
	}

	completeWorkOrder() {
		if (this.item.workOrderComplete === true) {
			alert("Work Order is already Complete");
			return;
		}
		if (confirm("Are you sure you wish to mark this work order as complete? This will complete all stages in this work order, though inspections will still need adding. This cannot be undone.") === false) {
			return;
		}

		// Call the increment endpoint
		this.itemService.complete(this.item.id)
			.subscribe(
				() => {
					this.getItem(this.item.id, this.mode);
					notify("Successfully Completed Work Order", "success", 5000);
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	create() {
		this.readOnly = false;
		this.buildButtons("create");
		this.title = "Create New " + this.itemType;
	}

	createItem() {
		this.itemService.createSingle(this.item)
			.subscribe(
				() => {
					notify("Successfully Updated " + this.itemType, "success", 5000);
					this.item.supportingDocuments = JSON.stringify(this.supportingDocuments);
					this.item = new WorkOrder();
					this.close();
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	createQLoop() {								
		this.itemService.createQLoop(this.popupCreateQLoopDto).subscribe(
			(res: any) => {
				notify("Successfully Created Q-Loop", "success", 5000);
				const newWorkOrderResponse = res.response;
				this.itemService.getSingleByLookupId(newWorkOrderResponse.lookupId).subscribe(
					(res: any) => {
						const newWorkOrder = res.response;
						this.router.navigate([`${this.itemUrl}/${newWorkOrder.id}`]);
					},
					(err) => {
						console.log(err);
						notify("Something went wrong!", "error", 5000);
					}
				);
			},
			(err) => {
				console.log(err);
				notify("Something went wrong!", "error", 5000);
			}
		);
		this.closePopup();
	}

	createQLoopPopup() {
		this.popupCreateQLoopDto = new CreateQLoopDto();
		this.popupCreateQLoopDto.workOrderId = this.item.id;
		this.popupCreateQLoopDto.quantity = this.item.quantity;
		this.popupCreateQLoopDto.dateRequired = this.item.dateRequired;
		this.popupTitle = "Create Q-Loop";
		this.popupVisible = true;
		this.popupAddButtonOptions = { onClick: () => this.createQLoop(), text: "Create Q-Loop" };
	}

	deleteSupportingDocumentLink(e: any) {
		if (confirm("Are you sure you wish to delete this document? This will save any outstanding changes to the work order.") == false) {
			return;
		}

		const index = this.supportingDocuments.findIndex(d => d.version === e.row.data.version);
		this.supportingDocuments.splice(index, 1);

		this.updateItem();

		return false;
	}

	downloadFileLink(e: any) {
		const lookupId = Guid.parse(e.row.data.lookupId);
		this.fileService.downloadFile(lookupId).subscribe(
			(response: any) =>{
				const dataType = response.type;
				const binaryData = [];
				binaryData.push(response);
				const downloadLink = document.createElement("a");
				downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
				downloadLink.setAttribute("download", e.row.data.fileName);
				document.body.appendChild(downloadLink);
				downloadLink.click();
			}
		);
		return false;
	}

	edit() {
		this.readOnly = false;
		this.buildButtons("edit");
		this.title = "Edit " + this.itemType;
		notify("You are now editing...", "warning", 5000);
	}

	editChildRow(e: any) {
		this.router.navigate([`${this.itemUrl}/${e.row.data.id}_edit`]);
	}

	editSplitRow(e: any) {
		this.router.navigate([`${this.itemUrl}/${e.row.data.id}_edit`]);
	}

	generateAndDownloadMasterLabelPDF() {
		this.itemService.generateAndDownloadMasterLabelPdf(this.item.id).subscribe(
			(response: any) =>{
				const dataType = response.type;
				const binaryData = [];
				binaryData.push(response);
				const downloadLink = document.createElement("a");
				downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
				downloadLink.setAttribute("download", "Work_Order_Master_Label_" + this.item.id + ".pdf");
				document.body.appendChild(downloadLink);
				downloadLink.click();
			},
			(err) => {
				console.log(err);
				notify("Something went wrong!", "error", 5000);
			},
		);
	}

	generateAndDownloadPDF() {
		this.itemService.generateAndDownloadPdf(this.item.id).subscribe(
			(response: any) =>{
				const dataType = response.type;
				const binaryData = [];
				binaryData.push(response);
				const downloadLink = document.createElement("a");
				downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
				downloadLink.setAttribute("download", "Work_Order_" + this.item.id + ".pdf");
				document.body.appendChild(downloadLink);
				downloadLink.click();
			}
		);
	}
	
	getChildren() {
		this.itemService.getForParent(this.item.id)
			.subscribe(
				(res: any) =>  {
					this.childWorkOrders = res.response;
				},
				(err) => console.log(err)
			);
	}

	getItem(itemId: number, buttonMode: string) {
		this.itemService.getSingleById(itemId)
			.subscribe(
				(res: any) => {
					this.item = res.response;
					this.supportingDocuments = JSON.parse(this.item.supportingDocuments);
					this.getWorkOrderStages(buttonMode);
					this.getSalesOrderPart();
					this.getChildren();
					this.getSplitWorkOrders();
					this.getPart();
					this.getSalesOrder();
					this.getOpsActionAudits(itemId);
				},
				(err) => {console.log(err); notify("Something Went Wrong!", "Error", 5000);}
			);
	}

	getOpsActionAudits(workOrderId: number)	{
		this.opsActionAuditService.getByWorkOrderId(workOrderId)
			.subscribe(
				(res: any) => {
					this.opsActionAudits = res.response;
				},
				(err) => console.log(err)
			);
	}

	getPart() {
		this.partService.getSingleById(this.item.partId)
			.subscribe(
				(res: any) => {
					this.part = res.response;
					this.consumablesRequired = JSON.parse(this.part.consumablesRequired);
					if (this.consumablesRequired === null || this.consumablesRequired === undefined) {
						this.consumablesRequired = [];
					}
					this.fixingsRequired = JSON.parse(this.part.fixingsRequired);
					if (this.fixingsRequired === null || this.fixingsRequired === undefined) {
						this.fixingsRequired = [];
					}
				},
				(err) => console.log(err)
			);
	}
	
	getPeople() {
		this.userProfileService.getActiveUsers()
			.subscribe(
				(res: any) =>  {
					this.people = res.response;
					this.people.sort((a,b) => a.fullName.localeCompare(b.fullName));
				},
				(err) => console.log(err)
			);
	}
	
	getPurchasableItems() {
		this.purchasableItemService.getAll()
			.subscribe(
				(res: any) => {
					this.purchasableItems = res.response.items;
					this.purchasableItems.sort((a,b) => a.name.localeCompare(b.name));
				},
				(err) => console.log(err)
			);
	}

	getSalesOrder() {
		this.salesOrderService.getSingleById(this.item.salesOrderId)
			.subscribe(
				(res: any) => this.salesOrder = res.response,
				(err) => console.log(err)
			);
	}

	getSalesOrderPart() {
		if (this.item.salesOrderPartId == null || this.item.salesOrderPartId == undefined) {
			return;
		}
		this.salesOrderPartService.getSingleById(this.item.salesOrderPartId)
			.subscribe(
				(res: any) => {
					this.salesOrderPart = res.response;
				},
				(err) => console.log(err)
			);
	}
	
	getSplitWorkOrders() {
		this.itemService.getForOriginal(this.item.id)
			.subscribe(
				(res: any) =>  {
					this.splitWorkOrders = res.response;
				},
				(err) => console.log(err)
			);
	}

	getWorkOrderStages(buttonMode: string) {
		this.workOrderStageService.getForWorkOrder(this.item.id)
			.subscribe(
				(res: any) => {
					this.workOrderStages = res.response;
					this.buildButtons(buttonMode);
				},
				(err) => console.log(err)
			);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	incrementWorkOrderStage() {
		if (this.popupWorkOrderStageIncrementData === null || this.popupWorkOrderStageIncrementData === undefined) {
			alert("Something went wrong!");
			return;
		}
		if (this.popupWorkOrderStageIncrementData.quantity > this.popupWorkOrderStageIncrementData.maximumQuantity) {
			alert("Quantity too high");
			return;
		}

		// Call the increment endpoint
		this.workOrderStageService.completedQuantity(this.popupWorkOrderStageIncrementData.id, this.popupWorkOrderStageIncrementData.quantity)
			.subscribe(
				() => {
					this.popupWorkOrderStageIncrementData = new WorkOrderStageIncrementQuantity();
					this.getItem(this.item.id, this.mode);
					this.closePopup();
					notify("Successfully Incremented Quantity", "success", 5000);
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	incrementWorkOrderStagePopup(e: any) {
		this.popupWorkOrderStageIncrementData = new WorkOrderStageIncrementQuantity();
		this.popupWorkOrderStageIncrementData.id = e.row.data.id;
		this.popupWorkOrderStageIncrementData.maximumQuantity = e.row.data.quantityRequired - e.row.data.quantityCompleted;
		this.popupWorkOrderStageIncrementData.quantity = 1;
		this.popupTitle = "Increase Completed Quantity for Stage " + e.row.data.stageNumber;
		this.popupVisible = true;
		this.popupAddButtonOptions = { onClick: () => this.incrementWorkOrderStage(), text: "Increase Completed Quantity" };
	}

	isIncrementWorkOrderStagePopupButtonAvailable(e: any) {
		return e.row.data.quantityRequired > e.row.data.quantityCompleted;
	}

	isPauseStageButtonAvailable(e: any) {
		return e.row.data.isPaused === false;
	}

	isReduceWorkOrderStagePopupButtonAvailable(e: any) {
		return e.row.data.quantityCompleted > 0 && (this.isSuperAdmin === true || this.isSupervisor === true);
	}

	isUnpauseStageButtonAvailable(e: any) {
		return e.row.data.isPaused === true;
	}

	reduceWorkOrderStage() {
		// Call the increment endpoint
		this.workOrderStageService.reduceQuantity(this.popupWorkOrderStageReduceQuantityData.workOrderStageId, this.popupWorkOrderStageReduceQuantityData.quantity)
			.subscribe(
				() => {
					this.popupWorkOrderStageReduceQuantityData = new ChangeWorkOrderStageQuantity();
					this.getItem(this.item.id, this.mode);
					this.closePopup();
					notify("Successfully Reduced Quantity", "success", 5000);
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	reduceWorkOrderStagePopup(e: any) {
		this.popupWorkOrderStageReduceQuantityData = new ChangeWorkOrderStageQuantity();
		this.popupWorkOrderStageReduceQuantityData.workOrderStageId = e.row.data.id;
		this.popupWorkOrderStageReduceQuantityData.quantity = e.row.data.completedQuantity;
		this.popupTitle = "Reduce Completed Quantity for Stage " + e.row.data.stageNumber;
		this.popupVisible = true;
		this.popupAddButtonOptions = { onClick: () => this.reduceWorkOrderStage(), text: "Reduce Completed Quantity" };
	}

	splitWorkOrder() {
		if (this.canSplitWorkOrder() === false) {
			alert("This Work Order cannot be split.");
		}
		if (confirm("Are you sure you wish to split this Work Order? This cannot be undone. Child work orders will also be split proportionally.") == false) {
			return;
		}
		this.itemService.split(this.item.id)
			.subscribe(
				() => this.close(),
				(err) => console.log(err)
			);
	}

	toggleStagePause(e: any) {
		this.workOrderStageService.togglePause(e.row.data.id)
			.subscribe(
				() => {
					notify("Successfully Toggled Pause", "success", 5000);
					this.getWorkOrderStages(this.mode);
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
		return false;
	}

	updateItem() {
		this.item.supportingDocuments = JSON.stringify(this.supportingDocuments);
		this.itemService.updateSingleById(this.item.id, this.item)
			.subscribe(
				() => {
					notify("Successfully Updated", "success", 5000);
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	uploadSupportingDocument(file: File) {
		const containerName = "workorderfiles";
		const uploadData = new FormData();
		uploadData.append("containerName", containerName);
		uploadData.append("files", file);
		// Add Mime Type key when mime types are decided upon...
		// uploadData.append("mimeTypeKey", "CADFileMimeTypes");
		this.fileService.uploadFile(uploadData)
			.subscribe(
				(res: any) => {
					this.popupSupportingDocument.lookupId = res.response[0].lookupId;
					this.popupSupportingDocument.fileName = res.response[0].name;
				},
				(err) => {
					console.log(err);
					notify("Supporting Document Upload Failed - Please ensure you are uploading a valid file", "error", 5000);
				}
			);
	}

	viewChildRow(e: any) {
		this.router.navigate([`${this.itemUrl}/${e.row.data.id}`]);
	}

	viewSalesOrder() {		
		this.router.navigate([`admin/salesOrders/${this.salesOrder.lookupId}`]);
	}

	viewSplitRow(e: any) {
		this.router.navigate([`${this.itemUrl}/${e.row.data.id}`]);
	}
}