<admin-header [primaryButtons]="headerPrimaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<dx-data-grid id="grid" class="data-grid" [dataSource]="items" [remoteOperations]="false" [allowColumnReordering]="true" [allowColumnResizing]="true" [rowAlternationEnabled]="true" [showBorders]="true" (onExporting)="logExport()">
    <dxo-paging [pageSize]="100"></dxo-paging>
	<dxo-state-storing [enabled]="true" type="localStorage" storageKey="woMain"></dxo-state-storing>
    <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
    <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
	<dxo-export [enabled]="true"></dxo-export>
	<dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>
	<dxi-column type="buttons">
        <dxi-button hint="View" icon="fal fa-eye" [onClick]="viewRow"></dxi-button>
		<dxi-button *ngIf="editEnabled" hint="Edit" icon="fal fa-pencil" [onClick]="editRow"></dxi-button>
		<dxi-button *ngIf="deleteEnabled" icon="fal fa-trash" [onClick]="deleteRow"></dxi-button>
    </dxi-column>
    <dxi-column dataField="id" dataType="string" alignment="left"></dxi-column>
    <dxi-column dataField="isQLoop" alignment="left" caption="Q-Loop"></dxi-column>
	<dxi-column dataField="cocNumber" dataType="string" alignment="left"></dxi-column>
	<dxi-column dataField="part.partNumber" dataType="string" alignment="left" caption="Part"></dxi-column>
	<dxi-column dataField="salesOrderId" dataType="string" alignment="left" caption="Sales Order"></dxi-column>
	<dxi-column dataField="salesOrder.client.name" dataType="string" alignment="left" caption="Client"></dxi-column>
	<dxi-column dataField="dateRequired" dataType="date" format="dd/MM/yyyy" alignment="left"></dxi-column>
	<dxi-column dataField="quantity" dataType="string" alignment="left"></dxi-column>
	<dxi-column dataField="workOrderComplete" dataType="boolean" alignment="left" caption="Complete"></dxi-column>
</dx-data-grid>
<dx-popup [width]="'75%'" [height]="600" [showTitle]="true" [title]="popupTitle" [dragEnabled]="false" [closeOnOutsideClick]="false" [showCloseButton]="false" container=".dx-viewport" [(visible)]="popupVisible">
	<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="before" [options]="popupAddButtonOptions"></dxi-toolbar-item>
	<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="after" [options]="popupCloseButtonOptions"></dxi-toolbar-item>
	<div *dxTemplate="let data of 'content'">
		<div *ngIf="popupTitle == 'Generate Summary PDF'">
			<dx-form class="m-3" [(formData)]="popupGenerateOpsPlanningPdf" [showColonAfterLabel]="true" labelLocation="top">
				<dxi-item dataField="dateRequiredFrom" editorType="dxDateBox" [label]="{ text: 'Date Required From' }" [editorOptions]="{ displayFormat: 'dd/MM/yyyy', type: 'date', showClearButton: true }"></dxi-item>
				<dxi-item dataField="dateRequiredTo" editorType="dxDateBox" [label]="{ text: 'Date Required To' }" [editorOptions]="{ displayFormat: 'dd/MM/yyyy', type: 'date', showClearButton: true }"></dxi-item>
				<dxi-item dataField="clientId" editorType="dxSelectBox" [label]="{ text: 'Client' }" [editorOptions]="{ items: clients, searchEnabled: true, displayExpr:'name', valueExpr:'id', showClearButton: true }"></dxi-item>
				<dxi-item dataField="status" editorType="dxSelectBox" [label]="{ text: 'Status' }" [editorOptions]="{ items: workOrderStatuses, searchEnabled: true, displayExpr:'name', valueExpr:'id', showClearButton: true }"></dxi-item>
			</dx-form>
		</div>
		<div *ngIf="popupTitle == 'Generate Bulk Master Labels PDF'">		
			<dx-data-grid class="data-grid" height="40vh" [dataSource]="bulkWorkOrderLineItems" [remoteOperations]="false" [allowColumnReordering]="false" [allowColumnResizing]="true" [rowAlternationEnabled]="true" [showBorders]="true">
				<dxo-paging [pageSize]="100" [enabled]="false"></dxo-paging>
				<dxo-pager [visible]="false" [showPageSizeSelector]="false" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
				<dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
				<dxo-group-panel [visible]="false"></dxo-group-panel>
				<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
				<dxo-export [enabled]="false"></dxo-export>
				<dxo-editing [allowUpdating]="true" mode="cell" [allowDeleting]="false" [confirmDelete]="false"></dxo-editing>
				<dxi-column [allowEditing]="false" dataField="workOrderId" dataType="text" alignment="left" caption="Work Order" [width]="250"></dxi-column>
				<dxi-column [allowEditing]="false" dataField="partNumber" dataType="string" alignment="left" caption="Part" [width]="250"></dxi-column>
				<dxi-column [allowEditing]="false" dataField="quantity" dataType="text" alignment="left" caption="Quantity" [width]="250"></dxi-column>
				<dxi-column [allowEditing]="false" dataField="dateRequired" dataType="date" format="dd/MM/yyyy" alignment="left"></dxi-column>
				<dxi-column dataField="includeInPdf" dataType="boolean" alignment="left" caption="Include in PDF"></dxi-column>
			</dx-data-grid>
		</div>
	</div>
</dx-popup>
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#grid' }" [(visible)]="gridLoading" [showIndicator]="true" [showPane]="true" [shading]="true"></dx-load-panel>