import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { CheckGroupPermissions } from "@helpers/checkGroupPermissions";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { Client } from "@interfaces/client.interface";
import { ClientLocation } from "@interfaces/clientLocation.interface";
import { DeleteObject } from "@interfaces/deleteObject.interface";
import { DeliveryNote } from "@interfaces/deliveryNote.interface";
import { DeliveryNoteDetailedReportDto } from "@interfaces/deliveryNoteDetailedReportDto.interface";
import { LogExport } from "@interfaces/logExport.interface";
import { AppSettingsService } from "@services/appSettings.service";
import { ClientService } from "@services/client.service";
import { ClientLocationService } from "@services/clientLocation.service";
import { DeliveryNoteService } from "@services/deliveryNote.service";
import { ExportLogService } from "@services/exportLog.service";
import notify from "devextreme/ui/notify";

@Component({
	moduleId: module.id,
	selector: "deliveryNotes",
	styleUrls: ["deliveryNotes.css"],
	templateUrl: "deliveryNotes.html"
})
export class DeliveryNotesAdminComponent {
	clientLocations: ClientLocation[] = [];
	clients: Client[] = [];
	gridLoading = false;
	headerPrimaryButtons: AdminHeaderButton[] = [];
	headerSecondaryButtons: AdminHeaderButton[] = [{ icon: "fal fa-download", method: "generateDetailedReportPopup", text: "Detailed Report" }];
	isSuperAdmin = false;
	itemType = "Delivery Note"
	itemUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	items: DeliveryNote[] = [];
	popupAddButtonOptions: any = { onClick: () => this.generateDetailedReport(), text: "Generate Report" };
	popupCloseButtonOptions: any = { onClick: () => this.closePopup(), text: "Close" };
	popupDeleteObject: DeleteObject = new DeleteObject();
	popupDeliveryNoteDetailedReportDto: DeliveryNoteDetailedReportDto = new DeliveryNoteDetailedReportDto();
	popupTitle = "Generate Detailed Report";
	popupVisible = false;
	title: string;
	
	constructor(private appSettingsService: AppSettingsService, private router: Router, private itemService: DeliveryNoteService, private clientService: ClientService, private clientLocationService: ClientLocationService, private exportLogService: ExportLogService) {
		this.title = "Delivery Notes";
		this.viewDeleted = this.viewDeleted.bind(this);
		this.viewRow = this.viewRow.bind(this);
		this.deleteRowPopup = this.deleteRowPopup.bind(this);
		this.getItems();
		this.getClients();
		this.getClientLocations();

		// If Super Admin
		this.isSuperAdmin = CheckGroupPermissions(appSettingsService, "Super Admin");
		if (this.isSuperAdmin) {
			this.headerPrimaryButtons = [{ method: "viewDeletedDeliveryNotes", text: "Deleted Delivery Notes" }];
		}
	}

	closePopup() {
		this.popupVisible = false;
	}

	deleteRow() {
		if (this.popupDeleteObject.reason === undefined || this.popupDeleteObject.reason === null || this.popupDeleteObject.reason === "") {
			alert("You must specify a reason for deletion.");
			return;
		}
		this.itemService.delete(this.popupDeleteObject).subscribe(
			() =>{
				notify("Successfully Deleted", "success", 5000);
				this.closePopup();
				this.getItems();
			},
			(err) => {
				console.log(err);
				notify("Something Went Wrong!", "Error", 5000);
			},
		);
	}
	
	deleteRowPopup(e: any) {
		this.popupTitle = "Delete Delivery Note";
		this.popupDeleteObject = new DeleteObject();
		this.popupDeleteObject.id = e.row.data.id;
		this.popupVisible = true;
		this.popupAddButtonOptions = { onClick: () => this.deleteRow(), text: "Delete" };
		return false;
	}

	generateDetailedReport() {
		this.itemService.detailedReport(this.popupDeliveryNoteDetailedReportDto).subscribe(
			(response: any) =>{
				const dataType = response.type;
				const binaryData = [];
				binaryData.push(response);
				const downloadLink = document.createElement("a");
				downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
				downloadLink.setAttribute("download", "Delivery_Note_Report.csv");
				document.body.appendChild(downloadLink);
				downloadLink.click();
				this.closePopup();
			}
		);
	}
	
	generateDetailedReportPopup() {
		this.popupTitle = "Generate Detailed Report";
		this.popupDeliveryNoteDetailedReportDto = new DeliveryNoteDetailedReportDto();
		this.popupDeliveryNoteDetailedReportDto.endDate = new Date;
		const startDate = new Date();
		startDate.setDate(this.popupDeliveryNoteDetailedReportDto.endDate.getDate() - 30);
		this.popupDeliveryNoteDetailedReportDto.startDate = startDate;
		this.popupVisible = true;
		this.popupAddButtonOptions = { onClick: () => this.generateDetailedReport(), text: "Generate Report" };

		return false;
	}

	getClientLocations() {
		this.clientLocationService.getAll()
			.subscribe(
				(res: any) =>  {
					this.clientLocations = res.response.items;
				},
				(err) => console.log(err)
			);
	}
	
	getClients() {
		this.clientService.getAll()
			.subscribe(
				(res: any) =>  {
					this.clients = res.response.items;
				},
				(err) => console.log(err)
			);
	}

	getItems() {
		this.gridLoading = true;
		this.itemService.getAll()
			.subscribe(
				(res: any) => {
					this.items = res.response.items;
					this.gridLoading = false;
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				},
			);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	logExport() {
		const logExport = new LogExport();
		logExport.gridName = "Delivery Notes";
		this.exportLogService.logExport(logExport)
			.subscribe(
				() => console.log("Export Logged"),
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
					return false;
				},
			);
	}

	viewDeleted() {
		this.router.navigate([`${this.itemUrl}/deleted`]);
	}

	viewRow(e: any) {
		this.router.navigate([`${this.itemUrl}/${e.row.data.lookupId}`]);
	}
}