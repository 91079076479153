import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { Client } from "@interfaces/client.interface";
import { ClientLocation } from "@interfaces/clientLocation.interface";
import { CreateManualShippingNote } from "@interfaces/createManualShippingNote.interface";
import { LogExport } from "@interfaces/logExport.interface";
import { ManualShippingNotePartRequiredData } from "@interfaces/manualShippingNotePartRequiredData.interface";
import { Part } from "@interfaces/part.interface";
import { ClientLocationService } from "@services/clientLocation.service";
import { ClientService } from "@services/client.service";
import { EdiShippingNoteService } from "@services/ediShippingNote.service";
import { ExportLogService } from "@services/exportLog.service";
import { PartService } from "@services/part.service";
import notify from "devextreme/ui/notify";

@Component({
	moduleId: module.id,
	selector: "ediShippingNotes",
	styleUrls: ["ediShippingNotes.css"],
	templateUrl: "ediShippingNotes.html"
})

export class EdiShippingNotesAdminComponent {
	changes: any = [];
	clientLocations: ClientLocation[] = [];
	clients: Client[] =[];
	editEnabled = true;
	editRowKey: any = null;
	gridLoading = false;
	headerPrimaryButtons: AdminHeaderButton[] = [{ method: "createManualShippingNotePopup", text: "Create Manual Shipping Note" }];
	itemType = "Shipping Note"
	itemUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	items: any[] = [];
	parts: Part[] = [];
	popupAddButtonOptions: any;
	popupClient: Client = new Client();
	popupCloseButtonOptions: any = { onClick: () => this.closePopup(), text: "Close" };
	popupCreateShippingNote: CreateManualShippingNote = new CreateManualShippingNote();
	popupCreateShippingNoteRequiredParts: ManualShippingNotePartRequiredData[] = [];
	popupTitle = "";
	popupVisible = false;
	statuses: any;
	title: string;
	validationErrors: string[] = [];
	
	constructor(private router: Router, private itemService: EdiShippingNoteService, private clientLocationService: ClientLocationService, private clientService: ClientService, private exportLogService: ExportLogService, private partService: PartService) {
		this.title = `${this.itemType}s`;
		this.editRow = this.editRow.bind(this);
		this.viewRow = this.viewRow.bind(this);
		this.getItems();
		this.getClients();
		this.getClientLocations();
		this.getParts();
	}

	boolYesNo(cellInfo: any) {
		if(cellInfo.value === true)
		{
			return "YES";
		}
		else
		{
			return "NO";
		}
	}

	closePopup() {
		this.popupVisible = false;
	}

	createManualShippingNote() {
		this.validationErrors = [];
		this.popupCreateShippingNote.partsRequired = JSON.stringify(this.popupCreateShippingNoteRequiredParts);
		this.itemService.manualCreate(this.popupCreateShippingNote)
			.subscribe(
				(res: any) => {
					this.router.navigate([`${this.itemUrl}/${res.response.lookupId}`]);
				},
				(err: any) => {
					for (const key in err) {
						this.validationErrors.push(err[key]);
					}
					console.log(err);
					notify("An error occurred, please double check all your details.", "Error", 5000);
				},
			);
	}

	createManualShippingNotePopup() {
		this.popupVisible = true;
		this.popupAddButtonOptions = { onClick: () => this.createManualShippingNote(), text: "Save Changes" };
		this.popupCloseButtonOptions = { onClick: () => this.closePopup(), text: "Close" };
		this.popupCreateShippingNote = new CreateManualShippingNote;
		this.popupCreateShippingNoteRequiredParts = [];
		this.popupTitle = "Create Manual Shipping Note";
	}

	editRow(e: any) {
		this.router.navigate([`${this.itemUrl}/${e.row.data.lookupId}_edit`]);
	}

	getClientLocations() {
		this.clientLocationService.getAll()
			.subscribe(
				(res: any) => {
					this.clientLocations = res.response.items;
				},
				(err) => {console.log(err); notify("Something Went Wrong!", "Error", 5000);}
			);
	}

	getClients() {
		this.clientService.getAll()
			.subscribe(
				(res: any) => {
					this.clients = res.response.items;
				},
				(err) => {console.log(err); notify("Something Went Wrong!", "Error", 5000);}
			);
	}

	getItems() {
		this.gridLoading = true;
		this.itemService.getAll()
			.subscribe(
				(res: any) => {
					this.items = res.response.items;
					this.gridLoading = false;
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				},
			);
	}

	getParts() {
		this.partService.getAll()
			.subscribe(
				(res: any) => {
					this.parts = res.response.items;
				},
				(err) => {console.log(err); notify("Something Went Wrong!", "Error", 5000);}
			);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	isEditButtonAvailable(e: any) {
		return e.row.data.isLocked == false;
	}

	logExport() {
		const logExport = new LogExport();
		logExport.gridName = "EDI Shipping Notes";
		this.exportLogService.logExport(logExport)
			.subscribe(
				() => console.log("Export Logged"),
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
					return false;
				},
			);
	}

	viewRow(e: any) {
		this.router.navigate([`${this.itemUrl}/${e.row.data.lookupId}`]);
	}
}