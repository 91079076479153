import { Injectable, Injector } from "@angular/core";
import { environment } from "@environments/environment";
import { CreateAllocationForDeliveryNoteLineItem } from "@interfaces/createAllocationForDeliveryNoteLineItem.interface";
import { Service } from "@services/service";

@Injectable()
export class AllocationService extends Service {
	constructor(injector: Injector) {
		super("Allocation", injector);
	}

	// API	
	app_getEmpty() {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/App_GetEmpty`);
	}
	
	app_getIncomplete() {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/App_GetIncomplete`);
	}

	createForDeliveryNote(data: CreateAllocationForDeliveryNoteLineItem) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/CreateForDeliveryNote`, data);
	}

	getFullDetails() {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetFullDetails`);
	}
}