import { Injectable, Injector } from "@angular/core";
import { environment } from "@environments/environment";
import { DeleteObject } from "@interfaces/deleteObject.interface";
import { DeliveryNoteDetailedReportDto } from "@interfaces/deliveryNoteDetailedReportDto.interface";
import { Service } from "@services/service";

@Injectable()
export class DeliveryNoteService extends Service {
	constructor(injector: Injector) {
		super("DeliveryNote", injector);
	}

	// API
	delete(data: DeleteObject) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/Delete`, data );
	}

	detailedReport(data: DeliveryNoteDetailedReportDto) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/DetailedReport`, data, { responseType: "blob" as "json"} );
	}

	generateAndPreviewPdf(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GenerateAndPreviewPdf/${id}`, { responseType: "blob" as "json"} );
	}

	getBySalesOrderId(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetBySalesOrderId/${id}`);
	}

	getDeleted() {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/Deleted`);
	}

	markSent(id: number) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/MarkSent/${id}`, {});
	}
}