import { AssetEventType } from "@enums/assetEventType.enum";
import { Model } from "@interfaces/model.interface";

export interface AssetEvent extends Model {
	assetId: number;
	eventDate: Date;
	eventType: AssetEventType;
	reporterId: number;
}

export class AssetEvent implements AssetEvent {
	eventDate = new Date();

	constructor(data?: Partial<AssetEvent>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}