export interface CreateAllocationForDeliveryNoteLineItem {
	deliveryNoteId: number;
	salesOrderPartId: number;
}

export class CreateAllocationForDeliveryNoteLineItem implements CreateAllocationForDeliveryNoteLineItem {
	constructor(data?: Partial<CreateAllocationForDeliveryNoteLineItem>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}