import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { LogExport } from "@interfaces/logExport.interface";
import { AllocationService } from "@services/allocation.service";
import { ExportLogService } from "@services/exportLog.service";
import notify from "devextreme/ui/notify";

@Component({
	moduleId: module.id,
	selector: "allocations",
	styleUrls: ["allocations.css"],
	templateUrl: "allocations.html"
})
export class AllocationsAdminComponent {
	gridLoading = false;
	headerPrimaryButtons: AdminHeaderButton[] = [];
	headerSecondaryButtons: AdminHeaderButton[] = [];
	itemType = "Allocation"
	itemUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	items: any = [];
	title: string;

	constructor(private router: Router, private exportLogService: ExportLogService, private itemService: AllocationService) {
		this.title = "Allocations";
		this.getItems();
		this.viewRow = this.viewRow.bind(this);
	}

	getItems() {
		this.gridLoading = true;
		this.itemService.getFullDetails()
			.subscribe(
				(res: any) => {
					this.items = res.response;
					this.gridLoading = false;
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				},
			);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	logExport() {
		const logExport = new LogExport();
		logExport.gridName = "Allocations";
		this.exportLogService.logExport(logExport)
			.subscribe(
				() => console.log("Export Logged"),
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
					return false;
				},
			);
	}

	viewRow(e: any) {
		this.router.navigate([`${this.itemUrl}/${e.row.data.id}`]);
	}
}