import { MaterialShape } from "@enums/materialShape.enum";
import { PartRelationshipType } from "@enums/partRelationshipType.enum";
import { Model } from "@interfaces/model.interface";

export interface Part extends Model {
	alertNumberIPSW: string;
	availableStock: number;
	backfillStock: number;
	batchQuantity: number;
	blankDimensionsConfirmed: boolean;
	blankLength: number;
	blankWidth: number;
	bplVersion: number;
	cadFiles: string;
	clientContactId?: number;
	clientId: number;
	consumablesRequired: string;
	cumulativeProductionDelivery: number;
	deliveryArrears: number;
	description: string;
	eclNumber: string;
	engineeringContactId?: number;
	fixingsRequired: string;
	gauge: number;
	masterPartId?: number;
	materialId?: number;
	materialShape: MaterialShape;
	maximumStockLevel: number;
	minimumStockLevel: number;
	needsCompleting: boolean;
	outsourcedSpecifications: string;
	packFactor: number;
	packFactorExternal: number;
	packagingExampleUrl: string;
	packagingExampleUrlExternal: string;
	packagingExternalId?: number;
	packagingId?: number;
	packagingSpecification: string;
	packagingSpecificationExternal: string;
	partNumber: string;
	partRelationship: PartRelationshipType;
	pitch?: number;
	productionPrice: number;
	projectId: number;
	projectManagerId?: number;
	revisionLevel?: number;
	router: string;
	stockLeadTime: number;
	stockThreshold: number;
	supportingDocuments: string;
	technicalDrawings: string;
	tileImageUrl: string;
	weight: number;
	wipStock: number;
}

export class Part implements Part {	
	blankDimensionsConfirmed = false;
	bplVersion = 0;
	cadFiles = "";
	deliveryArrears = 0;
	description = "";
	fixingsRequired = "";
	materialShape = MaterialShape.Sheet;
	needsCompleting = true;
	packFactor = 0;
	packFactorExternal = 0;
	packagingExampleUrl = "";
	packagingExampleUrlExternal = "";
	packagingSpecification = "";
	packagingSpecificationExternal = "";
	partNumber = "";
	partRelationship = PartRelationshipType.Standard;
	router = "";
	supportingDocuments = "";
	technicalDrawings = "";
	tileImageUrl = "";
	weight = 0;

	constructor(data?: Partial<Part>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}