import { Component, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { FirewallLogFailureReason } from "@enums/firewallLogFailureReason.enum";
import { ProductionStatus } from "@enums/productionStatus.enum";
import { environment } from "@environments/environment";
import { GetProductionStatusColor } from "@helpers/getProductionStatusColor";
import { GetPropertiesFromEnum } from "@helpers/getPropertiesFromEnum";
import { FirewallLog } from "@interfaces/firewallLog.interface";
import { InterfaceDevice } from "@interfaces/interfaceDevice.interface";
import { Machine } from "@interfaces/machine.interface";
import { Part } from "@interfaces/part.interface";
import { WorkOrderStage } from "@interfaces/workOrderStage.interface";
import { AppSettingsService } from "@services/appSettings.service";
import { FirewallLogService } from "@services/firewallLog.service";
import { InterfaceDeviceService } from "@services/interfaceDevice.service";
import { MachineService } from "@services/machine.service";
import { WorkOrderStageService } from "@services/workOrderStage.service";
import notify from "devextreme/ui/notify";

@Component({
	encapsulation: ViewEncapsulation.None,
	moduleId: module.id,
	selector: "operator",
	styleUrls: ["operator.css"],
	templateUrl: "operator.html"
})
export class OperatorComponent {
	areasOfIssue: any[] = [];
	availableStages: WorkOrderStage[] = [];
	clientName = "";
	companyName = "";
	copyright = "";
	currentStatus = ProductionStatus.Idle;
	currentStatusColor = "";
	interfaceDevice: InterfaceDevice = new InterfaceDevice();
	interfaceDeviceId = 0;
	isLoading = false;
	machine = new Machine();
	machineId = 0;
	nokReasons: any[] = [];
	operatorName = "";
	part = new Part();
	partImage = "";
	processName = "";
	profileId = 0;
	quantityComplete = 0;
	quantityEntered = 0;
	quantityNok = 0;
	quantityRequired = 0;
	runningStages: WorkOrderStage[] = [];
	selectedArea = "";
	selectedPaint = false;
	selectedProduction = false;
	selectedReason: any;
	showComplete = false;
	showFirewall = false;
	showMode = false;
	showNok = false;
	showPopup = false;
	showSuperLogin = false;
	showSupervisor = false;
	stageId = 0;
	stageNumber = "";
	workOrderId = 0;

	constructor(private route: ActivatedRoute, private interfaceDeviceService: InterfaceDeviceService, private workOrderStageService: WorkOrderStageService, private machineService: MachineService, private appSettingsService: AppSettingsService, private firewallLogService: FirewallLogService) {
		this.nokReasons = GetPropertiesFromEnum(FirewallLogFailureReason);
		this.areasOfIssue = [
			"CAD Issue",
			"Health and Safety",
			"Issue Found at Customer",
			"Maintenance Issue",
			"Person / Training Issue",
			"Process wrong",
			"Process - Assembly",
			"Process - 5 Axis Issue",
			"Process - Flat Laser issue",
			"Process - Goods-In",
			"Process - Press Issue",
			"Process - Press Brake issue",
			"Process - Weld Issue",
			"Process - Spot Weld Issue",
			"Process - Kingsland Issue",
			"Process - Fab/Deburr Issue",
			"Process - Firewall Issue",
			"Process - Logistics issue",
			"Process - Quality Issue",
			"Process - Toolroom",
			"Supplier - Material Issue",
			"Supplier - Outsource Issue",
		];
		this.currentStatusColor = GetProductionStatusColor(ProductionStatus.Idle);
		this.route.params
			.subscribe((params) => {
				this.interfaceDeviceId = Number.parseInt(params.id);
				this.isLoading = true;
				this.showPopup = true;
				this.getInterfaceDevice();
				setTimeout(() => {
					this.isLoading = false;
					this.showPopup = false;
				}, 2000);
			});
	}

	changeMode(status: ProductionStatus) {
		this.submitProductionMode(status);
		this.toggleMode();
	}

	getData() {
		if (this.interfaceDevice.machineId === undefined || this.interfaceDevice.machineId === null) {
			return;
		}
		this.machineService.app_OpsPlannerByMachineId(this.interfaceDevice.machineId)
			.subscribe(
				(res: any) => {
					if (res.response.length > 1) {
						this.machine = res.response[0];
						this.machineId = res.response[0].machineId;
						this.currentStatus = res.response[0].productionStatus;
						this.currentStatusColor = this.renderStatusColor();
						this.availableStages = res.response[0].availableStages;
						this.runningStages = res.response[0].runningStages;
						let profileId = 0;
						console.log(this.runningStages.length);
						if (this.runningStages.length > 0) {
							this.stageId = this.runningStages[0].id;
							this.availableStages = this.runningStages;
							this.partImage = res.response[0].runningStages[0].partImage;
							this.part = res.response[0].runningStages[0];
							this.clientName = res.response[0].runningStages[0].clientName;
							this.operatorName = res.response[0].runningStages[0].assignedUserProfileFullName;
							this.stageNumber = res.response[0].runningStages[0].operationNumber;
							this.machineId = res.response[0].runningStages[0].machineId;
							profileId = res.response[0].runningStages[0].assignedUserProfileId;
							this.workOrderId = res.response[0].runningStages[0].workOrderId;
							this.quantityComplete = res.response[0].runningStages[0].quantityCompleted;
							this.quantityRequired = res.response[0].runningStages[0].quantityRequired;
						} else {
							this.partImage = res.response[0].availableStages[0].partImage;
							this.clientName = res.response[0].availableStages[0].clientName;
							this.part = res.response[0].availableStages[0];
							this.stageNumber = res.response[0].availableStages[0].operationNumber;
							this.stageId = res.response[0].availableStages[0].id;
							this.machineId = res.response[0].availableStages[0].machineId;
							profileId = res.response[0].availableStages[0].assignedUserProfileId;
							this.operatorName = res.response[0].availableStages[0].assignedUserProfileFullName;
							this.workOrderId = res.response[0].availableStages[0].workOrderId;
							this.quantityComplete = res.response[0].availableStages[0].quantityCompleted;
							this.quantityRequired = res.response[0].availableStages[0].quantityRequired;
						}
						this.appSettingsService.set("profileId", profileId);
						this.profileId = profileId;
					}
				},
				(err: any) => {
					console.log(err);
					notify("Something Went Wrong!", "Error", 5000);
				}
			);
	}

	getEnvironmentProperty(property: string) {
		// @ts-ignore // Required to get around having to iterate through the object
		return environment["build"][property];
	}

	getInterfaceDevice() {
		this.interfaceDeviceService.app_InterfaceById(this.interfaceDeviceId)
			.subscribe(
				(res: any) => {
					this.interfaceDevice = res.response;
					this.getData();
				},
				(err: any) => {
					console.log(err);
					notify("Something Went Wrong!", "Error", 5000);
				}
			);
	}

	increaseScrap() {
		this.workOrderStageService.increaseScrapAmount(this.availableStages[0].id,this.quantityNok)
			.subscribe(
				() => {
					// notify("Stage Paused due to incomplete quantity", "success", 5000);
					this.reloadPage();
				},
				(err) => {
					notify("Something went wrong!", "error", 5000);
					console.log(err);
				}
			);
	}

	pauseWorkOrder() {
		this.workOrderStageService.app_togglePause(this.availableStages[0].id)
			.subscribe(
				() => {
					notify("Stage Paused due to incomplete quantity", "success", 5000);
					this.reloadPage();
				},
				(err) => {
					notify("Something went wrong!", "error", 5000);
					console.log(err);
				}
			);
	}

	reloadPage() {
		this.isLoading = true;
		this.showPopup = true;
		this.getData();
		setTimeout(() => {
			this.isLoading = false;
			this.showPopup = false;
		}, 2000);
	}

	renderStatusColor() {
		return GetProductionStatusColor(this.currentStatus);
	}

	submitArea() {
		console.log(this.selectedArea);
	}

	submitCompleted() {
		let firewall = false;
		this.toggleComplete();
		this.isLoading = true;
		this.showPopup = true;
		if (this.availableStages[0].processId == 6 || this.availableStages[0].processId == 53) {
			this.quantityEntered = this.quantityEntered + this.quantityNok;
			firewall = true;
		}
		this.workOrderStageService.completedQuantityApiKey(this.availableStages[0].id, this.quantityEntered - this.quantityComplete)
			.subscribe(
				() => {
					notify("Part Complete Submitted Successfully!", "success", 5000);
					if (firewall) {
						this.showFirewall = false;
						this.showNok = false;
						if ((this.quantityComplete + this.quantityNok) != this.quantityRequired) {
							//Pause Work Order.
							this.pauseWorkOrder();
						}
						if(this.quantityNok > 0){
							this.increaseScrap();
						}
					}
					this.reloadPage();
				},
				(err) => {
					notify("Something went wrong!", "error", 5000);
					console.log(err);
				}
			);
	}

	submitFirewall() {
		if (this.quantityNok > 0) {
			this.showFirewall = false;
			this.showNok = true;
		} else {
			const log = new FirewallLog();
			log.numberOfPartsChecked = this.quantityEntered;
			log.numberOfPartsFailed = this.quantityNok;
			log.operatorId = this.profileId;
			log.partId = this.part.id;
			this.submitFirewallLogService(log);
			this.showFirewall = false;
			this.showPopup = false;

		}
	}

	submitFirewallLog() {
		// Create log.
		const log = new FirewallLog();
		log.areaOfIssue = this.selectedArea;
		log.failureReason = this.selectedReason;
		log.numberOfPartsChecked = this.quantityEntered;
		log.numberOfPartsFailed = this.quantityNok;
		log.numberOfPartsReworkable = 0;
		log.isProduction = this.selectedProduction == true ? true : false;
		log.prePaint = this.selectedPaint == true  ? true : false;
		log.asnNumber = "";
		log.operatorId = this.profileId;
		log.partId = this.part.id;

		this.submitFirewallLogService(log);

	}

	submitFirewallLogService(log: FirewallLog) {
		this.firewallLogService.appCreateFirewallLog(log)
			.subscribe(
				() => {

					this.showPopup = false;
					this.showNok = false;
					this.showFirewall = false;
					this.submitCompleted();
				},
				(err) => {
					notify("Something went wrong!", "error", 5000);
					console.log(err);
				}
			);
	}

	submitIsProduction() {
		console.log(this.selectedProduction);
	}



	submitPaint() {
		console.log(this.selectedPaint);
	}

	submitProductionMode(prodStatus: ProductionStatus) {
		this.machineService.updateProductionStatus(this.machineId, prodStatus)
			.subscribe(
				() => {
					notify("Production Mode Changed Successfully!", "success", 5000);
					this.reloadPage();
				},
				(err) => {
					notify("Something went wrong!", "error", 5000);
					console.log(err);
				}
			);
	}

	submitReason() {
		console.log(this.selectedReason);
	}

	submitStillageRequest() {
		this.machineService.requestStillageForStage(this.stageId)
			.subscribe(
				() => {
					notify("Request Submitted Successfully!", "success", 10000);
				},
				(err) => {
					notify("Something went wrong!", "error", 5000);
					console.log(err);
				}
			);
		this.getData();
	}

	submitSupervisorRequest() {
		this.machineService.requestSupervisorForStage(this.stageId)
			.subscribe(
				() => {
					notify("Request Submitted Successfully!", "success", 5000);
				},
				(err) => {
					notify("Something went wrong!", "error", 5000);
					console.log(err);
				}
			);
		this.getData();
	}

	/* Toggles */
	toggleComplete() {
		if (this.availableStages[0].processId == 6 || this.availableStages[0].processId == 53) {
			this.showFirewall = !this.showFirewall;
			this.showPopup = !this.showPopup;
		} else {
			this.showComplete = !this.showComplete;
			this.showPopup = !this.showPopup;
		}
	}

	toggleMode() {
		this.showMode = !this.showMode;
		this.showPopup = !this.showPopup;
	}

	toggleSupervisor() {
		if (!this.showSupervisor) {
			this.submitSupervisorRequest();
		}
		this.showSupervisor = !this.showSupervisor;
		this.showPopup = !this.showPopup;
	}

	updateNokQuantity() {
		this.quantityNok = this.quantityRequired - this.quantityEntered;
	}

	windowWidthCheck() {
		return window.innerWidth >= 768;
	}

}