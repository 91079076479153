import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { LogExport } from "@interfaces/logExport.interface";
import { UserProfile } from "@interfaces/userProfile.interface";
import { AuthService } from "@services/auth.service";
import { ExportLogService } from "@services/exportLog.service";
import { QmsDocumentService } from "@services/qmsDocument.service";
import { UserProfileService } from "@services/userProfile.service";
import notify from "devextreme/ui/notify";

@Component({
	moduleId: module.id,
	selector: "qmsDocuments",
	styleUrls: ["qmsDocuments.css"],
	templateUrl: "qmsDocuments.html"
})
export class QmsDocumentsAdminComponent {
	deleteEnabled = true;
	editEnabled = true;
	gridLoading = false;
	headerPrimaryButtons: AdminHeaderButton[] = [];
	isSuperAdmin = false;
	itemType = "QMS Document";
	itemUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	items: any[] = [];
	people: UserProfile[] = [];
	title: string;
	validationErrors: string[] = [];
	
	constructor(private router: Router, private itemService: QmsDocumentService, private authService: AuthService, private exportLogService: ExportLogService, private userProfileService: UserProfileService) {
		this.headerPrimaryButtons.push({ icon: "fal fa-plus", method: "addNewItem", text: `New ${this.itemType}` });
		this.title = `${this.itemType}s`;
		this.viewRow = this.viewRow.bind(this);
		this.editRow = this.editRow.bind(this);
		this.deleteRow = this.deleteRow.bind(this);
		this.getItems();
		this.getPeople();
		this.getSuperAdminStatus();
	}

	addNewItem() {
		this.router.navigate([`${this.itemUrl}/create`]);
	}

	deleteRow(e: any) {
		if (this.isSuperAdmin == false) {
			return;
		}

		if (confirm("Are you sure you want to delete this item?") == true)
		{
			this.itemService.deleteSingleById(e.row.data.id)
				.subscribe(
					() => {
						notify(this.itemType + " deleted!", "success", 5000);
						this.getItems();
					},
					(err) => {
						console.log(err);
						notify("Something went wrong!", "error", 5000);
					},
				);
		}
	}

	editRow(e: any) {
		this.router.navigate([`${this.itemUrl}/${e.row.data.lookupId}_edit`]);
	}

	getItems() {
		this.gridLoading = true;
		this.itemService.getAll()
			.subscribe(
				(res: any) => {
					this.items = res.response.items;
					this.gridLoading = false;
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				},
			);
	}
	
	getPeople() {
		this.userProfileService.getActiveUsers()
			.subscribe(
				(res: any) =>  {
					this.people = res.response;
					this.people.sort((a,b) => a.fullName.localeCompare(b.fullName));
				},
				(err) => console.log(err)
			);
	}
	
	getSuperAdminStatus() {
		this.authService.getCurrentUserIsSuperAdmin()
			.subscribe(
				(res: any) => {
					this.isSuperAdmin = res.response;
				}
			);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	logExport() {
		const logExport = new LogExport();
		logExport.gridName = "QMS Documents";
		this.exportLogService.logExport(logExport)
			.subscribe(
				() => console.log("Export Logged"),
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
					return false;
				},
			);
	}
	
	qmsId(cellInfo: any){		
		return "QMS-" + cellInfo.value;
	}

	viewRow(e: any) {
		this.router.navigate([`${this.itemUrl}/${e.row.data.lookupId}`]);
	}
}