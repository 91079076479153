import { Component, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { Allocation } from "@interfaces/allocation.interface";
import { Client } from "@interfaces/client.interface";
import { ClientLocation } from "@interfaces/clientLocation.interface";
import { CreateAllocationWorkOrder } from "@interfaces/createAllocationWorkOrder.interface";
import { Part } from "@interfaces/part.interface";
import { UserProfile } from "@interfaces/userProfile.interface";
import { AllocationService } from "@services/allocation.service";
import { AllocationWorkOrderService } from "@services/allocationWorkOrder.service";
import { ClientService } from "@services/client.service";
import { ClientLocationService } from "@services/clientLocation.service";
import { DeliveryNoteService } from "@services/deliveryNote.service";
import { PartService } from "@services/part.service";
import { UserProfileService } from "@services/userProfile.service";
import notify from "devextreme/ui/notify";

@Component({
	encapsulation: ViewEncapsulation.None,
	moduleId: module.id,
	selector: "allocations-id",
	styleUrls: ["allocations-id.css"],
	templateUrl: "allocations-id.html"
})
export class AllocationsIdAdminComponent {
	allocationWorkOrders: any = [];
	clientLocations: ClientLocation[] = [];
	clients: Client[] = [];
	headerPrimaryButtons: AdminHeaderButton[] = [];
	headerSecondaryButtons: AdminHeaderButton[] = [];
	headerTertiaryButtons: AdminHeaderButton[] = [];
	item: Allocation = new Allocation();
	itemType = "Allocation";
	itemUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	parts: Part[] = [];
	people: UserProfile[] = [];
	popupCloseButtonOptions: any = { onClick: () => this.closePopup(), text: "Close" };
	popupCreateAllocationWorkOrder: CreateAllocationWorkOrder = new CreateAllocationWorkOrder();
	popupSaveButtonOptions: any;
	popupTitle = "";
	popupVisible = false;
	returnUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	title: string = "View " + this.itemType;

	constructor(private router: Router, private route: ActivatedRoute, private itemService: AllocationService, private allocationWorkOrderService: AllocationWorkOrderService, private clientLocationService: ClientLocationService, private clientService: ClientService, private deliveryNoteService: DeliveryNoteService, private partService: PartService, private userProfileService: UserProfileService) {
		this.getClients();
		this.getClientLocations();
		this.getParts();
		this.getPeople();
		this.buildButtons();

		this.route.params.subscribe((params) => {
			this.getItem(params.id);
			this.getAllocationWorkOrders(params.id);
		});

		this.createAllocationWorkOrderPopup = this.createAllocationWorkOrderPopup.bind(this);

		this.viewWorkOrder = this.viewWorkOrder.bind(this);
		this.viewPart = this.viewPart.bind(this);
	}

	buildButtons() {
		this.headerPrimaryButtons = [
			{ method: "close", text: "Close" }
		];
		this.headerSecondaryButtons = [
			{ method: "createAllocationWorkOrderPopup", text: "Allocate from Work Order" }
		];
		this.headerTertiaryButtons = [
			{ method: "viewDeliveryNote", text: "View Delivery Note" },
			{ method: "viewPart", text: "View Part" }
		];
	}

	close() {
		this.router.navigate([`/${this.returnUrl}/`]);
	}

	closePopup() {
		this.popupVisible = false;
	}

	createAllocationWorkOrder() {
		this.allocationWorkOrderService.createForWorkOrder(this.popupCreateAllocationWorkOrder)
			.subscribe(
				() => {
					this.popupVisible = false;
					this.getItem(this.item.id);
					this.getAllocationWorkOrders(this.item.id);
				},
				(err: any) => {
					notify("Could not add work order. Please check the details are valid and this work order has been completed.", "Error", 5000);
					console.log(err);
				}
			);
	}

	createAllocationWorkOrderPopup() {
		this.popupCreateAllocationWorkOrder = new CreateAllocationWorkOrder();
		this.popupCreateAllocationWorkOrder.allocationId = this.item.id;
		this.popupTitle = "Allocate from Work Order";
		this.popupVisible = true;
		this.popupSaveButtonOptions = { onClick: () => this.createAllocationWorkOrder(), text: "Allocate" };
	}
	
	getAllocationWorkOrders(id: number) {
		this.allocationWorkOrderService.forAllocation(id)
			.subscribe(
				(res: any) =>  {
					this.allocationWorkOrders = res.response;
				},
				(err) => console.log(err)
			);
	}

	getClientLocations() {
		this.clientLocationService.getAll()
			.subscribe(
				(res: any) => {
					this.clientLocations = res.response.items;
				},
				(err) => console.log(err)
			);
	}

	getClients() {
		this.clientService.getAll()
			.subscribe(
				(res: any) => {
					this.clients = res.response.items;
				},
				(err) => console.log(err)
			);
	}

	getItem(id: number) {
		this.itemService.getSingleById(id)
			.subscribe(
				(res: any) => {
					this.item = res.response;
				},
				(err) => {
					console.log(err);
					notify("Something Went Wrong!", "Error", 5000);
				},
			);
	}
	
	getParts() {
		this.partService.getAll()
			.subscribe(
				(res: any) =>  {
					this.parts = res.response.items;
				},
				(err) => console.log(err)
			);
	}
	
	getPeople() {
		this.userProfileService.getActiveUsers()
			.subscribe(
				(res: any) =>  {
					this.people = res.response;
					this.people.sort((a,b) => a.fullName.localeCompare(b.fullName));
				},
				(err) => console.log(err)
			);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	viewDeliveryNote() {
		if (this.item.deliveryNoteId === undefined) {
			return;
		}
		this.deliveryNoteService.getSingleById(this.item.deliveryNoteId)
			.subscribe(
				(res: any) =>  {
					const deliveryNote = res.response;
					this.router.navigate([`admin/deliveryNotes/${deliveryNote.lookupId}`]);
				},
				(err) => console.log(err)
			);
	}

	viewPart() {
		this.router.navigate([`admin/parts/${this.item.partId}`]);
	}

	viewWorkOrder(e: any) {
		this.router.navigate([`admin/workOrders/${e.row.data.workOrderId}`]);
	}
}