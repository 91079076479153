<admin-header [primaryButtons]="headerPrimaryButtons" [secondaryButtons]="headerSecondaryButtons" [tertiaryButtons]="headerTertiaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<div *ngIf="item.isSent" class="greatNews">ASN has been sent for this shipping note!</div>
<div *ngIf="item.showPalletBalanceWarning" class="badNews">WARNING: This shipping note has unbalanced pallets and needs extra care when packing.</div>
<div class="form-container">
	<dx-form class="m-3" [(formData)]="item" [showColonAfterLabel]="true" labelLocation="top" [colCount]="2" [readOnly]="readOnly">
		<dxi-item itemType="group" caption="Details" [colSpan]="2" [colCount]="6">
			<dxi-item [colSpan]="2" [label]="{ text: 'Client' }"><span>{{client.name}}</span></dxi-item>
			<dxi-item [colSpan]="2" [label]="{ text: 'Location' }"><span>{{clientLocation.name}}</span></dxi-item>
			<dxi-item [colSpan]="2" [label]="{ text: 'Dock' }"><span>{{item.dock}}</span></dxi-item>
			<dxi-item dataField="asnNumber" [colSpan]="3" [label]="{ text: 'ASN Number' }" [editorOptions]="{ readOnly: true }"></dxi-item>
			<dxi-item dataField="shippingReferenceNumber" [colSpan]="3" [label]="{ text: 'Shipping Reference Number' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
			<dxi-item dataField="dateRequired" editorType="dxDateBox" dataType="date" [colSpan]="3" [label]="{ text: 'Date Required' }" [editorOptions]="{ readOnly: true, displayFormat: 'dd/MM/yyyy' }"></dxi-item>
			<dxi-item dataField="estimatedArrivalDate" editorType="dxDateBox" dataType="date" [colSpan]="3" [label]="{ text: 'Arrival Date' }" [editorOptions]="{ displayFormat: 'dd/MM/yyyy' }"></dxi-item>
		</dxi-item>
		<dxi-item itemType="group" caption="Items" [colSpan]="2" [colCount]="4">
			<dx-data-grid class="data-grid" [dataSource]="shippingNoteItems" [remoteOperations]="false" [allowColumnReordering]="true" [allowColumnResizing]="true" [rowAlternationEnabled]="true" [showBorders]="true" (onRowPrepared)="onLineItemRowPrepared($event)">
				<dxo-paging [pageSize]="100"></dxo-paging>
				<dxo-state-storing [enabled]="true" type="localStorage" storageKey="edismItems"></dxo-state-storing>
				<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
				<dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
				<dxo-group-panel [visible]="true"></dxo-group-panel>
				<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
				<dxo-export [enabled]="true"></dxo-export>
				<dxi-column *ngIf="item.isLocked === false" type="buttons">
					<dxi-button hint="Edit" icon="fal fa-pencil" [onClick]="editLineItemPopup"></dxi-button>
				</dxi-column>
				<dxi-column dataField="ediOrder.orderReference" dataType="string" alignment="left" caption="Order"></dxi-column>
				<dxi-column dataField="ediOrder.orderedQuantity" dataType="number" alignment="left" caption="Ordered"></dxi-column>
				<dxi-column dataField="ediOrder.part.partNumber" dataType="string" alignment="left" caption="Part No."></dxi-column>
				<dxi-column dataField="ediOrder.part.packFactorExternal" dataType="string" alignment="left" caption="Pack Factor"></dxi-column>
				<dxi-column dataField="quantity" dataType="number" alignment="left"></dxi-column>
				<dxi-column dataField="ediOrder.part.packagingExternal.name" dataType="string" alignment="left" caption="Container Type"></dxi-column>
				<dxi-column dataField="containerQuantity" dataType="number" alignment="left" caption="Containers"></dxi-column>
				<dxi-column dataField="netWeight" dataType="number" alignment="left" caption="Net Kg"></dxi-column>
				<dxi-column dataField="grossWeight" dataType="number" alignment="left" caption="Gross Kg"></dxi-column>
			</dx-data-grid>
		</dxi-item>
	</dx-form>
</div>
<dx-popup [width]="500" [height]="300" [showTitle]="true" [title]="popupTitle" [dragEnabled]="false" [closeOnOutsideClick]="false" [showCloseButton]="false" container=".dx-viewport" [(visible)]="popupVisible">
	<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="before" [options]="popupAddButtonOptions"></dxi-toolbar-item>
	<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="after" [options]="popupCloseButtonOptions"></dxi-toolbar-item>
		<dx-form class="m-3" [(formData)]="popupLineItem" [showColonAfterLabel]="true" labelLocation="top">
			<dxi-item dataField="quantity"><dxi-validation-rule type="required" message="Quantity is required"></dxi-validation-rule></dxi-item>
		</dx-form>
</dx-popup>