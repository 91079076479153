import { MaterialPurpose } from "@enums/materialPurpose.enum";
import { PurchaseType } from "@enums/purchaseType.enum";
import { Supplier } from "@interfaces/supplier.interface";
import { Model } from "@interfaces/model.interface";

export interface PurchaseLog extends Model {
	coC: number;
	cocNo: string;
	completedBy: number;
	contactEmail: string;
	contactName: string;
	correctDelivery: boolean;
	deliveryNote: number;
	dueDate: Date;
	email: string;
	freightAmount: number;
	lineItems: string; // JSON string
	materialPurpose: MaterialPurpose;
	orderRecieved: boolean;
	packedIn: string;
	partId?: number;
	printerCode: string;
	projectId: number;
	purchaseType: PurchaseType
	specialInstructions: string;
	stateOfDelivery: number;
	supplier: Supplier;
	supplierContactId: number;
	supplierId: number;
	supportingDocuments: string;
	totalCost: number;
	totalQuantity: number;
	userProfileId: number;
	weekNo: number;
	weight:number;
	workReferenceNo: string;
}

export class PurchaseLog implements PurchaseLog {
	cocNo = "";
	contactEmail = "";
	contactName = "";
	correctDelivery = false;
	dueDate = new Date();
	email = "";
	lineItems = ""; // JSON string
	materialPurpose = MaterialPurpose.Other;
	orderRecieved = false;
	printerCode = "";
	purchaseType = PurchaseType.Consumables;
	supportingDocuments = "";
	totalCost = 0;
	totalQuantity = 0;
	weekNo = 0;
	workReferenceNo = "";

	constructor(data?: Partial<PurchaseLog>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}