import { Component } from "@angular/core";
import { CheckGroupPermissions } from "@helpers/checkGroupPermissions";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { Client } from "@interfaces/client.interface";
import { ImportEdiOrdersCSV } from "@interfaces/importEdiOrdersCSV.interface";
import { ReduceArrearsOnParts } from "@interfaces/reduceArrearsOnParts.interface";
import { ReduceArrearsOnPartsLineItem } from "@interfaces/reduceArrearsOnPartsLineItem.interface";
import { AppSettingsService } from "@services/appSettings.service";
import { ClientService } from "@services/client.service";
import { EdiMessageService } from "@services/ediMessage.service";
import { EdiOrderService } from "@services/ediOrder.service";
import { FileService } from "@services/file.service";
import { PartService } from "@services/part.service";
import notify from "devextreme/ui/notify";

@Component({
	moduleId: module.id,
	selector: "ediOrders",
	styleUrls: ["ediOrders.css"],
	templateUrl: "ediOrders.html"
})
export class EdiOrdersAdminComponent {
	clients: Client[] = [];
	deleteEnabled = true;
	editEnabled = true;
	headerPrimaryButtons: AdminHeaderButton[] = [];
	isSuperAdmin = false;
	itemType = "Part Order"
	itemUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	orderTable: any;
	popupAddButtonOptions: any = { onClick: () => this.importCSV(), text: "Import" };
	popupCloseButtonOptions: any = { onClick: () => this.closePopup(), text: "Close" };
	popupImportDetails: ImportEdiOrdersCSV = new ImportEdiOrdersCSV();
	popupReduceArrearsOnParts: ReduceArrearsOnParts = new ReduceArrearsOnParts();
	popupTitle = "Import Edi Orders Message CSV";
	popupVisible = false;
	reduceArrearsOnPartsLineItems: ReduceArrearsOnPartsLineItem[] = [];
	selectedClient: Client =  new Client();
	title: string;
	validationErrors: string[] = [];

	constructor(private appSettingsService: AppSettingsService, private itemService: EdiOrderService, private ediMessageService: EdiMessageService, private clientService: ClientService, private fileService: FileService, private partService: PartService) {
		this.title = `${this.itemType}s`;
		this.isSuperAdmin = CheckGroupPermissions(appSettingsService, "Super Admin");
		this.getClients();
		this.uploadCSVForImport = this.uploadCSVForImport.bind(this);
	}

	buildButtons() {
		this.headerPrimaryButtons = [];
		this.headerPrimaryButtons.push({ method: "downloadPickListPdf", text: "Pick List" });
		this.headerPrimaryButtons.push({ icon: "fal fa-upload", method: "importCSVPopup", text: "Import Edi Orders Message CSV" });

		if (this.selectedClient != null && this.selectedClient.sendASNsViaEDI === false && this.reduceArrearsOnPartsLineItems.length > 0) {
			this.headerPrimaryButtons.push({ method: "reduceArrearsPopup", text: "Reduce Arrears" });
		}
	}

	closePopup() {
		this.popupVisible = false;
	}

	downloadPickListPdf() {
		const date = new Date();
		this.itemService.pickList().subscribe(
			(response: any) =>{
				const dataType = response.type;
				const binaryData = [];
				binaryData.push(response);
				const downloadLink = document.createElement("a");
				downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
				downloadLink.setAttribute("download", date.toDateString() + "_EDI_Picklist.pdf");
				document.body.appendChild(downloadLink);
				downloadLink.click();
			}
		);
	}

	getClients() {
		this.clientService.getEdiClients()
			.subscribe(
				(res: any) => {
					this.clients = res.response;
					this.clients.sort((a,b) => a.name.localeCompare(b.name));
					const jlrIndex = this.clients.findIndex(c => c.id === 118);
					if (jlrIndex > 0) {
						const jlrEntry = this.clients.splice(jlrIndex, 1);
						this.clients.unshift(jlrEntry[0]);
					}

					this.selectedClient = this.clients[0];
					this.getOrderTable();
				},
				(err: any) => console.log(err)
			);
	}

	getOrderTable() {
		this.itemService.getOrderTableByClient(this.selectedClient.id)
			.subscribe(
				(res: any) => {
					this.orderTable = res.response;
					this.reduceArrearsOnPartsLineItems = [];

					if (this.selectedClient.sendASNsViaEDI === false) {
						this.orderTable.rows.forEach((row: any) => {
							if (row.deliveryArrears > 0) {
								this.reduceArrearsOnPartsLineItems.push({currentArrears: row.deliveryArrears, partId: row.partId, partNumber: row.partNumber, reduceArrearsBy: row.deliveryArrears});
							}
						});
					}
					this.buildButtons();
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				},
			);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	importCSV() {
		this.validationErrors = [];
		this.ediMessageService.importOrdersCSV(this.popupImportDetails)
			.subscribe(
				() => {
					this.closePopup();
					notify("CSV Imported", "success", 5000);
					this.getOrderTable();
				},
				(err: any) => {
					this.validationErrors = err.file;
				}
			);
	}

	importCSVPopup() {
		this.validationErrors = [];
		this.popupVisible = true;
		this.popupAddButtonOptions = { onClick: () => this.importCSV(), text: "Import" };
		this.popupImportDetails = new ImportEdiOrdersCSV();
		this.popupTitle = "Import Edi Orders Message CSV";
	}

	reduceArrears() {
		this.popupReduceArrearsOnParts.parts = JSON.stringify(this.reduceArrearsOnPartsLineItems);
		this.partService.bulkReduceArrears(this.popupReduceArrearsOnParts)
			.subscribe(
				() => {
					this.closePopup();
					notify("Arrears Reduced, reloading details", "success", 5000);
					this.getOrderTable();
				},
				(err: any) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	reduceArrearsPopup() {
		this.popupVisible = true;
		this.popupAddButtonOptions = { onClick: () => this.reduceArrears(), text: "Reduce Arrears" };
		this.popupTitle = "Reduce Arrears";
	}

	syncTreeViewSelection(data:any){
		this.selectedClient = data.itemData;
		this.getOrderTable();
	}

	uploadCSVForImport(file: File) {
		this.validationErrors = [];
		const containerName = "class-schedules";
		const uploadData = new FormData();
		uploadData.append("containerName", containerName);
		uploadData.append("files", file);
		this.fileService.uploadFile(uploadData)
			.subscribe(
				(res: any) => {
					this.popupImportDetails.fileLookupId = res.response[0].lookupId;
				},
				(err) => {
					console.log(err);
					notify("CSV Upload Failed - Please ensure you are uploading a valid file", "error", 5000);
				}
			);
	}
}