<admin-header [primaryButtons]="headerPrimaryButtons" [secondaryButtons]="headerSecondaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<div class="form-container">
	<dx-form class="m-3" [(formData)]="item" [showColonAfterLabel]="true" labelLocation="top" [readOnly]="readOnly">
		<dxi-item itemType="tabbed" [tabPanelOptions]="{hoverStateEnabled:false}">
			<dxo-tab-panel-options [deferRendering]="false"></dxo-tab-panel-options>
			<dxi-tab title="Details" [colCount]="2">
				<dxi-item dataField="cellNumber"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
				<dxi-item dataField="name"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
				<dxi-item dataField="manufacturer"></dxi-item>
				<dxi-item dataField="tonnage"></dxi-item>
				<dxi-item dataField="qualityLoopDelay"></dxi-item>
				<dxi-item dataField="productionStatus" editorType="dxSelectBox" [editorOptions]="{ items: productionStatuses, displayExpr:'name', valueExpr:'id', searchEnabled: true }"></dxi-item>
				<dxi-item dataField="siteId" editorType="dxSelectBox" [editorOptions]="{ items: sites, displayExpr:'name', valueExpr:'id' }" [label]="{ text: 'Site' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
				<dxi-item dataField="department" editorType="dxSelectBox" [editorOptions]="{ items: departments, displayExpr:'name', valueExpr:'id' }" [label]="{ text: 'Department' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
				<dxi-item dataField="setUpCost" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency:'GBP', precision: 2} }"></dxi-item>
				<dxi-item dataField="interfaceDeviceId" editorType="dxSelectBox" [editorOptions]="{ items: interfaceDevices, displayExpr:'name', valueExpr:'id', searchEnabled: true, showClearButton: true }"></dxi-item>
			</dxi-tab>
			<dxi-tab title="Floorplan Layout">
				<dxi-item dataField="xPosition"></dxi-item>
				<dxi-item dataField="yPosition"></dxi-item>
				<dxi-item dataField="depth"></dxi-item>
				<dxi-item dataField="width"></dxi-item>
				<dxi-item dataField="rotationAmount"></dxi-item>
			</dxi-tab>
			<dxi-tab title="Events">
				<dx-data-grid class="data-grid m-3" [dataSource]="events" [remoteOperations]="false" [allowColumnReordering]="true" [rowAlternationEnabled]="true" [showBorders]="true">
					<dxo-paging [pageSize]="50"></dxo-paging>
					<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
					<dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
					<dxo-group-panel [visible]="true"></dxo-group-panel>
					<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
					<dxo-export [enabled]="true"></dxo-export>
					<dxi-column type="buttons">
						<dxi-button hint="View Image" icon="fal fa-eye" [onClick]="viewEventImage" [visible]="doesEventHaveImage"></dxi-button>
						<dxi-button hint="Edit" icon="fal fa-pencil" [onClick]="editEvent"></dxi-button>
						<dxi-button hint="Delete" icon="fal fa-trash" [onClick]="deleteEvent"></dxi-button>
					</dxi-column>
					<dxi-column dataField="eventDate" dataType="date" format="dd/MM/yyy" alignment="left"></dxi-column>
					<dxi-column dataField="eventType" dataType="string" alignment="left">
						<dxo-lookup [dataSource]="machineEventTypes" valueExpr="id" displayExpr="name"></dxo-lookup>
					</dxi-column>
					<dxi-column dataField="description" dataType="text" alignment="left"></dxi-column>
					<dxi-column dataField="downtimeHours" dataType="number" alignment="left" caption="Downtime (hors)"></dxi-column>
					<dxi-column dataField="downtimeCost" dataType="number" alignment="left" caption="Cost" [format]="{ type: 'currency', currency: 'GBP', precision: 2 }"></dxi-column>
				</dx-data-grid>
			</dxi-tab>			
			<dxi-tab title="Documents">
				<dx-data-grid class="data-grid" [dataSource]="documents" [remoteOperations]="false" [allowColumnReordering]="false" [rowAlternationEnabled]="true" [showBorders]="true" height="200px">
					<dxo-paging [pageSize]="100"></dxo-paging>
					<dxo-pager [visible]="false" [showPageSizeSelector]="false" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
					<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
					<dxo-group-panel [visible]="false"></dxo-group-panel>
					<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
					<dxo-export [enabled]="false"></dxo-export>
					<dxi-column dataField="fileName" dataType="text" alignment="left" caption="File">
						<dxi-validation-rule type="required"></dxi-validation-rule>
					</dxi-column>
					<dxi-column dataField="description" dataType="text" alignment="left" caption="Description">
						<dxi-validation-rule type="required"></dxi-validation-rule>
					</dxi-column>
					<dxi-column type="buttons">
						<dxi-button hint="Download" icon="fal fa-download" [onClick]="downloadFileLink"></dxi-button>
						<dxi-button *ngIf="mode !== 'view'" hint="Delete" icon="fal fa-trash" [onClick]="deleteDocumentLink"></dxi-button>
					</dxi-column>
				</dx-data-grid>
			</dxi-tab>
			<dxi-tab title="Ops Audits">
				<dx-data-grid class="data-grid" [dataSource]="opsActionAudits" [remoteOperations]="false" [allowColumnReordering]="false" [rowAlternationEnabled]="true" [showBorders]="true" height="500px">
					<dxo-paging [pageSize]="100"></dxo-paging>
					<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
					<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
					<dxo-group-panel [visible]="false"></dxo-group-panel>
					<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
					<dxo-export [enabled]="false"></dxo-export>
					<dxi-column dataField="createdAt" dataType="datetime" format="dd/MM/yyyy HH:mm:ss" alignment="left" caption="Date"></dxi-column>
					<dxi-column dataField="workOrderId" dataType="text" alignment="left" caption="Work Order"></dxi-column>
					<dxi-column dataField="operationNumber" dataType="text" alignment="left" caption="OP"></dxi-column>
					<dxi-column dataField="part.partNumber" dataType="text" alignment="left" caption="Part"></dxi-column>
					<dxi-column dataField="machine.cellNumber" dataType="text" alignment="left" caption="Cell"></dxi-column>
					<dxi-column dataField="machine.name" dataType="text" alignment="left" caption="Machine"></dxi-column>
					<dxi-column dataField="operator.fullName" dataType="text" alignment="left" caption="Operator"></dxi-column>
					<dxi-column dataField="quantity" dataType="number" alignment="left" caption="Qty"></dxi-column>
					<dxi-column dataField="description" dataType="text" alignment="left" caption="Description"></dxi-column>
					<dxi-column dataField="userProfile.fullName" dataType="text" alignment="left" caption="User"></dxi-column>
				</dx-data-grid>
			</dxi-tab>
			<dxi-tab title="Questionnaires">
				<dx-data-grid class="data-grid" [dataSource]="questionnaires" [remoteOperations]="false" [allowColumnReordering]="false" [rowAlternationEnabled]="true" [showBorders]="true" height="500px">
					<dxo-paging [pageSize]="100"></dxo-paging>
					<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
					<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
					<dxo-group-panel [visible]="false"></dxo-group-panel>
					<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
					<dxo-export [enabled]="false"></dxo-export>
					<dxi-column dataField="createdAt" dataType="datetime" format="dd/MM/yyyy HH:mm:ss" alignment="left" caption="Date"></dxi-column>
					<dxi-column dataField="userProfile.fullName" caption="User" dataType="string" alignment="left"></dxi-column>
					<dxi-column [showEditorAlways]='false' dataField="wearingCorrectPPE" alignment="left" caption="Wearing Correct PPE" [customizeText]="boolYesNo"></dxi-column>
					<dxi-column [showEditorAlways]='false' dataField="areaSafe" alignment="left" caption="Area is Safe" [customizeText]="boolYesNo"></dxi-column>
					<dxi-column [showEditorAlways]='false' dataField="areaClean" alignment="left" caption="Area is Clean and Tidy" [customizeText]="boolYesNo"></dxi-column>
					<dxi-column [showEditorAlways]='false' dataField="haveSOP" alignment="left" caption="You have SOP" [customizeText]="boolYesNo"></dxi-column>
					<dxi-column [showEditorAlways]='false' dataField="haveFirstOff" alignment="left" caption="You have First Off" [customizeText]="boolYesNo"></dxi-column>
					<dxi-column [showEditorAlways]='false' dataField="haveStageSample" alignment="left" caption="You have Stage Sample" [customizeText]="boolYesNo"></dxi-column>
				</dx-data-grid>
			</dxi-tab>
		</dxi-item>
	</dx-form>
</div>
<dx-popup [width]="750" [height]="600" [showTitle]="true" [title]="popupTitle" [dragEnabled]="false" [closeOnOutsideClick]="false" [showCloseButton]="false" container=".dx-viewport" [(visible)]="popupVisible">
	<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="before" [options]="popupAddButtonOptions"></dxi-toolbar-item>
	<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="after" [options]="popupCloseButtonOptions"></dxi-toolbar-item>
	<div *ngIf="popupTitle.includes('Event')">
		<dx-form class="m-3" [(formData)]="popupEvent" [showColonAfterLabel]="true" labelLocation="top" [colCount]="2">
			<dxi-item dataField="eventDate" [label]="{ text: 'Date'}" editorType="dxDateBox" [editorOptions]="{ displayFormat: 'dd/MM/yyyy', type: 'date' }">
				<dxi-validation-rule type="required" message="Event Date is required"></dxi-validation-rule>
			</dxi-item>
			<dxi-item dataField="eventType" editorType="dxSelectBox" [editorOptions]="{ items: machineEventTypes, displayExpr: 'name', valueExpr: 'id', searchEnabled: true, value: '' }">
				<dxi-validation-rule type="required" message="Event Type is required"></dxi-validation-rule>
			</dxi-item>
			<dxi-item dataField="description" editorType="dxTextBox"></dxi-item>
			<dxi-item dataField="downtimeHours" [label]="{ text: 'Downtime (hours)'}" editorType="dxNumberBox" [editorOptions]="{format: {type:'fixedPoint', precision: 2, min: 0} }"></dxi-item>
			<dxi-item dataField="downtimeCost" [label]="{ text: 'Cost'}" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency:'GBP', precision: 2, min: 0} }"></dxi-item>
			<dxi-item>
				<dx-file-uploader [maxFileSize]="4194304" [uploadFile]="uploadEventImage"></dx-file-uploader>
			</dxi-item>
			<dxi-item [colSpan]="2">
				<div class="eventImage" [style]="eventImageStyle"></div>
			</dxi-item>
		</dx-form>
	</div>
	<div *ngIf="popupTitle == 'Add New Document'">
		<dx-form class="m-3" [(formData)]="popupDocument" [showColonAfterLabel]="true" labelLocation="top">
			<dxi-item dataField="description">
				<dxi-validation-rule type="required" message="Description is required"></dxi-validation-rule>					
			</dxi-item>
			<dxi-item>
				<dx-file-uploader [maxFileSize]="4194304" [uploadFile]="uploadDocument"></dx-file-uploader>
			</dxi-item>
		</dx-form>
	</div>
</dx-popup>