import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { OpsPlanningWorkOrderSummaryPdfRowStatus } from "@enums/opsPlanningWorkOrderSummaryPdfRowStatus.enum";
import { GetPropertiesFromEnum } from "@helpers/getPropertiesFromEnum";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { BulkGenerateWorkOrdersPdf } from "@interfaces/bulkGenerateWorkOrdersPdf.interface";
import { BulkGenerateWorkOrdersPdfLineItem } from "@interfaces/bulkGenerateWorkOrdersPdfLineItem.interface";
import { Client } from "@interfaces/client.interface";
import { GenerateOpsPlanningWorkOrdersPdf } from "@interfaces/generateOpsPlanningWorkOrdersPdf.interface";
import { LogExport } from "@interfaces/logExport.interface";
import { WorkOrder } from "@interfaces/workOrder.interface";
import { ClientService } from "@services/client.service";
import { ExportLogService } from "@services/exportLog.service";
import { WorkOrderService } from "@services/workOrder.service";
import notify from "devextreme/ui/notify";

@Component({
	moduleId: module.id,
	selector: "workOrders",
	styleUrls: ["workOrders.css"],
	templateUrl: "workOrders.html"
})
export class WorkOrdersAdminComponent {
	bulkWorkOrderLineItems: BulkGenerateWorkOrdersPdfLineItem[] = [];
	clients: Client[] = [];
	deleteEnabled = true;
	editEnabled = true;
	gridLoading = false;
	headerPrimaryButtons: AdminHeaderButton[] = [{ icon: "fal fa-download", method: "generateAndDownloadSummaryPDFPopup", text: "Summary PDF" }];
	itemType = "Work Order"
	itemUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	items: any[] = [];
	popupAddButtonOptions: any;
	popupBulkGenerateWorkOrdersPdf: BulkGenerateWorkOrdersPdf = new BulkGenerateWorkOrdersPdf();
	popupCloseButtonOptions: any = { onClick: () => this.closePopup(), text: "Close" };
	popupGenerateOpsPlanningPdf: GenerateOpsPlanningWorkOrdersPdf = new GenerateOpsPlanningWorkOrdersPdf();
	popupTitle = "";
	popupVisible = false;
	showCompleted = false;
	statuses: any;
	title: string;
	workOrderStatuses: any;
	
	constructor(private router: Router, private clientService: ClientService, private exportLogService: ExportLogService, private itemService: WorkOrderService) {
		this.title = `${this.itemType}s`;
		this.viewRow = this.viewRow.bind(this);
		this.editRow = this.editRow.bind(this);
		this.deleteRow = this.deleteRow.bind(this);
		this.getClients();
		this.getItems(this.showCompleted);
		this.workOrderStatuses = GetPropertiesFromEnum(OpsPlanningWorkOrderSummaryPdfRowStatus);
	}

	addNewItem() {
		this.router.navigate([`${this.itemUrl}/create`]);
	}

	buildButtons(showCompleted: boolean) {
		this.headerPrimaryButtons = [
			{ icon: "fal fa-download", method: "generateAndDownloadSummaryPDFPopup", text: "Summary PDF" },
			{ icon: "fal fa-download", method: "generateAndDownloadBulkMasterLabelsPDFPopup", text: "Master Labels PDF" }
		];

		if (showCompleted) {
			this.headerPrimaryButtons.push({ method: "showIncomplete", text: "Show Incomplete" });
		}
		else {
			this.headerPrimaryButtons.push({ method: "showComplete", text: "Show Complete" });
		}
	}

	closePopup() {
		this.popupVisible = false;
	}

	deleteRow(e: any) {
		const result = confirm("Are you sure you want to delete this work order? Please be aware this will also delete any linked, child and parent work orders connected to it. This will fail if any quantity in any linked work order has been completed.");
		if(result)
		{
			this.itemService.deleteSingleById(e.row.data.id)
				.subscribe(
					() => {
						notify(this.itemType + " deleted!", "success", 5000);
						this.getItems(this.showCompleted);
					},
					(err) => {
						console.log(err);
						notify("Something went wrong!", "error", 5000);
					},
				);
		}
	}

	editRow(e: any) {
		this.router.navigate([`${this.itemUrl}/${e.row.data.id}_edit`]);
	}

	generateAndDownloadBulkMasterLabelsPDF() {		
		this.popupBulkGenerateWorkOrdersPdf.workOrders = JSON.stringify(this.bulkWorkOrderLineItems);
		this.itemService.generateAndDownloadBulkMasterLabelsPdf(this.popupBulkGenerateWorkOrdersPdf).subscribe(
			(response: any) =>{
				const dataType = response.type;
				const binaryData = [];
				binaryData.push(response);
				const downloadLink = document.createElement("a");
				downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
				downloadLink.setAttribute("download", "Work_Order_Master Labels.pdf");
				document.body.appendChild(downloadLink);
				downloadLink.click();
				this.closePopup();
			}
		);
	}

	generateAndDownloadBulkMasterLabelsPDFPopup() {
		this.bulkWorkOrderLineItems = [];
		this.items.forEach((workOrder: WorkOrder) => {
			const lineItem = new BulkGenerateWorkOrdersPdfLineItem();
			lineItem.includeInPdf = false;
			lineItem.workOrderId = workOrder.id;
			lineItem.dateRequired = workOrder.dateRequired;
			lineItem.partNumber = workOrder.part.partNumber;
			lineItem.quantity = workOrder.quantity;
			this.bulkWorkOrderLineItems.push(lineItem);
		});
		this.popupBulkGenerateWorkOrdersPdf = new BulkGenerateWorkOrdersPdf();
		this.popupTitle = "Generate Bulk Master Labels PDF";
		this.popupVisible = true;
		this.popupAddButtonOptions = { onClick: () => this.generateAndDownloadBulkMasterLabelsPDF(), text: "Generate PDF" };
	}

	generateAndDownloadSummaryPDF() {
		this.itemService.opsPlanningWorkOrderSummaryPdf(this.popupGenerateOpsPlanningPdf).subscribe(
			(response: any) =>{
				const dataType = response.type;
				const binaryData = [];
				binaryData.push(response);
				const downloadLink = document.createElement("a");
				downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
				downloadLink.setAttribute("download", "Work_Order_Summary.pdf");
				document.body.appendChild(downloadLink);
				downloadLink.click();
				this.closePopup();
			}
		);
	}

	generateAndDownloadSummaryPDFPopup() {
		this.popupGenerateOpsPlanningPdf = new GenerateOpsPlanningWorkOrdersPdf();
		this.popupTitle = "Generate Summary PDF";
		this.popupVisible = true;
		this.popupAddButtonOptions = { onClick: () => this.generateAndDownloadSummaryPDF(), text: "Generate Summary PDF" };
	}

	getClients() {
		this.clientService.getAll()
			.subscribe(
				(res: any) => this.clients = res.response.items,
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				},
			);
	}

	getItems(showCompleted: boolean) {
		this.gridLoading = true;
		this.items = [];
		if (showCompleted === true) {
			this.itemService.getAllComplete()
				.subscribe(
					(res: any) => {
						this.items = res.response;
						this.gridLoading = false;
					},
					(err) => {
						console.log(err);
						notify("Something went wrong!", "error", 5000);
					},
				);
		} else {
			this.itemService.getAllIncomplete()
				.subscribe(
					(res: any) => {
						this.items = res.response;
						this.gridLoading = false;
					},
					(err) => {
						console.log(err);
						notify("Something went wrong!", "error", 5000);
					},
				);
		}
		this.buildButtons(showCompleted);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	logExport() {
		const logExport = new LogExport();
		logExport.gridName = "Work Orders";
		this.exportLogService.logExport(logExport)
			.subscribe(
				() => console.log("Export Logged"),
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
					return false;
				},
			);
	}

	showComplete() {
		this.showCompleted = true;
		this.getItems(true);
	}

	showIncomplete() {
		this.showCompleted = false;
		this.getItems(false);
	}

	viewRow(e: any) {
		this.router.navigate([`${this.itemUrl}/${e.row.data.id}`]);
	}
}