import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { Client } from "@interfaces/client.interface";
import { LogExport } from "@interfaces/logExport.interface";
import { Project } from "@interfaces/project.interface";
import { UploadCSV } from "@interfaces/uploadCSV.interface";
import { FileService } from "@services/file.service";
import { ClientService } from "@services/client.service";
import { ExportLogService } from "@services/exportLog.service";
import { PartService } from "@services/part.service";
import { ProjectService } from "@services/project.service";
import notify from "devextreme/ui/notify";

@Component({
	moduleId: module.id,
	selector: "parts",
	styleUrls: ["parts.css"],
	templateUrl: "parts.html"
})
export class PartsAdminComponent {
	clients: Client[] = [];
	deleteEnabled = true;
	editEnabled = true;
	headerPrimaryButtons: AdminHeaderButton[] = [];
	itemType = "Part";
	itemUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	items: any[] = [];
	popupAddButtonOptions: any = { onClick: () => this.importCSV(), text: "Import CSV" };
	popupCloseButtonOptions: any = { onClick: () => this.closePopup(), text: "Close" };
	popupImportDetails: UploadCSV = new UploadCSV();
	popupTitle = "Import CSV";
	popupVisible = false;
	projects: Project[] = [];
	title: string;
	validationErrors: string[] = [];
	
	constructor(private router: Router, private itemService: PartService, private clientService: ClientService, private exportLogService: ExportLogService, private fileService: FileService, private projectService: ProjectService) {
		this.headerPrimaryButtons.push({ icon: "fal fa-plus", method: "addNewItem", text: `New ${this.itemType}` });
		this.headerPrimaryButtons.push({ icon: "fal fa-upload", method: "importCSVPopup", text: "Import CSV" });
		this.title = `${this.itemType}s`;
		this.viewRow = this.viewRow.bind(this);
		this.editRow = this.editRow.bind(this);
		this.deleteRow = this.deleteRow.bind(this);
		this.uploadCSVForImport = this.uploadCSVForImport.bind(this);
		this.getItems();
		this.getClients();
		this.getProjects();
	}

	addNewItem() {
		this.router.navigate([`${this.itemUrl}/create`]);
	}

	boolYesNo(cellInfo: any){		
		if(cellInfo.value === true)
		{
			return "YES";
		}
		else
		{
			return "NO";
		}
	}
	
	closePopup() {
		this.popupVisible = false;
	}

	deleteRow(e: any) {
		const result = confirm("Are you sure you want to delete this item?");
		if(result)
		{
			this.itemService.deleteSingleById(e.row.data.id)
				.subscribe(
					() => {
						notify(this.itemType + " deleted!", "success", 5000);
						this.getItems();
					},
					(err) => {
						console.log(err);
						notify("Something went wrong!", "error", 5000);
					},
				);
		}
	}

	editRow(e: any) {
		this.router.navigate([`${this.itemUrl}/${e.row.data.id}_edit`]);
	}

	getClients() {
		this.clientService.getAll()
			.subscribe(
				(res: any) => this.clients = res.response.items,
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				},
			);
	}

	getItems() {
		this.itemService.getAll()
			.subscribe(
				(res: any) => this.items = res.response.items,
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				},
			);
	}

	getProjects() {
		this.projectService.getAll()
			.subscribe(
				(res: any) => this.projects = res.response.items,
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				},
			);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	importCSV() {
		this.validationErrors = [];
		this.itemService.importCSV(this.popupImportDetails)
			.subscribe(
				() => {
					this.closePopup();
					this.getItems();
				},
				(err: any) => {
					this.validationErrors = err.file;
				}
			);
	}

	importCSVPopup() {
		this.validationErrors = [];
		this.popupVisible = true;
		this.popupAddButtonOptions = { onClick: () => this.importCSV(), text: "Import CSV" };
		this.popupCloseButtonOptions = { onClick: () => this.closePopup(), text: "Close" };
		this.popupImportDetails = new UploadCSV();
		this.popupTitle = "Import CSV";
	}

	logExport() {
		const logExport = new LogExport();
		logExport.gridName = "Parts";
		this.exportLogService.logExport(logExport)
			.subscribe(
				() => console.log("Export Logged"),
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
					return false;
				},
			);
	}
	
	onRowPrepared(e: any) {
		if (e.rowType === "data") {  
			if (e.data.needsCompleting === true)  {
				e.rowElement.classList.add("needsCompleting");
				e.rowElement.style.backgroundColor = "#ffd2ad";  
				e.rowElement.className = e.rowElement.className.replace("dx-row-alt", "");
			}
		}  
	}

	uploadCSVForImport(file: File) {
		this.validationErrors = [];
		const containerName = "parts-imports";
		const uploadData = new FormData();
		uploadData.append("containerName", containerName);
		uploadData.append("files", file);
		this.fileService.uploadFile(uploadData)
			.subscribe(
				(res: any) => {
					this.popupImportDetails.fileLookupId = res.response[0].lookupId;
				},
				(err) => {
					console.log(err);
					notify("CSV Upload Failed - Please ensure you are uploading a valid file", "error", 5000);
				}
			);
	}

	viewRow(e: any) {
		this.router.navigate([`${this.itemUrl}/${e.row.data.id}`]);
	}
}