import { MaterialType } from "@enums/materialType.enum";
import { PurchaseType } from "@enums/purchaseType.enum";
import { Model } from "@interfaces/model.interface";

export interface PurchasableItem extends Model {
	cost?: number;
	description: string;
	materialType: MaterialType;
	minimumBuyableQuantity: number;
	name: string;
	purchaseType: PurchaseType;
}

export class PurchasableItem implements PurchasableItem {
	minimumBuyableQuantity = 1;
	name = "";

	constructor(data?: Partial<PurchasableItem>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}