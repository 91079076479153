import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DeviceType } from "@enums/deviceType.enum";
import { GetPropertiesFromEnum } from "@helpers/getPropertiesFromEnum";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { InterfaceDevice } from "@interfaces/interfaceDevice.interface";
import { Machine } from "@interfaces/machine.interface";
import { InterfaceDeviceService } from "@services/interfaceDevice.service";
import { MachineService } from "@services/machine.service";
import notify from "devextreme/ui/notify";

@Component({
	moduleId: module.id,
	selector: "interfaceDevices-id",
	styleUrls: ["interfaceDevices-id.css"],
	templateUrl: "interfaceDevices-id.html"
})
export class InterfaceDevicesIdAdminComponent {
	createEnabled = true;
	deviceTypes: any;
	editEnabled = true;
	headerPrimaryButtons: AdminHeaderButton[] = [];
	headerSecondaryButtons: AdminHeaderButton[] = [];
	item: InterfaceDevice = new InterfaceDevice();
	itemType = "Interface Device";
	machines: Machine[] = [];
	mode = "";
	readOnly = true;
	returnUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	title: string = "View " + this.itemType;

	constructor(private router:Router, private route: ActivatedRoute, private itemService: InterfaceDeviceService, private machineService: MachineService) {
		this.buildButtons("view");
		this.deviceTypes = GetPropertiesFromEnum(DeviceType);
		this.getMachines();
		this.route.params
			.subscribe((params) => {
				if (params.id != "create") {
					if (params.id.split("_").length > 1) {
						this.getItem(params.id.split("_")[0]);
						this.edit();
					} else {
						this.getItem(params.id);
					}
				} else {
					this.create();
				}
			});
	}

	buildButtons(mode: string) {
		this.mode = mode;
		if (mode == "edit" && this.editEnabled) {
			this.headerPrimaryButtons = [
				{ method: "updateItem", text: "Save Changes" },
				{ method: "cancelEditing", text: "View Mode" },
				{ method: "close", text: "Close" }
			];
		} else if (mode == "create" && this.createEnabled) {
			this.headerPrimaryButtons = [
				{ method: "createItem", text: "Save" },
				{ method: "close", text: "Close" }
			];
		} else if (mode == "view") {
			this.headerPrimaryButtons = [
				{ method: "edit", text: "Edit" },
				{ method: "close", text: "Close" }
			];
		}
	}

	cancelEditing() {
		if (confirm("Are you sure you wish to switch modes? Unsaved changes will be discarded.") == false) {
			return;
		}
		this.readOnly = true;
		this.buildButtons("view");
		this.title = "View " + this.itemType;
	}

	close() {
		this.router.navigate([`/${this.returnUrl}/`]);
	}

	create() {
		this.readOnly = false;
		this.buildButtons("create");
		this.title = "Create New " + this.itemType;
	}

	createItem() {
		this.itemService.createSingle(this.item)
			.subscribe(
				() => {
					notify("Successfully Updated " + this.itemType, "success", 5000);
					this.item = new InterfaceDevice();
					this.close();
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}

	edit() {
		this.readOnly = false;
		this.buildButtons("edit");
		this.title = "Edit " + this.itemType;
		notify("You are now editing...", "warning", 5000);
	}

	getItem(itemId: number) {
		this.itemService.getSingleById(itemId)
			.subscribe(
				(res: any) => {
					this.item = res.response;
				},
				(err) => {console.log(err); notify("Something Went Wrong!", "Error", 5000);}
			);
	}

	getMachines() {
		this.machineService.getAll()
			.subscribe(
				(res: any) => this.machines = res.response.items,
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				},
			);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	updateItem() {
		this.itemService.updateSingleById(this.item.id,this.item)
			.subscribe(
				() => {
					notify("Successfully Updated " + this.itemType, "success", 5000);
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				}
			);
	}
}