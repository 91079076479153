import { Injectable, Injector } from "@angular/core";
import { environment } from "@environments/environment";
import { ChangeEdiOrderAssignment } from "@interfaces/changeEdiOrderAssignment.interface";
import { ChangeEdiOrderPriority } from "@interfaces/changeEdiOrderPriority.interface";
import { Service } from "@services/service";

@Injectable()
export class EdiOrderService extends Service {
	constructor(injector: Injector) {
		super("EdiOrder", injector);
	}

	// API
	app_SetAsPicked(id: number) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/App_SetAsPicked/${id}`, {});
	}

	assignMachine(data: ChangeEdiOrderAssignment) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/AssignMachine`, data);
	}

	assignOperator(data: ChangeEdiOrderAssignment) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/AssignOperator`, data);
	}
	
	changePriority(data: ChangeEdiOrderPriority){
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/ChangePriority`, data);
	}

	getOrderTableByClient(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/OrderTable/${id}`);
	}

	pickList() {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/PickList`, { responseType: "blob" as "json" });
	}
}