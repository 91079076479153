import { Component, ViewEncapsulation } from "@angular/core";
import { StockStoreService } from "@services/stockStore.service";
import { BarcodeFormat } from "@zxing/library";
import { ZXingScannerComponent } from "@zxing/ngx-scanner";
import notify from "devextreme/ui/notify";
import { Guid } from "guid-typescript";

@Component({
	encapsulation: ViewEncapsulation.None,
	moduleId: module.id,
	selector: "moveStock",
	styleUrls: ["moveStock.css"],
	templateUrl: "moveStock.html"
})
export class MoveStockComponent {
	allowedFormats = BarcodeFormat.QR_CODE;
	availableDevices: MediaDeviceInfo[] = [];
	hasCameras = false;
	hasPermission = false;
	result = "";
	scanner: ZXingScannerComponent = new ZXingScannerComponent();
	scannerEnabled = true;
	selectedDevice: any;

	constructor(private stockStoreService: StockStoreService) {
		this.getCameras();
	}

	cameraFound(e: any) {
		// Log to see if the camera was found
		console.log(e);
		this.availableDevices = e;
		this.selectedDevice = e[0];
		this.hasCameras = true;
		this.hasPermission = true;
		console.log("Devices: ", this.availableDevices);

		// selects the devices's back camera by default'
		for (const device of e) {
			if (/back|rear|environment/gi.test(device.label)) {
				new this.scanner.deviceChange(true);
				this.selectedDevice = device;
				break;
			} else if (e.length == 1) {
				new this.scanner.deviceChange(true);
				this.selectedDevice = device;
				break;
			}
		}
	}

	camerasNotFound() {
		// Display an alert modal here
	}

	getCameras() {
		console.log("Hit!");
	}

	onDeviceSelectChange() {
		console.log("Selection changed: ", this.selectedDevice.label);
		// this.selectedDevice = this.scanner.device!;
	}

	onScanSuccess(result: string) {
		const input = window.prompt("Enter Location", "");
		this.stockStoreService.App_PlacedItem(Guid.parse(result),input!)
			.subscribe(
				() => {
					notify("Item Placed!", "Success", 5000);
				},
				(err) => {
					console.log(err);
					notify("Something Went Wrong!", "Error", 5000);
				}
			);
	}
}