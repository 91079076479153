import { Model } from "@interfaces/model.interface";

export interface Allocation extends Model {
	clientId: number;
	clientLocationId: number;
	deliveryNoteId: number;
	lineItemQuantity: number;
	partId: number;
	reportedById: number;
	totalAssigned: number;
}

export class Allocation implements Allocation {
	constructor(data?: Partial<Allocation>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}