<admin-header [primaryButtons]="headerPrimaryButtons" [tertiaryButtons]="headerTertiaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<div class="form-container">
	<dx-form class="m-3" [(formData)]="item" [showColonAfterLabel]="true" labelLocation="top" [readOnly]="readOnly" (onFieldDataChanged)="mainFormDataChanged($event)">
		<dxi-item itemType="tabbed" [tabPanelOptions]="{hoverStateEnabled:false, scrollByContent: true}">
			<dxo-tab-panel-options [deferRendering]="false"></dxo-tab-panel-options>
			<dxi-tab title="Main Details" [colCount]="2">
				<dxi-item dataField="manufacturingVendorCode" [editorOptions]="{ readOnly: true }" [label]="{ text: 'Manufacturing Vendor Code' }"></dxi-item>
				<dxi-item dataField="shippingGSDBCode" [editorOptions]="{ readOnly: true }" [label]="{ text: 'Shipping GSDB Code' }"></dxi-item>
				<dxi-item dataField="clientId" editorType="dxSelectBox" [editorOptions]="{ items: clients, searchEnabled: true, displayExpr:'name', showClearButton: false, valueExpr:'id' }" [label]="{ text: 'Client' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
				<dxi-item dataField="clientContactId" editorType="dxSelectBox" [editorOptions]="{ items: clientContacts, searchEnabled: true, displayExpr: 'displayName', showClearButton: false, valueExpr:'id' }" [label]="{ text: 'Contact' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
				<dxi-item dataField="projectId" editorType="dxSelectBox" [editorOptions]="{ items: projects, searchEnabled: true, displayExpr:'name', showClearButton: false, valueExpr:'id' }" [label]="{ text: 'Project' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
				<dxi-item dataField="enquiryDate" editorType="dxDateBox" [label]="{ text: 'Enquiry Date' }" [editorOptions]="{ displayFormat: 'dd/MM/yyyy', type: 'date' }"></dxi-item>
			</dxi-tab>
			<dxi-tab title="Uploads">
				<dxi-item itemType="group" caption="Part Image - Required" [colCount]="2">
					<dxi-item>
						<div class="image" [style]="imageStyle"></div>
					</dxi-item>
					<dxi-item>
						<dx-file-uploader [visible]="mode != 'view'" [showFileList]="false" [maxFileSize]="2097152" [uploadFile]="uploadImage" accept="image/*" [allowedFileExtensions]="['.gif', '.jpg', '.jpeg', '.png']"></dx-file-uploader>
					</dxi-item>
				</dxi-item>
				<dxi-item itemType="group" caption="Supporting Documents">
					<dxi-item itemType="group">
						<dx-data-grid class="data-grid" [dataSource]="supportingDocuments" [remoteOperations]="false" [allowColumnReordering]="false" [rowAlternationEnabled]="true" [showBorders]="true" height="200px">
							<dxo-paging [pageSize]="100"></dxo-paging>
							<dxo-pager [visible]="false" [showPageSizeSelector]="false" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
							<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
							<dxo-group-panel [visible]="false"></dxo-group-panel>
							<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
							<dxo-export [enabled]="false"></dxo-export>
							<dxi-column dataField="fileName" dataType="text" alignment="left" caption="File">
								<dxi-validation-rule type="required"></dxi-validation-rule>
							</dxi-column>
							<dxi-column dataField="description" dataType="text" alignment="left" caption="Description">
								<dxi-validation-rule type="required"></dxi-validation-rule>
							</dxi-column>
							<dxi-column type="buttons">
								<dxi-button hint="Download" icon="fal fa-download" [onClick]="downloadFileLink"></dxi-button>
								<dxi-button [visible]="mode != 'view'" hint="Delete" icon="fal fa-trash" [onClick]="deleteSupportingDocumentLink"></dxi-button>
							</dxi-column>
						</dx-data-grid>
					</dxi-item>
					<dxi-item itemType="group">
						<dxi-item [visible]="mode != 'view'" itemType="button" [buttonOptions]="supportingDocumentButtonOptions"></dxi-item>
					</dxi-item>
				</dxi-item>
				<dxi-item itemType="group" caption="CAD Files">
					<dxi-item itemType="group">
						<dx-data-grid class="data-grid" [dataSource]="cadFiles" [remoteOperations]="false" [allowColumnReordering]="false" [rowAlternationEnabled]="true" [showBorders]="true" height="200px">
							<dxo-paging [pageSize]="100"></dxo-paging>
							<dxo-pager [visible]="false" [showPageSizeSelector]="false" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
							<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
							<dxo-group-panel [visible]="false"></dxo-group-panel>
							<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
							<dxo-export [enabled]="false"></dxo-export>
							<dxi-column dataField="fileName" dataType="text" alignment="left" caption="File">
								<dxi-validation-rule type="required"></dxi-validation-rule>
							</dxi-column>
							<dxi-column dataField="description" dataType="text" alignment="left" caption="Version">
								<dxi-validation-rule type="required"></dxi-validation-rule>
							</dxi-column>
							<dxi-column type="buttons">
								<dxi-button hint="Download" icon="fal fa-download" [onClick]="downloadFileLink"></dxi-button>
								<dxi-button [visible]="mode != 'view'" hint="Delete" icon="fal fa-trash" [onClick]="deleteCADFileLink"></dxi-button>
							</dxi-column>
						</dx-data-grid>
					</dxi-item>
					<dxi-item itemType="group">
						<dxi-item [visible]="mode != 'view'" itemType="button" [buttonOptions]="cadFileButtonOptions"></dxi-item>
					</dxi-item>
				</dxi-item>
			</dxi-tab>
			<dxi-tab title="Part Details" [colCount]="2">
				<dxi-item dataField="partNumber" [label]="{ text: 'Part Number' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
				<dxi-item dataField="supersededPartNumber" [label]="{ text: 'Superseded Part Number' }"></dxi-item>
				<dxi-item dataField="revisionLevel" [label]="{ text: 'Revision Level' }"></dxi-item>
				<dxi-item dataField="partDescription" [label]="{ text: 'Part Description' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
				<dxi-item dataField="annualVolume" editorType="dxNumberBox" [editorOptions]="{format: {type:'fixedPoint', precision: 0, min: 0}}" [label]="{ text: 'Annual Volume' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
				<dxi-item dataField="batchSize" editorType="dxNumberBox" [editorOptions]="{format: {type:'fixedPoint', precision: 0, min: 0}}" [label]="{ text: 'Batch Size' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
				<dxi-item dataField="numberOfShifts" editorType="dxNumberBox" [editorOptions]="{format: {type:'fixedPoint', precision: 0, min: 0}}" [label]="{ text: 'Number of Shifts' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
				<dxi-item dataField="currentEngineeringLevel" [label]="{ text: 'Current Engineering Level' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
				<dxi-item dataField="oldEngineeringLevel" [label]="{ text: 'Old Engineering Level' }"></dxi-item>
				<dxi-item dataField="clientLocationId" editorType="dxSelectBox" [editorOptions]="{ items: clientLocations, searchEnabled: true, displayExpr: 'name', showClearButton: false, valueExpr:'id' }" [label]="{ text: 'Plant' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
				<dxi-item dataField="destinationName" [label]="{ text: 'Destination Name / Dock' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
			</dxi-tab>
			<dxi-tab title="Materials">
				<dxi-item itemType="group" caption="Raw Materials">
					<dxi-item itemType="group">
						<dx-data-grid #rawMaterialsGridContainer class="data-grid" [dataSource]="rawMaterials" [remoteOperations]="false" [allowColumnReordering]="false" [rowAlternationEnabled]="true" [showBorders]="true" height="200px" (onSaved)="calculateRawMaterialLineData()">
							<dxo-paging [pageSize]="100"></dxo-paging>
							<dxo-pager [visible]="false" [showPageSizeSelector]="false" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
							<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
							<dxo-group-panel [visible]="false"></dxo-group-panel>
							<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
							<dxo-export [enabled]="false"></dxo-export>
							<dxo-editing mode="popup" [allowAdding]="!readOnly" [allowDeleting]="!readOnly" [allowUpdating]="!readOnly" [confirmDelete]="true" [useIcons]="true" newRowPosition="last" [changes]="changes" [(editRowKey)]="editRowKey">
								<dxo-popup title="Raw Material Details" [showTitle]="true" [width]="'75%'" [height]="'auto'"></dxo-popup>
								<dxo-form [colCount]="2">
									<dxi-item dataField="purchasableItemId" editorType="dxSelectBox" [editorOptions]="{ items: materialPurchasableItems, searchEnabled: true, displayExpr:'name', valueExpr:'id'}" [label]="{ text: 'Grade' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
									<dxi-item dataField="gauge" editorType="dxSelectBox" [editorOptions]="{ items: thicknesses, searchEnabled: false }" [label]="{ text: 'Gauge (mm)' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>	
									<dxi-item dataField="width" editorType="dxNumberBox" [editorOptions]="{format: {type:'fixedPoint', precision: 1, min: 0}}" [label]="{ text: 'Width (mm)' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
									<dxi-item dataField="pitch" editorType="dxNumberBox" [editorOptions]="{format: {type:'fixedPoint', precision: 1, min: 0}}" [label]="{ text: 'Pitch (mm)' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
									<dxi-item dataField="partsPerBlow" editorType="dxNumberBox" [editorOptions]="{format: {type:'fixedPoint', precision: 0, min: 0}}" [label]="{ text: 'Parts per Blow' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
									<dxi-item dataField="grossWeight" editorType="dxNumberBox" [editorOptions]="{format: {type:'fixedPoint', precision: 5, min: 0}, readOnly: true}" [label]="{ text: 'Gross Weight (kg)' }"></dxi-item>
									<dxi-item dataField="rawMaterialPricePerKg" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0} }" [label]="{ text: 'Price per tonne' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
									<dxi-item dataField="netWeight" editorType="dxNumberBox" [editorOptions]="{format: {type:'fixedPoint', precision: 5, min: 0}}" [label]="{ text: 'Net Weight (kg)' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
									<dxi-item dataField="scrapWeight" editorType="dxNumberBox" [editorOptions]="{format: {type:'fixedPoint', precision: 5, min: 0}, readOnly: true}" [label]="{ text: 'Scrap Weight (kg)' }"></dxi-item>
									<dxi-item dataField="scrapCostPerKg" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0} }" [label]="{ text: 'Scrap Cost per kg' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
									<dxi-item dataField="scrapValue" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0}, readOnly: true }" [label]="{ text: 'Scrap Value' }"></dxi-item>
									<dxi-item dataField="grossMaterialCost" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0}, readOnly: true }" [label]="{ text: 'Gross Cost' }"></dxi-item>
									<dxi-item dataField="netMaterialCost" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0}, readOnly: true }" [label]="{ text: 'Net Cost' }"></dxi-item>
									<dxi-item dataField="overhead" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0} }" [label]="{ text: 'Overhead' }"></dxi-item>
								</dxo-form>
							</dxo-editing>
							<dxo-toolbar>
								<dxi-item name="addRowButton" showText="always"></dxi-item>
							</dxo-toolbar>
							<dxi-column dataField="purchasableItemId" dataType="number" alignment="left" caption="Grade">
								<dxo-lookup [dataSource]="materialPurchasableItems" displayExpr="name" valueExpr="id"></dxo-lookup>
							</dxi-column>
							<dxi-column dataField="gauge" dataType="number" alignment="left" caption="Gauge (mm)"></dxi-column>
							<dxi-column dataField="width" dataType="number" alignment="left" caption="Width (mm)"></dxi-column>
							<dxi-column dataField="pitch" dataType="number" alignment="left" caption="Pitch (mm)"></dxi-column>
							<dxi-column dataField="partsPerBlow" dataType="number" alignment="left" caption="Parts/Blow"></dxi-column>
							<dxi-column dataField="grossWeight" dataType="number" alignment="left" caption="Gross Weight (kg)"></dxi-column>
							<dxi-column dataField="rawMaterialPricePerKg" dataType="number" alignment="right" caption="Raw Material Price /tonne" [format]="{ type:'currency', currency: currency, precision: 4, min: 0 }"></dxi-column>
							<dxi-column dataField="netWeight" dataType="number" alignment="left" caption="Net Weight (kg)"></dxi-column>
							<dxi-column dataField="scrapWeight" dataType="number" alignment="left" caption="Scrap Weight (kg)"></dxi-column>
							<dxi-column dataField="scrapCostPerKg" dataType="number" alignment="right" caption="Scrap Cost /kg" [format]="{ type:'currency', currency: currency, precision: 4, min: 0 }"></dxi-column>
							<dxi-column dataField="scrapValue" dataType="number" alignment="right" caption="Scrap Value" [format]="{ type:'currency', currency: currency, precision: 4, min: 0 }"></dxi-column>
							<dxi-column dataField="grossMaterialCost" dataType="number" alignment="right" caption="Gross Cost" [format]="{ type:'currency', currency: currency, precision: 4, min: 0 }"></dxi-column>
							<dxi-column dataField="netMaterialCost" dataType="number" alignment="right" caption="Net Cost" [format]="{ type:'currency', currency: currency, precision: 4, min: 0 }"></dxi-column>
							<dxi-column dataField="overhead" dataType="number" alignment="right" caption="Overhead" [format]="{ type:'currency', currency: currency, precision: 4, min: 0 }"></dxi-column>
							<dxi-column type="buttons">
								<dxi-button name="edit"></dxi-button>
								<dxi-button name="delete"></dxi-button>
							</dxi-column>
						</dx-data-grid>
					</dxi-item>
					<dxi-item itemType="group">
						<dxi-item dataField="rawMaterialSubtotal" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0}, readOnly: true }" [label]="{ text: 'Net Material Costs' }"></dxi-item>
					</dxi-item>
				</dxi-item>
				<dxi-item itemType="group" caption="Bought Out Parts">					
					<dxi-item itemType="group">
						<dx-data-grid #boughtOutPartsGridContainer class="data-grid" [dataSource]="boughtOutParts" [remoteOperations]="false" [allowColumnReordering]="false" [rowAlternationEnabled]="true" [showBorders]="true" height="200px" (onSaved)="calculateBoughtOutMaterialsLineData()">
							<dxo-paging [pageSize]="100"></dxo-paging>
							<dxo-pager [visible]="false" [showPageSizeSelector]="false" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
							<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
							<dxo-group-panel [visible]="false"></dxo-group-panel>
							<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
							<dxo-export [enabled]="false"></dxo-export>
							<dxo-editing mode="popup" [allowAdding]="!readOnly" [allowDeleting]="!readOnly" [allowUpdating]="!readOnly" [confirmDelete]="true" [useIcons]="true" newRowPosition="last" [changes]="changes" [(editRowKey)]="editRowKey">
								<dxo-popup title="Bought Out Part Details" [showTitle]="true" [width]="'75%'" [height]="'auto'"></dxo-popup>
								<dxo-form [colCount]="2">
									<dxi-item dataField="supplierId" editorType="dxSelectBox" [editorOptions]="{ items: suppliers, searchEnabled: true, displayExpr:'name', valueExpr:'id'}" [label]="{ text: 'Supplier' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
									<dxi-item dataField="purchasableItemId" editorType="dxSelectBox" [editorOptions]="{ items: purchasableItems, searchEnabled: true, displayExpr:'name', valueExpr:'id', showClearButton: true }" [label]="{ text: 'Part' }"></dxi-item>	
									<dxi-item dataField="itemName" [label]="{ text: 'Description' }"></dxi-item>
									<dxi-item dataField="costPerPart" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0} }" [label]="{ text: 'Cost per Part' }"></dxi-item>
									<dxi-item dataField="quantity" editorType="dxNumberBox" [editorOptions]="{format: {type:'fixedPoint', precision: 0, min: 0}}" [label]="{ text: 'Quantity Per' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
									<dxi-item dataField="total" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0}, readOnly: true }" [label]="{ text: 'Total' }"></dxi-item>
									<dxi-item dataField="overhead" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0} }" [label]="{ text: 'Overhead' }"></dxi-item>
								</dxo-form>
							</dxo-editing>
							<dxo-toolbar>
								<dxi-item name="addRowButton" showText="always"></dxi-item>
							</dxo-toolbar>
							<dxi-column dataField="supplierId" dataType="number" alignment="left" caption="Supplier">
								<dxo-lookup [dataSource]="suppliers" displayExpr="name" valueExpr="id"></dxo-lookup>
							</dxi-column>
							<dxi-column dataField="purchasableItemId" dataType="number" alignment="left" caption="Item">
								<dxo-lookup [dataSource]="purchasableItems" displayExpr="name" valueExpr="id"></dxo-lookup>
							</dxi-column>
							<dxi-column dataField="itemName" dataType="text" alignment="left" caption="Item name"></dxi-column>
							<dxi-column dataField="costPerPart" dataType="number" alignment="right" caption="Cost / Part" [format]="{ type:'currency', currency: currency, precision: 4, min: 0 }"></dxi-column>
							<dxi-column dataField="quantity" dataType="number" alignment="right" caption="Quantity"></dxi-column>
							<dxi-column dataField="total" dataType="number" alignment="right" caption="Total" [format]="{ type:'currency', currency: currency, precision: 4, min: 0 }"></dxi-column>
							<dxi-column dataField="overhead" dataType="number" alignment="right" caption="Overhead" [format]="{ type:'currency', currency: currency, precision: 4, min: 0 }"></dxi-column>
							<dxi-column type="buttons">
								<dxi-button name="edit"></dxi-button>
								<dxi-button name="delete"></dxi-button>
							</dxi-column>
						</dx-data-grid>
					</dxi-item>
					<dxi-item itemType="group">
						<dxi-item dataField="boughtOutPartsSubtotal" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0}, readOnly: true }" [label]="{ text: 'Bought Out Parts' }"></dxi-item>
					</dxi-item>
				</dxi-item>
			</dxi-tab>
			<dxi-tab title="Router">
				<dxi-item itemType="group">
					<dx-data-grid #pressProcessesGridContainer class="data-grid" [dataSource]="pressProcesses" [remoteOperations]="false" [allowColumnReordering]="false" [rowAlternationEnabled]="true" [showBorders]="true" height="200px" (onInitNewRow)="onInitNewPressProcessRow($event)" (onSaved)="calculatePressProcessLineData()">
						<dxo-paging [pageSize]="100"></dxo-paging>
						<dxo-pager [visible]="false" [showPageSizeSelector]="false" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
						<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
						<dxo-group-panel [visible]="false"></dxo-group-panel>
						<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
						<dxo-export [enabled]="false"></dxo-export>
						<dxo-editing mode="popup" [allowAdding]="!readOnly" [allowDeleting]="!readOnly" [allowUpdating]="!readOnly" [confirmDelete]="true" [useIcons]="true" newRowPosition="last" [changes]="changes" [(editRowKey)]="editRowKey">
							<dxo-popup title="Press Process Details" [showTitle]="true" [width]="'75%'" [height]="'auto'"></dxo-popup>
							<dxo-form [colCount]="2">
								<dxi-item dataField="processId" editorType="dxSelectBox" [editorOptions]="{ items: processes, searchEnabled: true, displayExpr:'name', valueExpr:'id'}" [label]="{ text: 'Process' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
								<dxi-item dataField="machineId" editorType="dxSelectBox" [editorOptions]="{ items: processes, searchEnabled: true, displayExpr: displayMachineInDropdown, valueExpr:'id'}" [label]="{ text: 'Machine' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
								<dxi-item dataField="machineTonnage" [editorOptions]="{readOnly: true }" [label]="{ text: 'Tonnage' }"></dxi-item>
								<dxi-item dataField="partsPerHour" editorType="dxNumberBox" [editorOptions]="{format: {type:'fixedPoint', precision: 0, min: 0}}" [label]="{ text: 'Parts per Hour' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
								<dxi-item dataField="partsPerBlow" editorType="dxNumberBox" [editorOptions]="{format: {type:'fixedPoint', precision: 0, min: 0}}" [label]="{ text: 'Parts per Blow' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
								<dxi-item dataField="men" editorType="dxNumberBox" [editorOptions]="{format: {type:'fixedPoint', precision: 0, min: 0}}" [label]="{ text: 'Men' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
								<dxi-item [colSpan]="2">Leave hourly running costs empty to take them from the process.</dxi-item>
								<dxi-item dataField="hourlyRunningCostLabour" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0} }" [label]="{ text: 'Hourly Cost: Labour' }"></dxi-item>
								<dxi-item dataField="hourlyRunningCostMachine" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0} }" [label]="{ text: 'Hourly Cost: Machine' }"></dxi-item>
								<dxi-item dataField="hourlyRunningCostTotal" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0}, readOnly: true }" [label]="{ text: 'Hourly Cost: Total' }"></dxi-item>
								<dxi-item [colSpan]="2">Leave setting cost empty to take it from the machine.</dxi-item>
								<dxi-item dataField="settingCost" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0} }" [label]="{ text: 'Setting Cost' }"></dxi-item>
								<dxi-item dataField="processCost" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0}, readOnly: true }" [label]="{ text: 'Process Cost' }"></dxi-item>
								<dxi-item dataField="setupTime" editorType="dxNumberBox" [editorOptions]="{format: {type:'fixedPoint', precision: 0, min: 0}}" [label]="{ text: 'Setup Time (minutes)' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
								<dxi-item dataField="processTime" editorType="dxNumberBox" [editorOptions]="{format: {type:'fixedPoint', precision: 0, min: 0}}" [label]="{ text: 'Process Time (seconds per part)' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
								<dxi-item dataField="totalTime" editorType="dxNumberBox" [editorOptions]="{format: {type:'fixedPoint', precision: 0, min: 0}, readOnly: true }" [label]="{ text: 'Total Time (minutes)' }"></dxi-item>
							</dxo-form>
						</dxo-editing>
						<dxo-toolbar>
							<dxi-item name="addRowButton" showText="always"></dxi-item>
						</dxo-toolbar>
						<dxi-column dataField="processId" dataType="number" alignment="left" caption="Process">
							<dxo-lookup [dataSource]="processes" displayExpr="name" valueExpr="id"></dxo-lookup>
						</dxi-column>
						<dxi-column dataField="machineId" dataType="number" alignment="left" caption="Machine">
							<dxo-lookup [dataSource]="machines" displayExpr="name" valueExpr="id" [displayExpr]="displayMachineInDropdown"></dxo-lookup>
						</dxi-column>
						<dxi-column dataField="tonnage" dataType="text" alignment="left" caption="Tonnage"></dxi-column>
						<dxi-column dataField="partsPerHour" dataType="number" alignment="left" caption="Parts/Hour"></dxi-column>
						<dxi-column dataField="partsPerBlow" dataType="number" alignment="left" caption="Parts/Blow"></dxi-column>
						<dxi-column dataField="men" dataType="number" alignment="left" caption="Men"></dxi-column>
						<dxi-column dataField="hourlyRunningCostLabour" dataType="number" alignment="right" caption="Hourly Labour" [format]="{ type:'currency', currency: currency, precision: 4, min: 0 }"></dxi-column>
						<dxi-column dataField="hourlyRunningCostMachine" dataType="number" alignment="right" caption="Hourly Machine" [format]="{ type:'currency', currency: currency, precision: 4, min: 0 }"></dxi-column>
						<dxi-column dataField="hourlyRunningCostTotal" dataType="number" alignment="right" caption="Hourly Total" [format]="{ type:'currency', currency: currency, precision: 4, min: 0 }"></dxi-column>
						<dxi-column dataField="settingCost" dataType="number" alignment="right" caption="Setting Cost" [format]="{ type:'currency', currency: currency, precision: 4, min: 0 }"></dxi-column>
						<dxi-column dataField="processCost" dataType="number" alignment="right" caption="Process Cost" [format]="{ type:'currency', currency: currency, precision: 4, min: 0 }"></dxi-column>
						<dxi-column dataField="setupTime" dataType="number" alignment="right" caption="Setup Time"></dxi-column>
						<dxi-column dataField="processTime" dataType="number" alignment="right" caption="Process Time"></dxi-column>
						<dxi-column dataField="totalTime" dataType="number" alignment="right" caption="Total Time"></dxi-column>
						<dxi-column type="buttons">
							<dxi-button name="edit"></dxi-button>
							<dxi-button name="delete"></dxi-button>
						</dxi-column>
					</dx-data-grid>
				</dxi-item>
				<dxi-item itemType="group">
					<dxi-item dataField="pressProcessSubtotal" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0}, readOnly: true }" [label]="{ text: 'Press Process Costs' }"></dxi-item>
				</dxi-item>
			</dxi-tab>
			<dxi-tab title="Tools & Processes">
				<dxi-item itemType="group" caption="Tooling & Fixtures">
					<dxi-item itemType="group">
						<dx-data-grid #toolingFixturesGridContainer class="data-grid" [dataSource]="toolingFixtures" [remoteOperations]="false" [allowColumnReordering]="false" [rowAlternationEnabled]="true" [showBorders]="true" height="200px" (onInitNewRow)="onInitNewToolingFixtureRow($event)" (onSaved)="calculateToolingFixturesLineData()">
							<dxo-paging [pageSize]="100"></dxo-paging>
							<dxo-pager [visible]="false" [showPageSizeSelector]="false" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
							<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
							<dxo-group-panel [visible]="false"></dxo-group-panel>
							<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
							<dxo-export [enabled]="false"></dxo-export>
							<dxo-editing mode="popup" [allowAdding]="!readOnly" [allowDeleting]="!readOnly" [allowUpdating]="!readOnly" [confirmDelete]="true" [useIcons]="true" newRowPosition="last" [changes]="changes" [(editRowKey)]="editRowKey">
								<dxo-popup title="Tooling / Fixture Details" [showTitle]="true" [width]="'75%'" [height]="'auto'"></dxo-popup>
								<dxo-form [colCount]="2">
									<dxi-item dataField="description" editorType="dxSelectBox" [editorOptions]="{ items: toolingFixtureDescriptions, searchEnabled: true, displayExpr:'name', valueExpr:'id' }" [label]="{ text: 'Description' }"></dxi-item>	
									<dxi-item dataField="superseded" editorType="dxCheckBox" [label]="{ text: 'Superseded' }"></dxi-item>
									<dxi-item dataField="change" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0} }" [label]="{ text: 'Change' }"></dxi-item>
									<dxi-item dataField="total" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0} }" [label]="{ text: 'Total' }"></dxi-item>
									<dxi-item dataField="overhead" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0} }" [label]="{ text: 'Overhead' }"></dxi-item>
								</dxo-form>
							</dxo-editing>
							<dxo-toolbar>
								<dxi-item name="addRowButton" showText="always"></dxi-item>
							</dxo-toolbar>
							<dxi-column dataField="description" dataType="number" alignment="left" caption="Item">
								<dxo-lookup [dataSource]="toolingFixtureDescriptions" displayExpr="name" valueExpr="id"></dxo-lookup>
							</dxi-column>
							<dxi-column dataField="superseded" alignment="left" caption="Superseded"></dxi-column>
							<dxi-column dataField="change" dataType="number" alignment="right" caption="Change" [format]="{ type:'currency', currency: currency, precision: 4, min: 0 }"></dxi-column>
							<dxi-column dataField="total" dataType="number" alignment="right" caption="Total" [format]="{ type:'currency', currency: currency, precision: 4, min: 0 }"></dxi-column>
							<dxi-column dataField="overhead" dataType="number" alignment="right" caption="Overhead" [format]="{ type:'currency', currency: currency, precision: 4, min: 0 }"></dxi-column>
							<dxi-column type="buttons">
								<dxi-button name="edit"></dxi-button>
								<dxi-button name="delete"></dxi-button>
							</dxi-column>
						</dx-data-grid>
					</dxi-item>
					<dxi-item itemType="group">
						<dxi-item dataField="toolingFixtureSubtotal" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0}, readOnly: true }" [label]="{ text: 'Totals' }"></dxi-item>
						<dxi-item dataField="toolingFixtureTotalChanges" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0}, readOnly: true }" [label]="{ text: 'Changes' }"></dxi-item>
					</dxi-item>
				</dxi-item>
				<dxi-item itemType="group" caption="Outsourced Processes">
					<dxi-item itemType="group">
						<dx-data-grid #outsourcedProcessesGridContainer class="data-grid" [dataSource]="outsourcedProcesses" [remoteOperations]="false" [allowColumnReordering]="false" [rowAlternationEnabled]="true" [showBorders]="true" height="200px" (onSaved)="calculateOutsourcedProcessesLineData()">
							<dxo-paging [pageSize]="100"></dxo-paging>
							<dxo-pager [visible]="false" [showPageSizeSelector]="false" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
							<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
							<dxo-group-panel [visible]="false"></dxo-group-panel>
							<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
							<dxo-export [enabled]="false"></dxo-export>
							<dxo-editing mode="popup" [allowAdding]="!readOnly" [allowDeleting]="!readOnly" [allowUpdating]="!readOnly" [confirmDelete]="true" [useIcons]="true" newRowPosition="last" [changes]="changes" [(editRowKey)]="editRowKey">
								<dxo-popup title="Outsourced Process Details" [showTitle]="true" [width]="'75%'" [height]="'auto'"></dxo-popup>
								<dxo-form [colCount]="2">
									<dxi-item dataField="supplierId" editorType="dxSelectBox" [editorOptions]="{ items: suppliers, searchEnabled: true, displayExpr:'name', valueExpr:'id'}" [label]="{ text: 'Supplier' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
									<dxi-item dataField="outsourcedSpecificationId" editorType="dxSelectBox" [editorOptions]="{ items: outsourcedSpecifications, searchEnabled: true, displayExpr:'specification', valueExpr:'id' }" [label]="{ text: 'Specification' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
									<dxi-item dataField="pricePerPart" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0} }" [label]="{ text: 'Price per Part' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
									<dxi-item dataField="overhead" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0} }" [label]="{ text: 'Overhead' }"></dxi-item>
								</dxo-form>
							</dxo-editing>
							<dxo-toolbar>
								<dxi-item name="addRowButton" showText="always"></dxi-item>
							</dxo-toolbar>
							<dxi-column dataField="supplierId" dataType="number" alignment="left" caption="Supplier">
								<dxo-lookup [dataSource]="suppliers" displayExpr="name" valueExpr="id"></dxo-lookup>
							</dxi-column>
							<dxi-column dataField="outsourcedSpecificationId" dataType="number" alignment="left" caption="Specification">
								<dxo-lookup [dataSource]="outsourcedSpecifications" displayExpr="specification" valueExpr="id"></dxo-lookup>
							</dxi-column>
							<dxi-column dataField="pricePerPart" dataType="number" alignment="right" caption="Price / Part" [format]="{ type:'currency', currency: currency, precision: 4, min: 0 }"></dxi-column>
							<dxi-column dataField="overhead" dataType="number" alignment="right" caption="Overhead" [format]="{ type:'currency', currency: currency, precision: 4, min: 0 }"></dxi-column>
							<dxi-column type="buttons">
								<dxi-button name="edit"></dxi-button>
								<dxi-button name="delete"></dxi-button>
							</dxi-column>
						</dx-data-grid>
					</dxi-item>
					<dxi-item itemType="group">
						<dxi-item dataField="outsourcedProcessesSubtotal" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0}, readOnly: true }" [label]="{ text: 'Outsourced Processes' }"></dxi-item>
					</dxi-item>
				</dxi-item>
			</dxi-tab>
			<dxi-tab title="Additional Details">
				<dxi-item itemType="group" caption="Packaging & Lead Times"  [colCount]="2">
					<dxi-item dataField="packagingTerms" editorType="dxSelectBox" [editorOptions]="{ items: packagingTerms, searchEnabled: true, displayExpr:'name', showClearButton: false, valueExpr:'id' }" [label]="{ text: 'Packaging Terms' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
					<dxi-item dataField="packagingId" editorType="dxSelectBox" [editorOptions]="{ items: packaging, searchEnabled: true, displayExpr:'name', showClearButton: true, valueExpr:'id' }" [label]="{ text: 'Packaging' }"></dxi-item>
					<dxi-item dataField="deliveryTerms" editorType="dxSelectBox" [editorOptions]="{ items: incoterms, searchEnabled: true, displayExpr:'name', valueExpr:'id' }" [label]="{ text: 'Delivery Terms' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>	
					<dxi-item dataField="leadTimeFirstOff" [label]="{ text: 'Production Tooling Lead Time to First Off Parts (weeks)' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
					<dxi-item dataField="leadTimePhaseThree" [label]="{ text: 'Production Tooling Lead Time to Phase 3 PSW (weeks)' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
				</dxi-item>
				<dxi-item itemType="group" caption="Additional Costs"  [colCount]="2">
					<dxi-item dataField="boughtOutPartsOverhead" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0}, readOnly: true }" [label]="{ text: 'Bought Out Overhead' }"></dxi-item>
					<dxi-item dataField="boughtOutPartsOverheadDisplayToCustomer" editorType="dxCheckBox" [label]="{ text: 'Display to Customer' }"></dxi-item>
					<dxi-item dataField="materialOverhead" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0}, readOnly: true }" [label]="{ text: 'Material Overhead' }"></dxi-item>
					<dxi-item dataField="materialOverheadDisplayToCustomer" editorType="dxCheckBox" [label]="{ text: 'Display to Customer' }"></dxi-item>
					<dxi-item dataField="outsourcedOverhead" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0}, readOnly: true }" [label]="{ text: 'Outsourced Overhead' }"></dxi-item>
					<dxi-item dataField="outsourcedOverheadDisplayToCustomer" editorType="dxCheckBox" [label]="{ text: 'Display to Customer' }"></dxi-item>
					<dxi-item dataField="toolingOverhead" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0}, readOnly: true }" [label]="{ text: 'Tooling Overhead' }"></dxi-item>
					<dxi-item dataField="toolingOverheadDisplayToCustomer" editorType="dxCheckBox" [label]="{ text: 'Display to Customer' }"></dxi-item>
					<dxi-item dataField="profit" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0}, readOnly: true }" [label]="{ text: 'Profit' }"></dxi-item>
					<dxi-item dataField="profitDisplayToCustomer" editorType="dxCheckBox" [label]="{ text: 'Display to Customer' }"></dxi-item>
					<dxi-item dataField="additionalCostsSubtotal" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0}, readOnly: true }" [label]="{ text: 'Subtotal' }"></dxi-item>
					<dxi-item dataField="additionalCostsSubtotalDisplayToCustomer" editorType="dxCheckBox" [label]="{ text: 'Display to Customer' }"></dxi-item>
				</dxi-item>
			</dxi-tab>
			<dxi-tab title="Relationships">
				<dxi-item dataField="parentQuotationAnalysisFormId" editorType="dxSelectBox" [editorOptions]="{ items: parentQAFs, searchEnabled: true, displayExpr:'id', valueExpr:'id', showClearButton: true }" [label]="{ text: 'Parent QAF' }"></dxi-item>
				<dxi-item itemType="group" [colSpan]="1" [colCount]="1">
					<dx-data-grid class="data-grid" [dataSource]="childQAFs" [remoteOperations]="false" [allowColumnReordering]="false" [rowAlternationEnabled]="true" [showBorders]="true" height="200px">
						<dxo-paging [pageSize]="100"></dxo-paging>
						<dxo-pager [visible]="false" [showPageSizeSelector]="false" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
						<dxo-search-panel [visible]="false" [highlightCaseSensitive]="true"></dxo-search-panel>
						<dxo-group-panel [visible]="false"></dxo-group-panel>
						<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
						<dxo-export [enabled]="false"></dxo-export>
						<dxi-column dataField="partNumber" dataType="text" alignment="left" caption="Part Number"></dxi-column>
						<dxi-column dataField="batchSize" dataType="number" alignment="left" caption="Batch Size"></dxi-column>
						<dxi-column dataField="partsTotal" dataType="number" alignment="right" caption="Parts Total" [format]="{ type:'currency', currency: currency, precision: 4, min: 0 }"></dxi-column>
						<dxi-column dataField="packagingCost" dataType="number" alignment="right" caption="Packaging Cost" [format]="{ type:'currency', currency: currency, precision: 4, min: 0 }"></dxi-column>
						<dxi-column dataField="containerCost" dataType="number" alignment="right" caption="Container Cost" [format]="{ type:'currency', currency: currency, precision: 4, min: 0 }"></dxi-column>
						<dxi-column dataField="grandTotal" dataType="number" alignment="right" caption="Total" [format]="{ type:'currency', currency: currency, precision: 4, min: 0 }"></dxi-column>
						<dxi-column type="buttons">
							<dxi-button hint="View" icon="fal fa-eye" [onClick]="viewChildQAF"></dxi-button>
							<dxi-button [visible]="mode != 'view'" hint="Delete" icon="fal fa-trash" [onClick]="removeChildQAF"></dxi-button>
						</dxi-column>
					</dx-data-grid>
				</dxi-item>
			</dxi-tab>
			<dxi-tab title="Finishing Details" [colCount]="2">
				<dxi-item dataField="totalPartCost" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0}, readOnly: true }" [label]="{ text: 'Parts Total' }"></dxi-item>
				<dxi-item dataField="packagingAndLogistics" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0} }" [label]="{ text: 'Packaging & Logistics' }"></dxi-item>
				<dxi-item dataField="containerCost" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0} }" [label]="{ text: 'Container Cost / Rent' }"></dxi-item>
				<dxi-item dataField="grandTotal" editorType="dxNumberBox" [editorOptions]="{format: {type:'currency', currency: currency, precision: 4, min: 0}, readOnly: true }" [label]="{ text: 'Total' }"></dxi-item>
				<dxi-item dataField="unitOfMeasure" [label]="{ text: 'Unit of Measure' }"></dxi-item>
				<dxi-item dataField="currency" editorType="dxSelectBox" [editorOptions]="{ items: currencies, searchEnabled: true, displayExpr:'name', valueExpr:'id' }" [label]="{ text: 'Currency' }"></dxi-item>
				<dxi-item dataField="additionalNotes" editorType="dxTextArea" [colSpan]="2" [editorOptions]="{ height: 150 }" [label]="{ text: 'Additional Notes' }"></dxi-item>
				<dxi-item itemType="group" caption="Approval" [colSpan]="2" [colCount]="2">
					<dxi-item dataField="approved" editorType="dxCheckBox" [label]="{ text: 'Approved' }" [editorOptions]="{ readOnly: true }"></dxi-item>
					<dxi-item dataField="approvedById" editorType="dxSelectBox" [editorOptions]="{ items: userProfiles, searchEnabled: true, displayExpr:'fullName', valueExpr:'id', readOnly: true }" [label]="{ text: 'Approved By' }"></dxi-item>
					<dxi-item dataField="approvedOn" [label]="{ text: 'Approved On' }" [editorOptions]="{ readOnly: true }"></dxi-item>
				</dxi-item>
			</dxi-tab>
		</dxi-item>
	</dx-form>
	<dx-popup [width]="750" [height]="600" [showTitle]="true" [title]="popupTitle" [dragEnabled]="false" [closeOnOutsideClick]="false" [showCloseButton]="false" container=".dx-viewport" [(visible)]="popupVisible">
		<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="before" [options]="popupAddButtonOptions"></dxi-toolbar-item>
		<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="after" [options]="popupCloseButtonOptions"></dxi-toolbar-item>
		<div *dxTemplate="let data of 'content'">
			<div *ngIf="popupTitle == 'Add New CAD File'">
				<dx-form class="m-3" [(formData)]="popupCADFile" [showColonAfterLabel]="true" labelLocation="top">
					<dxi-item dataField="description" [label]="{ text: 'Version' }">
						<dxi-validation-rule type="required" message="Version is required"></dxi-validation-rule>					
					</dxi-item>
					<dxi-item>
						<dx-file-uploader [maxFileSize]="4194304" [uploadFile]="uploadCADFile"></dx-file-uploader>
					</dxi-item>
				</dx-form>
			</div>
			<div *ngIf="popupTitle == 'Add New Supporting Document'">
				<dx-form class="m-3" [(formData)]="popupSupportingDocument" [showColonAfterLabel]="true" labelLocation="top">
					<dxi-item dataField="description">
						<dxi-validation-rule type="required" message="Description is required"></dxi-validation-rule>					
					</dxi-item>
					<dxi-item>
						<dx-file-uploader [maxFileSize]="4194304" [uploadFile]="uploadSupportingDocument"></dx-file-uploader>
					</dxi-item>
				</dx-form>
			</div>
		</div>
	</dx-popup>
</div>