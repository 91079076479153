<admin-header [primaryButtons]="headerPrimaryButtons" [secondaryButtons]="headerSecondaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<div id="dashboard-container">
	<div *ngIf="mode === 'GoodsIn'" id="goodsIn" style="margin-top:1rem;">
		<table class="goodsInBoardHeader">
			<thead>
				<tr>
					<th><img src="../../../assets/full-logo.jpg"></th>
					<th>Goods In Dashboard</th>
					<th>Last Update</th>
					<th>{{today}}</th>
				</tr>
			</thead>
		</table>
		<ul class="goodsInDashboardLegend">
			<li class="received">Received</li>
		</ul>
		<div *ngFor="let week of goodsInDashboard" class="goodsInDashboardWeek">
			<h2>{{week.header}}</h2>
			<table class="goodsInDashboard">
				<thead>
					<tr>
						<th *ngFor="let day of week.columns" scope="col" class="{{day.isEndOfMonth == true ? 'monthEnd' : ''}}">{{day.day}}<br >{{day.date}}</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td *ngFor="let day of week.columns">
							<div *ngFor="let purchaseLog of day.purchaseLogs" class="{{purchaseLog.received === true ? 'received' : ''}}">
								<span>{{purchaseLog.id}}</span>
								<span>{{purchaseLog.supplier}}</span>
								<span>Lines: {{purchaseLog.lineItems}}</span>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
	<div *ngIf="mode === 'Promises'" id="promises" style="margin-top:1rem;">
		<table class="promiseBoardHeader">
			<thead>
				<tr>
					<th><img src="../../../assets/full-logo.jpg"></th>
					<th>Weekly Promise</th>
					<th>Last Update</th>
					<th>{{today}}</th>
				</tr>
			</thead>
		</table>
		<ul class="promiseBoardLegend">
			<li class="monthEnd">End of Month</li>
			<li class="internal">Internal (BPL)</li>
			<li class="external">External (Client)</li>
			<li class="complete">Complete</li>
			<li class="private"><i class="fal fa-user-lock"></i> Private</li>
			<li class="filter">
				<dx-select-box [dataSource]="promiseDepartments" displayExpr="name" placeholder="Filter" valueExpr="id" [showClearButton]="true" (onValueChanged)="departmentFilterChanged($event)"></dx-select-box>
			</li>
		</ul>
		<div *ngFor="let week of promiseBoard" class="promiseBoardWeek">
			<h2>{{week.header}}</h2>
			<table class="promiseBoard">
				<thead>
					<tr>
						<th *ngFor="let day of week.columns" scope="col" class="{{day.isEndOfMonth == true ? 'monthEnd' : ''}}">{{day.day}}<br >{{day.date}}</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td *ngFor="let day of week.columns">
							<div *ngFor="let promise of day.promises" class="{{promise.promiseType === 10 ? 'internal' : 'external'}}{{promise.completed === true ? ' complete' : ''}}{{promise.isPrivate === true ? ' private' : ''}}">
								<b *ngIf="promise.isPrivate" class="privateIcon"><i class="fal fa-user-lock"></i></b>
								<span>{{promise.description}}</span>
								<a href="#" (click)="editPromisePopup(promise)"><i class="fal fa-pencil"></i> Edit</a>
								<a href="#" (click)="deletePromise(promise)"><i class="fal fa-trash"></i> Delete</a>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
	<div *ngIf="mode === 'Sales'" id="sales" style="margin-top:1rem;">
		<div class="row">
			<div class="col-3">
				<dx-pie-chart palette="bright" [dataSource]="myBreakdown">
					<dxi-series argumentField="type" valueField="amount">
						<dxo-label [visible]="true">
							<dxo-connector [visible]="true" [width]="1"></dxo-connector>
						</dxo-label>
					</dxi-series>
					<dxo-size [height]="250" [width]="300"></dxo-size>
				</dx-pie-chart>
			</div>
			<div class="col-3 text-center py-5">
				<label class="d-block fs-20">Sales Value (30 Days)</label>
				<label class="d-block fs-30">£{{thirtyDayValue}}</label>
			</div>
			<div class="col-3 text-center py-5">
				<label class="d-block fs-20">Quotes Waiting Approval</label>
				<label class="d-block fs-30">{{quotesWaitingApproval}}</label>
			</div>
			<div class="col-3 text-center py-5">
				<label class="d-block fs-20">Quotes Sent</label>
				<label class="d-block fs-30">{{quotesSent}}</label>
			</div>
		</div>
		<div class="row">
			<div class="col-3">
				<a>
					<div class="button-container">
					<div class="icon"><div><i class="fal fa-question"></i></div></div>
					<div class="icon-text">Enquiries</div>
					</div>
				</a>
			</div>
			<div class="col-3">
				<a>
					<div class="button-container">
						<div class="icon"><i class="fal fa-conveyor-belt-alt"></i></div>
						<div class="icon-text">Production Quotes</div>
					</div>
				</a>
			</div>
			<div class="col-3">
				<a>
					<div class="button-container">
						<div class="icon"><i class="fal fa-hammer"></i></div>
						<div class="icon-text">Prototype Quotes</div>
					</div>
				</a>
			</div>
			<div class="col-3">
				<a>
					<div class="button-container">
						<div class="icon"><i class="fal fa-chart-pie"></i></div>
						<div class="icon-text">Sales Analysis</div>
					</div>
				</a>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<dx-data-grid class="data-grid" [dataSource]="mySummary" [remoteOperations]="false" [allowColumnReordering]="false" [rowAlternationEnabled]="true" [showBorders]="true">
					<dxo-paging [pageSize]="100"></dxo-paging>
					<dxo-state-storing [enabled]="true" type="localStorage" storageKey="salesDashboardMySummary"></dxo-state-storing>
					<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
					<dxi-column type="buttons">
						<dxi-button hint="View" icon="fal fa-eye" [onClick]="viewSalesEnquiryRow"></dxi-button>
					</dxi-column>
					<dxi-column dataField="quoteNumber" alignment="left"></dxi-column>
					<dxi-column dataField="createdAt" caption="Date Created" dataType="date" format="dd/MM/yyyy"></dxi-column>
					<dxi-column dataField="client.name" caption="Customer Name"></dxi-column>
					<dxi-column dataField="enquiryValue" caption="Quote Value" alignment="right" [format]="{ style: 'currency', currency: 'GBP', useGrouping: true, minimumSignificantDigits: 3 }"></dxi-column>	
					<dxi-column dataField="enquiryQuantity" alignment="right"></dxi-column>
					<dxi-column [showEditorAlways]='false' dataField="approved" alignment="left" [customizeText]='boolYesNo'></dxi-column>
					<dxi-column [showEditorAlways]='false' dataField="quoted" alignment="left" [customizeText]='boolYesNo'></dxi-column>
					<dxi-column dataField="quoteSubmitted" dataType="date" format="dd/MM/yyyy"></dxi-column>	
				</dx-data-grid>
			</div>
		</div>
	</div>
	<div *ngIf="mode === 'Staff'" id="staff" style="margin-top:1rem;">
		<dx-form class="m-3" [colCount]="2">
			<dxi-item itemType="group" caption="Day Shift Holidays" [colSpan]="1" [colCount]="1">
				<dx-data-grid class="data-grid" [dataSource]="dayShiftHolidayRequests" [remoteOperations]="false" [allowColumnReordering]="true" [allowColumnResizing]="true" [rowAlternationEnabled]="true" [showBorders]="true">
					<dxo-paging [pageSize]="100"></dxo-paging>
					<dxo-state-storing [enabled]="true" type="localStorage" storageKey="storage"></dxo-state-storing>
					<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
					<dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
					<dxo-group-panel [visible]="true"></dxo-group-panel>
					<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
					<dxo-export [enabled]="true"></dxo-export>
					<dxi-column dataField="requestedById" caption="Employee"><dxo-lookup [dataSource]="people" valueExpr="id" displayExpr="fullName"></dxo-lookup></dxi-column>
					<dxi-column dataField="startDate" dataType="date" alignment="left" caption="Start Date" format="dd/MM/yyyy" sortOrder="asc"></dxi-column>
					<dxi-column dataField="startTime" dataType="number" alignment="left" caption="Start Time"><dxo-lookup [dataSource]="holidayTimes" displayExpr="name" valueExpr="id"></dxo-lookup></dxi-column>
					<dxi-column dataField="endDate" dataType="date" alignment="left" caption="End Date" format="dd/MM/yyyy"></dxi-column>
					<dxi-column dataField="endTime" dataType="number" alignment="left" caption="End Time"><dxo-lookup [dataSource]="holidayTimes" displayExpr="name" valueExpr="id"></dxo-lookup></dxi-column>
				</dx-data-grid>
			</dxi-item>
			<dxi-item itemType="group" caption="Night Shift Holidays" [colSpan]="1" [colCount]="1">
				<dx-data-grid class="data-grid" [dataSource]="nightShiftHolidayRequests" [remoteOperations]="false" [allowColumnReordering]="true" [allowColumnResizing]="true" [rowAlternationEnabled]="true" [showBorders]="true">
					<dxo-paging [pageSize]="100"></dxo-paging>
					<dxo-state-storing [enabled]="true" type="localStorage" storageKey="storage"></dxo-state-storing>
					<dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
					<dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
					<dxo-group-panel [visible]="true"></dxo-group-panel>
					<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
					<dxo-export [enabled]="true"></dxo-export>
					<dxi-column dataField="requestedById" caption="Employee"><dxo-lookup [dataSource]="people" valueExpr="id" displayExpr="fullName"></dxo-lookup></dxi-column>
					<dxi-column dataField="startDate" dataType="date" alignment="left" caption="Start Date" format="dd/MM/yyyy" sortOrder="asc"></dxi-column>
					<dxi-column dataField="startTime" dataType="number" alignment="left" caption="Start Time"><dxo-lookup [dataSource]="holidayTimes" displayExpr="name" valueExpr="id"></dxo-lookup></dxi-column>
					<dxi-column dataField="endDate" dataType="date" alignment="left" caption="End Date" format="dd/MM/yyyy"></dxi-column>
					<dxi-column dataField="endTime" dataType="number" alignment="left" caption="End Time"><dxo-lookup [dataSource]="holidayTimes" displayExpr="name" valueExpr="id"></dxo-lookup></dxi-column>
				</dx-data-grid>
			</dxi-item>
		</dx-form>
	</div>
	<div *ngIf="mode === 'Tools'" id="tools" style="margin-top:1rem;">
	</div>
</div>
<dx-popup [width]="'75%'" [height]="600" [showTitle]="true" [title]="popupTitle" [dragEnabled]="false" [closeOnOutsideClick]="false" [showCloseButton]="false" container=".dx-viewport" [(visible)]="popupVisible">
	<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="before" [options]="popupAddButtonOptions"></dxi-toolbar-item>
	<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="after" [options]="popupCloseButtonOptions"></dxi-toolbar-item>
	<div *dxTemplate="let data of 'content'">
		<dx-form class="m-3" [(formData)]="popupPromise" [showColonAfterLabel]="true" labelLocation="top">
			<dxi-item dataField="promiseDate" editorType="dxDateBox" [editorOptions]="{ displayFormat: 'dd/MM/yyyy', type: 'date' }" [label]="{ text: 'Promise Date' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
			<dxi-item dataField="promiseDepartment" editorType="dxSelectBox" [editorOptions]="{ items: promiseDepartments, displayExpr:'name', valueExpr:'id', searchEnabled: true }" [colSpan]="3"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
			<dxi-item dataField="promiseType" editorType="dxSelectBox" [editorOptions]="{ items: promiseTypes, displayExpr:'name', valueExpr:'id', searchEnabled: true }" [colSpan]="3"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
			<dxi-item dataField="description"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>
			<dxi-item dataField="completed" editorType="dxCheckBox"></dxi-item>
			<dxi-item dataField="isPrivate" editorType="dxCheckBox"></dxi-item>
		</dx-form>
	</div>
</dx-popup>