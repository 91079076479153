<admin-header [primaryButtons]="headerPrimaryButtons" [secondaryButtons]="headerSecondaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<dx-data-grid id="grid" class="data-grid" [dataSource]="items" [remoteOperations]="false" [allowColumnReordering]="true" [allowColumnResizing]="true" [rowAlternationEnabled]="true" [showBorders]="true" (onExporting)="logExport()">
    <dxo-paging [pageSize]="100"></dxo-paging>
	<dxo-state-storing [enabled]="true" type="localStorage" storageKey="salesEnquiryFeasibility"></dxo-state-storing>
    <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
    <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
	<dxo-export [enabled]="true"></dxo-export>
	<dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>
	<dxi-column type="buttons">
        <dxi-button hint="Production Sign Off" icon="fal fa-clipboard-check" [onClick]="signOffProductionPopup" [visible]="isSignOffAsProductionAvailable"></dxi-button>
        <dxi-button hint="Quality Sign Off" icon="fal fa-clipboard-check" [onClick]="signOffQualityPopup" [visible]="isSignOffAsQualityAvailable"></dxi-button>
    </dxi-column>
    <dxi-column dataField="id" alignment="left" sortOrder="desc"></dxi-column>
	<dxi-column dataField="assignedToId" caption="Assigned To">
		<dxo-lookup [dataSource]="users" valueExpr="id" displayExpr="fullName"></dxo-lookup>
	</dxi-column>
	<dxi-column dataField="client.name" caption="Client Name"></dxi-column>
	<dxi-column [showEditorAlways]='false' dataField="productionSignedOffById" alignment="left" [customizeText]="signedOffYesNo" caption="Signed Off by Production"></dxi-column>
	<dxi-column [showEditorAlways]='false' dataField="qualitySignedOffById" alignment="left" [customizeText]="signedOffYesNo" caption="Signed Off by Quality"></dxi-column>
</dx-data-grid>
<dx-popup [width]="'75%'" [height]="750" [showTitle]="true" [title]="popupTitle" [dragEnabled]="false" [closeOnOutsideClick]="false" [showCloseButton]="false" container=".dx-viewport" [(visible)]="popupVisible">
	<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="before" [options]="popupSaveButtonOptions"></dxi-toolbar-item>
	<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="after" [options]="popupCloseButtonOptions"></dxi-toolbar-item>
	<div *dxTemplate="let data of 'content'">
		<div>
			<dx-form class="m-3 feasibilityPopupForm" [(formData)]="popupSalesEnquiry" [showColonAfterLabel]="true" labelLocation="top" [colCount]="2" [readOnly]="true">
				<dxi-item itemType="group">
					<dxi-item dataField="feasibilityQuestionIsProductAdequatelyDefined" editorType="dxSelectBox" [editorOptions]="{ items: yesNoDropdown, searchEnabled: false, displayExpr:'name', valueExpr:'id', showClearButton: true }" [label]="{ text: 'Is product adequately defined (application requirements, etc. to enable feasibility evaluation?)' }"></dxi-item>
					<dxi-item dataField="feasibilityQuestionCanEngineeringPerformanceSpecsBeMet" editorType="dxSelectBox" [editorOptions]="{ items: yesNoDropdown, searchEnabled: false, displayExpr:'name', valueExpr:'id', showClearButton: true }" [label]="{ text: 'Can Engineering Performance Specifications be met as written?' }"></dxi-item>
					<dxi-item dataField="feasibilityQuestionCanProductBeManufacturedToTolerances" editorType="dxSelectBox" [editorOptions]="{ items: yesNoDropdown, searchEnabled: false, displayExpr:'name', valueExpr:'id', showClearButton: true }" [label]="{ text: 'Can product be manufactured with process capability that meet requirements?' }"></dxi-item>
					<dxi-item dataField="feasibilityQuestionCanProductBeManufacturedWithProcessCapability" editorType="dxSelectBox" [editorOptions]="{ items: yesNoDropdown, searchEnabled: false, displayExpr:'name', valueExpr:'id', showClearButton: true }" [label]="{ text: 'Is product adequately defined (application requirements, etc. to enable feasibility evaluation?)' }"></dxi-item>
					<dxi-item dataField="feasibilityQuestionCorrelatePrevention" editorType="dxSelectBox" [editorOptions]="{ items: yesNoDropdown, searchEnabled: false, displayExpr:'name', valueExpr:'id', showClearButton: true }" [label]="{ text: 'Correlate prevention to CCs SCs & HICs to determine effectiveness of the method (PFMEA)' }"></dxi-item>
					<dxi-item dataField="feasibilityQuestionIsAdequateCapacity" editorType="dxSelectBox" [editorOptions]="{ items: yesNoDropdown, searchEnabled: false, displayExpr:'name', valueExpr:'id', showClearButton: true }" [label]="{ text: 'Is there adequate capacity to produce product?' }"></dxi-item>
					<dxi-item dataField="feasibilityQuestionDoesDesignAllowEfficientHandling" editorType="dxSelectBox" [editorOptions]="{ items: yesNoDropdown, searchEnabled: false, displayExpr:'name', valueExpr:'id', showClearButton: true }" [label]="{ text: 'Does the design allow the use of efficient material handling techniques?' }"></dxi-item>
					<dxi-item dataField="feasibilityQuestionIsStatisticalControlRequired" editorType="dxSelectBox" [editorOptions]="{ items: yesNoDropdown, searchEnabled: false, displayExpr:'name', valueExpr:'id', showClearButton: true }" [label]="{ text: 'Is statistical process control required on the product?' }"></dxi-item>
					<dxi-item dataField="feasibilityQuestionIsStatisticalControlRequiredOnSimilar" editorType="dxSelectBox" [editorOptions]="{ items: yesNoDropdown, searchEnabled: false, displayExpr:'name', valueExpr:'id', showClearButton: true }" [label]="{ text: 'Is statistical process control presently used on similar products?' }"></dxi-item>
				</dxi-item>
				<dxi-item itemType="group">
					<dxi-item itemType="group" caption="Can the product be manufactured within normal cost parameters? Abnormal cost considerations may include:">
						<dxi-item dataField="feasibilityQuestionCostsForCapital" editorType="dxSelectBox" [editorOptions]="{ items: yesNoDropdown, searchEnabled: false, displayExpr:'name', valueExpr:'id', showClearButton: true }" [label]="{ text: 'Costs for capital equipment?' }"></dxi-item>
						<dxi-item dataField="feasibilityQuestionCostsForTooling" editorType="dxSelectBox" [editorOptions]="{ items: yesNoDropdown, searchEnabled: false, displayExpr:'name', valueExpr:'id', showClearButton: true }" [label]="{ text: 'Costs for tooling?' }"></dxi-item>
						<dxi-item dataField="feasibilityQuestionAlternativeManufacturing" editorType="dxSelectBox" [editorOptions]="{ items: yesNoDropdown, searchEnabled: false, displayExpr:'name', valueExpr:'id', showClearButton: true }" [label]="{ text: 'Alternative manufacturing methods?' }"></dxi-item>
					</dxi-item>
					<dxi-item itemType="group" caption="Where statistical process control is used on similar products:">
						<dxi-item dataField="feasibilityQuestionAreProcessesInControl" editorType="dxSelectBox" [editorOptions]="{ items: yesNoDropdown, searchEnabled: false, displayExpr:'name', valueExpr:'id', showClearButton: true }" [label]="{ text: 'Are the processes in control and stable?' }"></dxi-item>
						<dxi-item dataField="feasibilityQuestionDoesCapabilityMeetCustomerRequirements" editorType="dxSelectBox" [editorOptions]="{ items: yesNoDropdown, searchEnabled: false, displayExpr:'name', valueExpr:'id', showClearButton: true }" [label]="{ text: 'Does process capability meet customer requirements?' }"></dxi-item>
					</dxi-item>
					<dxi-item dataField="feasibilityQuestionAdditionalDetails" editorType="dxTextArea" [label]="{ text: 'Additional Details' }" [editorOptions]="{ height: 100 }"></dxi-item>
				</dxi-item>
				<dxi-item *ngIf="popupSalesEnquiry.feasibilityQuestionnaireCompletedOn != null" [colSpan]="2">Feasibility completed on {{formatDateTime(popupSalesEnquiry.feasibilityQuestionnaireCompletedOn)}} by {{renderUser(popupSalesEnquiry.feasibilityQuestionnaireCompletedById)}}</dxi-item>
			</dx-form>
		</div>
	</div>
</dx-popup>
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#grid' }" [(visible)]="gridLoading" [showIndicator]="true" [showPane]="true" [shading]="true"></dx-load-panel>