import { Injectable, Injector } from "@angular/core";
import { environment } from "@environments/environment";
import { UploadCSV } from "@interfaces/uploadCSV.interface";
import { Service } from "@services/service";

@Injectable()
export class PartService extends Service {
	constructor(injector: Injector) {
		super("Part", injector);
	}

	// API
	getBySpecificationSupplier(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetBySpecificationSupplier/${id}`);
	}

	getMasterParts() {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/MasterParts`);
	}
	
	getSubPartsForMaster(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/SubPartsForMaster/${id}`);
	}
	
	importCSV(data: UploadCSV) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/ImportCSV`, data);
	}
	
	incrementBPLVersion(id: number) {
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/IncrementBPLVersion/${id}`, {});
	}

	purchaseSpecifications(id: number) {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/PurchaseSpecifications/${id}`);
	}
}