<admin-header [primaryButtons]="headerPrimaryButtons" [secondaryButtons]="headerSecondaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<dx-data-grid id="grid" class="data-grid" [dataSource]="items" [remoteOperations]="false" [allowColumnReordering]="true" [allowColumnResizing]="true" [rowAlternationEnabled]="true" [showBorders]="true" (onExporting)="logExport()">
    <dxo-paging [pageSize]="100"></dxo-paging>
	<dxo-state-storing [enabled]="true" type="localStorage" storageKey="deliveryMain"></dxo-state-storing>
    <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
    <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
	<dxo-export [enabled]="true"></dxo-export>
	<dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>
	<dxi-column type="buttons">
        <dxi-button hint="View" icon="fal fa-eye" [onClick]="viewRow"></dxi-button>
		<dxi-button icon="fal fa-trash" [onClick]="deleteRowPopup"></dxi-button>
    </dxi-column>
    <dxi-column dataField="id" alignment="left" sortOrder="asc"></dxi-column>
	<dxi-column dataField="clientId" caption="Client">
		<dxo-lookup [dataSource]="clients" displayExpr="name" valueExpr="id"></dxo-lookup>
	</dxi-column>
	<dxi-column dataField="clientLocationId" caption="Delivery Location">
		<dxo-lookup [dataSource]="clientLocations" displayExpr="name" valueExpr="id"></dxo-lookup>
	</dxi-column>
	<dxi-column dataField="createdAt" dataType="date" format="dd/MM/yyyy"></dxi-column>
	<dxi-column dataField="dateSent" dataType="date" format="dd/MM/yyyy"></dxi-column>
</dx-data-grid>
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#grid' }" [(visible)]="gridLoading" [showIndicator]="true" [showPane]="true" [shading]="true"></dx-load-panel>
<dx-popup [width]="'75%'" [height]="600" [showTitle]="true" [title]="popupTitle" [dragEnabled]="false" [closeOnOutsideClick]="false" [showCloseButton]="false" container=".dx-viewport" [(visible)]="popupVisible">
	<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="before" [options]="popupAddButtonOptions"></dxi-toolbar-item>
	<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="after" [options]="popupCloseButtonOptions"></dxi-toolbar-item>
	<div *dxTemplate="let data of 'content'">
		<div *ngIf="popupTitle == 'Delete Delivery Note'">
			<dx-form class="m-3" [(formData)]="popupDeleteObject" [showColonAfterLabel]="true" labelLocation="top">
				<dxi-item dataField="reason" [label]="{ text: 'Reason' }"><dxi-validation-rule type="required"></dxi-validation-rule></dxi-item>	
			</dx-form>
		</div>
		<div *ngIf="popupTitle == 'Generate Detailed Report'">
			<dx-form class="m-3" [(formData)]="popupDeliveryNoteDetailedReportDto" [showColonAfterLabel]="true" labelLocation="top">
				<dxi-item dataField="startDate" editorType="dxDateBox" [editorOptions]="{ displayFormat: 'dd/MM/yyyy', type: 'date' }"></dxi-item>
				<dxi-item dataField="endDate" editorType="dxDateBox" [editorOptions]="{ displayFormat: 'dd/MM/yyyy', type: 'date' }"></dxi-item>
				<dxi-item dataField="clientId" editorType="dxSelectBox" [editorOptions]="{ items: clients, searchEnabled: true, displayExpr:'name', valueExpr:'id', showCloseButton: true }" [label]="{ text: 'Client' }"></dxi-item>	
			</dx-form>
		</div>
	</div>
</dx-popup>