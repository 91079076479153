import { Injectable, Injector } from "@angular/core";
import { environment } from "@environments/environment";
import { Service } from "@services/service";

@Injectable()
export class QuotationAnalysisFormService extends Service {
	constructor(injector: Injector) {
		super("QuotationAnalysisForm", injector);
	}
	
	// API	
	approve(id: number){
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/Approve/${id}`, {});
	}

	getByParentId(id: number){
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetByParentId/${id}`);
	}

	getWithNoParent(){
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/GetWithNoParent`);
	}
	
	removeParentFromQAF(id: number){
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/RemoveParentFromQAF/${id}`, {});
	}
	
	setParentForQAF(id: number, parentId: number){
		return this.http.post(`${environment.server.apiURL}/${this.endpoint}/SetParentForQAF/${id}/${parentId}`, {});
	}
}