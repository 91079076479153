import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { CheckGroupPermissions } from "@helpers/checkGroupPermissions";
import { AdminHeaderButton } from "@interfaces/adminHeaderButton.interface";
import { Client } from "@interfaces/client.interface";
import { ClientLocation } from "@interfaces/clientLocation.interface";
import { DeleteObject } from "@interfaces/deleteObject.interface";
import { InvoiceGridRow } from "@interfaces/invoiceGridRow.interface";
import { LogExport } from "@interfaces/logExport.interface";
import { AppSettingsService } from "@services/appSettings.service";
import { ClientService } from "@services/client.service";
import { ClientLocationService } from "@services/clientLocation.service";
import { ExportLogService } from "@services/exportLog.service";
import { InvoiceService } from "@services/invoice.service";
import notify from "devextreme/ui/notify";

@Component({
	moduleId: module.id,
	selector: "invoices",
	styleUrls: ["invoices.css"],
	templateUrl: "invoices.html"
})
export class InvoicesAdminComponent {
	clientLocations: ClientLocation[] = [];
	clients: Client[] = [];
	gridLoading = false;
	headerPrimaryButtons: AdminHeaderButton[] = [{ method: "viewArchive", text: "View Archive" }];
	headerSecondaryButtons: AdminHeaderButton[] = [];
	isSuperAdmin = false;
	itemType = "Invoice"
	itemUrl: string = location.href.split("/")[3] + "/" + location.href.split("/")[4];
	items: InvoiceGridRow[] = [];
	popupAddButtonOptions: any = { onClick: () => this.deleteRow(), text: "Delete" };
	popupCloseButtonOptions: any = { onClick: () => this.closePopup(), text: "Close" };
	popupDeleteObject: DeleteObject = new DeleteObject();
	popupTitle = "Generate Detailed Report";
	popupVisible = false;
	title: string;
	
	constructor(private appSettingsService: AppSettingsService, private router: Router, private itemService: InvoiceService, private clientService: ClientService, private clientLocationService: ClientLocationService, private exportLogService: ExportLogService) {
		this.title = "Invoices";
		this.deleteRowPopup = this.deleteRowPopup.bind(this);
		this.generatePreviewPDF = this.generatePreviewPDF.bind(this);
		this.viewArchive = this.viewArchive.bind(this);
		this.viewDeleted = this.viewDeleted.bind(this);
		this.viewRow = this.viewRow.bind(this);
		this.viewSalesOrder = this.viewSalesOrder.bind(this);
		
		this.getItems();
		this.getClients();
		this.getClientLocations();
		
		// If Super Admin
		this.isSuperAdmin = CheckGroupPermissions(appSettingsService, "Super Admin");
		if (this.isSuperAdmin) {
			this.headerPrimaryButtons.push({ method: "viewDeleted", text: "Deleted Invoices" });
		}
	}

	closePopup() {
		this.popupVisible = false;
	}

	deleteRow() {
		if (this.popupDeleteObject.reason === "") {
			alert("You must specify a reason for deletion.");
			return;
		}
		this.itemService.delete(this.popupDeleteObject).subscribe(
			() =>{
				notify("Successfully Deleted", "success", 5000);
				this.closePopup();
				this.getItems();
			},
			(err) => {
				console.log(err);
				notify("Something Went Wrong!", "Error", 5000);
			},
		);
	}
	
	deleteRowPopup(e: any) {
		this.popupTitle = "Delete Invoice";
		this.popupDeleteObject = new DeleteObject();
		this.popupDeleteObject.id = e.row.data.id;
		this.popupVisible = true;
		this.popupAddButtonOptions = { onClick: () => this.deleteRow(), text: "Delete" };
		return false;
	}

	generatePreviewPDF(e: any) {
		this.itemService.generateAndPreviewPdf(e.row.data.id).subscribe(
			(response: any) =>{
				const dataType = response.type;
				const binaryData = [];
				binaryData.push(response);
				const downloadLink = document.createElement("a");
				downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
				downloadLink.setAttribute("download", "INV" + e.row.data.id + "-" + this.clients.find(x => x.id == e.row.data.clientId)?.name +  ".pdf");
				document.body.appendChild(downloadLink);
				downloadLink.click();
			}
		);
	}
	
	getClientLocations() {
		this.clientLocationService.getAll()
			.subscribe(
				(res: any) =>  {
					this.clientLocations = res.response.items;
				},
				(err) => console.log(err)
			);
	}
	
	getClients() {
		this.clientService.getAll()
			.subscribe(
				(res: any) =>  {
					this.clients = res.response.items;
				},
				(err) => console.log(err)
			);
	}

	getItems() {
		this.gridLoading = true;
		this.itemService.getRecent()
			.subscribe(
				(res: any) => {
					this.items = res.response;
					this.gridLoading = false;
				},
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
				},
			);
	}

	headerButtonClick(method: any) {
		// @ts-ignore // Required to be able to call the method directly from the variable
		if (this[method]) this[method]();
	}

	logExport() {
		const logExport = new LogExport();
		logExport.gridName = "Invoices";
		this.exportLogService.logExport(logExport)
			.subscribe(
				() => console.log("Export Logged"),
				(err) => {
					console.log(err);
					notify("Something went wrong!", "error", 5000);
					return false;
				},
			);
	}

	viewArchive() {
		this.router.navigate([`${this.itemUrl}/archive`]);
	}

	viewDeleted() {
		this.router.navigate([`${this.itemUrl}/deleted`]);
	}

	viewRow(e: any) {
		this.router.navigate([`${this.itemUrl}/${e.row.data.lookupId}`]);
	}

	viewSalesOrder(e: any) {
		this.router.navigate([`admin/salesOrders/${e.row.data.salesOrderLookupId}`]);
	}
}