<admin-header [primaryButtons]="headerPrimaryButtons" [secondaryButtons]="headerSecondaryButtons" [tertiaryButtons]="headerTertiaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<div class="row">
	<div class="col-8">
		<div class="row">
			<div *ngFor="let machine of machines" class="col-12 machineInfo">
				<h2 style="background-color: {{machine.siteColour}};"><i class="fa fa-dot-circle" style="margin-right:20px; color:{{this.renderStatusColor(machine.productionStatus)}};"></i>{{machine.cellNumber}} - <a href="#" (click)="changeMachineProductionStatusPopup(machine.machineId)">{{machine.productionStatusName}}</a></h2>
				<p>{{machine.machineName}}</p>
				<p>{{machine.department}}</p>
				<table>
					<tbody>
						<tr>
							<th class="running" colspan="10">Running</th>
						</tr>
						<tr>
							<th scope="col">W/O</th>
							<th scope="col">Date Req.</th>
							<th scope="col">Part</th>
							<th scope="col">OP</th>
							<th scope="col">QReq.</th>
							<th scope="col">QComp.</th>
							<th scope="col">Operator</th>
							<th></th>
							<th></th>
							<th></th>
						</tr>
						<tr *ngFor="let workOrderStage of machine.runningStages" [class]="workOrderStage.topFive === true || machinesShowingAllRunning.indexOf(machine.machineId) >= 0 ? '' : 'hidden'">
							<th scope="row" [class]="workOrderStage.isPaused ? 'paused' : ''"><i *ngIf="workOrderStage.isPaused" class="fa-pause-circle fal me-2"></i><a href="/admin/workOrders/{{workOrderStage.workOrderId}}">{{workOrderStage.workOrderId}}</a></th>
							<td>{{workOrderStage.dateRequired.split('T')[0]}}</td>
							<td id="runningPartNumber{{workOrderStage.id}}">{{workOrderStage.partNumber}}								
								<dx-tooltip target="#runningPartNumber{{workOrderStage.id}}" showEvent="mouseenter" hideEvent="mouseleave" position="right">
									<div *dxTemplate="let data = data; of: 'content'">
										<img alt="{{workOrderStage.partNumber}}" width="300" src="{{workOrderStage.partImage}}" />
									</div>
								</dx-tooltip>
							</td>
							<td>{{workOrderStage.operationNumber}}</td>
							<td>{{workOrderStage.quantityCompleted}}</td>
							<td>{{workOrderStage.quantityRequired}}</td>
							<td>{{workOrderStage.assignedUserProfileFullName}}</td>
							<td><a href="#" (click)="changeWorkOrderStageOperatorPopup(workOrderStage.id)"><i class="fal fa-user-edit"></i></a></td>
							<td></td>
							<td></td>
						</tr>
						<tr *ngIf="machine.runningStages.length > 5">
							<th colspan="10"><a href="#" (click)="toggleMachineShowingAllRunning(machine.machineId)">Show {{(machinesShowingAllRunning.indexOf(machine.machineId) >= 0 ? 'Less' : 'All')}}</a></th>
						</tr>
						<tr>
							<th class="available" colspan="10">Available</th>
						</tr>
						<tr>
							<th scope="col">W/O</th>
							<th scope="col">Date Req.</th>
							<th scope="col">Part</th>
							<th scope="col">OP</th>
							<th scope="col">QReq.</th>
							<th scope="col">QComp.</th>
							<th scope="col">Operator</th>
							<th></th>
							<th>Priority</th>
							<th>Move</th>
						</tr>
						<tr *ngFor="let workOrderStage of machine.availableStages" [class]="workOrderStage.topFive === true || machinesShowingAllAvailable.indexOf(machine.machineId) >= 0 ? '' : 'hidden'">
							<th scope="row" [class]="workOrderStage.isPaused ? 'paused' : ''"><i *ngIf="workOrderStage.isPaused" class="fa-pause-circle fal me-2"></i><a href="/admin/workOrders/{{workOrderStage.workOrderId}}">{{workOrderStage.workOrderId}}</a></th>
							<td>{{workOrderStage.dateRequired.split('T')[0]}}</td>
							<td id="availablePartNumber{{workOrderStage.id}}">{{workOrderStage.partNumber}}
								<dx-tooltip target="#availablePartNumber{{workOrderStage.id}}" showEvent="mouseenter" hideEvent="mouseleave" position="right">
									<div *dxTemplate="let data = data; of: 'content'">
										<img alt="{{workOrderStage.partNumber}}" width="300" src="{{workOrderStage.partImage}}" />
									</div>
								</dx-tooltip>
							</td>
							<td>{{workOrderStage.operationNumber}}</td>
							<td>{{workOrderStage.quantityRequired}}</td>
							<td>{{workOrderStage.quantityComplete}}</td>
							<td>{{workOrderStage.assignedUserProfileFullName}}</td>
							<td><a href="#" (click)="changeWorkOrderStageOperatorPopup(workOrderStage.id)"><i class="fal fa-user-edit"></i></a></td>
							<td>
								<a href="#" (click)="changeWorkOrderStagePriority(workOrderStage.id, workOrderStage.priority + 1)"><i class="fal fa-arrow-up"></i></a>
								<strong class="priority">{{workOrderStage.priority}}</strong>
								<a href="#" (click)="changeWorkOrderStagePriority(workOrderStage.id, workOrderStage.priority - 1)"><i class="fal fa-arrow-down"></i></a>
							</td>
							<td><a href="#" (click)="changeWorkOrderStageMachinePopup(workOrderStage.id)"><i class="fal fa-arrows-alt"></i></a></td>
						</tr>
						<tr *ngIf="machine.availableStages.length > 5">
							<th colspan="10"><a href="#" (click)="toggleMachineShowingAllAvailable(machine.machineId)">Show {{(machinesShowingAllAvailable.indexOf(machine.machineId) >= 0 ? 'Less' : 'All')}}</a></th>
						</tr>
						<tr>
							<th class="pending" colspan="10">Pending</th>
						</tr>
						<tr>
							<th scope="col">W/O</th>
							<th scope="col">Date Req.</th>
							<th scope="col">Part</th>
							<th scope="col">OP</th>
							<th scope="col">QReq.</th>
							<th scope="col">QComp.</th>
							<th scope="col">Operator</th>
							<th></th>
							<th></th>
							<th></th>
						</tr>
						<tr *ngFor="let workOrderStage of machine.pendingStages" [class]="workOrderStage.topFive === true || machinesShowingAllPending.indexOf(machine.machineId) >= 0 ? '' : 'hidden'">
							<th scope="row" [class]="workOrderStage.isPaused ? 'paused' : ''"><i *ngIf="workOrderStage.isPaused" class="fa-pause-circle fal me-2"></i><a href="/admin/workOrders/{{workOrderStage.workOrderId}}">{{workOrderStage.workOrderId}}</a></th>
							<td>{{workOrderStage.dateRequired.split('T')[0]}}</td>
							<td id="pendingPartNumber{{workOrderStage.id}}">{{workOrderStage.partNumber}}
								<dx-tooltip target="#pendingPartNumber{{workOrderStage.id}}" showEvent="mouseenter" hideEvent="mouseleave" position="right">
									<div *dxTemplate="let data = data; of: 'content'">
										<img alt="{{workOrderStage.partNumber}}" width="300" src="{{workOrderStage.partImage}}" />
									</div>
								</dx-tooltip>
							</td>
							<td>{{workOrderStage.operationNumber}}</td>
							<td>{{workOrderStage.quantityRequired}}</td>
							<td>{{workOrderStage.quantityComplete}}</td>
							<td>{{workOrderStage.assignedUserProfileFullName}}</td>
							<td></td>
							<td></td>
							<td></td>
						</tr>
						<tr *ngIf="machine.pendingStages.length > 5">
							<th colspan="10"><a href="#" (click)="toggleMachineShowingAllPending(machine.machineId)">Show {{(machinesShowingAllPending.indexOf(machine.machineId) >= 0 ? 'Less' : 'All')}}</a></th>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
	<div class="col-4">
		<dx-data-grid class="data-grid" [dataSource]="userProfiles" [remoteOperations]="false" [allowColumnReordering]="false" [allowColumnResizing]="false" [rowAlternationEnabled]="true" [showBorders]="false">
			<dxo-paging [pageSize]="100"></dxo-paging>
			<dxo-pager [visible]="false" [showPageSizeSelector]="false" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
			<dxo-search-panel [visible]="false" [highlightCaseSensitive]="false"></dxo-search-panel>
			<dxo-group-panel [visible]="false"></dxo-group-panel>
			<dxo-grouping [autoExpandAll]="false"></dxo-grouping>
			<dxo-export [enabled]="false"></dxo-export>
			<dxi-column dataField="fullName" dataType="string" alignment="left" caption="Name"></dxi-column>
			<dxi-column dataField="shiftId" dataType="string" alignment="left" caption="Shift"><dxo-lookup [dataSource]="shifts" valueExpr="id" displayExpr="name"></dxo-lookup></dxi-column>
			<dxi-column dataField="jobTitle" dataType="string" alignment="left" caption="Job Title"></dxi-column>
			<dxi-column dataField="currentDepartment" dataType="string" alignment="left" caption="Curr. Dept."><dxo-lookup [dataSource]="departments" valueExpr="id" displayExpr="name"></dxo-lookup></dxi-column>
		</dx-data-grid>
	</div>
</div>
<dx-popup [width]="750" [height]="300" [showTitle]="true" [title]="popupTitle" [dragEnabled]="false" [closeOnOutsideClick]="false" [showCloseButton]="false" container=".dx-viewport" [(visible)]="popupVisible">
	<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="before" [options]="popupAddButtonOptions"></dxi-toolbar-item>
	<dxi-toolbar-item widget="dxButton" toolbar="bottom" location="after" [options]="popupCloseButtonOptions"></dxi-toolbar-item>
	<div *ngIf="popupTitle === 'Change Machine'">
		<dx-form class="m-3" [(formData)]="popupChangeWorkOrderStageMachine" [showColonAfterLabel]="true" labelLocation="top">
			<dxi-item dataField="machineId" editorType="dxSelectBox" [editorOptions]="{ items: machines, searchEnabled: true, displayExpr: displayMachineInDropdown, valueExpr:'machineId' }" [label]="{ text: 'Machine' }"></dxi-item>
		</dx-form>
	</div>
	<div *ngIf="popupTitle === 'Change Operator'">
		<dx-form class="m-3" [(formData)]="popupChangeWorkOrderStageOperator" [showColonAfterLabel]="true" labelLocation="top">
			<dxi-item dataField="userProfileId" editorType="dxSelectBox" [editorOptions]="{ items: userProfiles, searchEnabled: true, displayExpr:'fullName', valueExpr:'id' }" [label]="{ text: 'User' }"></dxi-item>
		</dx-form>
	</div>
	<div *ngIf="popupTitle === 'Change Production Status'">
		<dx-form class="m-3" [(formData)]="popupChangeMachineProductionStatus" [showColonAfterLabel]="true" labelLocation="top">
			<dxi-item dataField="productionStatus" editorType="dxSelectBox" [editorOptions]="{ items: productionStatuses, searchEnabled: true, displayExpr:'name', valueExpr:'id' }" [label]="{ text: 'Production Status' }"></dxi-item>
		</dx-form>
	</div>
</dx-popup>