<admin-header [primaryButtons]="headerPrimaryButtons" [secondaryButtons]="headerSecondaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<dx-data-grid id="grid" class="data-grid" [dataSource]="items" [remoteOperations]="false" [allowColumnReordering]="true" [allowColumnResizing]="true" [rowAlternationEnabled]="true" [showBorders]="true" (onExporting)="logExport()">
    <dxo-paging [pageSize]="100"></dxo-paging>
	<dxo-state-storing [enabled]="true" type="localStorage" storageKey="machineQuestionnaireMain"></dxo-state-storing>
    <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
    <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
	<dxo-export [enabled]="true"></dxo-export>
	<dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>
    <dxi-column dataField="createdAt" dataType="datetime" format="dd/MM/yyyy HH:mm:ss" alignment="left" caption="Date"></dxi-column>
	<dxi-column dataField="machineId" caption="Machine" dataType="string" alignment="left"><dxo-lookup [dataSource]="machines" valueExpr="id" [displayExpr]="displayMachineInDropdown"></dxo-lookup></dxi-column>
	<dxi-column dataField="userProfileId" caption="User" dataType="string" alignment="left"><dxo-lookup [dataSource]="userProfiles" valueExpr="id" displayExpr="fullName"></dxo-lookup></dxi-column>
	<dxi-column [showEditorAlways]='false' dataField="wearingCorrectPPE" alignment="left" caption="Wearing Correct PPE" [customizeText]="boolYesNo"></dxi-column>
	<dxi-column [showEditorAlways]='false' dataField="areaSafe" alignment="left" caption="Area is Safe" [customizeText]="boolYesNo"></dxi-column>
	<dxi-column [showEditorAlways]='false' dataField="areaClean" alignment="left" caption="Area is Clean and Tidy" [customizeText]="boolYesNo"></dxi-column>
	<dxi-column [showEditorAlways]='false' dataField="haveSOP" alignment="left" caption="You have SOP" [customizeText]="boolYesNo"></dxi-column>
	<dxi-column [showEditorAlways]='false' dataField="haveFirstOff" alignment="left" caption="You have First Off" [customizeText]="boolYesNo"></dxi-column>
	<dxi-column [showEditorAlways]='false' dataField="haveStageSample" alignment="left" caption="You have Stage Sample" [customizeText]="boolYesNo"></dxi-column>
</dx-data-grid>
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#grid' }" [(visible)]="gridLoading" [showIndicator]="true" [showPane]="true" [shading]="true"></dx-load-panel>