import { Model } from "@interfaces/model.interface";

export interface Asset extends Model {
	assetTypeId?: number;
	make: string;
	model: string;
	name: string;
	purchaseAmount: number;
	purchaseDate: Date;
	serialNumber: string;
	warranty: number;
}

export class Asset implements Asset {	
	make = "";
	model = "";
	name = "";
	purchaseAmount = 0.00;
	purchaseDate = new Date;
	serialNumber = "";
	warranty = 0;

	constructor(data?: Partial<Asset>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}