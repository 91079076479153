import { Model } from "@interfaces/model.interface";

export interface AssetType extends Model {
	name: string;
}

export class AssetType implements AssetType {	
	constructor(data?: Partial<AssetType>) {
		if (data) {
			for (const key of Object.keys(data)) {
				(this as any)[key] = (data as any)[key];
			}
		}
	}
}