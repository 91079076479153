<admin-header [primaryButtons]="headerPrimaryButtons" [secondaryButtons]="headerSecondaryButtons" [title]="title" (eventFired)="headerButtonClick($event)"></admin-header>
<dx-data-grid id="grid" class="data-grid" [dataSource]="items" [remoteOperations]="false" [allowColumnReordering]="true" [allowColumnResizing]="true" [rowAlternationEnabled]="true" [showBorders]="true" (onExporting)="logExport()">
    <dxo-paging [pageSize]="100"></dxo-paging>
	<dxo-state-storing [enabled]="true" type="localStorage" storageKey="salesMain"></dxo-state-storing>
    <dxo-pager [visible]="true" [showPageSizeSelector]="true" [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
    <dxo-search-panel [visible]="true" [highlightCaseSensitive]="true"></dxo-search-panel>
    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
	<dxo-export [enabled]="true"></dxo-export>
	<dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>
	<dxi-column type="buttons">
        <dxi-button hint="View" icon="fal fa-eye" [onClick]="viewRow"></dxi-button>
		<dxi-button *ngIf="editEnabled" hint="Edit" icon="fal fa-pencil" [onClick]="editRow"></dxi-button>
		<dxi-button *ngIf="deleteEnabled" icon="fal fa-trash" [onClick]="deleteRow"></dxi-button>
    </dxi-column>
    <dxi-column dataField="id" alignment="left" sortOrder="desc"></dxi-column>
	<dxi-column dataField="assignedToId">
		<dxo-lookup [dataSource]="users" valueExpr="id" displayExpr="fullName"></dxo-lookup>
	</dxi-column>
	<dxi-column dataField="client.name" caption="Customer Name"></dxi-column>
	<dxi-column dataField="salesEnquiryStatus" caption="Status">
		<dxo-lookup [dataSource]="statuses" valueExpr="id" displayExpr="name"></dxo-lookup>
	</dxi-column>
	<dxi-column dataField="enquiryLines" alignment="right"></dxi-column>
	<dxi-column dataField="enquiryValue" alignment="right" [format]="{ style: 'currency', currency: 'GBP', useGrouping: true, minimumSignificantDigits: 3 }"></dxi-column>
	<dxi-column dataField="enquiryQuantity" alignment="right"></dxi-column>
	<dxi-column dataField="createdAt" dataType="date" format="dd/MM/yyyy"></dxi-column>
	<dxi-column [showEditorAlways]='false' dataField="approved" alignment="left" [customizeText]="boolYesNo"></dxi-column>
	<dxi-column [showEditorAlways]='false' dataField="quoted" alignment="left" [customizeText]="boolYesNo"></dxi-column>
	<dxi-column dataField="feasibilityQuestionnaireCompletedOn" dataType="date" format="dd/MM/yyyy" caption="Feasibility Completed On"></dxi-column>
	<dxi-column dataField="feasibilityQuestionnaireCompletedById" caption="Feasibility Completed By">
		<dxo-lookup [dataSource]="users" valueExpr="id" displayExpr="fullName"></dxo-lookup>
	</dxi-column>
	<dxi-column dataField="productionSignedOffById" alignment="left" [customizeText]="signedOffYesNo" caption="Signed Off by Production"></dxi-column>
	<dxi-column dataField="productionSignedOffOn" dataType="date" format="dd/MM/yyyy" caption="Production Sign Off On"></dxi-column>
	<dxi-column dataField="productionSignedOffById" caption="Production Signed Off By">
		<dxo-lookup [dataSource]="users" valueExpr="id" displayExpr="fullName"></dxo-lookup>
	</dxi-column>
	<dxi-column dataField="qualitySignedOffById" alignment="left" [customizeText]="signedOffYesNo" caption="Signed Off by Quality"></dxi-column>
	<dxi-column dataField="qualitySignedOffOn" dataType="date" format="dd/MM/yyyy" caption="Quality Sign Off On"></dxi-column>
	<dxi-column dataField="qualitySignedOffById" caption="Quality Signed Off By">
		<dxo-lookup [dataSource]="users" valueExpr="id" displayExpr="fullName"></dxo-lookup>
	</dxi-column>
	<dxi-column dataField="lineItems" dataType="string" [visible]="false"></dxi-column>
	<dxo-master-detail [enabled]="true" template="detail"></dxo-master-detail>
	<div *dxTemplate="let sale of 'detail'">
	  <div class="master-detail-caption">{{
		"Line Items for Sales Enquiry #" + sale.data.id
	  }}</div>
	  <salesEnquiryGridDetails [lineItemData]="sale.data.lineItems"></salesEnquiryGridDetails>
	</div>
</dx-data-grid>
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#grid' }" [(visible)]="gridLoading" [showIndicator]="true" [showPane]="true" [shading]="true"></dx-load-panel>