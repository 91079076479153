import { Injectable, Injector } from "@angular/core";
import { environment } from "@environments/environment";
import { Service } from "@services/service";

@Injectable()
export class SalesOrderPartService extends Service {
	constructor(injector: Injector) {
		super("SalesOrderPart", injector);
	}

	// API
	generateAndDownloadIncompleteSalesOrderPartReportPdf() {
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/IncompleteSalesOrderPartsReportPdf`, { responseType: "blob" as "json"} );
	}

	getForSalesOrder(id: number){
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/ForSalesOrder/${id}`);
	}

	outstanding(){
		return this.http.get(`${environment.server.apiURL}/${this.endpoint}/Outstanding/`);
	}
}